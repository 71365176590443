import React from 'react';
import { node } from 'prop-types';

import { Text } from '@s7/ui-kit';

import cx from './GroupAccount.sss';

export const Rule = React.memo(function Rule({ icon, children }) {
    return (
        <div className={cx('rule')}>
            <span className={cx('rule_icon')}>{icon}</span>
            <span className={cx('rule_text')}>
                <Text as="span">{children}</Text>
            </span>
        </div>
    );
});

Rule.propTypes = {
    children: node,
    icon: node,
};
