import React from 'react';
import { useSelector } from 'react-redux';
import { useWindowSize } from 'hooks/useWindowSize';
import {
    arrayOf,
    bool,
    instanceOf,
    node,
    object,
    oneOfType,
    shape,
    string,
} from 'prop-types';
import { fieldInputPropTypes, fieldMetaPropTypes } from 'types';

import { DateField as DateFieldBase } from '@s7/ui-kit';

import { getLocale } from '../../store/modules/languages';

const DateField = ({
    clearInvalidDateOnDatepickerOpen,
    disabledDays,
    disableOpenDatepickerWhileInvalidDate,
    input,
    isBirthday,
    message,
    meta,
    withRange,
    inputProps,
    ...rest
}) => {
    const invalid = meta.error && (meta.touched || meta.submitFailed);
    const locale = useSelector(getLocale);
    const { isMobile } = useWindowSize();

    return (
        <DateFieldBase
            maxDate="31.12.2100"
            minDate="01.01.2000"
            block
            {...input}
            {...{
                clearInvalidDateOnDatepickerOpen,
                disabledDays,
                disableOpenDatepickerWhileInvalidDate,
                invalid,
                isBirthday,
                locale,
                selectable: true,
                tooltip: invalid ? meta.error || message : undefined,
                withRange,
            }}
            {...(isMobile ? { autoComplete: 'off' } : {})}
            {...rest}
            classes={{
                ...rest.classes,
                popover: rest.classes?.popover,
            }}
            inputProps={{
                ...inputProps,
                ...input.inputProps,
                inputMode: 'numeric',
            }}
        />
    );
};

const disableDaysObjectPropType = shape({
    after: oneOfType([string, instanceOf(Date)]),
    before: oneOfType([string, instanceOf(Date)]),
    from: oneOfType([string, instanceOf(Date)]),
    to: oneOfType([string, instanceOf(Date)]),
});

DateField.propTypes = {
    clearInvalidDateOnDatepickerOpen: bool,
    disabledDays: oneOfType([
        string,
        instanceOf(Date),
        disableDaysObjectPropType,
        arrayOf(
            oneOfType([string, instanceOf(Date), disableDaysObjectPropType]),
        ),
    ]),
    disableOpenDatepickerWhileInvalidDate: bool,
    input: shape(fieldInputPropTypes),
    inputProps: object,
    isBirthday: bool,
    message: node,
    meta: shape(fieldMetaPropTypes),
    selectable: bool,
    withRange: bool,
};

DateField.defaultProps = {
    input: {},
    meta: {},
};

const DateFieldMemo = React.memo(DateField);

export { DateFieldMemo as DateField };
