import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { PROFILE_ANCHORS } from 'components/ProfileView/constants';
import { SectionItem } from 'components/Section';
import { DATE_FORMATS } from 'consts/dateFormats';
import PersonalDataEdit from 'containers/PersonalDataEdit';
import { AnalyticsContext } from 'contexts';
import moment from 'moment';
import {
    bool,
    func,
    node,
    object,
    oneOf,
    oneOfType,
    shape,
    string,
} from 'prop-types';
import { compose } from 'ramda';
import { Dictionary, Paper, PaperContent, PaperTitle } from 'shared';
import { getLocale } from 'store/modules/languages';
import {
    getBirthdate,
    getCustomerValue,
    getFullEngName,
    getFullRusName,
    getGender,
    getIsEdit,
    getPersonalNames,
    save,
    setEdit,
    update,
} from 'store/modules/profile';
import LabelWithText from 'ui-kit/atoms/LabelWithText';
import Sex from 'ui-kit/atoms/Sex';
import UserData from 'ui-kit/molecules/UserData';
import { normalizeName } from 'utils/format';
import { getLocationHash } from 'utils/hash';
import { dictKeys, getTestIdGetter } from 'utils/testIds';

import { Link, StatusMessage, Text } from '@s7/ui-kit';

import { setFooterChatWidgetOpen } from '../../store/modules/shared';

const DD_MM_YYYY = DATE_FORMATS.DD.MM.YYYY_dot;
const { ll } = DATE_FORMATS;

const TEXTS = {
    title: <Dictionary text="com.profile.personal.title" />,
};

const getTestId = getTestIdGetter(dictKeys.PROFILE_USER_INFO);

const mapStateToProps = state => {
    const locale = getLocale(state);
    const birthdate = moment(getBirthdate(state), DD_MM_YYYY);
    const gender = getGender(state);

    const personal = {
        birthdate: birthdate.isValid() ? (
            birthdate.format(ll)
        ) : (
            <Dictionary text="com.profile.empty" />
        ),
        gender,
        nameEng: getFullEngName(state) || getFullRusName(state),
        nameRus: locale === 'ru' ? getFullRusName(state) : '',
    };

    const initialValues = {
        ...getPersonalNames(state),
        birthdate: birthdate.isValid() ? birthdate.format(DD_MM_YYYY) : '',
        gender,
    };

    return {
        initialValues,
        isEdit: getIsEdit('personalData', state),
        isShortUser: getCustomerValue(state) === 'SHORT',
        locale,
        personal,
    };
};

const mapDispatchToProps = dispatch => ({
    onSave: payload => {
        dispatch(
            save({
                id: 'personalData',
                ...payload,
            }),
        );
    },
    onUpdate: payload => {
        dispatch(
            update({
                id: 'personalData',
                ...payload,
            }),
        );
    },
    setEdit: status =>
        dispatch(
            setEdit({
                id: 'personalData',
                status,
            }),
        ),
    openChat: () => dispatch(setFooterChatWidgetOpen(true)),
});

const TEST_IDS = {
    birthday: getTestId('birthday'),
    editButton: getTestId('editButton'),
    gender: getTestId('gender'),
    tooltip: getTestId('tooltip'),
    tooltipLink: getTestId('tooltipLink'),
};

const USER_DATA_TEST_IDS = {
    cyrillicName: getTestId('firstNameRU'),
    latinName: getTestId('firstNameEN'),
};

class PersonalDataContainer extends PureComponent {
    static propTypes = {
        handleSubmit: func,
        initialValues: object,
        isEdit: bool,
        isShortUser: bool,
        locale: string,
        onReset: func,
        onSave: func,
        onUpdate: func,
        openChat: func,
        personal: shape({
            birthdate: oneOfType([node, string]),
            gender: oneOf(['', 'MALE', 'FEMALE']),
            nameEng: string,
            nameRus: string,
        }),
        pristine: bool,
        reset: func,
        setEdit: func,
    };

    static defaultProps = {
        isEdit: false,
        onReset: () => {},
        onSave: () => {},
        onUpdate: () => {},
        openChat: () => {},
        pristine: false,
        setEdit: () => {},
    };

    componentDidMount() {
        if (getLocationHash() === PROFILE_ANCHORS.personal) {
            this.props.setEdit(true);
        }
    }

    handleEdit = () => {
        const { sendEvent } = this.context;

        sendEvent({
            event: 'edit_info',
            id: 'personal_data',
            result: 'initial_click',
            step: 'profile_info',
        });

        this.props.setEdit(true);
    };

    handleClose = () => {
        if (this.props.pristine) {
            this.props.setEdit(false);
        }
    };

    renderView() {
        const { nameRus, nameEng, birthdate, gender } = this.props.personal;
        const normalizedNameRu = normalizeName(nameRus, {
            lang: 'ru',
            withSpaces: true,
        });
        const normalizedNameEng = normalizeName(nameEng, {
            lang: 'en',
            withSpaces: true,
        });

        const userData = {
            mobileType: nameRus && nameEng ? normalizedNameRu : null,
            name: normalizedNameEng,
            type: nameRus && nameEng ? `/ ${normalizedNameRu}` : null,
        };

        return (
            <UserData
                {...userData}
                testIds={USER_DATA_TEST_IDS}
                flexHeading
                revertheadingMobile
            >
                <LabelWithText
                    label={
                        <Dictionary text="com.profile.personal.view.birthdate" />
                    }
                >
                    <Text
                        as="div"
                        weight="semibold"
                        noWrap
                        {...TEST_IDS.birthday}
                    >
                        {birthdate || <Dictionary text="com.profile.empty" />}
                    </Text>
                </LabelWithText>
                <LabelWithText
                    label={<Dictionary text="com.profile.personal.view.sex" />}
                >
                    <Text as="div" weight="semibold" noWrap>
                        {gender ? (
                            <Sex
                                text={[
                                    <Dictionary text="com.profile.sex.male" />,
                                    <Dictionary text="com.profile.sex.female" />,
                                ]}
                                sex={gender.toLowerCase()}
                                size="small"
                                testId={TEST_IDS.gender}
                            />
                        ) : (
                            <Dictionary
                                text="com.profile.emptym"
                                {...TEST_IDS.gender}
                            />
                        )}
                    </Text>
                </LabelWithText>
            </UserData>
        );
    }

    renderTooltip = () => {
        return (
            <>
                <StatusMessage {...TEST_IDS.tooltip}>
                    <Text as="span">
                        <Dictionary
                            text="com.profile.personal.tooltip.head"
                            html
                        />
                        <Link
                            view="info"
                            onClick={this.props.openChat}
                            {...TEST_IDS.tooltipLink}
                        >
                            <Dictionary text="com.profile.personal.tooltip.link" />
                        </Link>
                        <Dictionary text="com.profile.personal.tooltip.tail" />
                    </Text>
                </StatusMessage>
                <div style={{ height: '24px' }} />
            </>
        );
    };

    renderEdit() {
        const {
            onSave,
            onUpdate,
            pristine,
            onReset,
            initialValues,
            locale,
            isShortUser,
            isEdit,
        } = this.props;
        const hasCyrillic = locale === 'ru';
        const hasLatinName = Boolean(
            initialValues &&
                initialValues.firstname_en &&
                initialValues.lastname_en,
        );
        const hasCyrillicName = Boolean(
            initialValues &&
                initialValues.firstname_ru &&
                initialValues.lastname_ru,
        );

        const handleSave = e => {
            const { sendEvent } = this.context;

            sendEvent({
                event: 'edit_info',
                id: 'personal_data',
                result: 'add_click',
                step: 'profile_info',
            });
            onSave(e);
        };

        return (
            <PersonalDataEdit
                hasCyrillicName={hasCyrillicName}
                hasCyrlic={hasCyrillic}
                hasLatinName={hasLatinName}
                initialValues={initialValues}
                isShortUser={isShortUser}
                pristine={pristine}
                setEdit={this.props.setEdit}
                tooltip={isEdit && !isShortUser ? this.renderTooltip() : null}
                onReset={onReset}
                onSave={isShortUser ? handleSave : onUpdate}
            />
        );
    }

    render() {
        const { isEdit } = this.props;

        const contentLayout = this.renderView();

        return (
            <Paper>
                <PaperTitle onClick={this.handleEdit}>{TEXTS.title}</PaperTitle>
                <PaperContent>
                    <SectionItem
                        edit={this.renderEdit()}
                        isEdit={isEdit}
                        testIds={TEST_IDS}
                        isPaper
                        onEdit={this.handleEdit}
                    >
                        {contentLayout}
                    </SectionItem>
                </PaperContent>
            </Paper>
        );
    }
}

PersonalDataContainer.contextType = AnalyticsContext;

export default compose(connect(mapStateToProps, mapDispatchToProps))(
    PersonalDataContainer,
);
