import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PROFILE_EMPLOYEE_ROLES } from 'consts/profile';
import { func, string } from 'prop-types';
import { Dictionary } from 'shared';
import { deleteB2bProfile, getEmployeeRole } from 'store/modules/profile';
import LabelWithText from 'ui-kit/atoms/LabelWithText';
import UserData from 'ui-kit/molecules/UserData';

import { RoundButton, Text } from '@s7/ui-kit';
import { CloseSmallLinear } from '@s7/ui-kit/icons';

import cx from './MyCompanyInfo.sss';

const TEXTS = {
    detach: <Dictionary text="com.profile.mycompany.detach" />,
    position: <Dictionary text="com.profile.mycompany.position" />,
    [PROFILE_EMPLOYEE_ROLES.ADMIN]: (
        <Dictionary text="com.profile.mycompany.position.admin" />
    ),
    [PROFILE_EMPLOYEE_ROLES.PASSENGER]: (
        <Dictionary text="com.profile.mycompany.position.passenger" />
    ),
    [PROFILE_EMPLOYEE_ROLES.TRAVEL_MANAGER]: (
        <Dictionary text="com.profile.mycompany.position.travelManager" />
    ),
    [PROFILE_EMPLOYEE_ROLES.EMPLOYEE]: (
        <Dictionary text="com.profile.mycompany.position.user" />
    ),
};

const MyCompanyInfo = ({ name }) => {
    const dispatch = useDispatch();
    const role = useSelector(getEmployeeRole);
    const isAdmin = role === 'ADMIN';
    const handleDetach = useCallback(() => {
        dispatch(deleteB2bProfile());
    }, [dispatch]);

    return (
        <>
            <UserData>
                <LabelWithText>
                    <Text>{name}</Text>
                </LabelWithText>
                {role && (
                    <LabelWithText label={TEXTS.position}>
                        <Text>{TEXTS[role]}</Text>
                    </LabelWithText>
                )}
            </UserData>

            {!isAdmin && (
                <div className={cx('detach')}>
                    <RoundButton
                        direction="right"
                        size="xs"
                        text={TEXTS.detach}
                        view="destructive"
                        onClick={handleDetach}
                    >
                        <CloseSmallLinear />
                    </RoundButton>
                </div>
            )}
        </>
    );
};

MyCompanyInfo.propTypes = {
    handleDetach: func,
    name: string,
};

export default MyCompanyInfo;
