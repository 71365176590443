import React, { useEffect, useRef, useState } from 'react';
import { useGoogleAnalyticContext } from 'contexts';
import { bool, func, node, number, object, shape, string } from 'prop-types';
import { Dictionary } from 'shared';

import { Link, Loader, OnBoardingTooltip, Text } from '@s7/ui-kit';
import {
    CheckLinear,
    CloseSmallLinear,
    DotsVertical,
    TimeLinear,
} from '@s7/ui-kit/icons';

import cx from './GroupMember.sss';
import { ActionButtons } from './shared';

import { memberStatus } from '../../data/membership';
import Icon from '../Icon';

const popoverProps = { style: { zIndex: 1 } };

const statusTextByType = {
    [memberStatus.ACTIVE]: (
        <Dictionary text="com.profile.family-account.member.status.success" />
    ),
    [memberStatus.PENDING]: (
        <Dictionary text="com.profile.family-account.member.status.pending" />
    ),
    [memberStatus.DELETION_PENDING]: (
        <Dictionary text="com.profile.family-account.member.status.deletion-pending" />
    ),
    remove: <Dictionary text="com.profile.family-account.remove.confirm" />,
};

const iconsByType = {
    [memberStatus.ACTIVE]: <CheckLinear color="G600" size="md" block />,
    [memberStatus.PENDING]: <TimeLinear block />,
    [memberStatus.DELETION_PENDING]: <TimeLinear block />,
    remove: <CloseSmallLinear block />,
};

const noop = () => {};

export const GroupMember = React.memo(function Member({
    asTitle,
    id,
    initials,
    memberDeleting = {},
    isMobile,
    isNextActive,
    isPrevActive,
    name,
    openRemovalModal,
    removalTooltipProps,
    removeUserFromGroup,
    startedAt,
    status,
    membersCount,
    getTestId,
    isAvailableActions,
    nameComponent: Name,
    statusComponent: Status,
}) {
    const isActive = status === memberStatus.ACTIVE;
    const isDeleting = memberDeleting.status && memberDeleting.id === id;
    const [isShownRemoveLabel, setIsShowRemoveLabel] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);
    const rootRef = useRef(null);
    const { sendMyProfileGroupEvent } = useGoogleAnalyticContext();

    useEffect(() => {
        if (Status) {
            sendMyProfileGroupEvent({
                eventAction: 'Invite_Button_Show',
                eventLabel: `QTY:${membersCount}`,
            });
        }
        // ивент нужен только при первой отрисовке
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setIsShowRemoveLabel(false);
    }, [status]);

    const handleClick = () => {
        if (!isDeleting && !isMobile && !asTitle && isActive) {
            if (isAvailableActions) {
                setIsShowRemoveLabel(!isShownRemoveLabel);
            }

            if (isShownRemoveLabel) {
                removalTooltipProps.onClose();
            }
        }
    };

    const handleClickStatus = e => {
        if (isMobile && !isDeleting) {
            if (isActive) {
                openRemovalModal(id);
                sendMyProfileGroupEvent({ eventAction: 'Сlick_GoToDelete' });
            }
        } else if (isShownRemoveLabel) {
            e.stopPropagation();
        }
    };

    const handleChangeRemoveTooltipState = () => {
        removalTooltipProps.onClick(id);
    };

    const handleRemoveLabelClick = () => {
        sendMyProfileGroupEvent({ eventAction: 'Сlick_GoToDelete' });
    };

    const onConfirmDelete = () => {
        if (!isMobile) {
            handleChangeRemoveTooltipState();
        }

        removeUserFromGroup({ memberId: id });
        sendMyProfileGroupEvent({ eventAction: 'Сlick_DeleteConfirm' });
    };

    const onClickExpand = () => {
        setIsExpanded(!isExpanded);
    };

    const labelStatus = isShownRemoveLabel ? 'remove' : status;

    const getMobileRightIcon = () => {
        if (isDeleting) {
            return (
                <span className={cx('option_icon')}>
                    <Loader color="R600" size="x-small" />
                </span>
            );
        }

        if (
            status === memberStatus.PENDING ||
            status === memberStatus.DELETION_PENDING
        ) {
            return (
                <button
                    className={cx('option_icon', {
                        option_icon_expanded: isExpanded,
                    })}
                    type="button"
                    onClick={onClickExpand}
                >
                    <DotsVertical />
                </button>
            );
        }

        return (
            <button
                className={cx('option_icon')}
                type="button"
                onClick={handleClickStatus}
            >
                <CloseSmallLinear block />
            </button>
        );
    };

    const renderStatus = () => {
        return (
            <span
                className={cx('status_label', {
                    status_label_isRemove: isShownRemoveLabel,
                    status_label_isRemoving: isDeleting,
                })}
            >
                {isDeleting || isShownRemoveLabel ? (
                    <Link
                        as="button"
                        icon={iconsByType[labelStatus]}
                        isLoading={isDeleting}
                        type="button"
                        view="destructive"
                        onClick={
                            isShownRemoveLabel
                                ? handleRemoveLabelClick
                                : undefined
                        }
                        {...getTestId('deleteOpen')}
                    >
                        {statusTextByType[labelStatus]}
                    </Link>
                ) : (
                    <>
                        <span className={cx('status_icon')}>
                            {iconsByType[labelStatus]}
                        </span>
                        <Text as="span" {...getTestId('memberStatus')}>
                            {statusTextByType[labelStatus]}
                        </Text>
                    </>
                )}
            </span>
        );
    };

    const renderStatusButton = () => (
        <div
            aria-hidden="true"
            className={cx('status')}
            onClick={handleClickStatus}
        >
            {isShownRemoveLabel ? (
                <OnBoardingTooltip
                    actions={
                        <ActionButtons
                            {...removalTooltipProps}
                            cancelLabel={removalTooltipProps.cancelLabel}
                            cancelProps={getTestId('deleteCancel')}
                            confirmLabel={removalTooltipProps.confirmLabel}
                            confirmProps={getTestId('deleteConfirm')}
                            confirmTheme={removalTooltipProps.confirmTheme}
                            onCancel={removalTooltipProps.onClose}
                            onConfirm={onConfirmDelete}
                        />
                    }
                    opened={
                        removalTooltipProps.opened &&
                        removalTooltipProps.id === id
                    }
                    content={removalTooltipProps.content}
                    direction="topRight"
                    disableHover={removalTooltipProps.disableHover}
                    id="membership-status"
                    popoverProps={popoverProps}
                    title={removalTooltipProps.title}
                    view={removalTooltipProps.view}
                    onClick={isDeleting ? noop : handleChangeRemoveTooltipState}
                    onClose={removalTooltipProps.onClose}
                >
                    {renderStatus()}
                </OnBoardingTooltip>
            ) : (
                renderStatus()
            )}
        </div>
    );

    const renderBody = () => {
        return (
            <>
                {Name ||
                    (isMobile ? (
                        <div className={cx('name')}>
                            <Text as="div" weight="semibold">
                                <div
                                    className={cx('ellipsis')}
                                    {...getTestId('memberName')}
                                >
                                    {name}
                                </div>
                            </Text>
                        </div>
                    ) : (
                        <span className={cx('name')}>
                            <Text as="span" {...getTestId('memberName')}>
                                {name}
                            </Text>
                        </span>
                    ))}

                <span className={cx('date')}>
                    <Text
                        as="span"
                        color={isMobile && 'N100'}
                        {...getTestId('memberDate')}
                    >
                        {startedAt}
                    </Text>
                </span>

                {Status ||
                    ((!isMobile || (isMobile && isExpanded)) && (
                        <div className={cx('status_wrap')}>
                            {renderStatusButton()}
                        </div>
                    ))}
            </>
        );
    };

    return (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/interactive-supports-focus
        <div
            ref={rootRef}
            className={cx('root', {
                disabled: !isAvailableActions,
                root_expanded: isExpanded,
                root_status_active: status === memberStatus.ACTIVE,
                root_status_deletionPending:
                    status === memberStatus.DELETION_PENDING,
                root_status_pending: status === memberStatus.PENDING,
                root_title: asTitle,
            })}
            role="button"
            onClick={isAvailableActions ? handleClick : undefined}
        >
            {!isMobile && isActive && (
                <>
                    {(isPrevActive || isNextActive) && (
                        <span className={cx('icon_placeholder')}>
                            {isPrevActive && (
                                <span className={cx('prevActive')} />
                            )}
                            {isNextActive && (
                                <span className={cx('nextActive')} />
                            )}
                        </span>
                    )}
                </>
            )}

            <span
                className={cx('icon', {
                    [`icon_status_${status}`]: status,
                })}
            >
                {asTitle ? (
                    <Icon height={12} icon="group" width={18} newIcon />
                ) : (
                    <Text color="W100">{initials}</Text>
                )}
            </span>

            {isMobile ? (
                <>
                    <div className={cx('content')}>{renderBody()}</div>
                    {!asTitle && isAvailableActions && getMobileRightIcon()}
                </>
            ) : (
                renderBody()
            )}
        </div>
    );
});

GroupMember.propTypes = {
    asTitle: bool,
    date: string,
    getTestId: func,
    id: string,
    initials: string,
    isActive: bool,
    isAvailableActions: bool,
    isMobile: bool,
    isNextActive: bool,
    isPrevActive: bool,
    memberDeleting: shape({
        id: string,
        status: bool,
    }),
    membersCount: number,
    name: string,
    nameComponent: node,
    openRemovalModal: func,
    removalTooltipProps: object,
    removeUserFromGroup: func,
    startedAt: string,
    status: string,
    statusComponent: node,
};
