import React, { Children } from 'react';
import { node, string } from 'prop-types';

import cx from './RadioGroup.sss';

export const RadioGroup = ({ children, className }) => {
    return (
        <div className={cx('root', className)}>
            {Children.map(children, item => (
                <div className={cx('item')}>{item}</div>
            ))}
        </div>
    );
};

RadioGroup.propTypes = {
    children: node,
    className: string,
};
