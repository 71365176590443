import React from 'react';
import { func } from 'prop-types';

import image from './images/Cargo.png';
import imageMobile from './images/CargoMobile.png';
import modalImage from './images/modal.png';

import { Card } from '../Card';

export const CardCargo = ({ onClick }) => {
    return (
        <Card
            cardType="cargo"
            conditions={['com.profile.pets.page.card.cargo.condition1']}
            image={image}
            imageMobile={imageMobile}
            modalImage={modalImage}
            title="com.profile.pets.page.card.cargo.title"
            onClick={onClick}
        />
    );
};

CardCargo.propTypes = {
    onClick: func.isRequired,
};
