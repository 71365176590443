import React, { Component } from 'react';

import AppSSR from '../containers/AppSSR';
import { SessionService } from '../services';

const getDisplayName = Cmp => Cmp.displayName || Cmp.name || 'Component';

const checkAuth = () => {
    const { href } = window.location;

    return !SessionService.isAuth() && href.indexOf('/login') < 0;
};

export default function withAuth(WrappedComponent) {
    return class extends Component {
        static displayName = `withAuth${getDisplayName(WrappedComponent)}`;

        static async getInitialProps(ctx) {
            let Unlogged = false;
            const componentProps = WrappedComponent.getInitialProps
                ? await WrappedComponent.getInitialProps(ctx)
                : {};

            if (
                ctx &&
                ctx.req &&
                !SessionService.isAuth(ctx.req.cookies) &&
                ctx.req.method !== 'POST'
            ) {
                Unlogged = true;
            }

            return { ...componentProps, Unlogged };
        }

        render() {
            if (checkAuth()) {
                return <AppSSR {...this.props} Unlogged />;
            }

            return <WrappedComponent {...this.props} />;
        }
    };
}
