import React, { useCallback } from 'react';
import Modal from 'components/Modal';
import { ACTION_STATUS_PENDING } from 'consts';
import { arrayOf, func, shape, string } from 'prop-types';

import { Button, Link, Text, Title } from '@s7/ui-kit';
import { Pencil, Trash } from '@s7/ui-kit/icons';

import Contacts from './Contacts';
import Documents from './Documents';
import Loyalty from './Loyalty';
import cx from './Mobile.sss';
import TravelerModal from './Modal';

import { SECTIONS, testIds, TEXTS } from '../../constants';

const Mobile = ({
    birthday,
    contacts,
    documents,
    gender,
    loyaltyCards,
    names: { firstName, lastName },
    onClose,
    onContactsRemove,
    onDocumentRemove,
    onEdit,
    onLoyaltyRemove,
    onRemove,
    onRemoveCancel,
    onRemoveConfirmation,
    statusRemove,
}) => {
    const handleEditPersonal = useCallback(() => {
        onEdit({ section: SECTIONS.personal });
    }, [onEdit]);

    return (
        <TravelerModal title={TEXTS.back} onBack={onClose}>
            <div className={cx('heading')}>
                <div className={cx('person')}>
                    <div className={cx('name')}>
                        <span className={cx('name_part')}>
                            <Title
                                as="span"
                                level={3}
                                ellipsis
                                {...testIds.mobile.personal.firstName}
                            >
                                {firstName}
                            </Title>
                        </span>
                        {lastName && (
                            <span className={cx('name_part')}>
                                <Title
                                    as="span"
                                    level={3}
                                    ellipsis
                                    {...testIds.mobile.personal.lastName}
                                >
                                    {lastName}
                                </Title>
                            </span>
                        )}
                    </div>

                    <div className={cx('info')}>
                        <Text size="l" {...testIds.mobile.personal.birthday}>
                            {birthday}
                        </Text>
                        <span className={cx('dot')} />
                        <Text size="l" {...testIds.mobile.personal.gender}>
                            {TEXTS.gender.title}: {TEXTS.gender[gender]}
                        </Text>
                    </div>

                    <Link
                        as="button"
                        icon={<Pencil block />}
                        type="button"
                        view="b2c"
                        onClick={handleEditPersonal}
                        {...testIds.mobile.personal.edit}
                    >
                        {TEXTS.edit}
                    </Link>
                </div>
            </div>

            <div className={cx('item')}>
                <Contacts
                    contacts={contacts}
                    onEdit={onEdit}
                    onRemove={onContactsRemove}
                />
            </div>

            <div className={cx('item')}>
                <Documents
                    documents={documents}
                    onEdit={onEdit}
                    onRemove={onDocumentRemove}
                />
            </div>

            <div className={cx('item')}>
                <Loyalty
                    loyaltyCards={loyaltyCards}
                    onEdit={onEdit}
                    onRemove={onLoyaltyRemove}
                />
            </div>

            <div className={cx('remove')}>
                <Link
                    as="button"
                    icon={<Trash block />}
                    type="button"
                    view="destructive"
                    onClick={onRemoveConfirmation}
                    {...testIds.mobile.remove}
                >
                    {TEXTS.remove}
                </Link>
            </div>

            {statusRemove && (
                <Modal
                    dsModalProps={{
                        actions: [
                            <Button
                                disabled={
                                    statusRemove === ACTION_STATUS_PENDING
                                }
                                loading={statusRemove === ACTION_STATUS_PENDING}
                                size="medium"
                                theme="destructive"
                                block
                                onClick={onRemove}
                                {...testIds.mobile.removeConfirm}
                            >
                                {TEXTS.removeConfirm}
                            </Button>,
                            <Button
                                disabled={
                                    statusRemove === ACTION_STATUS_PENDING
                                }
                                size="medium"
                                theme="secondary"
                                block
                                onClick={onRemoveCancel}
                                {...testIds.mobile.removeCancel}
                            >
                                {TEXTS.cancel}
                            </Button>,
                        ],
                        buttonsOrientation: 'vertical',
                        mobilePosition: 'center',
                        onClose: onRemoveCancel,
                        onlyButtons: true,
                        size: 'small',
                        titleComponent: (
                            <Title level={3}>{TEXTS.removeTitle}</Title>
                        ),
                        width: 288,
                    }}
                    ds
                />
            )}
        </TravelerModal>
    );
};

Mobile.propTypes = {
    birthday: string,
    contacts: arrayOf(
        shape({
            type: string,
            value: string,
        }),
    ),
    documents: arrayOf(
        shape({
            expirationDate: string,
            id: string,
            number: string,
            type: string,
        }),
    ),
    gender: string,
    loyaltyCards: arrayOf(
        shape({
            airline: string,
            id: string,
            number: string,
        }),
    ),
    names: shape({
        firstName: string,
        lastName: string,
    }),
    onClose: func,
    onContactsRemove: func,
    onDocumentRemove: func,
    onEdit: func,
    onLoyaltyRemove: func,
    onRemove: func,
    onRemoveCancel: func,
    onRemoveConfirmation: func,
    statusRemove: string,
};

Mobile.defaultProps = {
    contacts: [],
    documents: [],
    loyaltyCards: [],
    names: {},
};

export default React.memo(Mobile);
