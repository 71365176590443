import { complement, compose, isNil, map, mapObjIndexed, pickBy } from 'ramda';

import createFieldValidate from './createFieldValidate';

export default function createValidate(options) {
    return (values, props) => {
        const realOptions =
            typeof options === 'function' ? options(values, props) : options;
        const config = realOptions;
        const rules = map(pickBy(Boolean), config);

        return compose(
            pickBy(complement(isNil)),
            mapObjIndexed((value, fieldName) => {
                const validate = createFieldValidate(rules[fieldName]);

                return validate(value);
            }),
        )(values);
    };
}
