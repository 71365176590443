import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useGoogleAnalyticContext } from 'contexts';
import { Loadable } from 'decorators';
import { compose } from 'ramda';
import { getIsShortProfile } from 'store/modules/profile';
import {
    fetchMoreTravelers,
    getHasMoreTravelers,
    getLeftTravelers,
    getTravelers,
    isFetchTravelers,
} from 'store/modules/travelers';
import { v4 as uuidv4 } from 'uuid';

import Failure from './Failure';
import { TravelersLoader } from './Loaders';
import Travelers from './Travelers';

const TravelersContainer = () => {
    const dispatch = useDispatch();
    const isShortProfile = useSelector(getIsShortProfile);
    const hasMoreTravelers = useSelector(getHasMoreTravelers);
    const isFetch = useSelector(isFetchTravelers);
    const travelers = useSelector(getTravelers);
    const leftTravelers = useSelector(getLeftTravelers);
    const [newTraveler, setNewTraveler] = useState('');
    const { sendMyProfileEvent } = useGoogleAnalyticContext();

    const handleMore = useCallback(() => {
        dispatch(fetchMoreTravelers());
    }, [dispatch]);

    const handleNew = useCallback(() => {
        sendMyProfileEvent({
            eventActionPath: ['travelers', 'onAddTravelerClick'],
        });
        setNewTraveler(`new_${uuidv4()}`);
    }, [sendMyProfileEvent]);

    const handleNewClose = useCallback(() => {
        setNewTraveler('');
    }, []);

    useEffect(() => {
        setNewTraveler('');
    }, [travelers.length]);

    return (
        <Travelers
            isFetch={isFetch}
            leftTravelers={leftTravelers}
            newTraveler={newTraveler}
            travelers={travelers}
            onMore={hasMoreTravelers ? handleMore : undefined}
            onNew={!isShortProfile ? handleNew : undefined}
            onNewClose={handleNewClose}
        />
    );
};

export default compose(
    Loadable({
        isError: state => state.travelers.isError,
        isLoading: state => !state.travelers.isInitialized,
        Loader: <TravelersLoader />,
        Rebooter: <Failure />,
    }),
    React.memo,
)(TravelersContainer);
