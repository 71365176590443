import React from 'react';
import { validateDiffDate } from 'components/DocumentForm/helpers';
import { UnitNounByLength } from 'components/UnitNounByLength';
import { DATE_FORMATS } from 'consts/dateFormats';
import { documentType } from 'data';
import moment from 'moment';
import { Dictionary } from 'shared';
import createValidate, { createFieldValidate } from 'utils/createValidate';

import { SECTIONS } from '../constants';

const DD_MM_YYYY = DATE_FORMATS.DD.MM.YYYY_dot;

export const getValidate = section =>
    createValidate(values => ({
        birthday: {
            dateCorrect: {
                errorText: <Dictionary text="com.profile.form.valid.date" />,
            },
            datePast: {
                errorText: <Dictionary text="com.profile.form.valid.date" />,
                format: DD_MM_YYYY,
            },
            required: {
                errorText: <Dictionary text="com.validation.required-long" />,
            },
        },
        email: {
            email: {
                errorText: <Dictionary text="com.validation.email.invalid" />,
            },
        },
        firstName: {
            latinName: {
                errorText: <Dictionary text="com.profile.form.valid.latin" />,
            },
            maxLength: {
                errorText: <Dictionary text="com.profile.form.valid.max51" />,
                length: 51,
            },
            minLength: {
                errorText: (
                    <UnitNounByLength
                        dictionaryKey="com.validation.min-length"
                        length={2}
                    />
                ),
                length: 2,
            },
            required: {
                errorText: <Dictionary text="com.validation.required-long" />,
            },
        },
        gender: {
            required: {
                errorText: <Dictionary text="com.validation.required-long" />,
            },
        },
        lastName: {
            latinName: {
                errorText: <Dictionary text="com.profile.form.valid.latin" />,
            },
            maxLength: {
                errorText: <Dictionary text="com.profile.form.valid.max51" />,
                length: 51,
            },
            minLength: {
                errorText: (
                    <UnitNounByLength
                        dictionaryKey="com.validation.min-length"
                        length={2}
                    />
                ),
                length: 2,
            },
            required: {
                errorText: <Dictionary text="com.validation.required-long" />,
            },
        },
        loyaltyAirline: {
            required:
                values.haveLoyalty || section === SECTIONS.loyalty
                    ? {
                          errorText: (
                              <Dictionary text="com.validation.required-long" />
                          ),
                      }
                    : false,
        },
        loyaltyNumber: {
            priority:
                (values.haveLoyalty || section === SECTIONS.loyalty) &&
                values.loyaltyAirline !== 'S7'
                    ? {
                          errorText: (
                              <Dictionary text="com.profile.form.valid.priority" />
                          ),
                      }
                    : false,
            required:
                values.haveLoyalty || section === SECTIONS.loyalty
                    ? {
                          errorText: (
                              <Dictionary text="com.validation.required-long" />
                          ),
                      }
                    : false,
            s7priority:
                (values.haveLoyalty || section === SECTIONS.loyalty) &&
                values.loyaltyAirline === 'S7'
                    ? {
                          errorText: (
                              <Dictionary text="com.profile.form.valid.s7priority" />
                          ),
                      }
                    : false,
        },
        ...(section === SECTIONS.contacts
            ? {
                  phone: {
                      phone: {
                          errorText: (
                              <Dictionary text="com.validation.phone.invalid" />
                          ),
                          required: false,
                      },
                  },
              }
            : {}),
    }));

export const required = createFieldValidate({
    required: {
        errorText: <Dictionary text="com.registration.form.valid.required" />,
    },
});

export const minLength10 = createFieldValidate({
    minLength: {
        errorText: (
            <UnitNounByLength
                dictionaryKey="com.validation.min-length"
                length={10}
            />
        ),
        length: 10,
    },
});

export const minLength9 = createFieldValidate({
    minLength: {
        errorText: (
            <UnitNounByLength
                dictionaryKey="com.validation.min-length"
                length={9}
            />
        ),
        length: 9,
    },
});

export const minLength2 = createFieldValidate({
    minLength: {
        errorText: (
            <UnitNounByLength
                dictionaryKey="com.validation.min-length"
                length={2}
            />
        ),
        length: 2,
    },
});

export const minLength3 = createFieldValidate({
    minLength: {
        errorText: (
            <UnitNounByLength
                dictionaryKey="com.validation.min-length"
                length={3}
            />
        ),
        length: 3,
    },
});

export const latinName = createFieldValidate({
    latinName: {
        errorText: <Dictionary text="com.profile.form.valid.latin" />,
    },
});

export const validateNumber = {
    [documentType.INTERNAL_PASSPORT]: [minLength10, required],
    [documentType.INTERNATIONAL_PASSPORT]: [minLength9, required],
    [documentType.VISA]: [minLength2, required],
    [documentType.OTHER]: [minLength3, required],
};

export const getDateOfIssueValidator = ({ birthday }) =>
    createFieldValidate({
        dateCorrect: {
            errorText: <Dictionary text="com.profile.form.valid.date" />,
        },
        datePast: {
            errorText: <Dictionary text="com.profile.form.valid.datepass" />,
        },
        required: {
            errorText: (
                <Dictionary text="com.registration.form.valid.required" />
            ),
        },
        ...validateDiffDate({
            date: birthday,
            traveler: true,
            type: 'effective',
            visa: true,
        }),
    });

export const getStartDateValidator = ({ dateOfIssue }) =>
    createFieldValidate({
        dateCorrect: {
            errorText: <Dictionary text="com.profile.form.valid.date" />,
        },
        required: {
            errorText: (
                <Dictionary text="com.registration.form.valid.required" />
            ),
        },
        ...validateDiffDate({
            date: dateOfIssue,
            traveler: true,
            type: 'issued',
            visa: true,
        }),
    });

export const getExpireDateValidator = ({ dateOfIssue, type }) =>
    createFieldValidate({
        ...(type !== documentType.INTERNAL_PASSPORT
            ? {
                  required: {
                      errorText: (
                          <Dictionary text="com.registration.form.valid.required" />
                      ),
                  },
              }
            : {}),
        dateCorrect: {
            errorText: <Dictionary text="com.profile.form.valid.date" />,
        },
        dateFuture: {
            errorText: <Dictionary text="com.profile.form.valid.future" />,
        },
        ...validateDiffDate({
            date: dateOfIssue || moment().format(DD_MM_YYYY),
            traveler: true,
        }),
    });
