import React from 'react';
import { object, string } from 'prop-types';
import { Field, Fields } from 'redux-form';
import { Column, Columns, Dictionary } from 'shared';
import { normalizeSeriesToLatin } from 'utils';
import { validateRequired } from 'utils/createValidate';

import { Text } from '@s7/ui-kit';

import cx from './MilitaryId.sss';

import { TextField } from '../../Fields';

const validateSeries = value => {
    if (value && !/^[a-zA-Z]{2,4}$/.test(value)) {
        return <Dictionary text="com.validation.military-id.series" />;
    }

    return undefined;
};

const validateNumber = value => {
    if (value && !/^[0-9]{7}$/.test(value)) {
        return <Dictionary text="com.validation.military-id.number" />;
    }

    return undefined;
};

const renderFields = ({
    series,
    number,
    seriesProps,
    numberProps,
    className,
}) => {
    const shouldShowHint = !(
        (number.meta.error && number.meta.touched) ||
        (series.meta.error && series.meta.touched)
    );

    return (
        <Columns className={cx('root', className)}>
            <Column desktop={50}>
                <Field
                    {...series}
                    {...seriesProps}
                    component={TextField}
                    label={<Dictionary text="com.birth-certificate.series" />}
                    maxLength={4}
                    name="series"
                />
            </Column>
            <Column desktop={50}>
                <Field
                    {...number}
                    {...numberProps}
                    component={TextField}
                    label={<Dictionary text="com.birth-certificate.number" />}
                    maxLength={7}
                    name="number"
                />
            </Column>
            {shouldShowHint && (
                <Text classes={{ root: cx('tooltip') }} color="N100" size="s">
                    <Dictionary text="com.slugify.hint" />
                </Text>
            )}
        </Columns>
    );
};

export const MilitaryId = ({ seriesProps, numberProps, className }) => {
    const handleFormat = (value, name = '') => {
        const splitValue = name.split('.');
        const lastName = splitValue[splitValue.length - 1];

        if (lastName === 'series') {
            return normalizeSeriesToLatin(value);
        }

        return value;
    };

    return (
        <Fields
            validate={{
                number: [validateRequired, validateNumber],
                series: [validateRequired, validateSeries],
            }}
            className={className}
            component={renderFields}
            format={handleFormat}
            names={['series', 'number']}
            numberProps={numberProps}
            seriesProps={seriesProps}
        />
    );
};

MilitaryId.propTypes = {
    className: string,
    numberProps: object,
    seriesProps: object,
};
