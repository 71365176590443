import React from 'react';
import { LOYALTY_STATUS_ACTIVE } from 'consts';
import { DATE_FORMATS } from 'consts/dateFormats';
import { PROFILE_OPERATION_TYPE } from 'consts/profile';
import { createSelector } from 'reselect';
import { Dictionary } from 'shared';
import { RULE_MILES_ACTIVATE_STATUS } from 'utils/allowFeature';

import { getDictionary } from '../../store/modules/languages';
import {
    getAvailableMilesOperations,
    getIsClosedMilesNotification,
    getLoyalLevel,
    getMiles,
    getNextLevel,
    getStatusExpirationDate,
    getStatusType,
    hasExpiringMiles,
    hasPrepaid,
    isInitialized as isInitializedLoyalty,
} from '../../store/modules/loyalty';
import {
    getExpirationPackages,
    getPackagesHasError,
    getPackagesIsLoading,
    getPackagesItems,
    getPackagesNotification,
    getStatusUpgradeHasError,
    getStatusUpgradeNotification,
} from '../../store/modules/miles';
import {
    getCustomerValue,
    getIsBlockedAllActionsMilles,
    getIsShortProfile,
    getProfileUnavailableOperations,
} from '../../store/modules/profile';
import { noun } from '../../utils/format';

const DD_MM_YY = DATE_FORMATS.DD.MM.YY_dot;

const getMilesNotificationsStatusTexts = viewPrepaid => ({
    ...(viewPrepaid
        ? {
              action: 'openProlongation',
          }
        : {
              link: 'https://www.s7.ru/priority/save-status.dot#kak-prodlit-status',
          }),
    button: viewPrepaid ? (
        <Dictionary text="com.miles.notifications.extend" />
    ) : (
        <Dictionary text="com.title.more" />
    ),
});

function getMilesTexts({ dictionary, miles }) {
    const milesText = noun(miles, [
        dictionary.t('com.units.status-miles.one'),
        dictionary.t('com.units.status-miles.once'),
        dictionary.t('com.units.status-miles.mult'),
    ]);
    const expireText = noun(miles, [
        dictionary.t('com.miles.notifications.expire.one'),
        dictionary.t('com.miles.notifications.expire.once'),
        dictionary.t('com.miles.notifications.expire.mult'),
    ]);
    const milesNotificationText = noun(miles, [
        dictionary.t('com.miles.notifications.units.mile.one'),
        dictionary.t('com.miles.notifications.units.mile.once'),
        dictionary.t('com.miles.notifications.units.mile.mult'),
    ]);

    return {
        expireText,
        milesNotificationText,
        milesText,
    };
}

const getNextLevelPointText = (quantity, type, dictionary) => {
    const milesText = noun(quantity, [
        dictionary.t('com.units.status-miles.one'),
        dictionary.t('com.units.status-miles.once'),
        dictionary.t('com.units.status-miles.mult'),
    ]);
    const flightsText = noun(quantity, [
        dictionary.t('com.units.serments.field.one'),
        dictionary.t('com.units.serments.field.once'),
        dictionary.t('com.units.serments.field.mult'),
    ]);

    return type === 'SEGMENT'
        ? `${quantity} ${flightsText}`
        : `${quantity} ${milesText}`;
};

const getNextLevelNotification = (upgradePackage, dictionary) => {
    if (!upgradePackage) {
        return null;
    }

    const pointsText = getNextLevelPointText(
        upgradePackage.quantity,
        upgradePackage.type,
        dictionary,
    );

    const suffix = upgradePackage.id.startsWith('UPGRADE_PREVIOUS_YEAR_TIER')
        ? 'last'
        : 'this';

    const upgradeDescription = `com.miles.notifications.upgrade.description.${suffix}.year`;
    const transform = textValue =>
        textValue.replace(/%pointvalue%/, pointsText);

    return <Dictionary text={upgradeDescription} transform={transform} html />;
};

const disallowedPackageCodesForStatusExpiration = [
    'qualifying.miles.no.packages',
    'miles.operation.last.success.date',
    'miles.operation.prolongation.already.accepted',
    'qualifying.miles.no.packages.applicable',
    'qualifying.miles.incorrect.tier.level',
];

const isPlatinumStatus = (status = '') => status.toLowerCase() === 'platinum';

export const getMilesNotifications = createSelector(
    getMiles,
    getStatusType,
    getLoyalLevel,
    hasExpiringMiles,
    getStatusExpirationDate,
    hasPrepaid,
    getCustomerValue,
    getDictionary,
    getIsClosedMilesNotification,
    isInitializedLoyalty,
    getExpirationPackages,
    state => getPackagesNotification(state, 'status'),
    state => getPackagesHasError(state, 'upgrade'),
    state => getPackagesNotification(state, 'upgrade'),
    state => getPackagesItems(state, 'upgrade'),
    getNextLevel,
    getStatusUpgradeHasError,
    getStatusUpgradeNotification,
    getAvailableMilesOperations,
    getIsBlockedAllActionsMilles,
    getIsShortProfile,
    getProfileUnavailableOperations,
    (
        miles,
        statusType,
        status,
        hasMilesExpiring,
        statusExpirationDate,
        viewPrepaid,
        customerValue,
        dictionary,
        isClosedMilesNotification,
        isLoyaltyInitialized,
        expirationPackages,
        statusPackagesNotification,
        upgradePackagesHasError,
        upgradePackagesNotification,
        upgradePackagesItems,
        nextLevel,
        statusUpgradeHasError,
        statusUpgradeNotification,
        availableMilesOperations,
        isBlockedAllActionsMilles,
        isShortProfile,
        unavailableOperations,
    ) => {
        const isFullType = customerValue === 'FULL';
        const { milesText, expireText } = getMilesTexts({
            dictionary,
            miles,
        });
        const partnerID = sessionStorage.getItem('partnerID');
        const redirect = sessionStorage.getItem('redirect');

        const notifications = [];

        if (customerValue === 'SHORT') {
            notifications.push({
                action: 'changeNeedLoadProfile',
                button: <Dictionary text="com.miles.notifications.become" />,
                isInternalLink: true,
                link:
                    partnerID && redirect
                        ? `/registration/loyalty?redirect=${encodeURIComponent(
                              redirect,
                          )}&partnerID=${partnerID}`
                        : '/registration/loyalty',
                text: [
                    <Dictionary text="com.miles.notifications.short" html />,
                ],
            });
        }

        if (
            isFullType &&
            statusType !== LOYALTY_STATUS_ACTIVE &&
            isLoyaltyInitialized &&
            !isClosedMilesNotification &&
            !unavailableOperations.includes(
                PROFILE_OPERATION_TYPE.MILES_PURCHASE_STATUS,
            )
        ) {
            const activateStatusAvailable =
                RULE_MILES_ACTIVATE_STATUS &&
                availableMilesOperations.isActivateStatus;

            notifications.push({
                action: activateStatusAvailable
                    ? 'openActivateStatus'
                    : { name: 'closeMilesNotification', type: 'onClose' },
                button: activateStatusAvailable && (
                    <Dictionary text="com.miles.activate-status.notification.link" />
                ),
                text: activateStatusAvailable ? (
                    <Dictionary text="com.miles.activate-status.notification.text" />
                ) : (
                    <>
                        <Dictionary text="com.profile.notifications.miles" />
                        &nbsp;
                        <a href="https://www.s7.ru/personal_account/ffpAccountLimitations.dot">
                            <Dictionary text="com.title.more" />
                        </a>
                    </>
                ),
                title: activateStatusAvailable && (
                    <Dictionary text="com.miles.activate-status.notification.title" />
                ),
            });
        }

        if (
            statusExpirationDate &&
            availableMilesOperations.isProlongation &&
            !disallowedPackageCodesForStatusExpiration.includes(
                statusPackagesNotification?.code,
            ) &&
            !unavailableOperations.includes(
                PROFILE_OPERATION_TYPE.MILES_PROLONGATION,
            )
        ) {
            const notificationsTexts =
                getMilesNotificationsStatusTexts(viewPrepaid);
            const text = [<Dictionary text="com.miles.notifications.status" />];

            if (hasMilesExpiring) {
                text.push(`, ${miles} ${milesText} ${expireText} `);
            } else {
                text.push(' ');
            }

            text.push(<b>{statusExpirationDate.format(DD_MM_YY)}</b>);

            notifications.push({
                isInternalLink: viewPrepaid,
                text,
                ...notificationsTexts,
            });
        }

        if (
            isFullType &&
            availableMilesOperations.isUpgrade &&
            !isPlatinumStatus(status) &&
            upgradePackagesNotification.code !==
                'qualifying.miles.no.packages.applicable' &&
            (!upgradePackagesHasError ||
                upgradePackagesNotification.code ===
                    'miles.source.phone.not.verified' ||
                upgradePackagesNotification.code ===
                    'miles.source.phone.not.exist') &&
            !(statusUpgradeNotification.title && !statusUpgradeHasError) &&
            !unavailableOperations.includes(
                PROFILE_OPERATION_TYPE.MILES_UPGRADE,
            )
        ) {
            const notificationsTexts = {
                action: 'openUpgrade',
                button: <Dictionary text="com.title.more" />,
            };
            const title = [
                <Dictionary text="com.miles.notifications.upgrade.title" />,
            ];

            title.push(<b> {(nextLevel || '').replace('_', ' ')}</b>);

            const [upgradePackage] = upgradePackagesItems;

            const text = getNextLevelNotification(upgradePackage, dictionary);

            notifications.push({
                isInternalLink: viewPrepaid,
                text,
                title,
                ...notificationsTexts,
            });
        }

        if (!isShortProfile && isBlockedAllActionsMilles) {
            notifications.push({
                text: (
                    <Dictionary
                        text="com.profile.notifications.miles-blocked.text"
                        html
                    />
                ),
                title: (
                    <Dictionary text="com.profile.notifications.miles-blocked.title" />
                ),
            });
        }
        return notifications;
    },
);
