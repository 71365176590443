import React, { useCallback, useMemo } from 'react';
import { useAnalyticsContext } from 'contexts';
import { memberStatus } from 'data/membership';
import {
    array,
    arrayOf,
    bool,
    func,
    node,
    number,
    object,
    shape,
    string,
} from 'prop-types';
import { ScrollIntoView } from 'shared';

import {
    Button,
    Link,
    OnBoardingTooltip,
    StatusMessage,
    Text,
    Title,
    Tooltip,
} from '@s7/ui-kit';
import {
    ChevronNormalDown,
    InfoLinear,
    PlusLinear,
    QuestionFilled,
} from '@s7/ui-kit/icons';

import cx from './GroupAccount.sss';
import { GroupMember } from './GroupMember';
import Rules from './Rules';
import { ActionButtons } from './shared';

import Icon from '../Icon';

const popoverProps = { style: { zIndex: 1 } };

const chevronIcon = (
    <ChevronNormalDown classes={{ root: cx('chevron-icon') }} />
);

export const GroupAccount = React.memo(function GroupAccount({
    accountBalance,
    accountInfoTooltipProps = {},
    adminName,
    getTestId,
    isHead,
    memberDeleting,
    isMembershipOfGroup,
    isMobile,
    isOpened,
    isSub,
    isTablet,
    leaveTooltipProps = {},
    members = [],
    notificationProps: {
        description: notificationDescription,
        ...notificationProps
    } = {},
    onClick,
    openInvitationModal,
    openLeaveModal,
    openRemovalModal,
    removalTooltipProps = {},
    removeUserFromGroup,
    rules = [],
    rulesDetailLink,
    rulesForTablet = [],
    texts = {},
    isMembershipCreationAvailable,
    membersCount,
    isAvailableActions,
}) {
    const isMembershipOfGroupAndSub = isMembershipOfGroup && isSub;
    const isRenderTitleAvailable =
        !isMembershipOfGroup || isMembershipOfGroupAndSub;
    const isRenderMembersAvailable = isMembershipOfGroup && isHead;
    const isRenderDescriptionAvailable =
        !isMembershipOfGroup || isMembershipOfGroupAndSub;

    const { sendEvent } = useAnalyticsContext();

    const handleOpenInvitationModal = useCallback(() => {
        sendEvent({
            event_name: 'click_cta',
            flow: 'companions',
            result: 'invite',
            step: 'membership_invite',
        });
        openInvitationModal();
    }, [openInvitationModal, sendEvent]);

    const titleContent = !isMembershipOfGroup ? (
        texts.title
    ) : (
        <>
            {adminName} - {texts.admin}
        </>
    );

    const nameComponentForGroupMemberTitle = (
        <div className={cx('title_text', 'title_text_account')}>
            <Title level={3} {...getTestId('infoBalance')}>
                {accountBalance}
            </Title>

            <span className={cx('title_text_info')}>
                <Tooltip
                    content={
                        <div>
                            <Text color="W100" size="s">
                                {texts.info}
                            </Text>
                            <Link
                                color="G600"
                                href={rulesDetailLink}
                                rel="noopener noreferrer"
                                target="_blank"
                                {...getTestId('infoLink')}
                            >
                                {texts.ruleDetails}
                            </Link>
                        </div>
                    }
                    id="account-information"
                    {...accountInfoTooltipProps}
                >
                    <Icon
                        height={16}
                        icon="information"
                        width={16}
                        newIcon
                        {...getTestId('infoIcon')}
                    />
                </Tooltip>
            </span>
        </div>
    );

    const statusComponentForGroupMemberTitle = useMemo(() => {
        if (isHead) {
            return isMobile ? (
                <button
                    className={cx('title_action')}
                    type="button"
                    onClick={handleOpenInvitationModal}
                    {...getTestId('inviteOpen')}
                >
                    <PlusLinear />
                </button>
            ) : (
                <div className={cx('title_action')}>
                    <Link
                        as="button"
                        icon={<PlusLinear />}
                        type="button"
                        view="b2c"
                        onClick={handleOpenInvitationModal}
                        {...getTestId('inviteOpen')}
                    >
                        {texts.invite}
                    </Link>
                </div>
            );
        }

        return <div />;
    }, [isHead, isMobile, handleOpenInvitationModal, texts.invite, getTestId]);

    const handleInfoLinkClick = useCallback(() => {
        sendEvent({
            event_name: 'view_screen',
            flow: 'companions',
            membership: 'more_about_rules',
            step: 'membership',
        });
    }, [sendEvent]);

    const handleQuitClick = useCallback(() => {
        sendEvent({
            event_name: 'view_screen',
            flow: 'companions',
            membership: 'leave',
            step: 'membership',
        });
    }, [sendEvent]);

    return (
        <div
            className={cx('root', {
                root_isGroup: isMembershipOfGroup,
                root_isHead: isHead,
                root_isSub: isSub,
                root_notification: notificationProps.isOpen,
                root_opened: isOpened,
            })}
        >
            {notificationProps.isOpen && (
                <div className={cx('notification')}>
                    <ScrollIntoView>
                        <StatusMessage
                            theme="b2c"
                            {...notificationProps}
                            namespace={getTestId('notification')['data-test']}
                        >
                            {notificationDescription}
                        </StatusMessage>
                    </ScrollIntoView>
                </div>
            )}

            {isRenderTitleAvailable && (
                <div className={cx('title')}>
                    <div className={cx('title_icon')}>
                        <Icon height={12} icon="group" width={18} newIcon />
                    </div>

                    <div className={cx('title_text')}>
                        {!isMembershipOfGroup ? (
                            <Title level={3} {...getTestId('title')}>
                                {titleContent}
                            </Title>
                        ) : (
                            <Text weight="semibold" {...getTestId('title')}>
                                {titleContent}
                            </Text>
                        )}
                    </div>
                </div>
            )}

            {isRenderMembersAvailable && (
                <>
                    <GroupMember
                        isNextActive={
                            members[0] &&
                            members[0].status === memberStatus.ACTIVE
                        }
                        statusComponent={
                            isAvailableActions
                                ? statusComponentForGroupMemberTitle
                                : undefined
                        }
                        getTestId={getTestId}
                        isAvailableActions={isAvailableActions}
                        isMobile={isMobile}
                        membersCount={membersCount}
                        nameComponent={nameComponentForGroupMemberTitle}
                        status={memberStatus.ACTIVE}
                        asTitle
                    />
                    {members.map((member, index) => (
                        <GroupMember
                            {...member}
                            key={member.id}
                            isNextActive={
                                index < members.length - 1 &&
                                members[index + 1].status ===
                                    memberStatus.ACTIVE
                            }
                            isPrevActive={
                                (index === 0 &&
                                    member.status === memberStatus.ACTIVE) ||
                                (index > 0 &&
                                    members[index - 1].status ===
                                        memberStatus.ACTIVE)
                            }
                            getTestId={getTestId}
                            isAvailableActions={isAvailableActions}
                            isMobile={isMobile}
                            memberDeleting={memberDeleting}
                            openRemovalModal={openRemovalModal}
                            removalTooltipProps={removalTooltipProps}
                            removeUserFromGroup={removeUserFromGroup}
                        />
                    ))}
                </>
            )}

            {isRenderDescriptionAvailable && (
                <>
                    <div className={cx('description')}>
                        <Text>{texts.description}</Text>
                    </div>

                    {!isMembershipOfGroup && isOpened && (
                        <>
                            <Rules {...{ isTablet, rules, rulesForTablet }} />
                            <div className={cx('actions')}>
                                {isMembershipCreationAvailable ? (
                                    <div className={cx('actions_button')}>
                                        <Button
                                            theme="b2c"
                                            block
                                            onClick={handleOpenInvitationModal}
                                            {...getTestId('inviteOpen')}
                                        >
                                            <span className={cx('plus-icon')}>
                                                <PlusLinear />
                                            </span>
                                            {texts.invite}
                                        </Button>
                                    </div>
                                ) : (
                                    <div className={cx('unavailable')}>
                                        <span
                                            className={cx('unavailable_icon')}
                                        >
                                            <QuestionFilled />
                                        </span>
                                        <Text
                                            color="N100"
                                            {...getTestId('unavailable')}
                                        >
                                            {texts.unavailable}
                                        </Text>
                                    </div>
                                )}
                                <div className={cx('actions_link')}>
                                    <Link
                                        classes={{ icon: cx('icon-link') }}
                                        href={rulesDetailLink}
                                        icon={<InfoLinear />}
                                        rel="noopener noreferrer"
                                        target="_blank"
                                        view="b2c"
                                        onClick={handleInfoLinkClick}
                                        {...getTestId('rules')}
                                    >
                                        {texts.ruleDetails}
                                    </Link>
                                </div>
                            </div>
                        </>
                    )}

                    {!isMembershipOfGroup && (
                        <div className={cx('collapse-button')}>
                            <Link
                                as="button"
                                classes={{ icon: cx('icon-link') }}
                                icon={chevronIcon}
                                type="button"
                                view={isOpened ? 'secondary' : 'b2c'}
                                onClick={onClick}
                                {...getTestId('collapse')}
                            >
                                {isOpened ? texts.collapse : texts.knowMore}
                            </Link>
                        </div>
                    )}

                    {isMembershipOfGroupAndSub && (
                        <div className={cx('actions_member')}>
                            {isAvailableActions && (
                                <div className={cx('actions_member_button')}>
                                    {isMobile ? (
                                        <Link
                                            icon={
                                                <Icon
                                                    height={16}
                                                    icon="log-out"
                                                    width={16}
                                                    newIcon
                                                />
                                            }
                                            as="button"
                                            type="button"
                                            view="secondary"
                                            onClick={openLeaveModal}
                                            {...getTestId('leaveOpen')}
                                        >
                                            {texts.leaveGroup}
                                        </Link>
                                    ) : (
                                        <OnBoardingTooltip
                                            actions={
                                                <ActionButtons
                                                    {...leaveTooltipProps}
                                                    cancelProps={getTestId(
                                                        'leaveCancel',
                                                    )}
                                                    confirmProps={getTestId(
                                                        'leaveConfirm',
                                                    )}
                                                    onCancel={
                                                        leaveTooltipProps.onClick
                                                    }
                                                />
                                            }
                                            id="leave-group"
                                            popoverProps={popoverProps}
                                            {...leaveTooltipProps}
                                            wrapperClassName={cx(
                                                'OnBoardingTooltip',
                                            )}
                                        >
                                            <Link
                                                icon={
                                                    <Icon
                                                        height={16}
                                                        icon="log-out"
                                                        width={16}
                                                        newIcon
                                                    />
                                                }
                                                as="button"
                                                type="button"
                                                view="secondary"
                                                onClick={handleQuitClick}
                                                {...getTestId('leaveOpen')}
                                            >
                                                {texts.leaveGroup}
                                            </Link>
                                        </OnBoardingTooltip>
                                    )}
                                </div>
                            )}

                            <Link
                                classes={{ root: cx('actions_member_button') }}
                                href={rulesDetailLink}
                                icon={<InfoLinear />}
                                rel="noopener noreferrer"
                                target="_blank"
                                view="secondary"
                                {...getTestId('rules')}
                            >
                                {texts.adminRuleDetails}
                            </Link>
                        </div>
                    )}
                </>
            )}
        </div>
    );
});

const textsShape = shape({
    description: node,
    title: node,
});

export const memberDeletingShape = shape({
    id: string,
    status: bool,
});

GroupAccount.propTypes = {
    accountBalance: string,
    accountInfoTooltipProps: object,
    adminName: string,
    getTestId: func,
    isAvailableActions: bool,
    isHead: bool,
    isMembershipCreationAvailable: bool,
    isMembershipOfGroup: bool,
    isMobile: bool,
    isOpened: bool,
    isSub: bool,
    isTablet: bool,
    leaveTooltipProps: object,
    memberDeleting: memberDeletingShape,
    members: arrayOf(object),
    membersCount: number,
    notificationProps: object,
    onClick: func,
    openInvitationModal: func,
    openLeaveModal: func,
    openRemovalModal: func,
    removalTooltipProps: object,
    removeUserFromGroup: func,
    rules: arrayOf(object),
    rulesDetailLink: string,
    rulesForTablet: arrayOf(array),
    texts: textsShape,
};
