import React, { PureComponent } from 'react';
import { bool, node, object, oneOf, string } from 'prop-types';

import { Text } from '@s7/ui-kit';

import cx from './LabelWithText.sss';

const LABEL_STYLE = { flexShrink: '0' };

export default class LabelWithText extends PureComponent {
    static propTypes = {
        align: oneOf(['right', 'center']),
        children: node,
        label: node,
        labelColor: string,
        rows: bool,
        rowsInfo: bool,
        testId: object,
    };

    static defaultProps = {
        labelColor: 'N100',
        testId: {},
    };

    render() {
        const { align, children, label, labelColor, rows, rowsInfo, testId } =
            this.props;

        return (
            <div
                className={cx('LabelWithText', {
                    LabelWithText_rows: rows,
                    [`LabelWithText_align_${align}`]: align,
                })}
            >
                {label && (
                    <div className={cx('label')}>
                        <Text
                            as="div"
                            color={labelColor}
                            style={LABEL_STYLE}
                            {...testId}
                        >
                            {label}
                        </Text>
                    </div>
                )}
                <div
                    className={cx('info', {
                        info_rowsInfo: rowsInfo,
                    })}
                >
                    {children}
                </div>
            </div>
        );
    }
}
