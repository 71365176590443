import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { domToReact } from 'html-react-parser';
import moment from 'moment';
import { array, bool, func } from 'prop-types';
import { compose } from 'ramda';
import { ClickOutside, Column, Columns, Dictionary } from 'shared';

import { Button, Link, Text } from '@s7/ui-kit';
import { Camera, Import } from '@s7/ui-kit/icons';

import cx from './DocumentScan.sss';

import { getCountries } from '../../store/modules/countries';
import {
    check,
    get,
    getData,
    getErrorMessage as getDocscanErrorMessage,
    getId,
    getStatus,
    getToken,
    send,
} from '../../store/modules/docscan';
import Icon from '../Icon';

const DICTIONARIES = {
    button: <Dictionary text="com.action.continue" />,
    errorDesktopText: (
        <Dictionary text="com.profile.docscan.error.text.desktop" />
    ),
    errorMobileText: (
        <Dictionary text="com.profile.docscan.error.text.mobile" />
    ),
    errorTitle: <Dictionary text="com.profile.docscan.error.title" />,
    subtitle: <Dictionary text="com.profile.docscan.subtitle.document" />,
    success: <Dictionary text="com.profile.docscan.success" />,
    title: <Dictionary text="com.profile.docscan.title.document" />,
    upload: <Dictionary text="com.profile.docscan.upload" />,
    visaSubtitle: <Dictionary text="com.profile.docscan.subtitle.visa" />,
    visaTitle: <Dictionary text="com.profile.docscan.title.visa" />,
};

const mapStateToProps = state => {
    const id = getId(state);
    const data = getData(state);
    const token = getToken(state);
    const status = getStatus(state);

    return {
        countries: getCountries(state),
        data,
        getErrorMessage: getDocscanErrorMessage(state),
        id,
        status,
        token,
    };
};

const mapDispatchToProps = dispatch => ({
    onCheck: payload => dispatch(check(payload)),
    onGet: payload => dispatch(get(payload)),
    onSend: payload => dispatch(send(payload)),
});

class DocumentScan extends PureComponent {
    static propTypes = {
        countries: array,
        data: func,
        disabled: bool,
        docscan: func,
        getErrorMessage: func,
        id: func,
        isVisa: bool,
        onCheck: func,
        onDocscanClick: func,
        onGet: func,
        onSend: func,
        status: func,
        token: func,
        withoutTitle: bool,
    };

    static defaultProps = {
        disabled: false,
        isVisa: false,
        status: () => 0,
        withoutTitle: false,
    };

    constructor(props) {
        super(props);

        this.state = {
            error: false,
            fileId: null,
            success: false,
        };
    }

    componentDidUpdate(prevProps) {
        const { fileId } = this.state;
        const currentStatus = this.props.status(fileId);
        const prevStatus = prevProps.status(fileId);

        if (prevStatus !== currentStatus) {
            this.checkStatus();
        }
    }

    handleClick = () => {
        this.field.click();

        if (this.props.onDocscanClick) {
            this.props.onDocscanClick();
        }
    };

    handleChange = () => {
        if (this.field.files && this.field.files[0]) {
            const reader = new FileReader();

            reader.onload = file => {
                const format = (this.field.files[0].type || '').replace(
                    'image/',
                    '',
                );
                const document = file.target.result;
                const fileId = moment().unix();

                this.setState({
                    fileId,
                });
                this.props.onSend({
                    document,
                    fileId,
                    format,
                });

                // проверка исправляет баг https://s7airlines.atlassian.net/browse/CSSSRS7-3435
                if (this.field) {
                    this.field.value = '';
                }

                setTimeout(() => {
                    clearTimeout(this.checktimer);
                }, 30000);
            };

            reader.readAsDataURL(this.field.files[0]);
        }
    };

    handleClickOutside = () => {
        this.setState({
            error: false,
            fileId: null,
        });
    };

    getFileRef = ref => {
        this.field = ref;
    };

    getScanStatus() {
        const statusUpload = new Set([0, 1, 2, 3, 5]);
        const statusError = new Set([4]);

        const { status } = this.props;
        const { fileId } = this.state;
        const scanStatus = status(fileId);
        const error = statusError.has(scanStatus) || this.state.error;
        const upload = statusUpload.has(scanStatus);

        return {
            error,
            upload,
        };
    }

    checkStatus() {
        const { status, id, token, data, docscan, countries } = this.props;
        const { fileId } = this.state;
        const fileStatus = status(fileId);

        if (fileStatus === 1 || fileStatus === 2) {
            this.props.onCheck({
                fileId,
                id: id(fileId),
                token: token(fileId),
            });

            clearTimeout(this.checktimer);
            this.checktimer = setTimeout(() => {
                this.checkStatus();
            }, 2000);
        }

        if (fileStatus === 3) {
            this.props.onGet({
                countries,
                fileId,
                id: id(fileId),
                token: token(fileId),
            });
        }

        if (fileStatus === 6) {
            const set = data(fileId);
            const hasData = Object.values(set).length > !!set.type ? 1 : 0;

            this.setState({
                error: !hasData,
                fileId: null,
                success: hasData,
            });

            docscan(set);
            setTimeout(() => {
                docscan(set);
            }, 50);

            if (hasData) {
                setTimeout(() => {
                    this.setState({
                        success: false,
                    });
                }, 3000);
            }
        }
    }

    parseOptions() {
        const { disabled } = this.props;
        const { upload } = this.getScanStatus();

        return {
            replace: domNode => {
                if (domNode.name && domNode.name === 'button') {
                    return (
                        <Link
                            as="button"
                            href={domNode.attribs.href}
                            icon={<Import />}
                            loading={disabled || upload}
                            type="button"
                            onClick={
                                disabled || upload ? () => {} : this.handleClick
                            }
                        >
                            {domToReact(domNode.children, this.parseOptions)}
                        </Link>
                    );
                }

                return domNode;
            },
        };
    }

    renderError() {
        const { getErrorMessage } = this.props;
        const { fileId } = this.state;

        return (
            <div className={cx('error')}>
                <div className={cx('error-title')}>
                    <span className={cx('desktop')}>
                        <Text color="W100" weight="semibold">
                            {getErrorMessage(fileId) || DICTIONARIES.errorTitle}
                        </Text>
                    </span>
                    <span className={cx('mobile')}>
                        <Text weight="semibold">{DICTIONARIES.errorTitle}</Text>
                    </span>
                </div>
                <div className={cx('error-text')}>
                    <span className={cx('desktop')}>
                        <Text color="W100" size="s">
                            {DICTIONARIES.errorDesktopText}
                        </Text>
                    </span>
                    <span className={cx('mobile')}>
                        <Text size="s">{DICTIONARIES.errorMobileText}</Text>
                    </span>
                </div>
                <div className={cx('error-button')}>
                    <span className={cx('desktop')}>
                        <Button onClick={this.handleClickOutside}>
                            {DICTIONARIES.button}
                        </Button>
                    </span>
                    <span className={cx('mobile')}>
                        <button
                            className={cx('error-link')}
                            type="button"
                            onClick={this.handleClickOutside}
                        >
                            {DICTIONARIES.button}
                        </button>
                    </span>
                </div>
            </div>
        );
    }

    render() {
        const { disabled, isVisa } = this.props;
        const { success } = this.state;
        const { upload, error } = this.getScanStatus();

        return (
            <Columns>
                <Column key="main" desktop={100} zindexInitial>
                    <div className={cx('desktop')}>
                        <div className={cx('inline')}>
                            <Text size="m">
                                <Dictionary
                                    parseOptions={this.parseOptions()}
                                    text="com.profile.docscan.inline-download"
                                />
                            </Text>

                            {error && (
                                <ClickOutside
                                    onClickOutside={this.handleClickOutside}
                                >
                                    {this.renderError()}
                                </ClickOutside>
                            )}
                        </div>
                    </div>
                    <div className={cx('mobile')}>
                        <Text>
                            {isVisa
                                ? DICTIONARIES.visaSubtitle
                                : DICTIONARIES.subtitle}
                        </Text>
                        <div className={cx('button')}>
                            <Button
                                loading={disabled || upload}
                                size="large"
                                theme="secondary"
                                block
                                onClick={
                                    disabled || upload
                                        ? () => {}
                                        : this.handleClick
                                }
                            >
                                <Camera size="md" /> {DICTIONARIES.upload}
                            </Button>
                        </div>
                        {error && (
                            <div className={cx('overlay')}>
                                <ClickOutside
                                    onClickOutside={this.handleClickOutside}
                                >
                                    {this.renderError()}
                                </ClickOutside>
                            </div>
                        )}
                    </div>

                    {!!success && (
                        <div className={cx('success')}>
                            <ClickOutside
                                onClickOutside={this.handleClickOutside}
                            >
                                <div className={cx('success-inner')}>
                                    <span className={cx('success-icon')}>
                                        <Icon
                                            height={20}
                                            icon="like"
                                            width={20}
                                        />
                                    </span>
                                    <Text color="W100">
                                        {DICTIONARIES.success}
                                    </Text>
                                </div>
                            </ClickOutside>
                        </div>
                    )}

                    <input
                        ref={this.getFileRef}
                        accept="image/*"
                        className={cx('field')}
                        disabled={disabled}
                        type="file"
                        onChange={this.handleChange}
                    />
                </Column>
            </Columns>
        );
    }
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
    DocumentScan,
);
