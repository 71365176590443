import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MyCompanyInvitation } from 'components/MyCompanyInvitation';
import { Dictionary } from 'shared';
import { getDictionary } from 'store/modules/languages';
import {
    confirmInvitation,
    disableShowInvitation,
    getConfirming,
    getDisabling,
    getHasInvitationError,
    getInvitationCompanyName,
    getInvitationEmployeeId,
} from 'store/modules/myCompany';

import { Link, StatusMessage } from '@s7/ui-kit';

const MyCompanyInvitationContainer = () => {
    const dispatch = useDispatch();
    const invitationCompanyName = useSelector(getInvitationCompanyName);
    const employeeId = useSelector(getInvitationEmployeeId);
    const hasInvitationError = useSelector(getHasInvitationError);
    const confirming = useSelector(getConfirming);
    const disabling = useSelector(getDisabling);

    const dictionary = useSelector(getDictionary);
    const handleCancel = useCallback(() => {
        dispatch(disableShowInvitation({ employeeId }));
    }, [dispatch, employeeId]);

    const handleConfirm = useCallback(() => {
        dispatch(confirmInvitation({ employeeId }));
    }, [dispatch, employeeId]);

    return (
        <>
            {hasInvitationError && (
                <>
                    <StatusMessage view="error">
                        <Dictionary text="com.profile.mycompany.error.text" />{' '}
                        <Link
                            href={`tel:${dictionary.t(
                                'com.profile.mycompany.error.phone',
                            )}`}
                            view="secondary"
                        >
                            {dictionary.t('com.profile.mycompany.error.phone')}
                        </Link>
                        ,{' '}
                        <Link
                            href={`mailto:${dictionary.t(
                                'com.profile.mycompany.error.email',
                            )}`}
                            view="secondary"
                        >
                            {dictionary.t('com.profile.mycompany.error.email')}
                        </Link>
                    </StatusMessage>
                    <br />
                </>
            )}
            <MyCompanyInvitation
                confirming={confirming}
                disabling={disabling}
                name={invitationCompanyName}
                onCancel={handleCancel}
                onConfirm={handleConfirm}
            />
        </>
    );
};

MyCompanyInvitationContainer.propTypes = {};

export default MyCompanyInvitationContainer;
