import React from 'react';
import Link from 'components/Link';
import { identity } from 'ramda';
import { createSelector } from 'reselect';
import { Dictionary } from 'shared';

import { Link as DSLink } from '@s7/ui-kit';

import {
    getCustomerValue,
    getIsWeakPassword,
} from '../../store/modules/profile';

export const getProfileNotifications = createSelector(identity, state => {
    const notifications = [];
    const isShortProfile = getCustomerValue(state) === 'SHORT';
    const partnerID = sessionStorage.getItem('partnerID');
    const redirect = sessionStorage.getItem('redirect');

    if (getIsWeakPassword(state)) {
        notifications.push({
            text: (
                <>
                    <Dictionary
                        text="com.profile.notifications.weak-password.before"
                        html
                    />{' '}
                    <DSLink
                        as={Link}
                        href="/settings#change-password"
                        view="warning"
                    >
                        <Dictionary
                            text="com.profile.notifications.weak-password.link"
                            html
                        />
                    </DSLink>{' '}
                    <Dictionary
                        text="com.profile.notifications.weak-password.after"
                        html
                    />
                </>
            ),
        });
    }

    if (isShortProfile) {
        notifications.push({
            button: (
                <Dictionary text="com.profile.social.notification.signup" />
            ),
            link:
                partnerID && redirect
                    ? `/registration/loyalty?redirect=${encodeURIComponent(
                          redirect,
                      )}&partnerID=${partnerID}`
                    : '/registration/loyalty',
            secondary: (
                <Dictionary text="com.profile.social.notification.login" />
            ),
            secondaryAction: 'openLogin',
            text: [
                <Dictionary text="com.profile.social.notification.text" html />,
            ],
        });
    }

    if (!isShortProfile && partnerID && redirect) {
        notifications.push({
            button: (
                <Dictionary text="com.profile.partner.notification.button" />
            ),
            buttonTheme: 'b2c',
            link: redirect,
            text: [
                <Dictionary text="com.profile.partner.notification.description" />,
            ],
            title: <Dictionary text="com.profile.partner.notification.title" />,
            view: 'default',
        });
    }

    return notifications;
});
