import { PETS_TYPE, PETS_WEIGHT } from 'consts/pets';

import DefaultAvatarBird from './images/Bird.svg';
import DefaultAvatarCat from './images/Cat.svg';
import DefaultAvatarDog from './images/Dog.svg';
import DefaultAvatarOther from './images/Other.svg';

const defaultAvatar = {
    [PETS_TYPE.CAT]: DefaultAvatarCat,
    [PETS_TYPE.DOG]: DefaultAvatarDog,
    [PETS_TYPE.BIRD]: DefaultAvatarBird,
    [PETS_TYPE.OTHER]: DefaultAvatarOther,
};

const animalNames = {
    [PETS_TYPE.CAT]: 'com.profile.pets.modal.form.animal.cat',
    [PETS_TYPE.DOG]: 'com.profile.pets.modal.form.animal.dog',
    [PETS_TYPE.BIRD]: 'com.profile.pets.modal.form.animal.bird',
    [PETS_TYPE.OTHER]: 'com.profile.pets.modal.form.animal.other',
};

const animalWeight = {
    [PETS_WEIGHT.UP_TO_10]: 'com.profile.pets.modal.form.weight.up-to-10',
    [PETS_WEIGHT.UP_TO_23]: 'com.profile.pets.modal.form.weight.up-to-23',
    [PETS_WEIGHT.UP_TO_50]: 'com.profile.pets.modal.form.weight.up-to-50',
    [PETS_WEIGHT.OVER_50]: 'com.profile.pets.modal.form.weight.over-50',
};

export const getDefaultAvatar = type => defaultAvatar[type];
export const getAnimalName = type => animalNames[type];
export const getAnimalWeight = weight => animalWeight[weight];
