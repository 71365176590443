import { assoc, uniq } from 'ramda';
import { createAction, createReducer } from 'redux-act';

import { registrationError } from '../../../data/errorCodes';

const initialState = {
    business: {
        company: null,
        email: null,
        invitation: null,
    },
    cache: {},
    confirmed: false,
    confirmedSecret: '',
    confirmError: null,
    draftProfile: null,
    forbiddenPhones: [],
    isBusiness: false,
    isConfirmEmailFailed: false,
    isConfirmEmailSended: false,
    isDraftProfileLoading: false,
    isInviteSuccess: false,
    isReferralModalOpen: false,
    needLoadProfile: false,
    phone: '',
    regError: null,
    resourceId: '',
    sended: false,
    sending: false,
    smsCount: 0,
};

export const registerShort = createAction('registration/registerShort');
export const registerShortSuccess = createAction(
    'registration/registerShortSuccess',
);
export const registerShortFailure = createAction(
    'registration/registerShortFailure',
);

export const resetRegistrationError = createAction(
    'registration/resetRegistrationError',
);
export const resetShortRegistration = createAction(
    'registration/resetShortRegistration',
);

export const resend = createAction('registration/resend');
export const resendSuccess = createAction('registration/resendSuccess');
export const resendFailure = createAction('registration/resendFailure');

export const confirm = createAction('registration/confirm');
export const confirmSuccess = createAction('registration/confirmSuccess');
export const confirmFailure = createAction('registration/confirmFailure');

export const upgrade = createAction('registration/upgrade');
export const upgradeSuccess = createAction('registration/upgradeSuccess');
export const upgradeFailure = createAction('registration/upgradeFailure');

export const registerFull = createAction('registration/registerFull');
export const registerFullSuccess = createAction(
    'registration/registerFullSuccess',
);
export const registerFullFailure = createAction(
    'registration/registerFullFailure',
);

export const changeNeedLoadProfile = createAction(
    'registration/changeNeedLoadProfile',
);

export const confirmEmail = createAction('registration/confirmEmail');
export const confirmEmailFailure = createAction(
    'registration/confirmEmailFailure',
);
export const confirmEmailSended = createAction(
    'registration/confirmEmailSended',
);

export const inviteSuccess = createAction('registration/inviteSuccess');
export const clearCache = createAction('registration/clearCache');
export const changeCache = createAction('registration/changeCache');

export const showReferralModal = createAction('registration/showReferralModal');
export const closeReferralModal = createAction(
    'registration/closeReferralModal',
);

export const fetchDraftProfile = createAction('registration/fetchDraftProfile');
export const fetchDraftProfileSuccess = createAction(
    'registration/fetchDraftProfileSuccess',
);
export const fetchDraftProfileFailure = createAction(
    'registration/fetchDraftProfileFailure',
);

export const startRegistrationB2B = createAction(
    'registration/startRegistrationB2B',
);
export const startRegistrationB2BFailure = createAction(
    'registration/startRegistrationB2BFailure',
);
export const setInvitationData = createAction('registration/setInvitationData');

const handleResetRegistrationError = state => ({ ...state, regError: null });
const handleResetShortRegistration = state => ({
    ...initialState,
    forbiddenPhones: state.forbiddenPhones,
});

const handleRegisterShort = state => ({
    ...state,
    regError: null,
});

const handleRegisterShortSuccess = (state, { phone, autoConfirm }) => ({
    ...state,
    confirmed: Boolean(autoConfirm),
    regError: null,
    registeredPhone: phone,
    sended: !autoConfirm,
    smsCount: state.smsCount + 1,
});

const handleRegisterShortFailure = (state, { phone, error }) => ({
    ...state,
    forbiddenPhones:
        error && error.code === registrationError.PHONE_DUPLICATE
            ? uniq([...state.forbiddenPhones, phone])
            : state.forbiddenPhones,
    regError: error,
    sended: false,
});

const handleResend = state => ({
    ...state,
    confirmaError: null,
    regError: null,
});

const handleResendFailure = (state, regError) => ({
    ...state,
    confirmError: null,
    regError,
});

const handleResendSuccess = state => ({
    ...state,
    regError: null,
    smsCount: state.smsCount + 1,
});

const handleConfirm = state => ({
    ...state,
    confirmed: false,
    confirmError: null,
});

const handleConfirmSuccess = (state, payload = {}) => {
    return {
        ...state,
        confirmed: true,
        confirmError: null,
        smsCount: 0,
        ...payload,
    };
};

const handleConfirmFailure = (state, confirmError) => ({
    ...state,
    confirmed: false,
    confirmError,
});

const handleUpgrade = state => ({
    ...state,
    regError: null,
    sended: true,
});

const handleUpgradeFailure = (state, regError) => ({
    ...state,
    regError,
    sended: false,
});

const handleRegisterFull = state => ({
    ...state,
    regError: null,
    sended: true,
});

const handleRegisterFullSuccess = state => {
    return {
        ...state,
        sended: false,
    };
};

const handleRegisterFullFailure = (state, regError) => ({
    ...state,
    regError,
    sended: false,
});

const handleChangeNeedLoadProfile = (state, needLoadProfile) => ({
    ...state,
    needLoadProfile,
});

const handleChangeCache = (state, payload) =>
    assoc('cache', payload || {}, state);
const handleClearCache = state => assoc('cache', {}, state);

const handleConfirmEmailFailure = state => ({
    ...state,
    isConfirmEmailFailed: true,
});

const handleConfirmEmailSended = state => ({
    ...state,
    isConfirmEmailSended: true,
});

const handleInviteSuccess = state => ({
    ...state,
    isInviteSuccess: true,
});

const handleShowReferralModal = state => ({
    ...state,
    isReferralModalOpen: true,
});

const handleCloseReferralModal = state => ({
    ...state,
    isReferralModalOpen: false,
});

const handleFetchDraftProfile = state => ({
    ...state,
    isDraftProfileLoading: true,
});

const handleFetchDraftProfileSuccess = (state, draftProfile) => ({
    ...state,
    draftProfile,
    isDraftProfileLoading: false,
});

const handleFetchDraftProfileFailure = state => ({
    ...state,
    isDraftProfileLoading: false,
});

const handleStartRegistrationB2B = (state, payload) => {
    const { invitation } = payload;

    return {
        ...state,
        business: {
            ...state.business,
            invitation,
        },
    };
};

const handleStartRegistrationB2BFailure = (state, error) => {
    return {
        ...state,
        regError: error,
    };
};

const handleSetInvitationData = (state, payload) => {
    return {
        ...state,
        invitation: {
            ...payload,
        },
    };
};

export default createReducer(
    {
        [changeCache]: handleChangeCache,
        [changeNeedLoadProfile]: handleChangeNeedLoadProfile,
        [clearCache]: handleClearCache,
        [closeReferralModal]: handleCloseReferralModal,
        [confirm]: handleConfirm,
        [confirmEmailFailure]: handleConfirmEmailFailure,
        [confirmEmailSended]: handleConfirmEmailSended,
        [confirmFailure]: handleConfirmFailure,
        [confirmSuccess]: handleConfirmSuccess,
        [fetchDraftProfile]: handleFetchDraftProfile,
        [fetchDraftProfileFailure]: handleFetchDraftProfileFailure,
        [fetchDraftProfileSuccess]: handleFetchDraftProfileSuccess,
        [inviteSuccess]: handleInviteSuccess,
        [registerFull]: handleRegisterFull,
        [registerFullFailure]: handleRegisterFullFailure,
        [registerFullSuccess]: handleRegisterFullSuccess,
        [registerShort]: handleRegisterShort,
        [registerShortFailure]: handleRegisterShortFailure,
        [registerShortSuccess]: handleRegisterShortSuccess,
        [resend]: handleResend,
        [resendFailure]: handleResendFailure,
        [resendSuccess]: handleResendSuccess,
        [resetRegistrationError]: handleResetRegistrationError,
        [resetShortRegistration]: handleResetShortRegistration,
        [setInvitationData]: handleSetInvitationData,
        [showReferralModal]: handleShowReferralModal,
        [startRegistrationB2B]: handleStartRegistrationB2B,
        [startRegistrationB2BFailure]: handleStartRegistrationB2BFailure,
        [upgrade]: handleUpgrade,
        [upgradeFailure]: handleUpgradeFailure,
    },
    initialState,
);

export * from './selectors';
