import React, { PureComponent } from 'react';
import { AnalyticsContext } from 'contexts';
import { arrayOf, bool, func, shape, string } from 'prop-types';

import cx from './Cards.sss';

import Card from '../Card';

export default class Cards extends PureComponent {
    static propTypes = {
        cards: arrayOf(
            shape({
                cardholder: string.isRequired,
                date: string,
                edit: bool,
                id: string.isRequired,
                main: bool,
                needConfirmForRemove: bool,
                number: string.isRequired,
                type: string.isRequired,
            }),
        ).isRequired,
        onChangeConfirmationRemove: func.isRequired,
        onEdit: func.isRequired,
        onRemove: func.isRequired,
    };

    handleRemove = id => {
        this.props.onRemove({
            id,
        });
    };

    handleEdit = id => {
        const { sendEvent } = this.context;

        this.props.onEdit({
            edit: true,
            id,
        });

        sendEvent({
            event: 'edit_info',
            id: 'traveller_profile',
            result: 'initial_click',
            step: 'travel_history',
        });
    };

    handleClose = id => {
        this.props.onEdit({
            edit: false,
            id,
        });
    };

    renderCard(item) {
        const { id, edit } = item;
        const { onChangeConfirmationRemove } = this.props;

        return (
            <div
                key={id}
                className={cx('card', {
                    card_edit: edit,
                })}
            >
                <Card
                    {...item}
                    onChangeConfirmationRemove={onChangeConfirmationRemove}
                    onClose={this.handleClose}
                    onEdit={this.handleEdit}
                    onRemove={this.handleRemove}
                />
            </div>
        );
    }

    render() {
        const { cards } = this.props;

        return (
            <div className={cx('cards')}>
                {cards.map(this.renderCard, this)}
            </div>
        );
    }
}

Cards.contextType = AnalyticsContext;
