import React from 'react';
import { bool, func, node, object, string } from 'prop-types';
import { reduxForm } from 'redux-form';

import { EDIT_FORM_NAME } from '../../../constants';
import { ModalForm } from '../../../ModalForm';

const ModalFormEdit = ({
    onClose,
    onSubmit,
    handleSubmit,
    submitting,
    errorMessage,
    onRemove,
    defaultImage,
}) => {
    return (
        <ModalForm
            defaultImage={defaultImage}
            errorMessage={errorMessage}
            formName={EDIT_FORM_NAME}
            handleSubmit={handleSubmit}
            submitText="com.profile.pets.modal.edit.button.save"
            submitting={submitting}
            title="com.profile.pets.modal.edit.title"
            onClose={onClose}
            onRemove={onRemove}
            onSubmit={onSubmit}
        />
    );
};

ModalFormEdit.propTypes = {
    defaultImage: node,
    errorMessage: string,
    handleSubmit: func,
    id: string.isRequired,
    initialValues: object,
    onClose: func.isRequired,
    onRemove: func.isRequired,
    onSubmit: func.isRequired,
    submitting: bool,
};

const ReduxForm = reduxForm({
    form: EDIT_FORM_NAME,
})(ModalFormEdit);

export { ReduxForm as ModalFormEdit };
