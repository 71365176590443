import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import Notifications from 'components/Notifications';
import {
    getScrollId,
    MILES_ACTIVATE_STATUS_ID,
    MILES_ACTIVATE_STATUS_NOTIFICATION,
    MILES_EXPIRATION_ID,
    MILES_PROLONGATION_ID,
    MILES_UPGRADE_ID,
} from 'containers/ActionMiles/ids';
import { array, bool, func, node, object, string } from 'prop-types';
import { compose, omit } from 'ramda';
import { bindActionCreators } from 'redux';
import { createSelector } from 'reselect';
import { openLoginModal } from 'store/modules/authentication';
import { closeWarning, getWarnings } from 'store/modules/documents';
import { closeMilesNotification } from 'store/modules/loyalty';
import { changeExpandedMileActions } from 'store/modules/miles';
import { getEmail, getTelephone, setEdit } from 'store/modules/profile';
import { changeNeedLoadProfile } from 'store/modules/registration';
import { capitalize } from 'utils/format';
import { scrollerTo } from 'utils/scroller';
import { isObject } from 'utils/type';

import { getMilesNotifications } from './miles';
import { getProfileNotifications } from './profile';
import { getDocumentExpirationWarning, getInfo } from './shared';
import { getTravelsNotifications } from './travels';

const getNotifications = createSelector(
    getProfileNotifications,
    getMilesNotifications,
    getTravelsNotifications,
    (state, props) => props,
    (profile, miles, travels, { isProfile, isMiles, isTravels }) => {
        if (isProfile && profile) {
            return profile;
        }
        if (isMiles && miles) {
            return miles;
        }
        if (isTravels && travels) {
            return travels;
        }

        return [];
    },
);

const mapStateToProps = (state, props) => {
    const email = getEmail(state);

    const notifications = getNotifications(state, props);
    const info = getInfo(state, { ...props, email });
    const docExpirationWarnings = props.isProfile
        ? getDocumentExpirationWarning(state)
        : [];

    return {
        fields: {
            email,
            telephone: getTelephone(state),
        },
        notifications: [...info, ...docExpirationWarnings, ...notifications],
        warnings: getWarnings(state),
    };
};

const mapDispatchToProps = dispatch => ({
    ...bindActionCreators(
        {
            changeExpandedMileActions,
            changeNeedLoadProfile,
            closeMilesNotification,
            openLoginModal,
            setEdit,
        },
        dispatch,
    ),
    onCloseWarning: ids => dispatch(closeWarning({ ids })),
});

class NotificationsContainer extends PureComponent {
    static propTypes = {
        changeExpandedMileActions: func,
        changeNeedLoadProfile: func,
        closeMilesNotification: func,
        expirationWarningText: string,
        fields: object,
        info: node,
        isErrorBussines: bool,
        isMiles: bool,
        isProcessBussines: bool,
        isProfile: bool,
        isSuccessBussines: bool,
        isTempCard: bool,
        notifications: array,
        onCloseWarning: func,
        onScroll: func,
        openLoginModal: func,
        setEdit: func,
        warnings: array,
    };

    static defaultProps = {
        warnings: [],
    };

    handleCloseWarnings = () => {
        const { warnings, onCloseWarning } = this.props;

        if (onCloseWarning && warnings.length > 0) {
            onCloseWarning(warnings.map(doc => doc.id));
        }
    };

    handleCloseMilesNotification = () => {
        if (this.props.closeMilesNotification) {
            this.props.closeMilesNotification();
        }
    };

    handleOpenProlongation = () => {
        if (this.props.changeExpandedMileActions) {
            this.props.changeExpandedMileActions({
                activateOnly: true,
                id: 'prolongation',
            });

            scrollerTo(getScrollId(MILES_PROLONGATION_ID), {
                delay: 0,
            });
        }
    };

    handleOpenExpire = () => {
        if (this.props.changeExpandedMileActions) {
            this.props.changeExpandedMileActions({
                activateOnly: true,
                id: 'expire',
            });

            scrollerTo(getScrollId(MILES_EXPIRATION_ID), {
                delay: 0,
            });
        }
    };

    handleOpenUpgrade = () => {
        if (this.props.changeExpandedMileActions) {
            this.props.changeExpandedMileActions({
                activateOnly: true,
                id: 'upgrade',
            });

            scrollerTo(getScrollId(MILES_UPGRADE_ID), {
                delay: 0,
            });
        }
    };

    handleOpenActivateStatus = () => {
        if (this.props.changeExpandedMileActions) {
            this.props.changeExpandedMileActions({
                activateOnly: true,
                id: 'activateStatus',
            });

            scrollerTo(getScrollId(MILES_ACTIVATE_STATUS_ID), {
                delay: 0,
            });
        }
    };

    handleChangeNeedLoadProfile = () => {
        this.props.changeNeedLoadProfile(true);
    };

    handleOpenLogin = () => {
        this.props.openLoginModal();
    };

    get notifications() {
        const { notifications } = this.props;

        return notifications.map(item => {
            const newItem = item;

            if (item.action) {
                let actionType;

                let actionName = item.action;

                if (isObject(item.action)) {
                    actionName = item.action.name;
                    actionType = item.action.type;
                }

                newItem[actionType || 'onClick'] =
                    this[actionName] || this[`handle${capitalize(actionName)}`];

                if (actionName === 'openActivateStatus') {
                    newItem.scrollTo = getScrollId(
                        MILES_ACTIVATE_STATUS_NOTIFICATION,
                    );
                }
            }

            if (item.secondaryAction) {
                newItem.onClickSecondary =
                    this[item.secondaryAction] ||
                    this[`handle${capitalize(item.secondaryAction)}`];
            }

            return omit(['action', 'secondaryAction'], newItem);
        });
    }

    render() {
        const { isTempCard, info } = this.props;

        return (
            <Notifications
                notifications={this.notifications}
                {...{ info, isTempCard }}
            />
        );
    }
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
    NotificationsContainer,
);
