import React, { useMemo } from 'react';
import { useAnalyticsContext } from 'contexts';
import { bool, func, string } from 'prop-types';

import { RoundButton, Text } from '@s7/ui-kit';
import { CheckLinear, InfoFiled, Pencil, PlusLinear } from '@s7/ui-kit/icons';

import cx from './ContactBlock.sss';

import { contactType } from '../../data';
import Dictionary from '../../shared/Dictionary';
import { normalizePhone } from '../../utils/format';
import { dictKeys, getTestIdGetter } from '../../utils/testIds';

const LABEL_BY_TYPE = {
    EMAIL: <Dictionary text="com.profile.contact.view.email" html />,
    PHONE: <Dictionary text="com.profile.contact.view.phone" html />,
};

const TEXTS = {
    add: <Dictionary text="com.action.add" />,
    confirm: <Dictionary text="com.action.confirm" />,
    notExists: <Dictionary text="com.profile.emptym" html />,
    unconfirmed: <Dictionary text="com.profile.unconfirmed" html />,
};

const getTestId = getTestIdGetter(dictKeys.PROFILE_CONTACT_INFO);

export const ContactBlock = React.memo(function ContactBlock(props) {
    const { isVerified, type, value, onClick, withActions } = props;
    const { sendEvent } = useAnalyticsContext();

    const { icon, actionText } = useMemo(() => {
        if (isVerified) {
            return {
                actionText: <Dictionary text="com.action.change" />,
                icon: <Pencil />,
            };
        }

        return {
            actionText: value ? TEXTS.confirm : TEXTS.add,
            icon: value ? <CheckLinear /> : <PlusLinear />,
        };
    }, [isVerified, value]);

    const contactValue =
        type === contactType.PHONE ? normalizePhone(value) : value;
    const lowType = type.toLowerCase();

    const handleClick = e => {
        if (onClick) {
            onClick(e);
        }

        sendEvent({
            event: 'edit_info',
            id: 'contacts',
            result: 'initial_click',
            step: 'profile_info',
        });
    };

    return (
        <div className={cx('root')} {...getTestId(lowType)}>
            {value && !isVerified && (
                <span className={cx('tag')}>
                    <InfoFiled /> <Text>{TEXTS.unconfirmed}</Text>
                </span>
            )}

            <div className={cx('content')}>
                <Text color="N100" size="s">
                    {LABEL_BY_TYPE[type]}
                </Text>
                <Text size="l">{contactValue || TEXTS.notExists}</Text>
            </div>

            {withActions && (
                <div className={cx('action')}>
                    <RoundButton
                        direction="right"
                        size="xs"
                        text={actionText}
                        view="b2c"
                        onClick={handleClick}
                        {...getTestId(`${lowType}EditButton`)}
                    >
                        {icon}
                    </RoundButton>
                </div>
            )}
        </div>
    );
});

ContactBlock.propTypes = {
    isVerified: bool,
    onClick: func,
    type: string,
    value: string,
    withActions: bool,
};

ContactBlock.defaultProps = {
    onClick: () => {},
};
