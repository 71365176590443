import React from 'react';
import { useDispatch } from 'react-redux';
import { Dictionary } from 'shared';
import { setFooterChatWidgetOpen } from 'store/modules/shared';

import { Button, Text, Title } from '@s7/ui-kit';
import { useWindowSize } from '@s7/ui-kit/hooks';
import { Message } from '@s7/ui-kit/icons';

import cx from './Questions.sss';

import { testIds } from '../../constants';

export const Questions = () => {
    const dispatch = useDispatch();
    const { width } = useWindowSize();

    const handleOpenChat = () => {
        if (width < 1024) {
            dispatch(setFooterChatWidgetOpen(true));
        } else {
            const chatWidgetButton = document.getElementById('buttonLogo');

            if (chatWidgetButton) {
                chatWidgetButton.click();
            }
        }
    };

    return (
        <div className={cx('root')} {...testIds.questions}>
            <div className={cx('title')}>
                <Title
                    alignment="center"
                    level={3}
                    {...testIds.questions.title}
                >
                    <Dictionary text="com.profile.pets.page.questions.title" />
                </Title>
            </div>
            <Button
                size="large"
                theme="white"
                block
                onClick={handleOpenChat}
                {...testIds.questions.chat}
            >
                <span className={cx('icon')}>
                    <Message />
                </span>
                <Text
                    as="span"
                    display="inline-block"
                    size="l"
                    weight="semibold"
                >
                    <Dictionary text="com.profile.pets.page.questions.button" />
                </Text>
            </Button>
        </div>
    );
};
