import React from 'react';
import { bool, node, object, shape } from 'prop-types';

import { Title } from '@s7/ui-kit';

import cx from './Section.sss';

const Section = ({ title, children, testIds, isHeadless, bottom }) => (
    <section className={cx('root')} {...testIds.root}>
        {!isHeadless && (
            <div className={cx('head')}>
                <div className={cx('title')}>
                    <Title color="N300" level={5} {...testIds.title}>
                        {title}
                    </Title>
                </div>
            </div>
        )}
        <div className={cx('content')}>{children}</div>
        {bottom && <div className={cx('bottom')}>{bottom}</div>}
    </section>
);

const testIdsShape = shape({
    archiveButton: object,
    root: object,
    title: object,
});

Section.propTypes = {
    bottom: node,
    children: node,
    isHeadless: bool,
    testIds: testIdsShape,
    title: node,
};

Section.defaultProps = {
    testIds: {
        archiveButton: {},
        root: {},
        title: {},
    },
};

export default React.memo(Section);
