import React from 'react';
import { bool, func, string } from 'prop-types';
import { Dictionary } from 'shared';

import { StatusMessage } from '@s7/ui-kit';

export const MyCompanyInvitation = ({
    confirming,
    disabling,
    name,
    onCancel,
    onConfirm,
}) => (
    <StatusMessage
        cancelButton={
            <Dictionary text="com.profile.mycompany.invitation.disable" />
        }
        confirmButton={
            <Dictionary text="com.profile.mycompany.invitation.confirm" />
        }
        title={
            <>
                <Dictionary text="com.profile.mycompany.invitation.title" />{' '}
                {name}
            </>
        }
        cancelProps={{ disabled: confirming, loading: disabling }}
        confirmProps={{ disabled: disabling, loading: confirming }}
        onCancel={onCancel}
        onConfirm={onConfirm}
    >
        <Dictionary text="com.profile.mycompany.invitation.description" html />
    </StatusMessage>
);

MyCompanyInvitation.propTypes = {
    confirming: bool,
    disabling: bool,
    name: string,
    onCancel: func,
    onConfirm: func,
};
