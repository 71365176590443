import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PROFILE_ANCHORS } from 'components/ProfileView/constants';
import { bool, func, number, string } from 'prop-types';

import DocumentContainerView from './DocumentContainerView';

import {
    getIsEdit,
    getNeedConfirmForRemove,
    getRemovingStatus,
    setEdit,
} from '../../store/modules/documents';
import DocumentEdit from '../DocumentEdit/DocumentEdit';

const DocumentContainer = ({
    counter,
    getTestId,
    id,
    isArchive,
    isNew,
    isVisa,
    onDocscanClick,
    onReset,
}) => {
    const dispatch = useDispatch();

    const isEdit = useSelector(state => getIsEdit(id, state));
    const removingStatus = useSelector(state => getRemovingStatus(id, state));
    const needConfirmForRemove = useSelector(state =>
        getNeedConfirmForRemove(id, state),
    );

    const handleSetEdit = (status = true) =>
        dispatch(
            setEdit({
                id,
                status,
            }),
        );

    const handleClose = () => {
        onReset();
        handleSetEdit(false);
    };

    useEffect(() => {
        const type = isVisa ? PROFILE_ANCHORS.visa : PROFILE_ANCHORS.documents;

        if (window.location.hash === `#${type}/${id}`) {
            dispatch(
                setEdit({
                    id,
                    status: true,
                }),
            );
        }
    }, [isVisa, id, dispatch]);

    if (isEdit || isNew || removingStatus) {
        return (
            <DocumentEdit
                counter={counter}
                id={id}
                isNew={isNew}
                isVisa={isVisa}
                needConfirmForRemove={needConfirmForRemove}
                removingStatus={removingStatus}
                onDocscanClick={onDocscanClick}
                onReset={handleClose}
                {...{ disabled: isVisa && isArchive, getTestId }}
            />
        );
    }

    return (
        <DocumentContainerView
            isVisa={isVisa}
            onSetEdit={handleSetEdit}
            {...{ getTestId, id, isArchive }}
        />
    );
};

DocumentContainer.propTypes = {
    counter: number,
    getTestId: func,
    id: string,
    isArchive: bool,
    isNew: bool,
    isVisa: bool,
    onDocscanClick: func,
    onReset: func,
};

DocumentContainer.defaultProps = {
    getTestId: () => {},
    onReset: () => {},
};

export default React.memo(DocumentContainer);
