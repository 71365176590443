import React from 'react';
import { BirthCertificate } from 'components/FormSections';
import { FormSection } from 'redux-form';
import { Column } from 'shared';

import { DateFields } from './DateFields';

import { fieldsPropTypes } from '../propTypes';

const testPrefix = 'birthCertificate';

export const BirthFields = props => {
    const { getTestId, disabled } = props;

    return (
        <>
            <Column desktop={50} autoZIndex>
                <FormSection name="seriesNumber">
                    <BirthCertificate
                        numberProps={{
                            disabled,
                            inputProps: getTestId(`${testPrefix}Number`),
                            tooltipProps: {
                                ...getTestId(`${testPrefix}Number`, {
                                    error: true,
                                }),
                            },
                        }}
                        seriesProps={{
                            disabled,
                            inputProps: getTestId(`${testPrefix}Series`),
                            tooltipProps: {
                                ...getTestId(`${testPrefix}Series`, {
                                    error: true,
                                }),
                            },
                        }}
                    />
                </FormSection>
            </Column>
            <Column desktop={50}>
                <DateFields
                    testIds={{
                        dateOfIssue: getTestId(`${testPrefix}DateOfIssue`),
                        dateOfIssueError: getTestId(
                            `${testPrefix}DateOfIssue`,
                            { error: true },
                        ),
                        expireDate: getTestId(`${testPrefix}ExpireDate`),
                        expireDateError: getTestId(`${testPrefix}ExpireDate`, {
                            error: true,
                        }),
                    }}
                    onlyEffective
                    {...props}
                />
            </Column>
        </>
    );
};

BirthFields.propTypes = fieldsPropTypes;
