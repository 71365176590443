import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { HASH_PROFILE_DOCUMENTS, HASH_PROFILE_VISA } from 'consts';
import { useAnalyticsContext, useGoogleAnalyticContext } from 'contexts';
import { documentType } from 'data';
import { loadingError } from 'language/errors';
import { bool } from 'prop-types';
import { includes, isEmpty } from 'ramda';
import { Dictionary } from 'shared';
import {
    addNew,
    closeNew,
    getCounter,
    getDocumentsEdit,
    getDocumentsIds,
    getIsDocsShowNewForm,
    isError as getIsError,
} from 'store/modules/documents';
import { getBirthdate } from 'store/modules/profile';
import GroupData from 'ui-kit/molecules/GroupData';
import { dictKeys, getTestIdGetter } from 'utils/testIds';

import { RoundButton, Text } from '@s7/ui-kit';
import { Archive, ArrowLeft, PlusLinear } from '@s7/ui-kit/icons';

import { Document } from '../Document';

const typesForDocuments = [
    documentType.INTERNAL_PASSPORT,
    documentType.INTERNATIONAL_PASSPORT,
    documentType.BIRTH_CERTIFICATE,
    documentType.MILITARY_CARD,
    documentType.OTHER,
];
const typesForVisa = [documentType.VISA];

const documentData = {
    docsType: typesForDocuments,
    empty: (
        <Text size="l">
            <Dictionary text="com.profile.documents.docs.empty" />
        </Text>
    ),
    getTestIds: getTestIdGetter(dictKeys.PROFILE_DOCUMENTS),
    textButton: <Dictionary text="com.profile.documents.docs.button" />,
    title: <Dictionary text="com.profile.documents.docs.title" />,
};
const visaData = {
    docsType: typesForVisa,
    empty: (
        <Text size="l">
            <Dictionary text="com.profile.documents.visa.empty" />
        </Text>
    ),
    getTestIds: getTestIdGetter(dictKeys.PROFILE_VISA),
    textButton: <Dictionary text="com.profile.documents.visa.button" />,
    title: <Dictionary text="com.profile.documents.visa.title" />,
};

const archiveMessages = {
    back: <Dictionary text="com.action.back" />,
    title: <Dictionary text="com.profile.documents.archive.title" />,
};

const DocumentsContainer = ({ isVisa, withArchive }) => {
    const dispatch = useDispatch();
    const { sendMyProfileEvent } = useGoogleAnalyticContext();
    const { sendEvent } = useAnalyticsContext();

    const [isArchive, setIsArchive] = useState(false);

    // данное свойство нужно для передачи компоненту <Document> чтобы он обновился в случае если изменится значение
    // даты рождения. bug(https://s7airlines.atlassian.net/browse/CSSSRS7-1846)
    const birthday = useSelector(getBirthdate);

    const { docsType, empty, title, textButton, testIds, getTestIds } =
        useMemo(() => {
            const data = isVisa ? visaData : documentData;

            data.testIds = {
                archiveButton: data.getTestIds('archiveButton'),
                item: data.getTestIds('row'),
                root: data.getTestIds('container'),
                title: data.getTestIds('title'),
            };

            return data;
        }, [isVisa]);

    useEffect(() => {
        const type = isVisa ? HASH_PROFILE_VISA : HASH_PROFILE_DOCUMENTS;

        if (window.location.hash === `#${type}/new`) {
            dispatch(addNew());
        }
    }, [dispatch, isVisa]);

    const ids = useSelector(state => getDocumentsIds()(state, docsType));
    const dataWithExpiredDate = useSelector(state =>
        getDocumentsIds({ onlyExpiredDate: true })(state, docsType),
    );
    const dataWithoutExpiredDate = useSelector(state =>
        getDocumentsIds({ onlyNotExpiredDate: true })(state, docsType),
    );

    const isError = useSelector(getIsError);
    const counter = useSelector(state => getCounter(state, docsType));

    const edit = useSelector(getDocumentsEdit);

    const editable = useMemo(() => {
        const currentIds = isArchive
            ? dataWithExpiredDate
            : dataWithoutExpiredDate;

        return (currentIds || []).map(id => includes(id, edit));
    }, [isArchive, dataWithExpiredDate, dataWithoutExpiredDate, edit]);

    const data = useMemo(() => {
        if (withArchive) {
            return isArchive ? dataWithExpiredDate : dataWithoutExpiredDate;
        }

        return ids;
    }, [
        withArchive,
        ids,
        isArchive,
        dataWithExpiredDate,
        dataWithoutExpiredDate,
    ]);

    const showDocsForm = isVisa ? 'showNewVisaForm' : 'showNewDocsForm';

    const withAdd = useSelector(state =>
        getIsDocsShowNewForm(state, showDocsForm),
    );

    const setWithAdd = (status = true) => {
        const action = status ? addNew : closeNew;

        dispatch(action(showDocsForm));
    };

    const archiveProps = useMemo(() => {
        if (!withArchive || isEmpty(dataWithExpiredDate)) {
            return {};
        }

        return {
            archive: {
                icon: isArchive ? <ArrowLeft /> : <Archive />,
                onClick: () => setIsArchive(!isArchive),
                title: isArchive ? archiveMessages.back : archiveMessages.title,
            },
        };
    }, [dataWithExpiredDate, isArchive, withArchive]);

    const handleAdd = () => {
        const step = isVisa ? 'visas' : 'documents';

        sendEvent({
            event_name: 'edit_info',
            item_id: `item_${step}`,
            result: 'add_new',
            step,
        });

        setWithAdd(true);
    };

    const handleCancel = () => {
        setWithAdd(false);
    };

    const handleDocscanClick = useCallback(() => {
        if (isVisa) {
            sendMyProfileEvent({
                eventActionPath: ['documents', 'onDocscanClick'],
            });
        }

        sendMyProfileEvent({
            eventActionPath: ['documents', 'onVisaDocscanClick'],
        });
    }, [isVisa, sendMyProfileEvent]);

    const documents = useMemo(() => {
        if (isEmpty(data)) {
            return empty;
        }

        return data.map(id => (
            <Document
                key={id}
                birthday={birthday}
                id={id}
                isVisa={isVisa}
                isRow
                {...{ getTestId: getTestIds, isArchive }}
            />
        ));
    }, [birthday, data, empty, getTestIds, isVisa, isArchive]);

    useEffect(() => {
        if (withArchive && dataWithExpiredDate.length === 0) {
            setIsArchive(false);
        }
    }, [dataWithExpiredDate, withArchive]);

    return (
        <GroupData
            editingChild={editable}
            error={loadingError}
            isError={isError}
            testIds={testIds}
            title={title}
            isDocs
            {...archiveProps}
        >
            {documents}

            {withAdd ? (
                <Document
                    key={`newdoc_${counter}`}
                    counter={counter}
                    isVisa={isVisa}
                    isNew
                    onDocscanClick={handleDocscanClick}
                    onReset={handleCancel}
                    {...{ getTestId: getTestIds }}
                />
            ) : (
                <RoundButton
                    direction="right"
                    size="xs"
                    text={textButton}
                    view="b2c"
                    onClick={handleAdd}
                    {...getTestIds('addButton')}
                >
                    <PlusLinear />
                </RoundButton>
            )}
        </GroupData>
    );
};

DocumentsContainer.propTypes = {
    isVisa: bool,
    withArchive: bool,
};

export default React.memo(DocumentsContainer);
