import React, { useCallback, useMemo, useState } from 'react';
import { PROFILE_MODALS } from 'components/ProfileView/constants';
import { contactType } from 'data';
import { useWindowSize } from 'hooks';
import { bool, func, number, object, shape, string } from 'prop-types';
import { Field } from 'redux-form';
import { Dictionary } from 'shared';
import Timer from 'ui-kit/molecules/Timer';
import { createFieldValidate } from 'utils/createValidate';
import { normalizePhone } from 'utils/format';
import { dictKeys, getTestIdGetter } from 'utils/testIds';

import { Button, Link, Loader, StatusMessage, Text, Title } from '@s7/ui-kit';
import { CloseLinear, Repeat } from '@s7/ui-kit/icons';
import noop from '@s7/ui-kit/utils/noop';

import cx from './ContactEdit.sss';

import { PhoneField, TextField } from '../Fields';
import { ModalWrapper } from '../Modal';

const getTestId = getTestIdGetter(dictKeys.PROFILE_CONTACT_INFO);

const TEXTS_BY_TYPE = ({ type, value }) => {
    const texts = {
        [contactType.PHONE]: {
            changeTitle: (
                <Dictionary
                    text="com.profile.contact.change.phone.title"
                    html
                />
            ),
            changeWarning: (
                <Dictionary
                    text="com.profile.contact.change.phone.warning"
                    html
                />
            ),
            info: (
                <Dictionary
                    text="com.profile.contact.change.phone.action-text"
                    html
                />
            ),
            label: (
                <Dictionary
                    text="com.profile.contact.change.phone.label"
                    html
                />
            ),
            sentInfo: (
                <Dictionary
                    transform={text =>
                        text.replace(/%phone%/, normalizePhone(value))
                    }
                    text="com.profile.contact.edit.phone.sent-message"
                    html
                />
            ),
            title: <Dictionary text="com.profile.contact.view.phone" html />,
            warning: (
                <Dictionary text="com.profile.contact.change.phone.info" html />
            ),
        },
        [contactType.EMAIL]: {
            changeTitle: (
                <Dictionary text="com.profile.contact.edit.email.title" html />
            ),
            changeWarning: (
                <Dictionary
                    text="com.profile.contact.change.email.warning"
                    html
                />
            ),
            info: (
                <Dictionary text="com.profile.contact.edit.email.info" html />
            ),
            label: <Dictionary text="com.profile.contact.view.email" html />,
            sentInfo: (
                <Dictionary
                    text="com.profile.contact.edit.email.enter-code"
                    transform={text => text.replace(/%email%/, value)}
                    html
                />
            ),
            title: <Dictionary text="com.profile.contact.view.email" html />,
        },
    };

    return texts[type];
};

const TEXTS = {
    cancel: <Dictionary text="com.title.cancel" />,
    change: <Dictionary text="com.action.change" />,
    codeLabel: <Dictionary text="com.title.confirmation-code" />,
    confirm: <Dictionary text="com.action.confirm" />,
    confirmTitleEmail: <Dictionary text="com.title.confirmation-email" />,
    confirmTitlePhone: <Dictionary text="com.title.confirmation-phone" />,
    getCode: <Dictionary text="com.action.get-code" />,
    requestCodeAfter: <Dictionary text="com.action.get-code-after" />,
    requestCodeAgain: <Dictionary text="com.action.get-code-again" />,
    save: <Dictionary text="com.action.save" />,
    support: (
        <Dictionary text="com.profile.contact.confirm.not-receive-code" html />
    ),
};

const validations = {
    email: createFieldValidate({
        email: {
            errorText: <Dictionary text="com.validation.email.invalid" />,
        },
    }),
    phone: createFieldValidate({
        phone: {
            errorText: <Dictionary text="com.validation.phone.invalid" />,
        },
        required: true,
    }),
};

const getValidation = ({ isPhone, isSent }) => {
    if (isSent) {
        return undefined;
    }

    return isPhone ? [validations.phone] : [validations.email];
};

const modalIdByType = {
    [contactType.EMAIL]: PROFILE_MODALS.editEmail,
    [contactType.PHONE]: PROFILE_MODALS.editPhone,
};

function ContactEdit(props) {
    const {
        currentEditContactType,
        editContact,
        editContactWarnings,
        isValueEmpty,
        onChangeContact,
        onClose,
        onCloseNotification,
        onCloseEditContactWarning,
        onConfirmContact,
        onResendCode,
        onResetTimer,
        remainingSeconds,
        submitting,
    } = props;

    const [localValue, setValue] = useState('');
    const {
        type,
        isVerified,
        isSent,
        notification = {},
        codeRequestsAmount,
    } = editContact;
    const { isDesktop } = useWindowSize();
    const fieldTexts = TEXTS_BY_TYPE({ type, value: localValue }) || {};
    const titleEmail = isVerified
        ? fieldTexts.changeTitle
        : TEXTS.confirmTitleEmail;
    const titlePhone = isVerified
        ? fieldTexts.changeTitle
        : TEXTS.confirmTitlePhone;
    const isPhone = type === contactType.PHONE;
    const isTime = remainingSeconds > 0;
    const isLoading = submitting;
    const lowType = type.toLowerCase();
    const modalId = modalIdByType[type];

    const [isClosedSupportInfo, setClosedSupportInfo] = useState(false);

    const handleCloseEditContactWarning = useCallback(() => {
        onCloseEditContactWarning(currentEditContactType);
    }, [currentEditContactType, onCloseEditContactWarning]);

    const handleCloseSupportInfo = useCallback(() => {
        setClosedSupportInfo(true);
    }, []);

    const handleCancel = useCallback(() => {
        onClose(true);
    }, [onClose]);

    const onChange = data => {
        setValue(isPhone ? data : data.target.value);
    };

    const fieldProps = {
        component: isPhone && !isSent ? PhoneField : TextField,
        disabled: isLoading,
        inputProps: { ...getTestId(`${lowType}Field`) },
        label: isSent ? TEXTS.codeLabel : fieldTexts.label,
        name: isSent ? 'secret' : 'value',
        tooltipProps: { ...getTestId(`${lowType}FieldValidation`) },
        validate: getValidation({ isPhone, isSent }),
        ...(isSent
            ? {
                  maxLength: 4,
                  normalize: val =>
                      isPhone
                          ? val.replace(/[^\d]/g, '')
                          : val.replace(/[^\w]/g, ''),
                  placeholder: isPhone ? '0000' : '____',
              }
            : { onChange }),
    };

    const calculatedNotification = useMemo(() => {
        if (notification.title) {
            return (
                <div
                    className={cx('notification')}
                    {...getTestId(`${lowType}Alert`)}
                >
                    <StatusMessage
                        title={
                            notification.description
                                ? notification.title
                                : undefined
                        }
                        view={notification.type}
                        onClose={onCloseNotification}
                    >
                        {notification.description || notification.title}
                    </StatusMessage>
                </div>
            );
        }

        if (editContactWarnings[currentEditContactType]) {
            return (
                <div
                    className={cx('notification')}
                    {...getTestId(`${lowType}Alert`)}
                >
                    <StatusMessage
                        view="warning"
                        onClose={handleCloseEditContactWarning}
                    >
                        {fieldTexts.changeWarning}
                    </StatusMessage>
                </div>
            );
        }

        return null;
    }, [
        fieldTexts.changeWarning,
        currentEditContactType,
        editContactWarnings,
        notification.description,
        notification.title,
        notification.type,
        onCloseNotification,
        handleCloseEditContactWarning,
        lowType,
    ]);

    const layout = (
        <>
            <div className={cx('content')}>
                {!isClosedSupportInfo && codeRequestsAmount > 1 && (
                    <div className={cx('notification')}>
                        <StatusMessage
                            onClose={handleCloseSupportInfo}
                            {...getTestId(`${lowType}Notification`)}
                        >
                            {TEXTS.support}
                        </StatusMessage>
                    </div>
                )}

                {calculatedNotification}

                <div className={cx('info')}>
                    <Text>
                        {isSent ? fieldTexts.sentInfo : fieldTexts.info}
                    </Text>
                </div>

                <div className={cx('form')}>
                    <Field {...fieldProps} />
                </div>
            </div>

            <div className={cx('actions', { 'actions-sent-code': isSent })}>
                {isSent ? (
                    <Link
                        as="button"
                        classes={{ root: cx('resend') }}
                        {...getTestId(`${lowType}ResendButton`)}
                        disabled={isTime || submitting}
                        icon={<Repeat />}
                        type="button"
                        view="b2c"
                        onClick={onResendCode}
                    >
                        {isTime ? (
                            <>
                                {TEXTS.requestCodeAfter}{' '}
                                <Timer
                                    remainingSeconds={remainingSeconds}
                                    onFinish={() =>
                                        onResetTimer(type.toLowerCase())
                                    }
                                />
                            </>
                        ) : (
                            TEXTS.requestCodeAgain
                        )}
                    </Link>
                ) : (
                    <Button
                        block
                        {...getTestId(`${lowType}CodeButton`)}
                        disabled={submitting || isValueEmpty}
                        theme="b2c"
                        onClick={
                            isVerified ? onChangeContact : onConfirmContact
                        }
                    >
                        {TEXTS.getCode}
                    </Button>
                )}

                <Button
                    block
                    {...getTestId(`${lowType}CancelButton`)}
                    disabled={submitting}
                    theme="secondary"
                    onClick={handleCancel}
                >
                    {TEXTS.cancel}
                </Button>
            </div>
        </>
    );

    return isDesktop ? (
        <ModalWrapper id={modalId} onClose={onClose} onRequestClose={noop}>
            <div className={cx('root')} {...getTestId(`${lowType}Popup`)}>
                <div className={cx('title')}>
                    <Title level={3}>{isPhone ? titlePhone : titleEmail}</Title>

                    <button
                        className={cx('title_icon')}
                        type="button"
                        onClick={onClose}
                        {...getTestId(`${lowType}PopupCloseButton`)}
                    >
                        <CloseLinear />
                    </button>
                </div>

                {layout}

                {isLoading && (
                    <div className={cx('loader')}>
                        <Loader color="W100" view="flow" />
                    </div>
                )}
            </div>
        </ModalWrapper>
    ) : (
        <ModalWrapper id={modalId} onClose={onClose} onRequestClose={noop}>
            <div {...getTestId(`${lowType}Popup`)}>
                <div className={cx('title')}>
                    <span className={cx('title_text')}>
                        <Text weight="semibold">
                            {isPhone ? titlePhone : titleEmail}
                        </Text>
                    </span>

                    <button
                        className={cx('title_icon')}
                        type="button"
                        onClick={onClose}
                        {...getTestId(`${lowType}PopupCloseButton`)}
                    >
                        <CloseLinear />
                    </button>
                </div>

                <div className={cx('root')}>
                    {layout}

                    {isLoading && (
                        <div className={cx('loader')}>
                            <Loader color="G600" view="flow" />
                        </div>
                    )}
                </div>
                <div className={cx('overlay')} />
            </div>
        </ModalWrapper>
    );
}

ContactEdit.propTypes = {
    currentEditContactType: string,
    editContact: object,
    editContactWarnings: shape({
        [contactType.EMAIL]: bool,
        [contactType.PHONE]: bool,
    }),
    isValueEmpty: bool,
    onChangeContact: func,
    onClose: func,
    onCloseEditContactWarning: func,
    onCloseNotification: func,
    onConfirmContact: func,
    onResendCode: func,
    onResetTimer: func,
    remainingSeconds: number,
    submitting: bool,
};

export default ContactEdit;
