import React, { useEffect } from 'react';
import { useGoogleAnalyticContext } from 'contexts';
import { arrayOf, bool, node, shape } from 'prop-types';

import cx from './GroupAccount.sss';
import { Rule } from './Rule';

const Rules = ({ isTablet, rules, rulesForTablet }) => {
    const { sendMyProfileGroupEvent } = useGoogleAnalyticContext();

    useEffect(() => {
        sendMyProfileGroupEvent({ eventAction: 'expand' });
    }, [sendMyProfileGroupEvent]);

    return (
        <div className={cx('rules')}>
            {isTablet
                ? rulesForTablet.map((row, index) => (
                      <div
                          key={`rule-row-${index + 1}`}
                          className={cx('rules_row')}
                      >
                          {row.map((rule, ruleIndex) => (
                              <Rule {...rule} key={`rule-${ruleIndex + 1}`} />
                          ))}
                      </div>
                  ))
                : rules.map((rule, index) => (
                      <Rule {...rule} key={`rule-${index + 1}`} />
                  ))}
        </div>
    );
};

const rulesShape = arrayOf(shape({ children: node, icon: node }));

Rules.propTypes = {
    isTablet: bool,
    rules: rulesShape,
    rulesForTablet: arrayOf(rulesShape),
};

export default React.memo(Rules);
