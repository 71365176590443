import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import NextLink from 'components/Link';
import { PETS_TYPE } from 'consts/pets';
import { useAnalyticsContext } from 'contexts';
import { shape, string } from 'prop-types';
import { Dictionary } from 'shared';
import { deletePet } from 'store/modules/pets';

import {
    Button,
    OnBoardingTooltip,
    RoundButton,
    Text,
    Title,
} from '@s7/ui-kit';
import { useMediaQuery } from '@s7/ui-kit/hooks';
import { Trash } from '@s7/ui-kit/icons';

import cx from './PetItem.sss';

import { testIds } from '../../constants';
import { getAnimalName, getAnimalWeight, getDefaultAvatar } from '../../utils';

export const PetItem = ({ avatar, customType, id, nickname, type, weight }) => {
    const breakpoints = useMediaQuery('xs');
    const isMobile = breakpoints.xs;
    const { sendEvent } = useAnalyticsContext();

    const [showTooltip, setShowTooltip] = useState(false);

    const dispatch = useDispatch();

    const Icon = getDefaultAvatar(type);

    const handleClickTooltip = () => {
        setShowTooltip(prev => !prev);
    };

    const handleCloseTooltip = () => {
        setShowTooltip(false);
    };

    const handleDeletePet = () => {
        sendEvent({
            event_name: 'click_submit',
            item_id: 'item_pets',
            result: 'go_to_delete',
            step: 'pets',
        });
        dispatch(deletePet({ petId: id }));
        setShowTooltip(false);
    };

    const onClickLink = () => {
        sendEvent({
            event: 'view_screen',
            flow: 'navigation',
            step: 'pets',
            ux_ui: 'avatar_menu',
        });
    };

    return (
        <div
            className={cx('root', { show_tooltip: showTooltip })}
            {...testIds.item.block}
        >
            <div className={cx('container')}>
                <div className={cx('image_container')}>
                    {avatar?.previewLink ? (
                        <img
                            alt={nickname}
                            className={cx('image')}
                            height={60}
                            src={avatar.previewLink}
                            {...testIds.item.avatar}
                            width={60}
                        />
                    ) : (
                        <Icon
                            className={cx('image')}
                            height={60}
                            width={60}
                            {...testIds.item.icon}
                        />
                    )}
                </div>
                <div className={cx('content')}>
                    <div className={cx('nickname')}>
                        <Title
                            color="N900"
                            level={4}
                            ellipsis
                            {...testIds.item.nickname}
                        >
                            {nickname}
                        </Title>
                    </div>
                    <div className={cx('info')}>
                        <Text color="N100" size="s" noWrap>
                            <span {...testIds.item.type}>
                                {type === PETS_TYPE.OTHER ? (
                                    customType
                                ) : (
                                    <Dictionary text={getAnimalName(type)} />
                                )}
                            </span>
                            {weight && (
                                <>
                                    {', '}
                                    <span {...testIds.item.weight}>
                                        <Dictionary
                                            text={getAnimalWeight(weight)}
                                            html
                                        />
                                    </span>
                                </>
                            )}
                        </Text>
                    </div>
                </div>
                <NextLink
                    className={cx('link')}
                    href={`pets/${id}`}
                    onClick={onClickLink}
                    {...testIds.link}
                />
                {!isMobile && (
                    <div className={cx('actions')}>
                        <OnBoardingTooltip
                            actions={
                                <>
                                    <Button
                                        theme="destructive"
                                        block
                                        onClick={handleDeletePet}
                                        {...testIds.item.deleteConfirm}
                                    >
                                        <Dictionary text="com.action.delete.confirm" />
                                    </Button>
                                    <Button
                                        theme="secondary"
                                        block
                                        onClick={handleCloseTooltip}
                                        {...testIds.item.deleteCancel}
                                    >
                                        <Dictionary text="com.action.cancel" />
                                    </Button>
                                </>
                            }
                            title={
                                <Dictionary text="com.profile.pets.page.remove" />
                            }
                            id="delete-pet"
                            opened={showTooltip}
                            view="dark"
                            disableHover
                            onClick={handleClickTooltip}
                            onClose={handleCloseTooltip}
                        >
                            <RoundButton
                                classes={{ root: cx('remove') }}
                                size="s"
                                view="secondary"
                                {...testIds.item.delete}
                            >
                                <Trash />
                            </RoundButton>
                        </OnBoardingTooltip>
                    </div>
                )}
            </div>
        </div>
    );
};

PetItem.propTypes = {
    avatar: shape({
        mainLink: string.isRequired,
        previewLink: string.isRequired,
    }),
    customType: string,
    id: string,
    nickname: string.isRequired,
    type: string.isRequired,
    weight: string,
};
