import React from 'react';
import { DATE_FORMATS } from 'consts/dateFormats';
import moment from 'moment';
import { forEach, length, not, prop, propOr } from 'ramda';
import { createSelector } from 'reselect';
import { Dictionary } from 'shared';

import {
    getBussines,
    isAuth as getIsBusinessAuth,
    isConfirmed as getIsBusinessConfirmed,
    isError as getIsBusinessError,
    isProcess as getIsBusinessProcess,
    isSuccess as getIsBusinessSuccess,
} from '../../store/modules/b2b';
import { getWarnings } from '../../store/modules/documents';
import { getDictionary } from '../../store/modules/languages';
import { getSessionValue } from '../../utils';
import { noun } from '../../utils/format';

const DD_MM_YYYY = DATE_FORMATS.DD.MM.YYYY_dot;

const getDateDifferenceMessage = (date, { daysText, monthsText }) => {
    const current = moment();
    const target = moment(date, DD_MM_YYYY);
    const diff = moment.duration(target.diff(current));

    if (diff.months() > 0) {
        return `${diff.months()} ${noun(diff.months(), monthsText)}`;
    }

    if (diff.days() > 0) {
        return `${diff.days()} ${noun(diff.days(), daysText)}`;
    }

    if (diff.days() === 0 && diff.hours() > 0) {
        return `${1} ${noun(1, daysText)}`;
    }

    return false;
};

const isBefore = (date1, date2) =>
    moment(date1, DD_MM_YYYY).isBefore(moment(date2, DD_MM_YYYY), 'hour');

export function getWarningMessage(warnings, dict) {
    if (not(warnings) || length(warnings) === 0) {
        return '';
    }
    const dateProp = 'expirationDate';
    const daysText = [
        dict.t('com.units.day.one'),
        dict.t('com.units.day.once'),
        dict.t('com.units.day.mult'),
    ];
    const monthsText = [
        dict.t('com.units.month.one'),
        dict.t('com.units.month.once'),
        dict.t('com.units.month.mult'),
    ];
    const documents = {
        INTERNAL_PASSPORT: dict.t(
            'com.profile.documents.warning.internal-passport',
        ),
        INTERNATIONAL_PASSPORT: dict.t(
            'com.profile.documents.warning.international-passport',
        ),
        OTHER: dict.t('com.profile.documents.warning.other'),
        VISA: dict.t('com.profile.documents.warning.visa'),
    };
    const expiringText = dict.t('com.profile.documents.warning.expiring');
    const expiredText = dict.t('com.profile.documents.warning.expired');

    let expiring = null;

    let expired = null;

    forEach(item => {
        if (item.status === 'EXPIRED') {
            if (
                not(expired) ||
                isBefore(prop(dateProp, expired), prop(dateProp, item))
            ) {
                expired = item;
            }
        } else if (
            not(expiring) ||
            isBefore(prop(dateProp, expiring), prop(dateProp, item))
        ) {
            expiring = item;
        }
    }, warnings);

    const differenceMessage = getDateDifferenceMessage(
        prop(dateProp, expiring),
        {
            daysText,
            monthsText,
        },
    );

    return expiring && differenceMessage
        ? expiringText
              .replace(
                  /%document%/,
                  propOr(documents.OTHER, prop('type', expiring), documents),
              )
              .replace(/%expirationDate%/, differenceMessage)
        : expiredText.replace(
              /%document%/,
              propOr(documents.OTHER, prop('type', expired), documents),
          );
}

export const getDocumentExpirationWarning = createSelector(
    getWarnings,
    getDictionary,
    (warnings, dictionary) => {
        const result = [];
        const message = getWarningMessage(warnings, dictionary);

        if (message) {
            result.push({
                action: { name: 'closeWarnings', type: 'onClose' },
                text: message,
            });
        }

        return result;
    },
);

export const getInfo = createSelector(
    getIsBusinessProcess,
    getIsBusinessSuccess,
    getIsBusinessError,
    getIsBusinessAuth,
    getIsBusinessConfirmed,
    getBussines,
    getDictionary,
    (state, props) => props,
    (
        isBusinessProcess,
        isBusinessSuccess,
        isBusinessError,
        isBusinessAuth,
        isBusinessConfirmed,
        { company, email: invitationEmail },
        dictionary,
        { isProfile, email },
    ) => {
        const info = [];

        if (isProfile && !isBusinessConfirmed && isBusinessProcess) {
            if (email && invitationEmail && email !== invitationEmail) {
                info.push({
                    icon: 'error',
                    text: (
                        <Dictionary text="com.profile.b2b.auth.email-mismatch.description" />
                    ),
                    title: (
                        <Dictionary text="com.profile.b2b.auth.email-mismatch.title" />
                    ),
                });

                return info;
            }

            if (isBusinessSuccess || isBusinessError) {
                let status = 'confirm.error';

                if (isBusinessSuccess) {
                    status = isBusinessAuth
                        ? 'confirm.success'
                        : 'success.registration';
                }

                info.push({
                    icon: isBusinessSuccess ? 'success' : 'error',
                    text: (
                        <Dictionary
                            text={`com${
                                isBusinessAuth ? '' : '.profile'
                            }.b2b.${status}`}
                            transform={text =>
                                text.replace(/%company%/, company)
                            }
                            html
                        />
                    ),
                });
            }
        }

        if (
            isProfile &&
            getSessionValue('b2bConfirmRegistration') &&
            !isBusinessSuccess
        ) {
            info.push({
                icon: 'success',
                text: (
                    <Dictionary
                        text="com.profile.b2b.success.registration"
                        html
                    />
                ),
            });
        }

        return info;
    },
);

// com.profile.b2b.success.registration
