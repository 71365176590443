import React from 'react';
import { bool, shape } from 'prop-types';
import { fieldInputPropTypes, fieldMetaPropTypes } from 'types';

import { Radio } from '@s7/ui-kit';

// eslint-disable-next-line react/prop-types
const RadioField = ({ error, input, meta, disabled, ...rest }) => {
    const invalid = meta.error && (meta.touched || meta.submitFailed);
    const { value, checked, onChange, name } = input;

    return (
        <Radio
            {...{
                checked,
                disabled,
                invalid,
                name,
                onChange,
                value,
            }}
            {...rest}
        />
    );
};

RadioField.propTypes = {
    disabled: bool,
    input: shape(fieldInputPropTypes),
    meta: shape(fieldMetaPropTypes),
};

RadioField.defaultProps = {
    input: {},
    meta: {},
};

const RadioFieldMemo = React.memo(RadioField);

export { RadioFieldMemo as RadioField };
