import { ACTION_STATUS_EDITING } from 'consts';
import { documentType } from 'data';
import { compose, indexBy, keys, pathOr, prop, propOr } from 'ramda';
import { formatDocNumber, isOver14YearsOld, normalizeCardNumber } from 'utils';

import { getValidate } from './validation';

import { airLineTypes, SECTIONS } from '../constants';

export const getFormConfig = ({ id, item, name, section, traveler }) => {
    const formConfig = {
        form: `traveler_${id}_${section}_${item}`,
        initialValues: {
            loyaltyAirline: pathOr(
                'S7',
                ['loyaltyCards', 0, 'airline'],
                traveler,
            ),
        },
    };

    if (name !== ACTION_STATUS_EDITING) {
        return formConfig;
    }

    const birthday = propOr('', 'birthday', traveler);

    if (!section || section === SECTIONS.personal) {
        formConfig.initialValues = {
            ...formConfig.initialValues,
            birthday,
            firstName: pathOr('', ['names', 'firstName'], traveler),
            gender: propOr('MALE', 'gender', traveler),
            lastName: pathOr('', ['names', 'lastName'], traveler),
        };
    }

    if (!section || section === SECTIONS.contacts) {
        const contacts = compose(
            indexBy(prop('type')),
            propOr({}, 'contacts'),
        )(traveler);

        formConfig.initialValues = {
            ...formConfig.initialValues,
            email: pathOr('', ['EMAIL', 'value'], contacts),
            phone: pathOr('', ['PHONE', 'value'], contacts),
        };
    }

    // Редактирование/добавление в мобилке
    if (section === SECTIONS.document) {
        const document = compose(
            propOr({}, item),
            indexBy(prop('id')),
            prop('documents'),
        )(traveler);

        if (!document.type) {
            document.type = isOver14YearsOld(birthday)
                ? documentType.INTERNAL_PASSPORT
                : documentType.BIRTH_CERTIFICATE;
        }

        if (document.type === documentType.BIRTH_CERTIFICATE) {
            document.birthCertificate = {
                number: document.number,
                series: document.series,
            };
        }

        formConfig.initialValues = document;
    }

    // Обработка данных для отображения документов
    if (!section) {
        const documents = traveler.documents.reduce((acc, document) => {
            const fieldName = `${SECTIONS.document}_${document.id}_`;
            const result = {
                ...acc,
                [`${fieldName}type`]: document.type,
                [`${fieldName}status`]: document.status,
                [`${fieldName}number`]: [document.series, document.number]
                    .filter(Boolean)
                    .join(''),
                [`${fieldName}schengen`]: document.schengen,
                [`${fieldName}countryOfIssue`]: document.countryOfIssue,
                [`${fieldName}placeOfIssue`]: document.placeOfIssue,
                [`${fieldName}dateOfIssue`]: document.dateOfIssue,
                [`${fieldName}startDate`]: '',
                [`${fieldName}expirationDate`]: document.expirationDate,
                [`${fieldName}destinationCountry`]: document.destinationCountry,
                [`${fieldName}birthCountry`]: document.birthCountry,
                [`${fieldName}placeOfBirth`]: document.placeOfBirth,
            };

            if (document.type === documentType.BIRTH_CERTIFICATE) {
                result[`${fieldName}birthCertificate`] = {
                    number: document.number,
                    series: document.series,
                };
            }

            if (document.type === documentType.MILITARY_CARD) {
                result[`${fieldName}militaryId`] = {
                    number: document.number,
                    series: document.series,
                };
            }

            return result;
        }, {});

        formConfig.initialValues = {
            ...formConfig.initialValues,
            ...documents,
        };
    }

    if (!section || section === SECTIONS.loyalty) {
        formConfig.initialValues = {
            ...formConfig.initialValues,
            haveLoyalty: !!traveler.loyaltyCards.length,
            loyaltyAirline: pathOr(
                'S7',
                ['loyaltyCards', 0, 'airline'],
                traveler,
            ),
            loyaltyNumber: pathOr('', ['loyaltyCards', 0, 'number'], traveler),
        };
    }

    formConfig.fields = keys(formConfig.initialValues);
    formConfig.validate = getValidate(section);

    return formConfig;
};

export const formatCardNumber = ({ airline, number }) =>
    airLineTypes.S7 === airline ? normalizeCardNumber(number) : number;

export const formatNumber = ({
    number: docNumber,
    type,
    series: docSeries,
}) => {
    const number = formatDocNumber({ number: docNumber, type });

    return docSeries ? `${docSeries} ${number}` : number;
};
