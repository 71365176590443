import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { DateField, SelectGender, TextField } from 'components/Fields';
import { HASH_PROFILE_PERSONAL } from 'consts';
import { DATE_FORMATS } from 'consts/dateFormats';
import { AnalyticsContext } from 'contexts';
import { modalIds } from 'data';
import withDetectBreakpointHOC from 'hoc/breakpointHOC/withDetectBreakpointHOC';
import moment from 'moment';
import { bool, func, node, oneOf, shape, string } from 'prop-types';
import { compose, toUpper } from 'ramda';
import { Field as FormField, getFormValues, reduxForm } from 'redux-form';
import { Column, Columns, Dictionary, UserEdit } from 'shared';
import {
    validateDateBirth,
    validateLastName,
    validateName,
    validateRequiredLong,
} from 'utils/createValidate';
import { normalizeName, normalizeSlugifyName } from 'utils/format';
import { dictKeys, getTestIdGetter } from 'utils/testIds';

import { Button, Modal, Text } from '@s7/ui-kit';

const DD_MM_YYYY = DATE_FORMATS.DD.MM.YYYY_dot;
const YYYY_MM_DD = DATE_FORMATS.YYYY.MM.DD_dash;

const getTestId = getTestIdGetter(dictKeys.PROFILE_USER_INFO);

const normalizeNamesRu = value =>
    normalizeName(value, {
        lang: 'ru',
    });

const formConfig = {
    fields: [
        'firstname_ru',
        'lastname_ru',
        'firstname_en',
        'lastname_en',
        'birthdate',
        'gender',
    ],
    form: 'personalData',
};

const SEX_OPTIONS = [
    {
        ...getTestId('sexF'),
        label: <Dictionary text="com.profile.sex.female" />,
        value: 'FEMALE',
    },
    {
        ...getTestId('sexM'),
        label: <Dictionary text="com.profile.sex.male" />,
        value: 'MALE',
    },
];

const MODAL_BUTTON_PROPS = {
    alignment: 'center',
};

const formValuesSelector = getFormValues('personalData');

const mapStateToProps = state => ({
    formValues: formValuesSelector(state) || {},
});

const formShape = shape({
    birthdate: string,
    firstname_en: string,
    firstname_ru: string,
    gender: oneOf(['', 'MALE', 'FEMALE']),
    lastname_en: string,
    lastname_ru: string,
});

/* eslint-disable camelcase */
// eslint-disable-next-line react/no-redundant-should-component-update
class PersonalDataEditContainer extends PureComponent {
    static propTypes = {
        change: func,
        error: node,
        formValues: formShape,
        handleSubmit: func,
        hasCyrillicName: bool,
        hasCyrlic: bool,
        hasLatinName: bool,
        initialValues: formShape,
        isShortUser: bool,
        onReset: func,
        onSave: func,
        pristine: bool,
        reset: func,
        setEdit: func,
        submitting: bool,
        tooltip: node,
    };

    static defaultProps = {
        onReset: () => {},
    };

    constructor(props) {
        super(props);

        this.state = {
            isErrorModalOpen: false,
        };

        this.modalActions = [
            <Button
                as="a"
                href="https://www.s7.ru/s7-priority/ffpContactUs.dot"
                rel="noopener noreferrer"
                target="_blink"
                theme="b2c"
                block
                onClick={this.handleClickContactUs}
            >
                <Dictionary text="com.global.contact-us" />
            </Button>,
            <Button
                theme="secondary"
                block
                onClick={this.closeErrorModal}
                {...getTestId('errorClose')}
            >
                <Dictionary text="com.modal.button.failure" />
            </Button>,
        ];
    }

    componentDidUpdate(prevProps) {
        const {
            formValues: { firstname_ru, lastname_ru },
            change,
            hasLatinName,
            hasCyrillicName,
            isShortUser,
        } = this.props;

        if (isShortUser && !(!hasCyrillicName && hasLatinName)) {
            if (
                firstname_ru &&
                prevProps.formValues.firstname_ru !== firstname_ru
            ) {
                change('firstname_en', normalizeSlugifyName(firstname_ru));
            }

            if (
                lastname_ru &&
                prevProps.formValues.lastname_ru !== lastname_ru
            ) {
                change('lastname_en', normalizeSlugifyName(lastname_ru));
            }
        }
    }

    handleClickContactUs = () => {
        const { sendEvent } = this.context;

        sendEvent({
            event: 'view_screen',
            flow: 'navigation',
            step: 'about_us',
            ux_ui: 'on_screen',
        });
    };

    handleReset = () => {
        this.props.setEdit(false);
        this.props.reset();
        this.props.onReset();
    };

    handleSubmit = this.props.handleSubmit(
        ({
            firstname_ru,
            lastname_ru,
            firstname_en,
            lastname_en,
            gender,
            birthdate,
        }) => {
            const { hasCyrlic, initialValues, pristine, setEdit } = this.props;

            if (pristine) {
                setEdit(false);

                return;
            }

            const names = [
                {
                    firstName: firstname_en,
                    lang: 'en',
                    lastName: lastname_en,
                    middleName: null,
                    prefix: 'MR',
                },
            ];

            if (hasCyrlic && (firstname_ru || lastname_ru)) {
                names.push({
                    firstName: firstname_ru,
                    lang: 'ru',
                    lastName: lastname_ru,
                    middleName: null,
                    prefix: 'MR',
                });
            }

            this.props.onSave({
                data: {
                    birthday: moment(birthdate, DD_MM_YYYY).format(YYYY_MM_DD),
                    gender: toUpper(gender),
                    names,
                },
                initialValues,
            });
            this.setState({
                isErrorModalOpen: true,
            });
        },
    );

    closeErrorModal = () => {
        this.setState({
            isErrorModalOpen: false,
        });
    };

    render() {
        const { isErrorModalOpen } = this.state;
        const { hasCyrlic, isShortUser, error, submitting, tooltip } =
            this.props;
        const isMobile =
            typeof window !== 'undefined' && window.innerWidth < 768;

        return (
            <UserEdit
                testIds={{
                    cancelButton: getTestId('cancelButton'),
                    closeButton: getTestId('closeButton'),
                    root: getTestId('form'),
                    saveButton: getTestId('saveButton'),
                }}
                cancelText={<Dictionary text="com.action.cancel" />}
                error={isShortUser ? error : undefined}
                routeHash={HASH_PROFILE_PERSONAL}
                saveButtonProps={getTestId('saveButton')}
                saveText={<Dictionary text="com.action.save" />}
                submitting={submitting}
                title={<Dictionary text="com.title.edit" />}
                onReset={this.handleReset}
                onSubmit={this.handleSubmit}
            >
                <div>
                    {tooltip}
                    <Columns>
                        {hasCyrlic && isShortUser && (
                            <Column desktop={50}>
                                <FormField
                                    label={
                                        <Dictionary text="com.profile.personal.edit.firstname" />
                                    }
                                    tooltipProps={getTestId('firstNameRU', {
                                        error: true,
                                    })}
                                    autoComplete="given-name"
                                    component={TextField}
                                    inputProps={getTestId('firstNameRU')}
                                    maxLength={30}
                                    name="firstname_ru"
                                    normalize={normalizeNamesRu}
                                    validate={validateName}
                                />
                            </Column>
                        )}
                        {hasCyrlic && isShortUser && (
                            <Column desktop={50}>
                                <FormField
                                    label={
                                        <Dictionary text="com.profile.personal.edit.lastname" />
                                    }
                                    tooltipProps={getTestId('lastNameRU', {
                                        error: true,
                                    })}
                                    autoComplete="family-name"
                                    component={TextField}
                                    inputProps={getTestId('lastNameRU')}
                                    maxLength={30}
                                    name="lastname_ru"
                                    normalize={normalizeNamesRu}
                                    validate={validateLastName}
                                />
                            </Column>
                        )}
                        <Column desktop={50}>
                            <FormField
                                label={
                                    <Dictionary text="com.profile.personal.edit.firstname.en" />
                                }
                                tooltipProps={getTestId('firstNameEN', {
                                    error: true,
                                })}
                                autoComplete="given-name"
                                component={TextField}
                                inputProps={getTestId('firstNameFieldEN')}
                                maxLength={30}
                                name="firstname_en"
                                normalize={normalizeSlugifyName}
                                validate={validateName}
                            />
                        </Column>
                        <Column desktop={50}>
                            <FormField
                                label={
                                    <Dictionary text="com.profile.personal.edit.lastname.en" />
                                }
                                tooltipProps={getTestId('lastNameEN', {
                                    error: true,
                                })}
                                autoComplete="family-name"
                                component={TextField}
                                inputProps={getTestId('lastNameFieldEN')}
                                maxLength={30}
                                name="lastname_en"
                                normalize={normalizeSlugifyName}
                                validate={validateLastName}
                            />
                        </Column>
                        <Column desktop={50}>
                            <FormField
                                disabledDays={[
                                    {
                                        before: moment()
                                            .subtract(99, 'years')
                                            .toDate(),
                                    },
                                ]}
                                label={
                                    <Dictionary text="com.profile.personal.edit.birthdate" />
                                }
                                tooltipProps={getTestId('birthday', {
                                    error: true,
                                })}
                                autoComplete="bday"
                                component={DateField}
                                inputProps={getTestId('birthdayField')}
                                name="birthdate"
                                validate={validateDateBirth}
                                isBirthday
                            />
                        </Column>
                        <Column desktop={50}>
                            <FormField
                                component={SelectGender}
                                name="gender"
                                options={SEX_OPTIONS}
                                tooltipProps={getTestId('sexError')}
                                validate={validateRequiredLong}
                                fullWidth
                            />
                        </Column>
                    </Columns>
                    {!isShortUser && error && (
                        <Modal
                            actions={this.modalActions}
                            buttonProps={MODAL_BUTTON_PROPS}
                            id={modalIds.PERSONAL_DATA_EDIT_ERROR}
                            isOpen={isErrorModalOpen}
                            portal
                            withoutHorizontalPaddings
                            onClose={this.closeErrorModal}
                            {...(isMobile
                                ? {
                                      buttonsOrientation: 'vertical',
                                      size: 'small',
                                  }
                                : {
                                      width: 460,
                                  })}
                        >
                            {isMobile && <br />}
                            <Text
                                {...getTestId('error')}
                                style={
                                    isMobile
                                        ? { maxWidth: 'calc(100% - 20px' }
                                        : {}
                                }
                            >
                                {error}
                            </Text>
                        </Modal>
                    )}
                </div>
            </UserEdit>
        );
    }
}

PersonalDataEditContainer.contextType = AnalyticsContext;

export default compose(
    withDetectBreakpointHOC,
    connect(mapStateToProps),
    reduxForm(formConfig),
)(PersonalDataEditContainer);
