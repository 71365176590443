import React, { memo } from 'react';
import { bool, node, oneOfType, string } from 'prop-types';
import Text from 'ui-kit/atoms/Text';

import cx from './PasswordChangeNotification.sss';

const PasswordChangeNotification = ({ isOpen, text, ...other }) => (
    <div className={cx('root', { visible: isOpen })} {...other}>
        <span aria-label="" className={cx('emoji')} role="img">
            &#128077;
        </span>
        <span className={cx('text')}>
            <Text color="white" size="semi">
                {text}
            </Text>
        </span>
    </div>
);

PasswordChangeNotification.propTypes = {
    isOpen: bool,
    text: oneOfType([string, node]),
};

export default memo(PasswordChangeNotification);
