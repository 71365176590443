export const PETS_TYPE = {
    BIRD: 'BIRD',
    CAT: 'CAT',
    DOG: 'DOG',
    OTHER: 'OTHER',
};

export const PETS_WEIGHT = {
    OVER_50: 'OVER_50',
    UP_TO_10: 'UP_TO_10',
    UP_TO_23: 'UP_TO_23',
    UP_TO_50: 'UP_TO_50',
};
