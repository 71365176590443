import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PETS_TYPE } from 'consts/pets';
import { useAnalyticsContext } from 'contexts';
import { useRouter } from 'next/router';
import { shape, string } from 'prop-types';
import { Dictionary, Paper, PaperContent, PaperTitle } from 'shared';
import { deletePet, getPetsEditFormError, updatePet } from 'store/modules/pets';

import { RoundButton, Text, Title } from '@s7/ui-kit';
import { useMediaQuery } from '@s7/ui-kit/hooks';
import { Pencil } from '@s7/ui-kit/icons';

import { ModalFormEdit } from './ModalFormEdit';
import cx from './Pet.sss';

import { testIds } from '../../constants';
import { getAnimalName, getAnimalWeight, getDefaultAvatar } from '../../utils';

export const Pet = ({ avatar, nickname, type, weight, customType, id }) => {
    const router = useRouter();
    const breakpoints = useMediaQuery('xs');
    const isMobile = breakpoints.xs;
    const { sendEvent } = useAnalyticsContext();

    const dispatch = useDispatch();
    const error = useSelector(getPetsEditFormError);
    const [showModal, setShowModal] = useState(false);

    const Icon = getDefaultAvatar(type);
    const currentImage = avatar?.mainLink;

    const handleRemove = () => {
        dispatch(deletePet({ petId: id }));
        router.push('/');
    };

    const handleUpdatePet = values => {
        dispatch(
            updatePet({
                data: {
                    ...values,
                    // previewLink и mainLink необходимы при обновлении питомца, чтобы не затирать изображение
                    mainLink: avatar?.mainLink,
                    previewLink: avatar?.previewLink,
                },
                petId: id,
            }),
        );
        sendEvent({
            event_name: 'edit_info',
            item_id: 'item_pets',
            result: 'initial_click',
            step: 'companions',
        });
    };

    const handleOpenModal = () => {
        sendEvent({
            event: 'edit_info',
            id: 'pets',
            result: 'initial_click',
            step: 'pets',
        });
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    return (
        <Paper {...testIds.item.block}>
            {isMobile ? (
                <div className={cx('header_actions')}>
                    <RoundButton
                        size="s"
                        view="secondary"
                        onClick={handleOpenModal}
                        {...testIds.item.edit}
                    >
                        <Pencil />
                    </RoundButton>
                </div>
            ) : (
                <PaperTitle>
                    <Dictionary text="com.profile.pets.page.pet.title" />
                </PaperTitle>
            )}
            <PaperContent>
                <div className={cx('root')}>
                    <div className={cx('image_container')}>
                        {currentImage ? (
                            <img
                                alt={nickname}
                                className={cx('image')}
                                height={60}
                                src={currentImage}
                                width={60}
                                {...testIds.item.avatar}
                            />
                        ) : (
                            <Icon
                                className={cx('image')}
                                height={60}
                                width={60}
                                {...testIds.item.icon}
                            />
                        )}
                    </div>
                    <div className={cx('content')}>
                        <div className={cx('nickname')}>
                            {isMobile ? (
                                <Title
                                    alignment="center"
                                    as="p"
                                    classes={{ root: cx('nickname_title') }}
                                    color="N900"
                                    level={2}
                                    {...testIds.item.nickname}
                                >
                                    {nickname}
                                </Title>
                            ) : (
                                <Title
                                    as="p"
                                    color="N900"
                                    level={4}
                                    ellipsis
                                    {...testIds.item.nickname}
                                >
                                    {nickname}
                                </Title>
                            )}
                        </div>
                        <div className={cx('info')}>
                            <Text
                                alignment={isMobile ? 'center' : 'left'}
                                color="N100"
                                size="s"
                                noWrap
                            >
                                <span {...testIds.item.type}>
                                    {type === PETS_TYPE.OTHER ? (
                                        customType
                                    ) : (
                                        <Dictionary
                                            text={getAnimalName(type)}
                                        />
                                    )}
                                </span>
                                {weight && (
                                    <>
                                        {', '}
                                        <span {...testIds.item.weight}>
                                            <Dictionary
                                                text={getAnimalWeight(weight)}
                                                html
                                            />
                                        </span>
                                    </>
                                )}
                            </Text>
                        </div>
                    </div>
                    <div className={cx('actions')}>
                        <RoundButton
                            size="s"
                            view="secondary"
                            onClick={handleOpenModal}
                            {...testIds.item.edit}
                        >
                            <Pencil />
                        </RoundButton>
                        {showModal && (
                            <ModalFormEdit
                                initialValues={{
                                    avatar: {
                                        imgUrl: currentImage,
                                    },
                                    customType,
                                    nickname,
                                    type,
                                    weight,
                                }}
                                defaultImage={getDefaultAvatar(type)}
                                errorMessage={error}
                                onClose={handleCloseModal}
                                onRemove={handleRemove}
                                onSubmit={handleUpdatePet}
                            />
                        )}
                    </div>
                </div>
            </PaperContent>
        </Paper>
    );
};

Pet.propTypes = {
    avatar: shape({
        mainLink: string.isRequired,
        previewLink: string.isRequired,
    }),
    customType: string,
    id: string.isRequired,
    nickname: string.isRequired,
    type: string.isRequired,
    weight: string,
};
