import { assoc, filter, mergeDeepRight, propOr } from 'ramda';
import { createAction, createReducer } from 'redux-act';

const BASE = 'passwordRecovery';

const initialState = {
    confirmParams: {
        id: null,
        passwordSet: false,
        secret: null,
    },
    isOpenRecoverySuccessMessage: false,
};

/* -------------- actions -------------- */
export const savePassword = createAction(`${BASE}/savePassword`);
export const savePasswordFailure = createAction(`${BASE}/savePasswordFailure`);
export const savePasswordSuccess = createAction(`${BASE}/savePasswordSuccess`);

export const changeIsOpenRecoverSuccessMessage = createAction(
    `${BASE}/changeIsOpenRecoverSuccessMessage`,
);
export const setConfirmParams = createAction(`${BASE}/setConfirmParams`);

export const resetToDefault = createAction(`${BASE}/resetToDefault`);

/* -------------- handlers -------------- */
const handleResetToDefault = () => ({
    ...initialState,
});
const handleChangeIsOpenRecoverSuccessMessage = (state, { isOpen }) =>
    assoc('isOpenRecoverySuccessMessage', isOpen, state);

const handleSetConfirmParams = (state, payload) =>
    assoc(
        'confirmParams',
        mergeDeepRight(
            propOr({}, 'confirmParams', state),
            filter(item => item != null, payload),
        ),
        state,
    );

export default createReducer(
    {
        [changeIsOpenRecoverSuccessMessage]:
            handleChangeIsOpenRecoverSuccessMessage,
        [resetToDefault]: handleResetToDefault,
        [setConfirmParams]: handleSetConfirmParams,
    },
    initialState,
);

export * from './selectors';
