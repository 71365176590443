import { path, pathOr, propOr } from 'ramda';

import defaultText from './defaultText';

const getErrorText = rules => error => {
    if (!error) {
        return undefined;
    }

    if (path([error, 'emptyErrorText'], rules)) {
        return ' ';
    }

    const validatorConfig = pathOr({}, [error], rules);
    const getText = propOr(defaultText[error], 'errorText', validatorConfig);
    const text =
        typeof getText === 'function' ? getText(validatorConfig) : getText;

    return text || 'Неизвестная ошибка';
};

export default getErrorText;
