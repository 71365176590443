import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DocumentScan from 'components/DocumentScan';
import {
    CheckboxField,
    DateField,
    SelectField,
    TextField,
} from 'components/Fields';
import { BirthCertificate, MilitaryId } from 'components/FormSections';
import { documentMaskByType, documentType } from 'data';
import { func, string } from 'prop-types';
import { compose, map, prop, sortBy } from 'ramda';
import {
    change,
    Field as FormField,
    FormSection,
    getFormValues,
} from 'redux-form';
import { getCountries } from 'store/modules/countries';
import { getDictionary } from 'store/modules/languages';
import {
    getDocListByParams,
    getIsDateCorrect,
    isOver14YearsOld,
    normalizeNumber,
    normalizeSeriesNumber,
} from 'utils';
import { normalizeName, normalizeNameWithSpaces } from 'utils/format';

import cx from './Form.sss';

import { testIds, TEXTS } from '../../constants';
import {
    getDateOfIssueValidator,
    getExpireDateValidator,
    getStartDateValidator,
    latinName,
    minLength2,
    required,
    validateNumber,
} from '../validation';

const filterCountry = countryOfIssue => countryOfIssue.visible;

const normalizeNumberByType = {
    [documentType.INTERNAL_PASSPORT]: normalizeNumber,
    [documentType.INTERNATIONAL_PASSPORT]: normalizeSeriesNumber,
    [documentType.VISA]: normalizeSeriesNumber,
    [documentType.OTHER]: normalizeSeriesNumber,
};

const getDocumentByBirthday = (birthday = '', docTypes = []) => {
    const availableDocumentType = isOver14YearsOld(birthday)
        ? documentType.INTERNAL_PASSPORT
        : documentType.BIRTH_CERTIFICATE;

    return docTypes.find(item => item.name === availableDocumentType);
};

const seriesNumberComponents = {
    [documentType.BIRTH_CERTIFICATE]: BirthCertificate,
    [documentType.MILITARY_CARD]: MilitaryId,
};

const getSeriesNumberPrefix = (type, prefix) => {
    switch (true) {
        case type === documentType.BIRTH_CERTIFICATE:
            return `${prefix}birthCertificate`;

        case type === documentType.MILITARY_CARD:
            return `${prefix}militaryId`;

        default:
            return prefix;
    }
};

const Document = ({ birthday: birthdayProp, form, onDocScan, prefix }) => {
    const dispatch = useDispatch();
    const dictionary = useSelector(getDictionary);
    const countries = useSelector(getCountries);

    const values = useSelector(getFormValues(form)) || {};
    const birthday = values.birthday || birthdayProp;
    const type = values[`${prefix}type`];
    const dateOfIssue = values[`${prefix}dateOfIssue`];

    const isBirth = type === documentType.BIRTH_CERTIFICATE;
    const isMilitaryId = type === documentType.MILITARY_CARD;
    const isOther = type === documentType.OTHER;
    const isPassport = type === documentType.INTERNAL_PASSPORT;
    const isVisa = type === documentType.VISA;

    const SeriesNumberComponent = seriesNumberComponents[type];

    const docTypes = useMemo(
        () =>
            compose(
                map(item => ({ ...item, label: dictionary.t(item.title) })),
                sortBy(prop('sort')),
            )(getDocListByParams({ birthday, militaryId: true, visa: true })),
        [birthday, dictionary],
    );

    const validateDateOfIssue = useMemo(
        () => getDateOfIssueValidator({ birthday }),
        [birthday],
    );
    const validateStartDate = useMemo(
        () => getStartDateValidator({ dateOfIssue }),
        [dateOfIssue],
    );
    const validateExpireDate = useMemo(
        () => getExpireDateValidator({ dateOfIssue, type }),
        [dateOfIssue, type],
    );

    const handleDocScan = useCallback(
        data =>
            onDocScan({
                data,
                prefix,
            }),
        [prefix, onDocScan],
    );

    useEffect(() => {
        if (!docTypes.find(({ value }) => value === type)) {
            const document = getIsDateCorrect(birthday)
                ? getDocumentByBirthday(birthday, docTypes)
                : docTypes[0];

            dispatch(change(form, `${prefix}type`, document.value));
        }
    }, [type, docTypes, prefix, form, dispatch, birthday]);

    return (
        <div className={cx('root')}>
            {onDocScan && (
                <div className={cx('docscan')}>
                    <DocumentScan docscan={handleDocScan} />
                </div>
            )}

            <div
                className={cx('column', 'large')}
                {...testIds.form.document.type}
            >
                <FormField
                    component={SelectField}
                    label={TEXTS.form.type}
                    name={`${prefix}type`}
                    options={docTypes}
                />
            </div>
            <div className={cx('column')}>
                {SeriesNumberComponent ? (
                    <FormSection name={getSeriesNumberPrefix(type, prefix)}>
                        <SeriesNumberComponent
                            className={
                                isBirth
                                    ? cx('birthCertificateContainer')
                                    : undefined
                            }
                            numberProps={{
                                inputProps: testIds.form.document.number,
                                tooltipProps: testIds.form.document.numberError,
                            }}
                            seriesProps={{
                                inputProps: testIds.form.document.series,
                                tooltipProps: testIds.form.document.seriesError,
                            }}
                        />
                    </FormSection>
                ) : (
                    <FormField
                        autoComplete="cc-csc"
                        component={TextField}
                        inputProps={testIds.form.document.number}
                        label={TEXTS.form.number}
                        mask={documentMaskByType[type]}
                        maxLength={!documentMaskByType[type] ? 15 : undefined}
                        name={`${prefix}number`}
                        normalize={normalizeNumberByType[type]}
                        tooltipProps={testIds.form.document.numberError}
                        validate={validateNumber[type]}
                    />
                )}
            </div>

            {isVisa && (
                <div className={cx('column', 'center')}>
                    <FormField
                        component={CheckboxField}
                        format={Boolean}
                        name={`${prefix}schengen`}
                        type="checkbox"
                        {...testIds.form.document.schengen}
                    >
                        {dictionary.t('com.profile.documents.form.schengen')}
                    </FormField>
                </div>
            )}
            {(isOther || isVisa) && (
                <div
                    className={cx('column', { large: isOther })}
                    {...testIds.form.document.country}
                >
                    <FormField
                        label={
                            isOther
                                ? dictionary.t(
                                      'com.profile.documents.form.citizenship',
                                  )
                                : dictionary.t(
                                      'com.profile.documents.form.country',
                                  )
                        }
                        autoComplete="passport country"
                        component={SelectField}
                        name={`${prefix}countryOfIssue`}
                        options={countries}
                        validate={[required]}
                        visible={filterCountry}
                        isAutocomplete
                        searchable
                    />
                </div>
            )}

            {isVisa && (
                <div className={cx('column')}>
                    <FormField
                        autoComplete="cc-csc"
                        component={TextField}
                        inputProps={testIds.form.document.placeOfIssue}
                        label={TEXTS.form.locality}
                        name={`${prefix}placeOfIssue`}
                        normalize={normalizeName}
                        tooltipProps={testIds.form.document.placeOfIssueError}
                        validate={[latinName, required]}
                    />
                </div>
            )}

            <div className={cx('column')}>
                <FormField
                    component={DateField}
                    disabledDays={[{ after: new Date() }]}
                    inputProps={testIds.form.document.dateOfIssue}
                    label={TEXTS.form.effective}
                    name={`${prefix}dateOfIssue`}
                    tooltipProps={testIds.form.document.dateOfIssueError}
                    validate={[validateDateOfIssue]}
                    isBirthday
                />
            </div>

            {isVisa && (
                <div className={cx('column')}>
                    <FormField
                        autoComplete="cc-csc"
                        component={DateField}
                        inputProps={testIds.form.document.issued}
                        label={TEXTS.form.issued}
                        name={`${prefix}startDate`}
                        tooltipProps={testIds.form.document.issuedError}
                        validate={[validateStartDate]}
                        isBirthday
                    />
                </div>
            )}

            {!isBirth && !isMilitaryId && !isPassport && (
                <div className={cx('column', 'last')}>
                    <FormField
                        component={DateField}
                        disabledDays={[{ before: new Date() }]}
                        inputProps={testIds.form.document.expire}
                        label={TEXTS.form.expire}
                        name={`${prefix}expirationDate`}
                        tooltipProps={testIds.form.document.expireError}
                        validate={[validateExpireDate]}
                    />
                </div>
            )}

            {isVisa && (
                <>
                    <div
                        className={cx('column')}
                        {...testIds.form.document.destination}
                    >
                        <FormField
                            label={dictionary.t(
                                'com.profile.documents.form.destinationcountry',
                            )}
                            autoComplete="passport country"
                            component={SelectField}
                            name={`${prefix}destinationCountry`}
                            options={countries}
                            validate={[required]}
                            visible={filterCountry}
                            isAutocomplete
                            searchable
                        />
                    </div>
                    <div
                        className={cx('column')}
                        {...testIds.form.document.birthCountry}
                    >
                        <FormField
                            label={dictionary.t(
                                'com.profile.documents.form.birthcountry',
                            )}
                            autoComplete="passport country"
                            component={SelectField}
                            name={`${prefix}birthCountry`}
                            options={countries}
                            validate={[required]}
                            visible={filterCountry}
                            isAutocomplete
                            searchable
                        />
                    </div>
                    <div className={cx('column')}>
                        <FormField
                            tooltipProps={
                                testIds.form.document.placeOfBirthError
                            }
                            autoComplete="cc-csc"
                            component={TextField}
                            inputProps={testIds.form.document.placeOfBirth}
                            label={TEXTS.form.placeOfBirth}
                            maxLength={30}
                            name={`${prefix}placeOfBirth`}
                            normalize={normalizeNameWithSpaces}
                            validate={[required, latinName, minLength2]}
                        />
                    </div>
                </>
            )}
        </div>
    );
};

Document.propTypes = {
    birthday: string,
    form: string,
    onDocScan: func,
    prefix: string,
};

Document.defaultProps = {
    prefix: '',
};

export default React.memo(Document);
