import React, { useCallback, useRef } from 'react';
import Modal from 'components/Modal';
import { QRCode } from 'components/QRCode';
import { useWindowSize } from 'hooks';
import { bool, func, node, string } from 'prop-types';
import { Dictionary } from 'shared';

import { Button } from '@s7/ui-kit';

import cx from './Preview.sss';

import { QR_INPUT_ACCEPT } from '../constants';

const QR_CODE_SIZE = 240;

const Preview = ({ id, loading, onClose, onRemove, onSave, title, value }) => {
    const inputRef = useRef(null);
    const { isMobile } = useWindowSize();

    const handleAddClick = useCallback(() => {
        inputRef.current.click();
    }, []);

    const handleRemove = useCallback(() => {
        onClose();
        onRemove();
    }, [onClose, onRemove]);

    const modalContent = () => (
        <div className={cx('modal')}>
            <div className={cx('qr')}>
                <QRCode
                    size={isMobile ? '100%' : QR_CODE_SIZE}
                    value={value}
                    withTitle={false}
                />
            </div>
            <Button
                classes={{ root: cx('button') }}
                disabled={loading}
                theme="secondary"
                block
                onClick={handleAddClick}
            >
                <Dictionary text="com.profile.qrcodes.update" />
            </Button>
            <Button
                classes={{ root: cx('button') }}
                disabled={loading}
                theme="secondary"
                block
                onClick={handleRemove}
            >
                <Dictionary text="com.profile.qrcodes.delete" />
            </Button>
        </div>
    );

    const handleChange = event => {
        const file = event.target.files?.[0];

        if (file) {
            onSave(file);
        }
    };

    return (
        <>
            <Modal
                contentComponent={modalContent}
                dsModalProps={{ onClose }}
                id={id}
                maxContentHeight={400}
                size="medium"
                title={title}
                withScroll={false}
                centered
                closeOnOverlayClick
                ds
                onClose={onClose}
            />
            <input
                ref={inputRef}
                accept={QR_INPUT_ACCEPT}
                className={cx('input')}
                type="file"
                onClick={event => {
                    event.target.value = null;
                }}
                onChange={handleChange}
            />
        </>
    );
};

Preview.propTypes = {
    id: string,
    loading: bool,
    onClose: func,
    onRemove: func,
    onSave: func,
    title: node,
    value: string,
};

export default Preview;
