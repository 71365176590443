import React from 'react';
import { useWindowSize } from 'hooks';
import { array, bool, func, node, object, shape } from 'prop-types';

import { Link, StatusMessage, Title } from '@s7/ui-kit';
import { Parameters } from '@s7/ui-kit/icons';

import cx from './GroupData.sss';

import IconButton from '../../atoms/IconButton';

const testIdsShape = shape({
    archiveButton: object,
    root: object,
    title: object,
});

const errorShape = shape({
    description: node,
    title: node,
});

const archiveShape = shape({
    icon: node.isRequired,
    onClick: func.isRequired,
    title: node,
});

const noop = () => {};

const GroupData = ({
    archive,
    children,
    editingChild,
    error,
    isEdit,
    isError,
    isFilter,
    isTransactions,
    onEdit,
    onTransactions,
    testIds,
    title,
}) => {
    const { isDesktop } = useWindowSize();

    return (
        <div
            className={cx('GroupData', {
                GroupData_edit: isEdit,
                GroupData_filter: isFilter,
            })}
            {...testIds.root}
        >
            <div className={cx('heading')}>
                <div className={cx('title')}>
                    <Title level={!isDesktop ? 5 : 3} {...testIds.title}>
                        {title}
                    </Title>
                </div>

                {isTransactions && (
                    <div className={cx('openFilter')}>
                        <Parameters onClick={onTransactions} />
                    </div>
                )}

                {isFilter && !isEdit && (
                    <div className={cx('edit')}>
                        <IconButton
                            glowTheme="red"
                            icon="filter"
                            theme="green"
                            onClick={onEdit}
                        />
                    </div>
                )}

                {archive && (
                    <Link
                        as="button"
                        icon={archive.icon}
                        type="button"
                        view="secondary"
                        onClick={archive.onClick}
                        {...testIds.archiveButton}
                    >
                        {archive.title}
                    </Link>
                )}
            </div>

            {isError ? (
                <div className={cx('error')}>
                    <StatusMessage title={error.title} view="error">
                        {error.description}
                    </StatusMessage>
                </div>
            ) : (
                <div className={cx('content')}>
                    {React.Children.map(children, (child, key) => {
                        if (!child) {
                            return null;
                        }

                        return (
                            <div
                                className={cx('item', {
                                    item_edit:
                                        editingChild[key] === true ||
                                        child.props.isNew,
                                    item_hideBorderBottom:
                                        child.props.hideBorderBottom,
                                    item_isDocRow:
                                        child.props.isRow && !editingChild[key],
                                })}
                                {...testIds.item}
                            >
                                {child}
                            </div>
                        );
                    })}
                </div>
            )}
        </div>
    );
};

GroupData.propTypes = {
    archive: archiveShape,
    children: node,
    editingChild: array,
    error: errorShape,
    isDocs: bool,
    isEdit: bool,
    isError: bool,
    isFilter: bool,
    isTransactions: bool,
    onEdit: func,
    onTransactions: func,
    testIds: testIdsShape,
    title: node,
};

GroupData.defaultProps = {
    editingChild: [],
    error: {},
    isEdit: false,
    isError: false,
    isFilter: false,
    onEdit: noop,
    onTransactions: noop,
    testIds: {
        archiveButton: {},
        root: {},
        title: {},
    },
    title: 'Title',
};

export default GroupData;
