import React, { useMemo } from 'react';
import { UnitNounByLength } from 'components/UnitNounByLength';
import { Field as FormField } from 'redux-form';
import { Column, Dictionary } from 'shared';

import { DateFields } from './DateFields';

import { documentType } from '../../../data';
import { createFieldValidate } from '../../../utils/createValidate';
import { normalizeNumber, normalizeSeries } from '../../../utils/format';
import { SelectField, TextField } from '../../Fields';
import { fieldsPropTypes } from '../propTypes';

const countryOfIssueValidate = createFieldValidate({
    required: {
        errorText: <Dictionary text="com.validation.required-long" />,
    },
});

const getValidatorByType = ({ type, isNotRussian }) => {
    let rules = {
        required: {
            errorText: <Dictionary text="com.validation.required-long" />,
        },
    };

    if (type === documentType.INTERNAL_PASSPORT) {
        rules = {
            minLength: {
                errorText: (
                    <UnitNounByLength
                        dictionaryKey="com.validation.min-length"
                        length={isNotRussian ? 8 : 10}
                    />
                ),
                length: isNotRussian ? 8 : 10,
            },
            ...(!isNotRussian
                ? {
                      errorText: (
                          <Dictionary text="com.profile.form.valid.numeric" />
                      ),
                      numeric: true,
                  }
                : {}),
            ...rules,
        };
    } else if (type === documentType.INTERNATIONAL_PASSPORT) {
        rules = {
            minLength: {
                errorText: (
                    <UnitNounByLength
                        dictionaryKey="com.validation.min-length"
                        length={9}
                    />
                ),
                length: 9,
            },
            ...(!isNotRussian
                ? {
                      errorText: (
                          <Dictionary text="com.profile.form.valid.numeric" />
                      ),
                      numeric: true,
                  }
                : {}),
            ...rules,
        };
    } else if (type === documentType.OTHER) {
        rules = {
            minLength: {
                errorText: (
                    <UnitNounByLength
                        dictionaryKey="com.validation.min-length"
                        length={3}
                    />
                ),
                length: 3,
            },
            nonCyrillic: {
                errorText: (
                    <Dictionary text="com.profile.form.valid.noncrillic" />
                ),
            },
            ...rules,
        };
    } else {
        rules = {
            minLength: {
                errorText: (
                    <UnitNounByLength
                        dictionaryKey="com.validation.min-length"
                        length={2}
                    />
                ),
                length: 2,
            },
            nonCyrillic: {
                errorText: (
                    <Dictionary text="com.profile.form.valid.noncrillic" />
                ),
            },
            ...rules,
        };
    }

    return createFieldValidate(rules);
};

const noCountryMessage = () => <Dictionary text="com.global.nocountry" />;

const MASKS_MAP = {
    INTERNAL_PASSPORT: '9999 999999',
    INTERNATIONAL_PASSPORT: '99 9999999',
};

export const PassportFields = props => {
    const {
        countries,
        dictionary,
        disabled,
        filterCountry,
        getTestId,
        isMobile,
        isOther,
        isRussian,
        prefix,
        type,
    } = props;

    const isNotRussian = !isRussian;
    const isPassport = [
        documentType.INTERNAL_PASSPORT,
        documentType.INTERNATIONAL_PASSPORT,
    ].includes(type);

    const maskProps = isRussian ? { mask: MASKS_MAP[type] } : {};

    // Библиотека `react-input-mask`, которую использует TextField не
    // позволяет задавать маску полю, у которого выставлен атрибут
    // maxLength, поэтому используется данное условие
    if (!maskProps.mask) {
        maskProps.maxLength = {
            INTERNAL_PASSPORT: isNotRussian ? 20 : 10,
            INTERNATIONAL_PASSPORT: isNotRussian ? 20 : 9,
            OTHER: 15,
        }[type];
    }

    const validateByType = useMemo(
        () => getValidatorByType({ isNotRussian, type }),
        [type, isNotRussian],
    );

    return (
        <>
            {isOther && (
                <Column
                    desktop={50}
                    autoZIndex
                    {...getTestId('passportCountryOfIssueList')}
                >
                    <FormField
                        label={dictionary.t(
                            'com.profile.documents.form.citizenship',
                        )}
                        component={SelectField}
                        controlProps={getTestId('passportCountryOfIssue')}
                        disabled={type === documentType.OTHER ? disabled : true}
                        getNoOptions={noCountryMessage}
                        name={`${prefix}countryOfIssue`}
                        namespace={getTestId('countryOfIssue')['data-test']}
                        options={countries}
                        tooltipProps={getTestId('tooltip')}
                        validate={countryOfIssueValidate}
                        visible={filterCountry}
                        isAutocomplete
                        searchable
                    />
                </Column>
            )}

            <Column desktop={50}>
                <FormField
                    label={dictionary.t(
                        'com.profile.documents.form.seriesnumber',
                    )}
                    normalize={
                        !isNotRussian && isPassport
                            ? normalizeNumber
                            : normalizeSeries
                    }
                    component={TextField}
                    disabled={disabled}
                    inputProps={getTestId('passportNumber')}
                    name={`${prefix}number`}
                    validate={validateByType}
                    {...maskProps}
                    tooltipProps={getTestId('tooltip')}
                />
            </Column>

            {type === documentType.INTERNATIONAL_PASSPORT && !isMobile && (
                <Column width={50} />
            )}

            <DateFields
                testIds={{
                    dateOfIssue: getTestId('passportDateOfIssue'),
                    dateOfIssueError: getTestId('tooltip'),
                    expireDate: getTestId('passportExpireDate'),
                    expireDateError: getTestId('tooltip'),
                }}
                {...props}
            />
        </>
    );
};

PassportFields.propTypes = fieldsPropTypes;
