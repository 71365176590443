import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { arrayOf, func, shape, string } from 'prop-types';
import { getAirlines } from 'store/modules/airlines';

import { Link, Text, Title } from '@s7/ui-kit';

import cx from './Loyalty.sss';

import { SECTIONS, testIds, TEXTS } from '../../../constants';
import Wrapper from '../../../Wrapper';
import { formatCardNumber } from '../../helpers';
import Item from '../Item';

const Loyalty = ({ loyaltyCards, onEdit, onRemove }) => {
    const airlines = useSelector(getAirlines);

    const handleEdit = useCallback(
        item =>
            onEdit({
                item,
                section: SECTIONS.loyalty,
            }),
        [onEdit],
    );

    const handleRemove = useCallback(
        item => {
            onRemove(item);
        },
        [onRemove],
    );

    const addButton = useMemo(() => {
        if (loyaltyCards.length) {
            return null;
        }

        return (
            <Link
                as="button"
                type="button"
                view="b2c"
                onClick={() => handleEdit('new')}
                {...testIds.mobile.loyalty.new}
            >
                {TEXTS.newShort}
            </Link>
        );
    }, [loyaltyCards.length, handleEdit]);

    return (
        <Wrapper aside={addButton} title={TEXTS.loyalty}>
            {loyaltyCards.length ? (
                loyaltyCards.map(({ airline, id, number }) => (
                    <Item
                        key={id}
                        editProps={testIds.mobile.loyalty.edit}
                        id={id}
                        removeProps={testIds.mobile.loyalty.remove}
                        toggleProps={testIds.mobile.loyalty.toggle}
                        onEdit={handleEdit}
                        onRemove={handleRemove}
                        {...testIds.mobile.loyalty.item}
                    >
                        <Title
                            classes={{ root: cx('title') }}
                            level={4}
                            {...testIds.mobile.loyalty.airline}
                        >
                            {airlines[airline]?.ffpProgram}
                        </Title>
                        <Text
                            size="l"
                            noWrap
                            {...testIds.mobile.loyalty.number}
                        >
                            {formatCardNumber({ airline, number })}
                        </Text>
                    </Item>
                ))
            ) : (
                <Item>
                    <Text color="N100" {...testIds.mobile.loyalty.empty}>
                        {TEXTS.emptyLoyalty}
                    </Text>
                </Item>
            )}
        </Wrapper>
    );
};

Loyalty.propTypes = {
    loyaltyCards: arrayOf(
        shape({
            expirationDate: string,
            id: string,
            number: string,
            type: string,
        }),
    ),
    onEdit: func,
    onRemove: func,
};

export default React.memo(Loyalty);
