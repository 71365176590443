import { AGE_CHILD, AGE_CHILD_WITH_PASSPORT } from 'consts';
import { DATE_FORMATS } from 'consts/dateFormats';
import { documentType } from 'data';
import moment from 'moment';
import { isEmpty, pickBy, propOr } from 'ramda';
import { normalizeSeriesToLatin } from 'utils';
import { RULE_NOT_SEND_EXPIRE_PASSWORD } from 'utils/allowFeature';
import { normalizeNumber } from 'utils/format';

const DD_MM_YYYY = DATE_FORMATS.DD.MM.YYYY_dot;
const YYYY_MM_DD = DATE_FORMATS.YYYY.MM.DD_dash;

export function prepareReferralInfo(data) {
    const referralInfo = pickBy(Boolean, data);

    return isEmpty(referralInfo) ? {} : { referralInfo };
}

export function prepareFields(
    {
        birthdate,
        campaign,
        documents,
        draft,
        email,
        enrollment,
        gender,
        homeAirport,
        latinName,
        latinSurname,
        locale,
        name,
        password = '',
        partnerID,
        phone,
        relativeMemberId,
        surname,
        memberId,
    },
    params = {},
) {
    const confirmed = propOr(false, 'confirmed', params);
    const age = moment().diff(moment(birthdate, DD_MM_YYYY), 'years');
    const isChild = age < AGE_CHILD_WITH_PASSPORT;
    const isParentCard = age < AGE_CHILD;

    let names = [
        {
            firstName: latinName,
            lang: 'en',
            lastName: latinSurname,
        },
        {
            firstName: name,
            lang: 'ru',
            lastName: surname,
        },
    ];

    if (locale !== 'ru') {
        names = [
            {
                firstName: name,
                lang: 'en',
                lastName: surname,
            },
        ];
    }

    return {
        birthday: moment(birthdate, DD_MM_YYYY).format(YYYY_MM_DD),
        contacts: [
            ...(!isChild || confirmed
                ? [
                      {
                          type: 'PHONE',
                          value: normalizeNumber(phone),
                      },
                  ]
                : []),
            {
                type: 'EMAIL',
                value: email,
            },
        ],
        documents: documents.map(
            ({
                country,
                issueDate,
                expireDate,
                docType,
                docNumber,
                docNumberSeries,
            }) => {
                let series = null;

                let number = docNumber;

                if (docType === documentType.BIRTH_CERTIFICATE) {
                    const { series: birthSeries, number: birthNumber } =
                        docNumberSeries;

                    series = birthSeries || null;
                    number = birthNumber;
                }

                if (RULE_NOT_SEND_EXPIRE_PASSWORD) {
                    return {
                        countryOfIssue: country,
                        dateOfIssue: moment(issueDate, DD_MM_YYYY).format(
                            YYYY_MM_DD,
                        ),
                        ...(docType !== documentType.INTERNAL_PASSPORT
                            ? {
                                  expirationDate: expireDate
                                      ? moment(expireDate, DD_MM_YYYY).format(
                                            YYYY_MM_DD,
                                        )
                                      : moment()
                                            .add(20, 'y')
                                            .format(YYYY_MM_DD),
                              }
                            : {}),
                        number,
                        series,
                        type: docType,
                    };
                }

                return {
                    countryOfIssue: country,
                    dateOfIssue: moment(issueDate, DD_MM_YYYY).format(
                        YYYY_MM_DD,
                    ),
                    expirationDate: expireDate
                        ? moment(expireDate, DD_MM_YYYY).format(YYYY_MM_DD)
                        : moment().add(20, 'y').format(YYYY_MM_DD),
                    number,
                    series,
                    type: docType,
                };
            },
        ),
        gdprAccepted: true,
        gender,
        names,
        preferences: {
            homeAirport,
            preferredLanguage: 'ru',
        },
        type: 'FULL',
        ...(password ? { password } : {}),
        ...(isParentCard
            ? {
                  relativeMembers: [
                      {
                          memberId: normalizeNumber(relativeMemberId),
                      },
                  ],
              }
            : {}),
        ...(draft ? { draftProfileId: draft } : {}),
        ...prepareReferralInfo({
            campaign,
            enrollment,
            memberId,
            partner: partnerID,
        }),
    };
}

export const documentTypeByAge = birthdate => {
    const age = moment().diff(moment(birthdate, DD_MM_YYYY), 'years');

    return age < 14
        ? documentType.BIRTH_CERTIFICATE
        : documentType.INTERNAL_PASSPORT;
};

export const getBirthCertificateDocNumber = (series, number) =>
    `${normalizeSeriesToLatin(series)} ${number}`;

const getCommonDocNumber = (series, number) => `${series}${number}`;

const getGenericDocNumber = (type, series, number) =>
    type === documentType.BIRTH_CERTIFICATE
        ? getBirthCertificateDocNumber(series, number)
        : getCommonDocNumber(series, number);

export const getFieldsByType = doc => {
    const fields = {
        docNumber: doc.series
            ? getGenericDocNumber(doc.type, doc.series, doc.number)
            : doc.number,
        docType: doc.type,
        issueDate: doc.dateOfIssue,
    };

    if (
        ![
            documentType.BIRTH_CERTIFICATE,
            documentType.INTERNAL_PASSPORT,
        ].includes(doc.type) &&
        doc.expirationDate
    ) {
        fields.expireDate = doc.expirationDate;
    }

    if (doc.type === documentType.OTHER && doc.countryOfIssue) {
        fields.country = doc.countryOfIssue;
    }

    return fields;
};
