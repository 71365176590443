import React, { useCallback, useMemo, useState } from 'react';
import { HASH_PROFILE_CONTACTS } from 'consts';
import { useWindowSize } from 'hooks';
import { arrayOf, bool, func, number, object, string } from 'prop-types';
import { Dictionary, Paper, PaperContent, PaperTitle, UserEdit } from 'shared';
import { notificationType } from 'types';
import { dictKeys, getTestIdGetter } from 'utils/testIds';

import { Link, StatusMessage } from '@s7/ui-kit';

import { ContactBlock } from './ContactBlock';
import ContactEdit from './ContactEdit';
import cx from './Contacts.sss';

const TEXTS = {
    title: <Dictionary text="com.profile.contact.title" />,
};

const getTestId = getTestIdGetter(dictKeys.PROFILE_CONTACT_INFO);

export const Contacts = function Contacts(props) {
    const {
        changeWarningData,
        currentEditContactType,
        data,
        editContact,
        editContactWarnings,
        isModalOpen,
        isOpenChangeWarning,
        isOpenPhoneInfoAboutFurtherChanges,
        isValueEmpty,
        onChangeContact,
        onCloseEditContactWarning,
        onCloseModal,
        onCloseNotification,
        onClosePhoneInfoAboutFurtherChanges,
        onConfirmContact,
        onResendCode,
        onResetTimer,
        remainingSeconds,
        submitting,
    } = props;

    const [isMoreMode, setIsMoreMode] = useState(false);
    const { isDesktop } = useWindowSize();

    const handleOpenMore = useCallback(() => {
        setIsMoreMode(true);
    }, []);

    const handleCloseMore = () => {
        setIsMoreMode(false);
    };

    const contentLayout = useMemo(() => {
        const { confirmLabel, description, onClick, onClose, title, view } =
            changeWarningData;

        return (
            <>
                {isOpenPhoneInfoAboutFurtherChanges && (
                    <div
                        className={cx('notification')}
                        {...getTestId('notification')}
                    >
                        <StatusMessage
                            onClose={onClosePhoneInfoAboutFurtherChanges}
                        >
                            <Dictionary
                                text="com.profile.contact.edit.phone.changed-info"
                                html
                            />
                        </StatusMessage>
                    </div>
                )}

                {isOpenChangeWarning && (
                    <div
                        className={cx('notification')}
                        {...getTestId('notification')}
                    >
                        <StatusMessage
                            title={title}
                            view={view}
                            onClose={onClose}
                        >
                            {description}{' '}
                            {confirmLabel && (
                                <Link view="warning" onClick={onClick}>
                                    {confirmLabel}
                                </Link>
                            )}
                        </StatusMessage>
                    </div>
                )}

                <div className={cx('contacts')}>
                    {data.map(contact => (
                        <ContactBlock
                            key={contact.type}
                            {...contact}
                            withActions={isDesktop || isMoreMode}
                        />
                    ))}
                </div>
            </>
        );
    }, [
        changeWarningData,
        data,
        isDesktop,
        isMoreMode,
        isOpenChangeWarning,
        isOpenPhoneInfoAboutFurtherChanges,
        onClosePhoneInfoAboutFurtherChanges,
    ]);

    return (
        <>
            <Paper>
                <PaperTitle onClick={handleOpenMore}>{TEXTS.title}</PaperTitle>
                <PaperContent>{contentLayout}</PaperContent>
            </Paper>

            {!isDesktop && isMoreMode && (
                <UserEdit
                    hasSubmit={false}
                    routeHash={HASH_PROFILE_CONTACTS}
                    title={TEXTS.title}
                    hideReset
                    hideSave
                    onReset={handleCloseMore}
                >
                    {contentLayout}
                </UserEdit>
            )}

            {isModalOpen && (
                <ContactEdit
                    onClose={onCloseModal}
                    {...{
                        currentEditContactType,
                        editContact,
                        editContactWarnings,
                        isValueEmpty,
                        onChangeContact,
                        onCloseEditContactWarning,
                        onCloseNotification,
                        onConfirmContact,
                        onResendCode,
                        onResetTimer,
                        remainingSeconds,
                        submitting,
                    }}
                />
            )}
        </>
    );
};

Contacts.propTypes = {
    changeWarningData: object,
    currentEditContactType: string,
    data: arrayOf(object),
    editContact: object,
    editContactType: string,
    editContactWarnings: object,
    isModalOpen: bool,
    isOpenChangeWarning: bool,
    isOpenPhoneInfoAboutFurtherChanges: bool,
    isValueEmpty: bool,
    notification: notificationType,
    onChangeContact: func,
    onCloseEditContactWarning: func,
    onCloseModal: func,
    onCloseNotification: func,
    onClosePhoneInfoAboutFurtherChanges: func,
    onConfirmContact: func,
    onResendCode: func,
    onResetTimer: func,
    remainingSeconds: number,
    submitting: bool,
};
