import React from 'react';
import { node } from 'prop-types';
import { Paper, PaperContent, PaperTitle } from 'shared';

import { testIds, TEXTS } from '../constants';

const Wrapper = ({ aside, bottom, children, title }) => (
    <Paper>
        <PaperTitle aside={aside} titleProps={testIds.title}>
            {title}
        </PaperTitle>
        <div>{children}</div>
        {bottom && <PaperContent>{bottom}</PaperContent>}
    </Paper>
);

Wrapper.propTypes = {
    aside: node,
    bottom: node,
    children: node,
    title: node,
};

Wrapper.defaultProps = {
    title: TEXTS.title,
};

export default Wrapper;
