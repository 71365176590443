import React, { useMemo } from 'react';
import { useWindowSize } from 'hooks';
import { arrayOf, bool, func, number, string } from 'prop-types';

import { Button, Link, RoundButton, Text } from '@s7/ui-kit';
import { PlusLinear } from '@s7/ui-kit/icons';

import { testIds, TEXTS } from './constants';
import { TravelerLoader } from './Loaders';
import Traveler from './Traveler';
import cx from './Travelers.sss';
import Wrapper from './Wrapper';

const Travelers = ({
    isFetch,
    leftTravelers,
    newTraveler,
    onMore,
    onNew,
    onNewClose,
    travelers,
}) => {
    const { isDesktop } = useWindowSize();

    const newTravelerButton = useMemo(
        () =>
            !!newTraveler || !onNew ? null : (
                <div className={cx('add')}>
                    {isDesktop ? (
                        <RoundButton
                            direction="right"
                            size="xs"
                            text={isDesktop ? TEXTS.new : TEXTS.newShort}
                            view="b2c"
                            onClick={onNew}
                            {...testIds.new}
                        >
                            <PlusLinear />
                        </RoundButton>
                    ) : (
                        <Link
                            as="button"
                            type="button"
                            view="b2c"
                            onClick={onNew}
                            {...testIds.new}
                        >
                            {TEXTS.newShort}
                        </Link>
                    )}
                </div>
            ),
        [newTraveler, onNew, isDesktop],
    );

    const content = useMemo(
        () =>
            !travelers.length
                ? null
                : travelers.map(id => <Traveler key={id} id={id} />),
        [travelers],
    );

    return (
        <Wrapper
            bottom={
                <>
                    {!travelers.length && !newTraveler && !isFetch && (
                        <Text size="l" {...testIds.empty}>
                            {TEXTS.empty}
                        </Text>
                    )}
                    {onMore && (
                        <div className={cx('more')}>
                            <Button
                                size={isDesktop ? 'large' : undefined}
                                theme="outline"
                                block
                                onClick={onMore}
                                {...testIds.more}
                            >
                                {TEXTS.showMore}&nbsp;({leftTravelers})
                            </Button>
                        </div>
                    )}
                    {isDesktop && newTravelerButton}
                </>
            }
            aside={!isDesktop ? newTravelerButton : null}
        >
            {content && <div {...testIds.list}>{content}</div>}

            {newTraveler && (
                <Traveler id={newTraveler} onNewClose={onNewClose} />
            )}

            {isFetch && <TravelerLoader />}
        </Wrapper>
    );
};

export default React.memo(Travelers);

Travelers.propTypes = {
    isFetch: bool,
    leftTravelers: number,
    newTraveler: string,
    onMore: func,
    onNew: func,
    onNewClose: func,
    travelers: arrayOf(string),
};
