import React from 'react';
import { bool, node, shape } from 'prop-types';
import { fieldInputPropTypes, fieldMetaPropTypes } from 'types';

import { SelectGender as SelectGenderBase } from '@s7/ui-kit';

const SelectGender = ({
    input,
    meta,
    message,
    invalid: invalidFromProps,
    ...rest
}) => {
    const invalid =
        invalidFromProps || (meta.error && (meta.touched || meta.submitFailed));

    return (
        <SelectGenderBase
            {...rest}
            value={input.value}
            onChange={input.onChange}
            {...{
                invalid,
                tooltip: invalid ? meta.error || message : undefined,
            }}
        />
    );
};

SelectGender.propTypes = {
    input: shape(fieldInputPropTypes),
    invalid: bool,
    message: node,
    meta: shape(fieldMetaPropTypes),
};

SelectGender.defaultProps = {
    input: {},
    meta: {},
};

const SelectGenderMemo = React.memo(SelectGender);

export { SelectGenderMemo as SelectGender };
