import React, { useMemo } from 'react';
import { useWindowSize } from 'hooks/useWindowSize';
import { bool, func, node, object, string } from 'prop-types';
import { Field as FormField } from 'redux-form';

import { Button, StatusMessage, Text, Title } from '@s7/ui-kit';

import cx from './GroupAccount.sss';

import { TextField } from '../Fields';
import Icon from '../Icon';
import Modal from '../Modal';

export const GroupAccountModal = React.memo(function GroupModalContent({
    actionText,
    description,
    error,
    findText,
    getTestId,
    id,
    isFind,
    isInvitation,
    isOpen,
    onCancel,
    onChange,
    onClose,
    onConfirm,
    texts = {},
    title,
    type,
    username,
}) {
    const { isMobile } = useWindowSize();

    const fieldIcon = useMemo(
        () => (
            <span className={cx({ 'success-icon': !!username })}>
                <Icon icon="s7-priority-card" newIcon />
            </span>
        ),
        [username],
    );

    const contentComponent = (
        <div className={cx('modal')} {...getTestId('inviteModal')}>
            {error && (
                <div className={cx('modal_notification')}>
                    <StatusMessage view="error" {...getTestId('inviteError')}>
                        {error}
                    </StatusMessage>
                </div>
            )}

            <div className={cx('modal_description')}>
                <Text as="span" size={!isMobile ? 'l' : undefined}>
                    {description}
                </Text>
            </div>

            {isInvitation && (
                <div className={cx('modal_card')}>
                    <FormField
                        component={TextField}
                        iconRight={fieldIcon}
                        inputProps={getTestId('inviteNumber')}
                        label={texts.ffpCardLabel}
                        mask="999 999 999"
                        name="ffpCard"
                        onChange={onChange}
                    />
                </div>
            )}

            {isFind && (
                <div className={cx('modal_find')}>
                    <Text size={!isMobile ? 'l' : undefined}>{findText}</Text>

                    <div className={cx('modal_username')}>
                        <Title level={4} {...getTestId('inviteName')}>
                            {username}
                        </Title>
                    </div>
                </div>
            )}
        </div>
    );

    const dsModalProps = {
        actions: [
            <Button
                size={!isMobile ? 'large' : 'medium'}
                theme="secondary"
                block
                onClick={onCancel}
                {...getTestId('inviteCancel')}
            >
                {texts.cancel}
            </Button>,
            <Button
                disabled={isInvitation && !isFind}
                size={!isMobile ? 'large' : 'medium'}
                theme={type === 'error' ? 'destructive' : 'b2c'}
                block
                onClick={onConfirm}
                {...getTestId('inviteConfirm')}
            >
                {actionText}
            </Button>,
        ],
        buttonProps: { alignment: 'center' },
        classes: {
            button: cx('modal_action_button'),
            buttons: cx('modal_actions'),
        },
        contentComponent,
        mobilePosition: 'center',
        onCancel,
        onConfirm,
        size: isMobile ? 'small' : 'medium',
        targetDisableScroll: document.body,
        title,
        titleComponent: <Title level={3}>{title}</Title>,
        type,
        width: isMobile ? 'auto' : 392,
    };

    return isOpen ? (
        <Modal
            dsModalProps={dsModalProps}
            id={id}
            ds
            widthAuto
            onClose={onClose}
        />
    ) : undefined;
});

GroupAccountModal.propTypes = {
    actionText: node,
    description: node,
    error: node,
    findResult: node,
    findText: node,
    getTestId: func,
    id: string,
    isFind: bool,
    isInvitation: bool,
    isOpen: bool,
    onCancel: func,
    onChange: func,
    onClose: func,
    onConfirm: func,
    texts: object,
    title: node,
    type: string,
    username: node,
};
