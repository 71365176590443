import React, { useMemo } from 'react';
import { DATE_FORMATS } from 'consts/dateFormats';
import moment from 'moment';
import { arrayOf, bool, func, node, shape, string } from 'prop-types';

import {
    Button,
    Link,
    OnBoardingTooltip,
    RoundButton,
    Text,
    Title,
} from '@s7/ui-kit';
import { CloseLinear, Pencil, Trash } from '@s7/ui-kit/icons';

import View from './View';
import cx from './View.sss';

import {
    airlineIconByType,
    documentIconByType,
    testIds,
    TEXTS,
} from '../../constants';
import { formatCardNumber, formatNumber } from '../helpers';

const DD_MM_YYYY = DATE_FORMATS.DD.MM.YYYY_dot;
const { ll } = DATE_FORMATS;

/**
 * У всей обертки сайта z-index: 200
 * Больше 202 не выставлять, иначе будет налазить на шапку
 */
const popoverProps = { style: { zIndex: 200 } };

const ViewContainer = ({
    birthday,
    children,
    contacts,
    documents,
    isNew,
    loyaltyCards,
    names: { firstName, lastName },
    onClose,
    onEdit,
    onOpen,
    onRemove,
    onRemoveCancel,
    onRemoveConfirmation,
    statusRemove,
    statusView,
    isDesktop,
}) => {
    const initials = useMemo(
        () =>
            isNew ? null : (
                <Text color="G600" size="xl" {...testIds.initials}>
                    {`${(firstName || '').charAt(0)}${(lastName || '').charAt(
                        0,
                    )}`.toUpperCase()}
                </Text>
            ),
        [firstName, lastName, isNew],
    );

    const birthdayText = useMemo(() => {
        const date = moment(birthday, DD_MM_YYYY);

        return date.isValid() ? date.format(ll) : undefined;
    }, [birthday]);

    const documentsView = useMemo(() => {
        if (!documents.length || statusView) {
            return null;
        }

        const { type, number, series } = documents[0];

        return (
            <div className={cx('info')}>
                <span className={cx('icon')}>{documentIconByType[type]}</span>
                <Text size="l" {...testIds.document}>
                    {formatNumber({ number, series, type })}
                </Text>
                {documents.length > 1 && (
                    <span className={cx('count')}>
                        <Text color="N80" size="l" {...testIds.documents}>
                            (+{documents.length - 1})
                        </Text>
                    </span>
                )}
            </div>
        );
    }, [documents, statusView]);

    const loyaltyView = useMemo(() => {
        if (!loyaltyCards.length || statusView) {
            return null;
        }

        const { airline, number } = loyaltyCards[0];

        return (
            <div className={cx('info')}>
                <span className={cx('icon')}>{airlineIconByType[airline]}</span>
                <Text size="l" noWrap {...testIds.loyalty}>
                    {formatCardNumber({ airline, number })}
                </Text>
            </div>
        );
    }, [loyaltyCards, statusView]);

    const contactsView = useMemo(() => {
        if (!contacts.length || statusView) {
            return null;
        }

        return contacts.map(({ value, type }) => (
            <Text
                key={value}
                color="N100"
                size="s"
                noWrap
                {...testIds[type.toLowerCase()]}
            >
                {value}
            </Text>
        ));
    }, [contacts, statusView]);

    const tooltipRemoveProps = useMemo(
        () => ({
            actions: (
                <>
                    <Button
                        theme="destructive"
                        block
                        onClick={onRemove}
                        {...testIds.removeConfirm}
                    >
                        {TEXTS.removeConfirm}
                    </Button>
                    <Button
                        theme="secondary"
                        block
                        onClick={onRemoveCancel}
                        {...testIds.removeCancel}
                    >
                        {TEXTS.cancel}
                    </Button>
                </>
            ),
            disableHover: true,
            onClick: statusRemove ? onRemoveCancel : onRemoveConfirmation,
            onClose: onRemoveCancel,
            opened: Boolean(statusRemove),
            title: (
                <Title color="W100" level={5}>
                    {TEXTS.removeTitle}
                </Title>
            ),
            view: 'dark',
            width: '296px',
        }),
        [onRemove, statusRemove, onRemoveCancel, onRemoveConfirmation],
    );

    const actions = useMemo(() => {
        if (statusView) {
            return [
                ...(!isNew
                    ? [
                          {
                              item: (
                                  <OnBoardingTooltip
                                      {...tooltipRemoveProps}
                                      id="remove-traveler"
                                      popoverProps={popoverProps}
                                  >
                                      <Link
                                          icon={<Trash block />}
                                          iconPosition="right"
                                          view="destructive"
                                          {...testIds.remove}
                                      >
                                          {TEXTS.remove}
                                      </Link>
                                  </OnBoardingTooltip>
                              ),
                              key: `${statusView}_edit`,
                          },
                      ]
                    : []),
                {
                    item: (
                        <Link
                            as="button"
                            icon={<CloseLinear block />}
                            iconPosition="right"
                            size="m"
                            type="button"
                            view="secondary"
                            onClick={onClose}
                            {...testIds.close}
                        >
                            {TEXTS.close}
                        </Link>
                    ),
                    key: 'close_edit',
                },
            ];
        }

        return [
            {
                item: (
                    <OnBoardingTooltip
                        {...tooltipRemoveProps}
                        id="remove-traveler"
                        popoverProps={popoverProps}
                    >
                        <RoundButton
                            size="s"
                            view="secondary"
                            {...testIds.remove}
                        >
                            <Trash />
                        </RoundButton>
                    </OnBoardingTooltip>
                ),
                key: `${statusView}_view`,
            },
            {
                item: (
                    <RoundButton
                        size="s"
                        view="secondary"
                        onClick={onEdit}
                        {...testIds.edit}
                    >
                        <Pencil />
                    </RoundButton>
                ),
                key: 'close_view',
            },
        ];
    }, [onEdit, onClose, statusView, isNew, tooltipRemoveProps]);

    return (
        <View
            actions={actions}
            actionsVisible={!!statusRemove}
            birthday={birthdayText}
            contacts={contactsView}
            documents={documentsView}
            firstName={firstName}
            initials={initials}
            isDesktop={isDesktop}
            lastName={lastName}
            loyalty={loyaltyView}
            statusView={statusView}
            onOpen={onOpen}
        >
            {statusView && children}
        </View>
    );
};

ViewContainer.propTypes = {
    birthday: string,
    children: node,
    contacts: arrayOf(
        shape({
            value: string,
        }),
    ),
    documents: arrayOf(
        shape({
            number: string,
            type: string,
        }),
    ),
    isDesktop: bool,
    isNew: bool,
    loyaltyCards: arrayOf(
        shape({
            airline: string,
            number: string,
        }),
    ),
    names: shape({
        firstName: string,
        lastName: string,
    }),
    onClose: func,
    onEdit: func,
    onOpen: func,
    onRemove: func,
    onRemoveCancel: func,
    onRemoveConfirmation: func,
    statusRemove: string,
    statusView: string,
};

ViewContainer.defaultProps = {
    contacts: [],
    documents: [],
    loyaltyCards: [],
    names: {},
};

export default React.memo(ViewContainer);
