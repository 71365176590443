import React from 'react';
import { loadingError } from 'language/errors';

import { StatusMessage } from '@s7/ui-kit';

import Wrapper from '../Wrapper';

const Failure = () => (
    <Wrapper
        bottom={
            <StatusMessage title={loadingError.title} view="error">
                {loadingError.description}
            </StatusMessage>
        }
    />
);

export default Failure;
