import React from 'react';
import { Dictionary } from 'shared';

import { validateRequiredLong } from './required';

import createFieldValidate from '../createFieldValidate';

const correct = createFieldValidate({
    dateCorrect: {
        errorText: <Dictionary text="com.profile.form.valid.date" />,
    },
});

const pass = createFieldValidate({
    datePast: {
        errorText: <Dictionary text="com.profile.form.valid.date" />,
    },
});

export const validateDateBirth = [correct, pass, validateRequiredLong];
