import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import DocumentForm from 'components/DocumentForm';
import DocumentRemove from 'components/DocumentRemove';
import DocumentScan from 'components/DocumentScan';
import {
    DATE_FORMATS,
    HASH_PROFILE_DOCUMENTS,
    HASH_PROFILE_VISA,
} from 'consts';
import { GoogleAnalyticContext } from 'contexts';
import { documentType } from 'data';
import moment from 'moment';
import {
    any,
    arrayOf,
    bool,
    func,
    node,
    objectOf,
    oneOf,
    shape,
    string,
} from 'prop-types';
import { compose, pick, propOr } from 'ramda';
import { getFormValues, reduxForm, reset } from 'redux-form';
import { Dictionary, UserEdit } from 'shared';
import { getCountries } from 'store/modules/countries';
import {
    changeConfirmationRemove,
    fieldsSelector,
    getDocFormValues,
    getDocument,
    getInitialValues,
    getNewDocumentValues,
    remove,
    save,
    setEdit,
} from 'store/modules/documents';
import { getDictionary } from 'store/modules/languages';
import { getBirthdate } from 'store/modules/profile';
import ProgressBar from 'ui-kit/molecules/ProgressBar';
import { RULE_NOT_SEND_EXPIRE_PASSWORD } from 'utils/allowFeature';

const PASSPORTS = [
    documentType.INTERNAL_PASSPORT,
    documentType.INTERNATIONAL_PASSPORT,
];
const DD_MM_YYYY = DATE_FORMATS.DD.MM.YYYY_dot;

const MAX_LENGTH = {
    INTERNAL_PASSPORT: [10, 20],
    INTERNATIONAL_PASSPORT: [9, 20],
    OTHER: [15, 15],
};

const TEXTS = {
    cancel: <Dictionary text="com.action.cancel" />,
    deleteConfirm: <Dictionary text="com.action.delete.confirm" />,
    no: <Dictionary text="com.title.no" />,
    removeText: (
        <Dictionary text="com.profile.documents.form.visa.remove.confirm" />
    ),
    removingStatus: {
        failure: (
            <Dictionary text="com.profile.documents.form.visa.remove.failure" />
        ),
        pending: <Dictionary text="com.profile.confirmation.delete.pending" />,
        success: (
            <Dictionary text="com.profile.documents.form.visa.remove.success" />
        ),
    },
    save: <Dictionary text="com.action.save" />,
    title: {
        document: <Dictionary text="com.profile.docscan.title.document" />,
        edit: <Dictionary text="com.title.edit" />,
        visa: <Dictionary text="com.profile.docscan.title.visa" />,
    },
};

const getFormName = ({ isNew, isVisa, counter, id }) =>
    `document_${isNew ? `new${isVisa ? 'visa' : 'doc'}_${counter}` : id}`;

const mapStateToProps = (state, props) => {
    const form = getFormName(props);
    const { isVisa, birthday } = props;

    const birthdate = birthday || getBirthdate(state);

    const docNew = getNewDocumentValues(isVisa, birthdate);
    const doc = getDocument(props.id, state) || docNew;

    const {
        type,
        countryOfIssue,
        number,
        series,
        seriesNumber,
        effectiveDate,
        dateOfIssue,
        startDate,
        expire,
    } = getDocFormValues(
        getFormValues(form)(state) || (props.isNew ? docNew : {}),
    );

    return {
        birthday: birthdate,
        countries: getCountries(state),
        countryOfIssue,
        dateOfIssue,
        dictionary: getDictionary(state),
        effectiveDate,
        expire,
        fields: type ? fieldsSelector(type) : [],
        form,
        initialValues: getInitialValues(doc),
        number,
        series,
        seriesNumber,
        startDate,
        type,
    };
};

const mapDispatchToProps = (dispatch, props) => ({
    onChangeConfirmedForRemove: needConfirmForRemove =>
        dispatch(
            changeConfirmationRemove({
                id: props.id,
                needConfirmForRemove,
            }),
        ),
    onRemove: () =>
        dispatch(
            remove({
                id: props.id,
            }),
        ),
    onResetFormToInitial: formName => {
        dispatch(reset(formName));
    },
    onSave: ({ sendMyProfileEvent, ...payload }) => {
        sendMyProfileEvent({
            eventActionPath: [
                'documents',
                props.isVisa ? 'onSaveVisaClick' : 'onSaveDocumentClick',
            ],
            eventLabel: props.isVisa
                ? `COUNTRY:${payload.data.countryOfIssue};MULTI:${
                      payload.data.multi ? 1 : 0
                  }`
                : `TYPE:${payload.data.type};CITIZENSHIP:${payload.data.countryOfIssue}`,
        });
        dispatch(
            save({
                id: props.id,
                ...payload,
            }),
        );
    },
    setEdit: status =>
        props.isNew
            ? null
            : dispatch(
                  setEdit({
                      id: props.id,
                      status,
                  }),
              ),
});

const FIELD_NAMES_BY_TYPE = {
    [documentType.VISA]: [
        'countryOfIssue',
        'number',
        'placeOfIssue',
        'countryOfIssue',
        'startDate',
        'dateOfIssue',
        'expirationDate',
        'multi',
        'destinationCountry',
        'birthCountry',
        'placeOfBirth',
    ],
    [documentType.INTERNAL_PASSPORT]: ['dateOfIssue', 'number'],
    [documentType.INTERNATIONAL_PASSPORT]: [
        'dateOfIssue',
        'expirationDate',
        'number',
    ],
    [documentType.OTHER]: [
        'countryOfIssue',
        'number',
        'dateOfIssue',
        'expirationDate',
    ],
    [documentType.BIRTH_CERTIFICATE]: ['series', 'number', 'dateOfIssue'],
    [documentType.MILITARY_CARD]: ['seriesNumber', 'dateOfIssue'],
};

class DocumentEditContainer extends PureComponent {
    static propTypes = {
        birthday: string,
        buttons: node,
        change: func,
        countries: arrayOf(
            shape({
                name: string,
                title: string,
            }),
        ),
        countryOfIssue: string,
        dateOfIssue: string,
        dictionary: objectOf(any),
        disabled: bool,
        error: node,
        expirationDate: string,
        expire: string,
        fields: arrayOf(string),
        form: string,
        getTestId: func,
        handleSubmit: func,
        hideSave: bool,
        id: string,
        initialize: func,
        isArchive: bool,
        isDocuments: bool,
        isNew: bool,
        isVisa: bool,
        needConfirmForRemove: bool,
        number: string,
        onChangeConfirmedForRemove: func,
        onCloseConfirmedRemove: func,
        onConfirmedRemove: func,
        onDocscanClick: func,
        onRemove: func,
        onReset: func,
        onSave: func,
        onSubmit: func,
        pristine: bool,
        removingStatus: oneOf(['pending', 'success', 'failure']),
        series: string,
        seriesNumber: string,
        setEdit: func,
        startDate: string,
        submitting: bool,
        type: string,
        types: arrayOf(
            shape({
                isDisabled: bool,
                name: string,
                title: string,
            }),
        ),
        untouch: func,
    };

    static defaultProps = {
        getTestId: () => {},
        onReset: () => {},
        onSubmit: () => {},
    };

    constructor(props) {
        super(props);

        this.state = { initialDocumentType: '' };
    }

    componentDidUpdate(prevProps) {
        const { initialDocumentType } = this.state;
        const {
            change,
            countryOfIssue,
            number = '',
            seriesNumber = '',
            type,
        } = this.props;

        const countryChanged = prevProps.countryOfIssue !== countryOfIssue;
        const typeChanged =
            type && type !== prevProps.type && type !== initialDocumentType;

        const isPassport = PASSPORTS.includes(type);
        const isRu = this.rusCountryCode === countryOfIssue;

        if (prevProps.type === undefined && type) {
            this.setState({
                initialDocumentType: type,
            });
        }

        if (seriesNumber && countryChanged && isPassport && isRu) {
            change('seriesNumber', seriesNumber.replace(/[^0-9]/g, ''));
        }

        if (number && countryChanged) {
            const maxLength =
                MAX_LENGTH[type] && MAX_LENGTH[type][isRu ? 0 : 1];

            if (maxLength && number.length > maxLength) {
                change('number', number.slice(0, maxLength));
            }
        }

        if (typeChanged) {
            if (isPassport && !isRu) {
                change('countryOfIssue', this.rusCountryCode);
            }

            if (prevProps.type) {
                this.handleResetFields(propOr([], type, FIELD_NAMES_BY_TYPE));
            }
        }
    }

    handleResetFields = names => {
        names.forEach(name => {
            if (name === 'seriesNumber') {
                this.props.change(name, { number: '', series: '' });
            } else {
                this.props.change(name, '');
            }
        });

        this.props.untouch(...names);
    };

    handleSubmit = this.props.handleSubmit(values => {
        const { fields } = this.props;
        const { sendMyProfileEvent } = this.context;

        if (this.props.pristine) {
            this.props.setEdit(false);
        }

        this.props.onSave({
            data: {
                ...pick(fields, values),
                ...([
                    documentType.BIRTH_CERTIFICATE,
                    documentType.MILITARY_CARD,
                ].includes(values.type)
                    ? {
                          number: values.seriesNumber.number,
                          series: values.seriesNumber.series,
                      }
                    : {}),
                ...(documentType.MILITARY_CARD === values.type
                    ? {
                          expirationDate: moment(values.dateOfIssue, DD_MM_YYYY)
                              .add(70, 'years')
                              .format(DD_MM_YYYY),
                      }
                    : {}),
                ...(documentType.INTERNAL_PASSPORT === values.type &&
                !RULE_NOT_SEND_EXPIRE_PASSWORD
                    ? {
                          expirationDate: moment(values.dateOfIssue, DD_MM_YYYY)
                              .add(20, 'years')
                              .format(DD_MM_YYYY),
                      }
                    : {}),
            },
            form: this.props.form,
            sendMyProfileEvent,
        });

        this.props.onSubmit();
    });

    handleReset = () => {
        this.props.setEdit(false);
        this.props.onReset();
    };

    handleOpenConfirmedForRemove = () => {
        this.props.onChangeConfirmedForRemove(true);
    };

    handleCloseConfirmedForRemove = () => {
        this.props.onChangeConfirmedForRemove(false);
    };

    handleConfirmedRemove = () => {
        this.props.onRemove();
    };

    handleSelectChange = selectedDocumentType => {
        const { initialDocumentType } = this.state;
        const { onResetFormToInitial } = this.props;

        if (selectedDocumentType === initialDocumentType) {
            onResetFormToInitial(getFormName(this.props));
        }
    };

    getExpired() {
        const { expire } = this.props;

        if (expire === '') {
            return false;
        }

        return moment(expire, DD_MM_YYYY).isBefore(moment());
    }

    getDisabledDayFields = () => {
        const { startDate } = this.props;
        const start = moment(startDate, DD_MM_YYYY).add(1, 'days').toDate();

        let before = new Date();

        if (moment().diff(start, before) < 0) {
            before = start;
        }

        return {
            expirationDate: startDate
                ? [
                      {
                          before,
                      },
                  ]
                : null,
        };
    };

    rusCountryCode = 'RU';

    docscan = data => {
        const { type, isVisa, initialize } = this.props;

        if (
            (isVisa && data.type === documentType.VISA) ||
            (!isVisa && data.type !== documentType.VISA)
        ) {
            if (data.type === documentType.INTERNAL_PASSPORT) {
                data.countryOfIssue = this.rusCountryCode;
            }

            if (!data.type) {
                data.type = type;
            }

            initialize({
                countryOfIssue:
                    data.countryOfIssue ?? data.country ?? this.rusCountryCode,
                dateOfIssue: data.dateOfIssue ?? '',
                expirationDate: data.expireDate ?? '',
                number: data.seriesNumber ?? '',
                placeOfIssue:
                    data.placeOfIssue ?? data.destinationCountry ?? '',
                startDate: data.issuedOn ?? '',
                type: data.type ?? documentType.OTHER,
            });
        }
    };

    renderButtons() {
        const { getTestId, type, isNew } = this.props;

        if (isNew || type !== 'VISA') {
            return null;
        }

        const expired = this.getExpired();

        return (
            <DocumentRemove
                expired={expired}
                onClick={this.handleOpenConfirmedForRemove}
                {...{ getTestId }}
            />
        );
    }

    render() {
        const {
            birthday,
            buttons,
            change,
            countries,
            countryOfIssue,
            dateOfIssue,
            dictionary,
            disabled,
            error,
            expirationDate,
            getTestId,
            hideSave,
            id,
            isArchive,
            isDocuments,
            isNew,
            isVisa,
            needConfirmForRemove,
            onDocscanClick,
            removingStatus,
            seriesNumber,
            startDate,
            submitting,
            type,
            types,
        } = this.props;

        const disabledDocScan = !!seriesNumber;

        return (
            <UserEdit
                routeHash={`${
                    isVisa ? HASH_PROFILE_VISA : HASH_PROFILE_DOCUMENTS
                }/${isNew ? 'new' : id}`}
                testIds={{
                    cancelButton: getTestId('cancelButton'),
                    removeCancel: getTestId('removeCancel'),
                    removeConfirm: getTestId('removeConfirm'),
                    removePopup: getTestId('removePopup'),
                    removeSuccessPopup: getTestId('removeSuccessPopup'),
                    root: getTestId(isNew ? 'addForm' : 'editForm'),
                    saveButton: getTestId('saveButton'),
                }}
                title={
                    isNew
                        ? TEXTS.title[isVisa ? 'visa' : 'document']
                        : TEXTS.title.edit
                }
                buttons={buttons || this.renderButtons()}
                cancelText={TEXTS.cancel}
                confirmationRemoveCancelText={TEXTS.no}
                confirmationRemoveConfirmText={TEXTS.deleteConfirm}
                confirmationRemoveText={isVisa ? TEXTS.removeText : ''}
                error={error}
                hideSave={hideSave}
                needConfirmForRemove={needConfirmForRemove}
                removingMessage={TEXTS.removingStatus[removingStatus]}
                removingStatus={removingStatus}
                saveText={TEXTS.save}
                isTraveler
                onCloseConfirmedRemove={this.handleCloseConfirmedForRemove}
                onConfirmedRemove={this.handleConfirmedRemove}
                onReset={this.handleReset}
                onSubmit={this.handleSubmit}
            >
                {isNew &&
                    (disabled || (
                        <DocumentScan
                            disabled={disabledDocScan}
                            docscan={this.docscan}
                            isVisa={isVisa}
                            onDocscanClick={onDocscanClick}
                        />
                    ))}
                <DocumentForm
                    birthday={birthday}
                    change={change}
                    countries={countries}
                    countryOfIssue={countryOfIssue}
                    dateOfIssue={dateOfIssue}
                    dictionary={dictionary}
                    disabled={disabled}
                    disabledDayFields={this.getDisabledDayFields()}
                    expirationDate={expirationDate}
                    isArchive={isArchive}
                    isDocuments={isDocuments}
                    isNew={isNew}
                    isVisa={isVisa}
                    startDate={startDate}
                    type={type}
                    types={types}
                    onSelectChange={this.handleSelectChange}
                    {...{ getTestId }}
                />
                <ProgressBar isOpen={submitting} size="large" />
            </UserEdit>
        );
    }
}

DocumentEditContainer.contextType = GoogleAnalyticContext;

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm({
        enableReinitialize: true,
        keepDirtyOnReinitialize: true,
    }),
)(DocumentEditContainer);
