import { createAction, createReducer } from 'redux-act';

const initialState = {
    confirming: false,
    disabling: false,
    hasError: false,
    hasInvitation: false,
    role: '',
    showInvitation: false,
};

export const checkShowInvitation = createAction('checkShowInvitation');
export const checkShowInvitationSuccess = createAction(
    'checkShowInvitationSuccess',
);

export const confirmInvitation = createAction('confirmInvitation');
export const confirmInvitationFailure = createAction(
    'confirmInvitationFailure',
);
export const confirmInvitationSuccess = createAction(
    'confirmInvitationSuccess',
);

export const disableShowInvitationFailure = createAction(
    'disableShowInvitationFailure',
);
export const disableShowInvitationSuccess = createAction(
    'disableShowInvitationSuccess',
);
export const disableShowInvitation = createAction('disableShowInvitation');

const handleCheckShowInvitationSuccess = (
    state,
    { companyName, employeeId, enabled },
) => ({
    ...state,
    companyName,
    employeeId,
    hasInvitation: enabled,
});

const handleConfirmInvitation = state => ({
    ...state,
    confirming: true,
});
const handleConfirmInvitationFailure = state => ({
    ...state,
    confirming: false,
    hasError: true,
});
const handleConfirmInvitationSuccess = state => ({
    ...state,
    confirming: false,
    hasInvitation: false,
});

const handleDisableShowInvitation = state => ({
    ...state,
    disabling: true,
});
const handleDisableShowInvitationFailure = state => ({
    ...state,
    disabling: false,
    hasError: true,
});
const handleDisableShowInvitationSuccess = state => ({
    ...state,
    disabling: false,
    hasInvitation: false,
});

export default createReducer(
    {
        [checkShowInvitationSuccess]: handleCheckShowInvitationSuccess,
        [confirmInvitation]: handleConfirmInvitation,
        [confirmInvitationFailure]: handleConfirmInvitationFailure,
        [confirmInvitationSuccess]: handleConfirmInvitationSuccess,
        [disableShowInvitation]: handleDisableShowInvitation,
        [disableShowInvitationFailure]: handleDisableShowInvitationFailure,
        [disableShowInvitationSuccess]: handleDisableShowInvitationSuccess,
    },
    initialState,
);

export * from './selectors';
