import React from 'react';
import { func } from 'prop-types';

import modalImage from './images/modal.png';
import image from './images/UnderSeat.png';
import imageMobile from './images/UnderSeatMobile.png';

import { Card } from '../Card';

export const CardUnderSeat = ({ onClick }) => {
    return (
        <Card
            conditions={[
                'com.profile.pets.page.card.under-seat.condition1',
                'com.profile.pets.page.card.under-seat.condition2',
                'com.profile.pets.page.card.under-seat.condition3',
                'com.profile.pets.page.card.under-seat.condition4',
                'com.profile.pets.page.card.under-seat.condition5',
                'com.profile.pets.page.card.under-seat.condition6',
                'com.profile.pets.page.card.under-seat.condition7',
                'com.profile.pets.page.card.under-seat.condition8',
            ]}
            cardType="underSeat"
            image={image}
            imageMobile={imageMobile}
            modalImage={modalImage}
            size="com.profile.pets.page.card.under-seat.size"
            subText="com.profile.pets.page.card.carrier"
            title="com.profile.pets.page.card.under-seat.title"
            onClick={onClick}
        />
    );
};

CardUnderSeat.propTypes = {
    onClick: func.isRequired,
};
