import React from 'react';
import { SelectField, TextField } from 'components/Fields';
import { arrayOf, shape, string } from 'prop-types';
import { Field as FormField } from 'redux-form';

import cx from './Form.sss';

import { testIds, TEXTS } from '../../constants';

const Loyalty = ({ airlineOptions }) => {
    return (
        <div className={cx('root')}>
            <div className={cx('column')} {...testIds.form.loyalty.airline}>
                <FormField
                    autocomplete="cc-type"
                    component={SelectField}
                    label={TEXTS.form.cardType}
                    maxLength={30}
                    name="loyaltyAirline"
                    options={airlineOptions}
                />
            </div>
            <div className={cx('column')}>
                <FormField
                    autocomplete="cc-csc"
                    component={TextField}
                    inputProps={testIds.form.loyalty.number}
                    label={TEXTS.form.card}
                    maxLength={30}
                    name="loyaltyNumber"
                    tooltipProps={testIds.form.loyalty.numberError}
                />
            </div>
        </div>
    );
};

Loyalty.propTypes = {
    airlineOptions: arrayOf(
        shape({
            label: string,
            value: string,
        }),
    ).isRequired,
};

export default React.memo(Loyalty);
