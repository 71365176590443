import React from 'react';
import { PETS_TYPE, PETS_WEIGHT } from 'consts/pets';
import { bool, func, object, string } from 'prop-types';
import { reduxForm } from 'redux-form';

import { ADD_FORM_NAME } from '../../constants';
import { ModalForm } from '../../ModalForm';

const ModalAddForm = ({
    onClose,
    onSubmit,
    handleSubmit,
    submitting,
    errorMessage,
}) => {
    return (
        <ModalForm
            errorMessage={errorMessage}
            formName={ADD_FORM_NAME}
            handleSubmit={handleSubmit}
            submitText="com.profile.pets.button.add"
            submitting={submitting}
            title="com.profile.pets.modal.add.title"
            onClose={onClose}
            onSubmit={onSubmit}
        />
    );
};

ModalAddForm.propTypes = {
    errorMessage: string,
    handleSubmit: func,
    initialValues: object,
    onClose: func.isRequired,
    onSubmit: func.isRequired,
    submitting: bool,
};

const ReduxForm = reduxForm({
    destroyOnUnmount: true,
    forceUnregisterOnUnmount: true,
    form: ADD_FORM_NAME,
    initialValues: {
        type: PETS_TYPE.DOG,
        weight: PETS_WEIGHT.UP_TO_10,
    },
})(ModalAddForm);

export { ReduxForm as ModalAddForm };
