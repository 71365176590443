import { complement, compose, head, keys, mapObjIndexed, pickBy } from 'ramda';

import getErrorText from './getErrorText';
import validators from './validators';

export default function createFieldValidate(rules) {
    return value => {
        const getError = compose(
            getErrorText(rules),
            head,
            keys,
            pickBy(complement(Boolean)),
        );

        if (!rules) {
            return undefined;
        }

        const { required, ...rest } = rules;

        const isValid = validators.required(required)(value);

        if (!isValid) {
            if (required) {
                return getError({
                    required: false,
                });
            }

            return getError({});
        }

        return compose(
            getError,
            mapObjIndexed((validatorConfig, validatorName) => {
                if (!validators[validatorName] || !validatorConfig) {
                    return true;
                }

                const validator = validators[validatorName](validatorConfig);

                return validator(value);
            }),
        )(rest);
    };
}
