import React from 'react';
import { DateField, SelectGender, TextField } from 'components/Fields';
import moment from 'moment';
import { Field as FormField } from 'redux-form';
import { normalizeLatin } from 'utils/format';

import cx from './Form.sss';

import { testIds, TEXTS } from '../../constants';

const SEX_OPTIONS = [
    {
        ...testIds.form.personal.genderFemale,
        label: TEXTS.form.genderFemale,
        value: 'FEMALE',
    },
    {
        ...testIds.form.personal.genderMale,
        label: TEXTS.form.genderMale,
        value: 'MALE',
    },
];

const Personal = () => (
    <div className={cx('root')}>
        <div className={cx('column')}>
            <FormField
                autoComplete="given-name"
                component={TextField}
                inputProps={testIds.form.personal.firstName}
                label={TEXTS.form.firstName}
                maxLength={30}
                name="firstName"
                normalize={normalizeLatin}
                tooltipProps={testIds.form.personal.firstNameError}
            />
        </div>
        <div className={cx('column')}>
            <FormField
                autoComplete="family-name"
                component={TextField}
                inputProps={testIds.form.personal.lastName}
                label={TEXTS.form.lastName}
                maxLength={30}
                name="lastName"
                normalize={normalizeLatin}
                tooltipProps={testIds.form.personal.lastNameError}
            />
        </div>
        <div className={cx('column')}>
            <FormField
                disabledDays={[
                    {
                        before: moment().subtract(99, 'years').toDate(),
                    },
                ]}
                autoComplete="bday"
                component={DateField}
                inputProps={testIds.form.personal.birthday}
                label={TEXTS.form.birthdate}
                name="birthday"
                tooltipProps={testIds.form.personal.birthdayError}
                isBirthday
            />
        </div>
        <div className={cx('column', 'mobile_first')}>
            <FormField
                component={SelectGender}
                name="gender"
                options={SEX_OPTIONS}
                tooltipProps={testIds.form.personal.genderError}
                {...testIds.form.personal.gender}
            />
        </div>
    </div>
);

export default React.memo(Personal);
