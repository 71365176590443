import React from 'react';
import ProfileView from 'components/ProfileView';
import Loadable from 'decorators/Loadable';
import { compose } from 'ramda';
import { isInitialized as isCardsInitialized } from 'store/modules/cards';
import {
    isError as isErrorLanguages,
    isLoading as isLoadingLanguages,
} from 'store/modules/languages';
import FailedToLoad from 'ui-kit/atoms/FailedToLoad';
import { SkeletonLoaderProfileView } from 'ui-kit/atoms/SkeletonLoader';

export default compose(
    Loadable({
        isError: state =>
            !(
                !state.profile.isError &&
                !state.countries.isError &&
                !isErrorLanguages(state)
            ),
        isLoading: state =>
            !state.profile.isInitialized ||
            !isCardsInitialized(state) ||
            !state.countries.isInitialized ||
            !state.documents.isInitialized ||
            isLoadingLanguages(state),
        Loader: <SkeletonLoaderProfileView />,
        Rebooter: <FailedToLoad />,
    }),
)(ProfileView);
