import { createAction, createReducer } from 'redux-act';

const STATUS = {
    completed: 3,
    end: 6,
    error: 4,
    getting: 5,
    inProgress: 2,
    submitted: 1,
    unknown: 0,
};
// Status values: Unknown = 0, Submitted = 1, InProgress = 2, Completed = 3, Error = 4, Getting = 5, End = 6
const initialState = {};

export const send = createAction('docscan/send');
export const sendSuccess = createAction('docscan/sendSuccess');
export const sendFailure = createAction('docscan/sendFailure');
export const check = createAction('docscan/check');
export const checkSuccess = createAction('docscan/checkSuccess');
export const checkFailure = createAction('docscan/checkFailure');
export const get = createAction('docscan/get');
export const getSuccess = createAction('docscan/getSuccess');
export const getFailure = createAction('docscan/getFailure');

const handleSend = (state, { fileId }) => ({
    ...state,
    [`${fileId}`]: {
        ...state[fileId],
        data: {},
        id: '',
        status: STATUS.unknown,
        token: '',
    },
});

const handleSendSuccess = (state, { id, token, fileId }) => ({
    ...state,
    [`${fileId}`]: {
        ...state[fileId],
        id,
        status: STATUS.submitted,
        token,
    },
});

const handleFailure = (state, { errorMessage, fileId }) => ({
    ...state,
    [`${fileId}`]: {
        errorMessage,
        status: STATUS.error,
    },
});

const handleGet = (state, { fileId }) => ({
    ...state,
    [`${fileId}`]: {
        ...state[fileId],
        status: STATUS.getting,
    },
});

const handleCheckSuccess = (state, { fileId, status }) => ({
    ...state,
    [`${fileId}`]: {
        ...state[fileId],
        status,
    },
});

const handleGetSuccess = (state, { document, fileId }) => ({
    ...state,
    [`${fileId}`]: {
        ...state[fileId],
        data: document,
        status: STATUS.end,
    },
});

export default createReducer(
    {
        [checkFailure]: handleFailure,
        [checkSuccess]: handleCheckSuccess,
        [get]: handleGet,
        [getFailure]: handleFailure,
        [getSuccess]: handleGetSuccess,
        [send]: handleSend,
        [sendFailure]: handleFailure,
        [sendSuccess]: handleSendSuccess,
    },
    initialState,
);

export * from './selectors';
