import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { PROFILE_OPERATION_TYPE } from 'consts/profile';
import { useAnalyticsContext, useGoogleAnalyticContext } from 'contexts';
import { useWindowSize } from 'hooks/useWindowSize';
import { bool, func, string } from 'prop-types';
import { compose } from 'ramda';
import { getFormValues, reduxForm } from 'redux-form';
import { Dictionary, Paper, PaperContent, PaperTitle } from 'shared';
import { getProfileUnavailableOperations } from 'store/modules/profile';

import {
    getAnalyticEventParameter,
    getMembershipOfferNotificationText,
} from './helpers';

import { GroupAccount, GroupAccountModal } from '../../components/GroupAccount';
import Icon from '../../components/Icon';
import { modalIds } from '../../data';
import { memberStatus, memberType } from '../../data/membership';
import { useIsVisible } from '../../hooks/useIsVisible';
import { getDictionary } from '../../store/modules/languages';
import {
    activateInMembership,
    checkCard,
    checkCardReset,
    clearNotification,
    confirmDeletionFromMembership,
    declineInMembership,
    deleteFromMembership,
    getCardInfo,
    getCurrentMember,
    getGroupMemberId,
    getIsConfirming,
    getIsDeclining,
    getIsMemberHead,
    getIsMemberOfGroup,
    getIsMembershipCreationAvailable,
    getIsMemberSub,
    getMemberDeleting,
    getMembers,
    getMembershipAdmin,
    getMembershipID,
    getMembershipNotification,
    getMiles,
    rejectDeletionFromMembership,
    sendMembershipInvitation,
} from '../../store/modules/loyalty';
import { getPets } from '../../store/modules/pets';
import { getTravelers } from '../../store/modules/travelers';
import { normalizeNumber, noun, numberWithSpaces } from '../../utils/format';
import { dictKeys, getTestIdGetter } from '../../utils/testIds';

const getTestId = getTestIdGetter(dictKeys.GROUP_ACCOUNT);

/* --------------- messages --------------- */
const textes = {
    familyTitle: <Dictionary text="com.profile.family-account.title" />,
};

const rulesTexts = {
    rule1: <Dictionary text="com.profile.family-account.rule1" html />,
    rule2: <Dictionary text="com.profile.family-account.rule2" html />,
    rule3: <Dictionary text="com.profile.family-account.rule3" html />,
    rule4: <Dictionary text="com.profile.family-account.rule4" html />,
};

const getTextsByRole = ({ gender, isSub, isPendingStatus, dictionary }) => ({
    ...rulesTexts,
    actionDelete: <Dictionary text="com.action.delete.confirm" />,
    actionInvite: (
        <Dictionary text="com.profile.family-account.action.invite" />
    ),
    admin: <Dictionary text="com.profile.family-account.admin" />,
    adminRuleDetails: (
        <Dictionary text="com.profile.family-account.admin-rule-details" />
    ),
    cancel: <Dictionary text="com.profile.family-account.leave.cancel" />,
    collapse: <Dictionary text="com.collapse" />,
    confirm: <Dictionary text="com.action.confirm" />,
    description:
        isSub && !isPendingStatus ? (
            <Dictionary
                text={`com.profile.family-account.sub-description.${(
                    gender || 'MALE'
                ).toLowerCase()}`}
            />
        ) : (
            <Dictionary text="com.profile.family-account.description" />
        ),
    ffpCardLabel: (
        <Dictionary text="com.profile.family-account.form.ffp-card" />
    ),
    info: <Dictionary text="com.profile.family-account.info" />,
    invite: <Dictionary text="com.profile.family-account.invite" />,
    knowMore: <Dictionary text="com.know-more" />,
    leaveGroup: <Dictionary text="com.profile.family-account.exit.title" />,
    ruleDetails: <Dictionary text="com.profile.family-account.rule-details" />,
    rulesLink: dictionary.t('com.profile.family-account.rule-link'),
    title: <Dictionary text="com.profile.family-account.subtitle" />,
    unavailable: <Dictionary text="com.profile.family-account.unavaiable" />,
});

const getInvitationTexts = ({ name, gender, dictionary }) => ({
    cancel: <Dictionary text="com.title.no" />,
    confirm: <Dictionary text="com.title.yes" />,
    description: (
        <Dictionary text="com.profile.family-account.invitation.modal.description" />
    ),
    find: (
        <Dictionary text="com.profile.family-account.invitation.modal.success-search" />
    ),
    notificationDescription: (
        <Dictionary
            text={getMembershipOfferNotificationText({
                dictionary,
                gender,
                getTestId,
                name,
            })}
            asText
            html
        />
    ),
    title: (
        <Dictionary
            tag="span"
            text="com.profile.family-account.invitation.title"
            {...getTestId('notificationTitle')}
        />
    ),
});

const removalTexts = {
    confirm: <Dictionary text="com.profile.family-account.remove.confirm" />,
    description: (
        <Dictionary text="com.profile.family-account.remove.description" />
    ),
    title: <Dictionary text="com.profile.family-account.remove.title" />,

    userNotificationDescription: (
        <Dictionary text="com.profile.family-account.remove.notification.description" />
    ),
    userNotificationTitle: (
        <Dictionary text="com.profile.family-account.remove.notification.title" />
    ),
};

const leaveTexts = {
    confirm: <Dictionary text="com.profile.family-account.leave.confirm" />,
    description: <Dictionary text="com.profile.family-account.exit-warning" />,
    title: <Dictionary text="com.profile.family-account.leave.title" />,
};

const getIcon = name => <Icon height={36} icon={name} width={40} newIcon />;
const rule1 = {
    children: rulesTexts.rule1,
    icon: getIcon('l-group'),
};
const rule2 = {
    children: rulesTexts.rule2,
    icon: getIcon('l-person'),
};
const rule3 = {
    children: rulesTexts.rule3,
    icon: getIcon('l-miles'),
};
const rule4 = {
    children: rulesTexts.rule4,
    icon: getIcon('l-aircraft'),
};

const rules = [rule1, rule2, rule3, rule4];
const rulesForTablet = [
    [rule1, rule2],
    [rule3, rule4],
];

const formConfig = {
    fields: ['ffpCard', 'code'],
    form: 'groupAccount',
};

const mapStateToProps = state => ({ membershipID: getMembershipID(state) });

const getReduxForm = getFormValues('groupAccount');

const memberActionType = {
    INVITATION: 'invitation',
    LEAVING: 'leaving',
    REMOVAL: 'removal',
};

const commonTooltipProps = {
    disableHover: true,
    view: 'dark',
};

export const GroupAccountContainerBase = React.memo(
    function GroupAccountContainer({ isOpened, membershipID, reset }) {
        const { sendMyProfileGroupEvent } = useGoogleAnalyticContext();
        const { sendEvent } = useAnalyticsContext();

        const ref = useRef(null);
        const isVisible = useIsVisible(ref);

        const [opened, setIsOpened] = useState(isOpened);
        const [modalState, setModalState] = useState({ isOpen: false });
        const [isAccountInfoTooltipOpened, setIsAccountInfoTooltipOpened] =
            useState(false);
        const [notificationShow, setNotificationShow] = useState(true);

        const [isOpenLeaveTooltip, setIsOpenLeaveTooltip] = useState(false);
        const [removeTooltipState, setRemoveTooltipState] = useState({
            isOpen: false,
        });
        const [isOpenAccountInfoTooltip, setIsOpenAccountInfoTooltip] =
            useState(false);

        const dispatch = useDispatch();

        const formValues = useSelector(getReduxForm) || {};
        const cardInfo = useSelector(getCardInfo);

        const members = useSelector(getMembers);
        const membersWithoutAdmin = members.filter(
            member => member.type !== memberType.HEAD,
        );

        const membersCount = members ? membersWithoutAdmin.length : 0;
        const groupMemberId = useSelector(getGroupMemberId);
        const currentMember = useSelector(getCurrentMember) || {};

        const membershipAdmin = useSelector(getMembershipAdmin) || {};
        const isMembershipOfGroup = useSelector(getIsMemberOfGroup);
        const memberDeleting = useSelector(getMemberDeleting);
        const isConfirming = useSelector(getIsConfirming);
        const isDeclining = useSelector(getIsDeclining);

        const unavailableOperations = useSelector(
            getProfileUnavailableOperations,
        );
        const isMembershipCreationAvailable = useSelector(
            getIsMembershipCreationAvailable,
        );
        const membershipNotification = useSelector(getMembershipNotification);
        const isHead = useSelector(getIsMemberHead);
        const isSub = useSelector(getIsMemberSub);

        const dictionary = useSelector(getDictionary);

        const travelers = useSelector(getTravelers);
        const travelersCount = travelers.length;

        const pets = useSelector(getPets) || [];
        const petsCount = pets.length;

        const balance = useSelector(getMiles);
        const balanceLabel = useMemo(
            () =>
                `${numberWithSpaces(balance)} ${noun(balance, [
                    dictionary.t('com.units.miles.one'),
                    dictionary.t('com.units.miles.once'),
                    dictionary.t('com.units.miles.mult'),
                ])}`,
            [balance, dictionary],
        );

        const { isMobile, isTablet } = useWindowSize();

        const isPendingStatus =
            currentMember.status === memberStatus.PENDING &&
            !membershipNotification;

        const texts = useMemo(
            () =>
                getTextsByRole({
                    dictionary,
                    gender: membershipAdmin.gender,
                    isPendingStatus,
                    isSub,
                }),
            [isSub, isPendingStatus, dictionary, membershipAdmin.gender],
        );

        const invitationTexts = useMemo(
            () =>
                getInvitationTexts({
                    dictionary,
                    gender: membershipAdmin.gender,
                    name: membershipAdmin.name,
                }),
            [dictionary, membershipAdmin],
        );

        useEffect(() => {
            const input = normalizeNumber(formValues.ffpCard || '');

            if (input.length === 9) {
                dispatch(checkCard({ card: input, membershipID }));
            }
        }, [dispatch, formValues.ffpCard, membershipID]);

        const handleChangeOpen = () => {
            sendEvent({
                event_name: 'view_screen',
                flow: 'companions',
                step: 'membership',
                ux_ui: 'modal',
            });

            setIsOpened(!opened);
        };

        const handleResetCardInfo = useCallback(() => {
            if (modalState.isOpen && !!cardInfo) {
                dispatch(checkCardReset());
            }
        }, [dispatch, modalState.isOpen, cardInfo]);

        useEffect(() => {
            sendEvent({
                event: 'view_screen',
                flow: 'navigation',
                parameter: getAnalyticEventParameter(
                    membersCount,
                    travelersCount,
                    petsCount,
                ),
                step: 'companions',
                ux_ui: 'on_screen',
            });
        }, [sendEvent, isVisible, membersCount, travelersCount, petsCount]);

        useEffect(() => {
            if (!modalState.isOpen && !!cardInfo) {
                dispatch(checkCardReset());
            }
        }, [dispatch, modalState.isOpen, cardInfo]);

        // ================================================================
        // API methods
        // ================================================================
        const sendInvitation = useCallback(
            id => {
                dispatch(sendMembershipInvitation({ subMemberId: id }));
            },
            [dispatch],
        );

        const removeUserFromGroup = useCallback(
            ({ memberId }) => {
                dispatch(
                    deleteFromMembership({
                        memberId,
                        membershipId: groupMemberId,
                    }),
                );
            },
            [dispatch, groupMemberId],
        );

        const acceptInvitation = () => {
            dispatch(
                activateInMembership({
                    memberId: currentMember.id,
                    membershipId: groupMemberId,
                }),
            );
            sendMyProfileGroupEvent({ eventAction: 'Click_Invite_Accept' });
        };

        const declineInvitation = () => {
            dispatch(
                declineInMembership({
                    memberId: currentMember.id,
                    membershipId: groupMemberId,
                }),
            );
            sendMyProfileGroupEvent({ eventAction: 'Click_Invite_Decline' });
        };

        const confirmDeletion = () => {
            dispatch(
                confirmDeletionFromMembership({
                    memberId: currentMember.id,
                    membershipId: groupMemberId,
                }),
            );
            sendEvent({
                event_name: 'click_cta',
                flow: 'companions',
                result: 'agree_to_leave',
                step: 'membership',
            });
        };

        const rejectDeletion = () => {
            dispatch(
                rejectDeletionFromMembership({
                    memberId: currentMember.id,
                    membershipId: groupMemberId,
                }),
            );
        };

        const clearStoreNotification = () => {
            dispatch(clearNotification());
        };

        // ================================================================
        // Other methods
        // ================================================================
        const handleChangeIsAccountInfoTooltipOpened = () => {
            setIsAccountInfoTooltipOpened(!isAccountInfoTooltipOpened);
        };

        const openInvitationModal = () => {
            setModalState({ isOpen: true, type: memberActionType.INVITATION });
            sendMyProfileGroupEvent({
                eventAction: 'Click_GoToInvite',
                eventLabel: `QTY:${membersCount}`,
            });
        };

        const openRemovalModal = id => {
            setModalState({ id, isOpen: true, type: memberActionType.REMOVAL });
        };

        const openLeaveModal = () => {
            setModalState({ isOpen: true, type: memberActionType.LEAVING });
            sendMyProfileGroupEvent({ eventAction: 'Click_GoToQuit ' });
        };

        const closeModal = useCallback(() => {
            if (modalState.type === memberActionType.INVITATION) {
                reset();
            }

            setModalState({ isOpen: false });
        }, [modalState.type, reset]);

        const openLeaveTooltip = () => {
            setIsOpenLeaveTooltip(true);
        };

        const closeLeaveTooltip = () => {
            setIsOpenLeaveTooltip(false);
        };

        const changeIsOpenLeaveTooltip = () => {
            setIsOpenLeaveTooltip(!isOpenLeaveTooltip);
        };

        const changeIsOpenRemovalTooltip = id => {
            setRemoveTooltipState({ id, isOpen: !removeTooltipState.isOpen });
        };

        const onCloseRemovalTooltip = () => {
            setRemoveTooltipState({ isOpen: false });
        };

        const openAccountInfoTooltip = () => {
            setIsOpenAccountInfoTooltip(true);
        };

        const closeAccountInfoTooltip = () => {
            setIsOpenAccountInfoTooltip(false);
        };

        const confirmMembershipNotificationProps = {
            cancelButton: invitationTexts.cancel,
            cancelProps: {
                disabled: isConfirming,
                loading: isDeclining,
            },
            confirmButton: invitationTexts.confirm,
            confirmProps: {
                disabled: isDeclining,
                loading: isConfirming,
            },
            description: invitationTexts.notificationDescription,
            isOpen: currentMember.status === memberStatus.PENDING && !isHead,
            onCancel: declineInvitation,
            onConfirm: acceptInvitation,
            onToggle: () => setNotificationShow(prev => !prev),
            opened: notificationShow,
            title: invitationTexts.title,
            view: 'default',
        };

        useEffect(() => {
            if (confirmMembershipNotificationProps.isOpen) {
                sendMyProfileGroupEvent({
                    eventAction: 'Invite_Show_Buttons',
                });
            }
        }, [
            confirmMembershipNotificationProps.isOpen,
            sendMyProfileGroupEvent,
        ]);

        const deletionFromMembershipNotificationProps = {
            cancelButton: texts.cancel,
            confirmButton: leaveTexts.confirm,
            description: removalTexts.userNotificationDescription,
            isOpen:
                currentMember.status === memberStatus.DELETION_PENDING &&
                notificationShow,
            onCancel: rejectDeletion,
            onClose: () => setNotificationShow(false),
            onConfirm: confirmDeletion,
            title: removalTexts.userNotificationTitle,
            view: 'warning',
        };

        const getStoreNotificationProps = () => {
            if (!membershipNotification) {
                return {};
            }

            const { description, title, type } = membershipNotification;

            return {
                view: type || 'error',
                ...(title && description
                    ? { description, title }
                    : { description: title }),
            };
        };

        const storeNotification = {
            isOpen: Boolean(membershipNotification),
            onClose: clearStoreNotification,
            ...getStoreNotificationProps(),
        };

        const currentNotificationProps = useMemo(() => {
            if (membershipNotification) {
                return storeNotification;
            }

            if (confirmMembershipNotificationProps.isOpen) {
                return confirmMembershipNotificationProps;
            }

            if (deletionFromMembershipNotificationProps.isOpen) {
                return deletionFromMembershipNotificationProps;
            }

            return {};
        }, [
            confirmMembershipNotificationProps,
            deletionFromMembershipNotificationProps,
            membershipNotification,
            storeNotification,
        ]);

        useEffect(() => {
            if (membershipNotification?.event) {
                sendEvent(membershipNotification.event);
            }
        }, [membershipNotification, sendEvent]);

        // ==================================================================
        // MODAL props
        // ==================================================================
        const invitationModalProps = useMemo(
            () => ({
                actionText: texts.actionInvite,
                description: invitationTexts.description,
                error: cardInfo?.errorMessage,
                findText: invitationTexts.find,
                id: modalIds.GROUP_ACCOUNT_INVITATION,
                isFind: cardInfo && !cardInfo.errorMessage,
                isOpen: modalState.isOpen,
                onCancel: closeModal,
                onChange: handleResetCardInfo,
                onClose: closeModal,
                onConfirm: () => {
                    sendInvitation(normalizeNumber(formValues.ffpCard || ''));
                    sendMyProfileGroupEvent({
                        eventAction: 'Click_Invite_Send',
                        eventLabel: `QTY:${membersCount};FFP_NUMBER:${formValues.ffpCard}`,
                    });
                    closeModal();
                },
                title: invitationTexts.title,
                username: cardInfo && cardInfo.name,
            }),
            [
                cardInfo,
                closeModal,
                formValues.ffpCard,
                handleResetCardInfo,
                invitationTexts.description,
                invitationTexts.find,
                invitationTexts.title,
                membersCount,
                modalState.isOpen,
                sendInvitation,
                sendMyProfileGroupEvent,
                texts.actionInvite,
            ],
        );

        const removeModalProps = useMemo(
            () => ({
                actionText: texts.actionDelete,
                description: removalTexts.description,
                id: modalIds.GROUP_ACCOUNT_DELETION,
                isOpen: modalState.isOpen,
                onCancel: closeModal,
                onClose: closeModal,
                onConfirm: () => {
                    removeUserFromGroup({ memberId: modalState.id });
                    closeModal();
                },
                title: removalTexts.title,
                type: 'error',
            }),
            [
                closeModal,
                modalState.id,
                modalState.isOpen,
                removeUserFromGroup,
                texts.actionDelete,
            ],
        );

        const leaveModalProps = useMemo(
            () => ({
                actionText: leaveTexts.confirm,
                description: leaveTexts.description,
                id: modalIds.GROUP_ACCOUNT_LEAVING,
                isOpen: modalState.isOpen,
                onCancel: closeModal,
                onClose: closeModal,
                onConfirm: () => {
                    removeUserFromGroup({ memberId: currentMember.id });
                    closeModal();
                    sendMyProfileGroupEvent({
                        eventAction: 'Click_QuitConfirm',
                    });
                },
                title: leaveTexts.title,
                type: 'error',
            }),
            [
                closeModal,
                currentMember.id,
                modalState.isOpen,
                removeUserFromGroup,
                sendMyProfileGroupEvent,
            ],
        );

        const modalPropsByType = {
            [memberActionType.INVITATION]: invitationModalProps,
            [memberActionType.REMOVAL]: removeModalProps,
            [memberActionType.LEAVING]: leaveModalProps,
        };

        // ==================================================================
        // TOOLTIP props
        // ==================================================================
        const removalTooltipProps = {
            ...commonTooltipProps,
            cancelLabel: texts.cancel,
            confirmLabel: removalTexts.confirm,
            confirmTheme: 'destructive',
            content: removalTexts.description,
            id: removeTooltipState.id,
            onClick: changeIsOpenRemovalTooltip,
            onClose: onCloseRemovalTooltip,
            opened: removeTooltipState.isOpen,
            title: removalTexts.title,
        };

        const leaveTooltipProps = {
            ...commonTooltipProps,
            cancelLabel: texts.cancel,
            confirmLabel: leaveTexts.confirm,
            confirmTheme: 'destructive',
            content: leaveTexts.description,
            onClick: changeIsOpenLeaveTooltip,
            onClose: changeIsOpenLeaveTooltip,
            onConfirm: () => {
                removeUserFromGroup({ memberId: currentMember.id });
                changeIsOpenLeaveTooltip();
                sendMyProfileGroupEvent({ eventAction: 'Click_QuitConfirm' });
            },
            opened: isOpenLeaveTooltip,
            title: leaveTexts.title,
        };

        const accountInfoTooltipProps = {
            disableHover: true,
            onClick: handleChangeIsAccountInfoTooltipOpened,
            opened: isAccountInfoTooltipOpened,
            popoverProps: { style: { width: 240 } },
        };

        return (
            <>
                {modalState.isOpen && (
                    <GroupAccountModal
                        {...modalPropsByType[modalState.type]}
                        isInvitation={
                            modalState.type === memberActionType.INVITATION
                        }
                        formValues={formValues}
                        getTestId={getTestId}
                        membershipID={membershipID}
                        texts={texts}
                    />
                )}

                <Paper ref={ref} {...getTestId('block')}>
                    <PaperTitle>{textes.familyTitle}</PaperTitle>
                    <PaperContent>
                        <GroupAccount
                            isAvailableActions={
                                !unavailableOperations.includes(
                                    PROFILE_OPERATION_TYPE.MEMBERSHIP,
                                )
                            }
                            accountBalance={balanceLabel}
                            isOpened={opened}
                            notificationProps={currentNotificationProps}
                            rulesDetailLink={texts.rulesLink}
                            onClick={handleChangeOpen}
                            {...{
                                accountInfoTooltipProps,
                                adminName: membershipAdmin.name,
                                closeAccountInfoTooltip,
                                closeLeaveTooltip,
                                getTestId,
                                isHead,
                                isMembershipCreationAvailable,
                                isMembershipOfGroup,
                                isMobile,
                                isOpenAccountInfoTooltip,
                                isOpenLeaveTooltip,
                                isSub,
                                isTablet,
                                leaveTooltipProps,
                                memberDeleting,
                                members: membersWithoutAdmin,
                                membersCount,
                                openAccountInfoTooltip,
                                openInvitationModal,
                                openLeaveModal,
                                openLeaveTooltip,
                                openRemovalModal,
                                removalTooltipProps,
                                removeUserFromGroup,
                                rules,
                                rulesForTablet,
                                texts,
                            }}
                        />
                    </PaperContent>
                </Paper>
            </>
        );
    },
);

GroupAccountContainerBase.propTypes = {
    isOpened: bool,
    membershipID: string,
    reset: func,
};

export const GroupAccountContainer = compose(
    connect(mapStateToProps),
    reduxForm(formConfig),
)(GroupAccountContainerBase);
