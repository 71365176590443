import React from 'react';
import { Dictionary } from 'shared';
import { dictKeys, getTestIdGetter } from 'utils/testIds';

import { DocumentLinear, S7Circle, SignRf } from '@s7/ui-kit/icons';

export const TEXTS = {
    back: <Dictionary text="com.profile.travelers.back" />,
    cancel: <Dictionary text="com.action.cancel" />,
    close: <Dictionary text="com.action.close" />,
    contacts: <Dictionary text="com.profile.contact.title" />,
    delete: <Dictionary text="com.action.delete" />,
    document: <Dictionary text="com.profile.travelers.document.title" />,
    documents: <Dictionary text="com.profile.documents.docs.title" />,
    documentsExpire: <Dictionary text="com.profile.documents.form.expire" />,
    documentsTypes: {
        BIRTH_CERTIFICATE: (
            <Dictionary text="com.profile.documents.docs.name.birth" />
        ),
        INTERNAL_PASSPORT: (
            <Dictionary text="com.profile.documents.docs.name.passport" />
        ),
        INTERNATIONAL_PASSPORT: (
            <Dictionary text="com.profile.documents.docs.name.outpassport" />
        ),
        OTHER: <Dictionary text="com.profile.documents.docs.name.other" />,
        VISA: <Dictionary text="com.profile.documents.visa.name" />,
    },
    edit: <Dictionary text="com.action.edit" />,
    empty: <Dictionary text="com.profile.travelers.empty" />,
    emptyContacts: <Dictionary text="com.profile.travelers.empty.contacts" />,
    emptyDocuments: <Dictionary text="com.profile.travelers.empty.documents" />,
    emptyLoyalty: (
        <Dictionary text="com.profile.travelers.empty.loyalty" html />
    ),
    form: {
        birthCountry: (
            <Dictionary text="com.profile.documents.form.birthcountry" />
        ),
        birthdate: <Dictionary text="com.profile.travelers.edit.birthdate" />,
        card: <Dictionary text="com.profile.travelers.edit.card" />,
        cardHas: <Dictionary text="com.profile.travelers.edit.hascard" />,
        cardType: <Dictionary text="com.profile.travelers.edit.type" />,
        country: <Dictionary text="com.profile.documents.form.country" />,
        countryOfIssue: (
            <Dictionary text="com.profile.documents.form.citizenship" />
        ),
        destinationCountry: (
            <Dictionary text="com.profile.documents.form.destinationcountry" />
        ),
        effective: <Dictionary text="com.profile.documents.form.effective" />,
        email: <Dictionary text="com.title.email" />,
        expire: <Dictionary text="com.profile.documents.form.expire" />,
        firstName: <Dictionary text="com.profile.travelers.edit.name" />,
        gender: <Dictionary text="com.title.gender" />,
        genderFemale: <Dictionary text="com.profile.sex.female" />,
        genderMale: <Dictionary text="com.profile.sex.male" />,
        issued: <Dictionary text="com.profile.documents.form.issued" />,
        lastName: <Dictionary text="com.profile.travelers.edit.surname" />,
        locality: <Dictionary text="com.profile.documents.form.locality" />,
        nocountry: <Dictionary text="com.global.nocountry" />,
        number: <Dictionary text="com.profile.documents.form.seriesnumber" />,
        phone: <Dictionary text="com.title.phone" />,
        placeOfBirth: (
            <Dictionary text="com.profile.documents.form.placeofbirth" />
        ),
        schengen: <Dictionary text="com.profile.documents.form.schengen" />,
        type: <Dictionary text="com.profile.documents.form.type" />,
    },
    gender: {
        FEMALE: <Dictionary text="com.profile.sex.female" />,
        MALE: <Dictionary text="com.profile.sex.male" />,
        title: <Dictionary text="com.title.gender" />,
    },
    loyalty: <Dictionary text="com.profile.travelers.loyalty" />,
    new: <Dictionary text="com.profile.travelers.new" />,
    newDocument: <Dictionary text="com.profile.documents.docs.button" />,
    newShort: <Dictionary text="com.action.add" />,
    personal: <Dictionary text="com.profile.travelers.personal.title" />,
    remove: <Dictionary text="com.profile.travelers.remove" />,
    removeConfirm: <Dictionary text="com.action.delete.confirm" />,
    removeTitle: <Dictionary text="com.profile.travelers.remove.title" />,
    save: <Dictionary text="com.action.save" />,
    showMore: <Dictionary text="com.action.show-more" />,
    title: <Dictionary text="com.profile.travelers.title" />,
};

export const SECTIONS = {
    contacts: 'contacts',
    document: 'document',
    loyalty: 'loyalty',
    personal: 'personal',
};

export const getTestId = getTestIdGetter(dictKeys.PROFILE_TRAVELERS);

export const testIds = {
    birthday: getTestId('birthday'),
    close: getTestId('close'),
    document: getTestId('document'),
    documents: getTestId('documents'),
    edit: getTestId('edit'),
    email: getTestId('email'),
    empty: getTestId('empty'),
    firstName: getTestId('firstName'),
    form: {
        cancel: getTestId('formCancel'),
        contacts: {
            email: getTestId('formContactsEmail'),
            emailError: getTestId('formContactsEmail', { error: true }),
            phone: getTestId('formContactsPhone'),
        },
        document: {
            birthCountry: getTestId('formDocumentBirthCountry'),
            country: getTestId('formDocumentCountry'),
            dateOfIssue: getTestId('formDocumentDateOfIssue'),
            dateOfIssueError: getTestId('formDocumentDateOfIssue', {
                error: true,
            }),
            destination: getTestId('formDocumentDestination'),
            empty: getTestId('formDocumentEmpty'),
            expire: getTestId('formDocumentExpire'),
            expireError: getTestId('formDocumentExpire', { error: true }),
            issued: getTestId('formDocumentIssued'),
            issuedError: getTestId('formDocumentIssued', { error: true }),
            item: getTestId('formDocumentItem'),
            new: getTestId('formDocumentNew'),
            number: getTestId('formDocumentNumber'),
            numberError: getTestId('formDocumentNumber', { error: true }),
            placeOfBirth: getTestId('formDocumentPlaceOfBirth'),
            placeOfBirthError: getTestId('formDocumentPlaceOfBirth', {
                error: true,
            }),
            placeOfIssue: getTestId('formDocumentPlaceOfIssue'),
            placeOfIssueError: getTestId('formDocumentPlaceOfIssue', {
                error: true,
            }),
            remove: getTestId('formDocumentRemove'),
            schengen: getTestId('formDocumentSchengen'),
            series: getTestId('formDocumentSeries'),
            seriesError: getTestId('formDocumentSeries', { error: true }),
            start: getTestId('formDocumentStart'),
            type: getTestId('formDocumentType'),
        },
        loyalty: {
            airline: getTestId('formLoyaltyAirline'),
            hasCard: getTestId('formLoyaltyHasCard'),
            number: getTestId('formLoyaltyNumber'),
            numberError: getTestId('formLoyaltyNumber', { error: true }),
        },
        personal: {
            birthday: getTestId('formPersonalBirthday'),
            birthdayError: getTestId('formPersonalBirthday', { error: true }),
            firstName: getTestId('formPersonalFirstName'),
            firstNameError: getTestId('formPersonalFirstName', { error: true }),
            gender: getTestId('formPersonalGender'),
            genderError: getTestId('formPersonalGender', { error: true }),
            genderFemale: getTestId('formPersonalGenderFemale'),
            genderMale: getTestId('formPersonalGenderMale'),
            lastName: getTestId('formPersonalLastName'),
            lastNameError: getTestId('formPersonalLastName', { error: true }),
        },
        root: getTestId('form'),
        save: getTestId('formSave'),
    },
    initials: getTestId('initials'),
    item: getTestId('item'),
    lastName: getTestId('lastName'),
    list: getTestId('list'),
    loyalty: getTestId('loyalty'),
    mobile: {
        back: getTestId('mobileBack'),
        cancel: getTestId('mobileCancel'),
        close: getTestId('mobileClose'),
        contacts: {
            edit: getTestId('mobileContactsEdit'),
            email: getTestId('mobileContactsEmail'),
            empty: getTestId('mobileContactsEmpty'),
            form: getTestId('mobileContactsForm'),
            item: getTestId('mobileContactsItem'),
            new: getTestId('mobileContactsNew'),
            phone: getTestId('mobileContactsPhone'),
            remove: getTestId('mobileContactsRemove'),
            toggle: getTestId('mobileContactsToggle'),
        },
        document: {
            edit: getTestId('mobileDocumentEdit'),
            empty: getTestId('mobileDocumentEmpty'),
            expire: getTestId('mobileDocumentExpire'),
            form: getTestId('mobileDocumentForm'),
            item: getTestId('mobileDocumentItem'),
            new: getTestId('mobileDocumentNew'),
            number: getTestId('mobileDocumentNumber'),
            remove: getTestId('mobileDocumentRemove'),
            toggle: getTestId('mobileDocumentToggle'),
            type: getTestId('mobileDocumentType'),
        },
        loyalty: {
            airline: getTestId('mobileLoyaltyAirline'),
            edit: getTestId('mobileLoyaltyEdit'),
            empty: getTestId('mobileLoyaltyEmpty'),
            form: getTestId('mobileLoyaltyForm'),
            item: getTestId('mobileLoyaltyItem'),
            new: getTestId('mobileLoyaltyNew'),
            number: getTestId('mobileLoyaltyNumber'),
            remove: getTestId('mobileLoyaltyRemove'),
            toggle: getTestId('mobileLoyaltyToggle'),
        },
        personal: {
            birthday: getTestId('mobilePersonalBirthday'),
            edit: getTestId('mobilePersonalEdit'),
            firstName: getTestId('mobilePersonalFirstName'),
            form: getTestId('mobilePersonalForm'),
            gender: getTestId('mobilePersonalGender'),
            lastName: getTestId('mobilePersonalLastName'),
        },
        remove: getTestId('mobileRemove'),
        removeCancel: getTestId('mobileRemoveCancel'),
        removeConfirm: getTestId('mobileRemoveConfirm'),
        save: getTestId('mobileSave'),
    },
    more: getTestId('more'),
    new: getTestId('new'),
    phone: getTestId('phone'),
    remove: getTestId('remove'),
    removeCancel: getTestId('removeCancel'),
    removeConfirm: getTestId('removeConfirm'),
};

export const documentIconByType = {
    BIRTH_CERTIFICATE: <DocumentLinear block />,
    INTERNAL_PASSPORT: <SignRf block />,
    INTERNATIONAL_PASSPORT: <DocumentLinear block />,
    OTHER: <DocumentLinear block />,
    VISA: <DocumentLinear block />,
};

export const airLineTypes = {
    AA: 'AA',
    AY: 'AY',
    BA: 'BA',
    CX: 'CX',
    IB: 'IB',
    JJ: 'JJ',
    JL: 'JL',
    LA: 'LA',
    MH: 'MH',
    QF: 'QF',
    QR: 'QR',
    RJ: 'RJ',
    S7: 'S7',
    UL: 'UL',
    US: 'US',
};

export const airlineIconByType = {
    [airLineTypes.S7]: <S7Circle height={16} width={16} block />,
};
