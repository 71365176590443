import React from 'react';
import { Dictionary } from 'shared';

import { validateRequired } from './required';

import createFieldValidate from '../createFieldValidate';

export const validatePhone = createFieldValidate({
    phone: {
        errorText: <Dictionary text="com.validation.enterphone" />,
    },
});

export const validatePhoneRequired = [validatePhone, validateRequired];
