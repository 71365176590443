import React from 'react';
import { func } from 'prop-types';

import image from './images/Baggage.png';
import imageMobile from './images/BaggageMobile.png';
import modalImage from './images/modal.png';

import { Card } from '../Card';

export const CardBaggage = ({ onClick }) => {
    return (
        <Card
            conditions={[
                'com.profile.pets.page.card.baggage.condition1',
                'com.profile.pets.page.card.baggage.condition2',
                'com.profile.pets.page.card.baggage.condition3',
                'com.profile.pets.page.card.baggage.condition4',
                'com.profile.pets.page.card.baggage.condition5',
                'com.profile.pets.page.card.baggage.condition6',
            ]}
            cardType="baggage"
            image={image}
            imageMobile={imageMobile}
            modalImage={modalImage}
            title="com.profile.pets.page.card.baggage.title"
            onClick={onClick}
        />
    );
};

CardBaggage.propTypes = {
    onClick: func.isRequired,
};
