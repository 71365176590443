import React, { PureComponent } from 'react';
import { bool, func } from 'prop-types';
import { Dictionary } from 'shared';

import { RoundButton } from '@s7/ui-kit';
import { Trash } from '@s7/ui-kit/icons';

import cx from './DocumentRemove.sss';

export default class DocumentRemove extends PureComponent {
    static propTypes = {
        expired: bool,
        getTestId: func,
        onClick: func,
    };

    static defaultProps = {
        getTestId: () => {},
    };

    render() {
        const { expired, onClick, getTestId } = this.props;

        return (
            <RoundButton
                text={
                    <>
                        <Dictionary text="com.action.delete" />{' '}
                        <span className={cx('hide', { hide_mobile: expired })}>
                            <Dictionary text="com.profile.documents.doc" />
                        </span>
                    </>
                }
                direction="left"
                size="xs"
                view="destructive"
                onClick={onClick}
                {...getTestId('removeButton')}
            >
                <Trash />
            </RoundButton>
        );
    }
}
