import React from 'react';
import { useDispatch } from 'react-redux';
import Cards from 'components/Cards';
import { loadingError } from 'language/errors';
import { arrayOf, bool, shape, string } from 'prop-types';
import { Dictionary, Paper, PaperContent, PaperTitle } from 'shared';
import {
    changeConfirmationRemove,
    edit as editCard,
    remove,
} from 'store/modules/cards';

import { StatusMessage } from '@s7/ui-kit';

const CardsContainer = props => {
    const { isError, cards } = props;

    const dispatch = useDispatch();

    const handleChangeConfirmationRemove = React.useCallback(
        ({ id, needConfirmForRemove }) => {
            dispatch(changeConfirmationRemove({ id, needConfirmForRemove }));
        },
        [dispatch],
    );

    const handleRemove = React.useCallback(
        ({ id }) => {
            dispatch(remove({ id }));
        },
        [dispatch],
    );

    const handleEdit = React.useCallback(
        ({ id, edit }) => {
            dispatch(editCard({ edit, id }));
        },
        [dispatch],
    );

    return (
        <Paper>
            <PaperTitle>
                <Dictionary text="com.profile.cards.title" />
            </PaperTitle>

            <PaperContent>
                {isError ? (
                    <StatusMessage title={loadingError.title} view="error">
                        {loadingError.description}
                    </StatusMessage>
                ) : (
                    <Cards
                        cards={cards}
                        onChangeConfirmationRemove={
                            handleChangeConfirmationRemove
                        }
                        onEdit={handleEdit}
                        onRemove={handleRemove}
                    />
                )}
            </PaperContent>
        </Paper>
    );
};

const cardsShape = shape({
    cardholder: string.isRequired,
    date: string,
    id: string.isRequired,
    main: bool,
    number: string.isRequired,
    type: string.isRequired,
});

CardsContainer.propTypes = {
    cards: arrayOf(cardsShape).isRequired,
    isError: bool,
};

export default CardsContainer;
