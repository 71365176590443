import React, { useCallback, useMemo } from 'react';
import { arrayOf, func, shape, string } from 'prop-types';

import { Link, Text } from '@s7/ui-kit';

import { SECTIONS, testIds, TEXTS } from '../../../constants';
import Wrapper from '../../../Wrapper';
import Item from '../Item';

const Contacts = ({ contacts, onEdit, onRemove }) => {
    const handleEdit = useCallback(() => {
        onEdit({ section: SECTIONS.contacts });
    }, [onEdit]);

    const addButton = useMemo(() => {
        if (contacts.length >= 2) {
            return null;
        }

        return (
            <Link
                as="button"
                type="button"
                view="b2c"
                onClick={handleEdit}
                {...testIds.mobile.contacts.new}
            >
                {TEXTS.newShort}
            </Link>
        );
    }, [contacts.length, handleEdit]);

    return (
        <Wrapper aside={addButton} title={TEXTS.contacts}>
            {contacts.length ? (
                <Item
                    editProps={testIds.mobile.contacts.edit}
                    removeProps={testIds.mobile.contacts.remove}
                    toggleProps={testIds.mobile.contacts.toggle}
                    onEdit={handleEdit}
                    onRemove={onRemove}
                    {...testIds.mobile.contacts.item}
                >
                    {contacts.map(({ value, type }) => (
                        <Text
                            key={type}
                            size="l"
                            {...testIds.mobile.contacts[type.toLowerCase()]}
                        >
                            {value}
                        </Text>
                    ))}
                </Item>
            ) : (
                <Item>
                    <Text color="N100" {...testIds.mobile.contacts.empty}>
                        {TEXTS.emptyContacts}
                    </Text>
                </Item>
            )}
        </Wrapper>
    );
};

Contacts.propTypes = {
    contacts: arrayOf(
        shape({
            type: string,
            value: string,
        }),
    ),
    onEdit: func,
    onRemove: func,
};

export default React.memo(Contacts);
