import React, { PureComponent } from 'react';
import { arrayOf, func, number, shape, string } from 'prop-types';

function checkBreakpoint(value, target) {
    if (!value || !target.value) {
        return false;
    }

    if (!target.allowedValues.includes(value)) {
        // console.warn([
        //   `invalid breakpoint '${value}' received,`,
        //   `expected one of [${target.allowedValues.join(', ')}].`,
        // ].join(' '))

        return false;
    }

    return true;
}

function withCheck(cb) {
    return function withCheckInner(value) {
        if (!checkBreakpoint(value, this)) {
            return null;
        }

        return cb(value, this);
    };
}

const up = (value, target) => target[target.value].score > target[value].score;
const down = (value, target) =>
    target[target.value].score < target[value].score;
const only = (value, target) => target.value === value;

export const detectBreakpointHOC = () => {
    /* eslint-disable sort-keys */
    const breakpoint = {
        xs: { min: 0, max: 767, score: 1 },
        sm: { min: 768, max: 959, score: 2 },
        md: { min: 960, max: 1279, score: 3 },
        lg: { min: 1280, score: 4 },
        allowedValues: ['xs', 'sm', 'md', 'lg'],
        up: withCheck(up),
        down: withCheck(down),
        only: withCheck(only),
        between(from, to) {
            if (!checkBreakpoint(from, this) || !checkBreakpoint(to, this)) {
                return false;
            }

            if (this[from].score >= this[to].score) {
                // console.warn(`first breakpoint must be less than second, got [${from} - ${to}]`)

                return false;
            }

            return this.up(from) && this.down(to);
        },
        value: null,
    };
    /* eslint-enable sort-keys */

    const subscriptions = [];
    const register = fn => subscriptions.push(fn);
    const unregister = fn =>
        subscriptions.forEach((item, index) => {
            if (item === fn) {
                subscriptions.splice(index, 1);
            }
        });
    const notifyAll = () => subscriptions.forEach(listener => listener());

    const getBreakpoint = width => {
        const { xs, sm, md } = breakpoint;

        if (width <= xs.max) {
            return 'xs';
        }
        if (width >= sm.min && width <= sm.max) {
            return 'sm';
        }
        if (width >= md.min && width <= md.max) {
            return 'md';
        }

        return 'lg';
    };

    const onResize = () => {
        if (
            process.browser &&
            getBreakpoint(window.innerWidth) !== breakpoint.value
        ) {
            breakpoint.value = getBreakpoint(window.innerWidth);
            notifyAll();
        }
    };

    return Component =>
        class extends PureComponent {
            constructor(props) {
                super(props);

                if (process.browser && !breakpoint.value) {
                    breakpoint.value = getBreakpoint(window.innerWidth);
                }
            }

            componentDidMount() {
                register(this.handleUpdate);

                if (process.browser && subscriptions.length === 1) {
                    window.addEventListener('resize', onResize);
                }
            }

            componentWillUnmount() {
                unregister(this.handleUpdate);

                if (process.browser && subscriptions.length === 0) {
                    window.removeEventListener('resize', onResize);
                }
            }

            handleUpdate = () => {
                this.forceUpdate();
            };

            render() {
                return (
                    <Component
                        {...this.props}
                        {...{ breakpoint: { ...breakpoint } }}
                    />
                );
            }
        };
};

const commonType = shape({
    max: number,
    min: number,
    score: number,
});

export const breakpointPropType = shape({
    allowedValues: arrayOf(string),
    between: func,
    down: func,
    lg: commonType,
    md: commonType,
    only: func,
    sm: commonType,
    up: func,
    value: string,
    xl: commonType,
    xs: commonType,
});

export default detectBreakpointHOC();
