import { validateMinLength6 } from './length';
import {
    validateLowercaseRequired,
    validateNumericCharacterRequired,
    validateSpecialCharacterRequired,
    validateUppercaseRequired,
} from './passwordStrength';
import { validateRequired } from './required';

export const validatePasswordCurrent = [validateRequired];

export const validatePasswordNew = [
    validateSpecialCharacterRequired,
    validateNumericCharacterRequired,
    validateLowercaseRequired,
    validateUppercaseRequired,
    validateMinLength6,
    validateRequired,
];
