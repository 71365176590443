export const getOnCardClickEvent = cardType => {
    const baseEvent = {
        event_name: 'click_cta',
        item_id: 'pets',
        item_name: '',
        step: 'companions',
    };

    switch (cardType) {
        case 'baggage':
            baseEvent.item_name = 'in_baggage';
            break;
        case 'underSeat':
            baseEvent.item_name = 'under_seat';
            break;
        case 'separateSeat':
            baseEvent.item_name = 'on_seat';
            break;
        case 'cargo':
            baseEvent.item_name = 'cargo';
            break;
        default:
            break;
    }

    return baseEvent;
};
