import React, { PureComponent } from 'react';
import { bool, func, number, oneOfType, string } from 'prop-types';
import { propOr } from 'ramda';

import cx from './Timer.sss';

export default class Timer extends PureComponent {
    static propTypes = {
        display: bool,
        isHighlight: bool,
        onFinish: func,
        remainingSeconds: oneOfType([string, number]),
    };

    static defaultProps = {
        display: true,
        isHighlight: false,
        onFinish: () => {},
        remainingSeconds: 60,
    };

    constructor(props) {
        super(props);

        this.state = {
            remainingSeconds: parseInt(
                propOr(0, 'remainingSeconds', props),
                10,
            ),
        };
    }

    componentDidMount() {
        this.timer = setInterval(() => {
            this.decrementRemainingTime();
        }, 1000);
    }

    componentWillUnmount() {
        clearInterval(this.timer);
    }

    handleZeroPad = time => (time < 10 ? `0${time}` : time);

    decrementRemainingTime = () => {
        const { remainingSeconds } = this.state;

        if (remainingSeconds > 0) {
            this.setState({ remainingSeconds: remainingSeconds - 1 });
        } else {
            clearInterval(this.timer);
        }

        if (remainingSeconds <= 1) {
            this.props.onFinish();
        }
    };

    timer;

    render() {
        const { display, isHighlight } = this.props;
        const { remainingSeconds } = this.state;
        const minutes = Math.floor(remainingSeconds / 60);
        const seconds = remainingSeconds % 60;

        return (
            display && (
                <span className={cx('root', { 'is-highlight': isHighlight })}>
                    {this.handleZeroPad(minutes)}:{this.handleZeroPad(seconds)}
                </span>
            )
        );
    }
}
