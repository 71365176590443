import {
    arrayOf,
    bool,
    func,
    object,
    objectOf,
    shape,
    string,
} from 'prop-types';

export const testIdsShape = shape({
    dateOfIssue: objectOf(string),
    dateOfIssueError: objectOf(string),
    expireDate: objectOf(string),
    expireDateError: objectOf(string),
});

export const countriesProp = arrayOf(
    shape({
        name: string,
        title: string,
        visa: bool,
        visible: bool,
    }),
);

export const typesProp = arrayOf(
    shape({
        name: string,
        title: string,
    }),
);

export const propTypes = {
    birthday: string,
    countries: countriesProp,
    countryOfIssue: string,
    dateOfIssue: string,
    disabled: bool,
    disabledDayFields: object,
    expirationDate: string,
    getTestId: func,
    isDocuments: bool,
    isVisa: bool,
    prefix: string,
    startDate: string,
    traveler: bool,
    type: string,
    types: typesProp,
};

const dictionaryShape = shape({
    t: func,
});

export const fieldsPropTypes = {
    ...propTypes,
    dictionary: dictionaryShape,
    filterCountry: func,
    isBirth: bool,
    isMobile: bool,
    isOther: bool,
    isOver14Year: bool,
    isRussiaBirth: bool,
    isRussian: bool,
    isRussianLocale: bool,
    isRussianPassport: bool,
};
