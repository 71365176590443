import { MutableRefObject, useEffect, useState } from 'react';

export const useIsVisible = <E extends Element, R extends MutableRefObject<E>>(
    ref: R,
): boolean => {
    const [isIntersecting, setIntersecting] = useState(false);

    useEffect(() => {
        const observer = new IntersectionObserver(([entry]) =>
            setIntersecting(entry.isIntersecting),
        );

        observer.observe(ref.current);

        return () => {
            observer.disconnect();
        };
    }, [ref]);

    return isIntersecting;
};
