import React from 'react';
import { useApi } from 'hooks';
import { SkeletonLoader } from 'ui-kit/atoms/SkeletonLoader';

import { Pets } from './Pets';

const PetItemSkeletonData = [
    {
        height: 40,
        type: 'circle',
        width: 40,
    },
    {
        items: [{ height: 40 }],
        type: 'items',
        width: 'calc(100% - 60px)',
    },
];
const desktop = {
    items: [
        {
            items: PetItemSkeletonData,
            type: 'flex',
        },
        {
            items: PetItemSkeletonData,
            type: 'flex',
        },
    ],
};

const PetsContainer = () => {
    const {
        modules: {
            pets: { loading },
        },
    } = useApi(['pets']);

    return loading ? (
        <SkeletonLoader desktop={desktop} mobile={desktop} title={200} />
    ) : (
        <Pets />
    );
};

export { PetsContainer as Pets };
