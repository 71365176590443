import { pathOr } from 'ramda';

export const getId = state => fileId =>
    state.docscan[fileId] && state.docscan[fileId].id;
export const getData = state => fileId =>
    state.docscan[fileId] && state.docscan[fileId].data;
export const getToken = state => fileId =>
    state.docscan[fileId] && state.docscan[fileId].token;
export const getStatus = state => fileId =>
    state.docscan[fileId] && state.docscan[fileId].status;
export const getErrorMessage = state => fileId =>
    pathOr(null, ['docscan', fileId, 'errorMessage'], state);
