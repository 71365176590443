import React, { useEffect, useRef } from 'react';
import { bool, func, node, string } from 'prop-types';
import disableScrollGetter from 'utils/disableScroll';

import { Button, Title } from '@s7/ui-kit';
import { ChevronNormalLeft, CloseLinear } from '@s7/ui-kit/icons';

import cx from './Modal.sss';

import { testIds, TEXTS } from '../../../constants';

const disableScroll = disableScrollGetter({ maxWidth: 1280 });

const Modal = ({
    children,
    onBack,
    onCancel,
    onSave,
    pristine,
    submitting,
    title,
    white,
    ...rest
}) => {
    const modalRef = useRef(null);

    useEffect(() => {
        disableScroll(true);

        return () => disableScroll(false);
    }, []);

    return (
        <div {...rest} ref={modalRef} className={cx('root', { white })}>
            <div className={cx('heading')}>
                <button
                    className={cx('back')}
                    type="button"
                    onClick={onBack}
                    {...testIds.mobile.back}
                >
                    {onBack && (
                        <ChevronNormalLeft className={cx('back_icon')} />
                    )}
                    <Title as="span" level={5}>
                        {title}
                    </Title>
                </button>
                {onCancel && (
                    <button
                        className={cx('close')}
                        type="button"
                        onClick={onCancel}
                        {...testIds.mobile.close}
                    >
                        <CloseLinear />
                    </button>
                )}
            </div>
            <div className={cx('wrapper')}>
                <div className={cx('content')}>{children}</div>
                {onSave && (
                    <div className={cx('bottom')}>
                        <div className={cx('action')}>
                            <Button
                                disabled={pristine}
                                loading={submitting}
                                size="large"
                                theme="b2c"
                                type="submit"
                                block
                                onClick={onSave}
                                {...testIds.mobile.save}
                            >
                                {TEXTS.save}
                            </Button>
                        </div>
                        <div className={cx('action')}>
                            <Button
                                size="large"
                                theme="secondary"
                                block
                                onClick={onCancel}
                                {...testIds.mobile.cancel}
                            >
                                {TEXTS.cancel}
                            </Button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

Modal.propTypes = {
    children: node,
    onBack: func,
    onCancel: func,
    onSave: func,
    pristine: bool,
    submitting: bool,
    title: string,
    white: bool,
};

export default React.memo(Modal);
