import React from 'react';
import { useWindowSize } from 'hooks/useWindowSize';
import { bool, node, shape } from 'prop-types';
import { fieldInputPropTypes, fieldMetaPropTypes } from 'types';

import { Textfield as TextFieldBase } from '@s7/ui-kit';

const TextField = ({
    disabled,
    input,
    meta,
    message,
    invalid: invalidFromProps,
    tooltip,
    ...rest
}) => {
    const invalid =
        invalidFromProps || (meta.error && (meta.touched || meta.submitFailed));
    const { isMobile } = useWindowSize();

    return (
        <TextFieldBase
            {...(isMobile ? { autoComplete: 'off' } : {})}
            {...rest}
            {...input}
            {...{
                invalid,
                tooltip: invalid ? meta.error || message || tooltip : tooltip,
            }}
            disabled={meta.submitting || disabled}
        />
    );
};

TextField.propTypes = {
    disabled: bool,
    input: shape(fieldInputPropTypes),
    invalid: bool,
    message: node,
    meta: shape(fieldMetaPropTypes),
    tooltip: node,
};

TextField.defaultProps = {
    input: {},
    meta: {},
};

const TextFieldMemo = React.memo(TextField);

export { TextFieldMemo as TextField };
