import { validateMinLength3, validateMinLength6 } from './length';
import { validateRequired } from './required';

import createFieldValidate from '../createFieldValidate';

const numeric = createFieldValidate({
    numeric: true,
});

const birthCertificateSeries = createFieldValidate({
    birthCertificateSeries: true,
});

const nonCyrillic = createFieldValidate({
    nonCyrillic: true,
});

export const validateDocumentNumber = [
    validateRequired,
    validateMinLength6,
    numeric,
];

export const validateDocumentSeries = [
    validateRequired,
    validateMinLength3,
    birthCertificateSeries,
];

export const validateDocumentSeriesLatin = [
    ...validateDocumentSeries,
    nonCyrillic,
];
