import React from 'react';
import { any, arrayOf, func, node, shape, string } from 'prop-types';
import { fieldMetaPropTypes } from 'redux-form';

import cx from './RadioSelect.sss';

const RadioSelect = ({ options = [], input, meta }) => {
    const { onChange, value } = input;
    const invalid = meta.error && (meta.touched || meta.submitFailed);

    const handleOptionClick = val => () => {
        onChange(val);
    };

    return (
        <div className={cx('root', { invalid })}>
            {options.length > 0 &&
                options.map(option => (
                    <button
                        key={option.value}
                        className={cx('option', {
                            disabled: option.disabled,
                            option_active: value === option.value,
                        })}
                        disabled={option.disabled}
                        type="button"
                        onClick={handleOptionClick(option.value)}
                    >
                        {option.label}
                    </button>
                ))}
        </div>
    );
};

const optionsShape = shape({
    label: node,
    value: string,
});

const inputShape = shape({
    onChange: func,
    value: any,
});

RadioSelect.propTypes = {
    input: inputShape,
    meta: shape(fieldMetaPropTypes),
    onChange: func,
    options: arrayOf(optionsShape),
    value: string,
};

const RadioSelectMemo = React.memo(RadioSelect);

export { RadioSelectMemo as RadioSelect };
