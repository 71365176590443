import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { arrayOf, bool, func, node, object, shape, string } from 'prop-types';
import { pick } from 'ramda';
import { Dictionary } from 'shared';
import { getDictionary, getLocale } from 'store/modules/languages';
import { fieldInputPropTypes, fieldMetaPropTypes } from 'types';
import { normalizeNumber } from 'utils/format';

import { PhoneField as Phone } from '@s7/ui-kit';
import { capitalize } from '@s7/ui-kit/utils/string';

const incorrectValueMessage = <Dictionary text="com.validation.enterphone" />;

const PhoneField = ({
    countryKeys,
    defaultCountry = 'ru',
    disabled,
    disabledCountries,
    input = {},
    invalid,
    isCorrectNumber,
    isRestoreCode,
    label,
    onChange,
    onCountrySort,
    subText,
    success,
    theme,
    message,
    meta = {},
    inputProps,
    ...rest
}) => {
    const locale = useSelector(getLocale);
    const dictionary = useSelector(getDictionary);

    const [isCorrectValue, setIsCorrectValue] = useState(true);
    const [value, setValue] = useState(input.value);
    const placeholder = useMemo(
        () => capitalize(dictionary.t('com.units.country.one')),
        [dictionary],
    );

    useEffect(() => {
        setValue(input.value);
    }, [input.value]);

    const handleChange = useCallback(
        (val, isCorrect, params) => {
            const onlyCode = val === params?.code;

            if (isCorrect) {
                input.onChange(normalizeNumber(val, isCorrect));
            } else {
                input.onChange(val);
            }

            if (onChange) {
                onChange();
            }

            setIsCorrectValue(isCorrect || onlyCode);
        },
        [input, onChange],
    );

    const isInvalid =
        !isCorrectValue ||
        invalid ||
        (meta.error && (meta.touched || meta.submitFailed));

    return (
        <Phone
            noOptionsMessage={<Dictionary text="com.global.nocountry" />}
            searchable
            {...input}
            {...{
                countryKeys,
                defaultCountry,
                disabled,
                disabledCountries,
                invalid: isInvalid,
                isCorrectNumber,
                isRestoreCode,
                label,
                locale,
                onBlur: input.onBlur,
                onChange: handleChange,
                onCountrySort,
                onFocus: input.onFocus,
                placeholder,
                subText,
                success,
                theme,
                tooltip: isInvalid
                    ? meta.error || message || incorrectValueMessage
                    : undefined,
                value,
                ...(pick(['data-test'], rest) || {}),
            }}
            inputProps={{
                ...inputProps,
                inputMode: 'numeric',
            }}
        />
    );
};

PhoneField.propTypes = {
    countryKeys: arrayOf(string),
    defaultCountry: string,
    disabled: bool,
    disabledCountries: arrayOf(string),
    input: shape(fieldInputPropTypes),
    inputProps: object,
    invalid: bool,
    isCorrectNumber: bool,
    isRestoreCode: bool,
    label: node,
    locale: string,
    message: node,
    meta: shape(fieldMetaPropTypes),
    onChange: func,
    onCountrySort: func,
    subText: node,
    success: bool,
    theme: string,
};

const PhoneFieldMemo = React.memo(PhoneField);

export { PhoneFieldMemo as PhoneField };
