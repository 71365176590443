import React from 'react';
import { useWindowSize } from 'hooks';
import { ContentLoader } from 'shared';

import View from '../Traveler/View';

const TravelerLoader = () => {
    const { isDesktop } = useWindowSize();

    const avatarSize = isDesktop ? 60 : 40;

    return (
        <View
            firstName={
                <ContentLoader height={isDesktop ? 20 : 16} width={170} />
            }
            initials={
                <ContentLoader
                    childrenProps={{
                        cx: avatarSize / 2,
                        cy: avatarSize / 2,
                        r: avatarSize / 2,
                    }}
                    as="circle"
                    height={avatarSize}
                    width={avatarSize}
                />
            }
            birthday={<ContentLoader height={12} width={80} />}
            contacts={<ContentLoader height={12} width={160} />}
            documents={<ContentLoader height={16} width={120} />}
            loyalty={<ContentLoader height={16} width={120} />}
        />
    );
};

export default TravelerLoader;
