import { assocPath, indexBy, path, pathOr, prop } from 'ramda';
import { createSelector } from 'reselect';
import { getSessionValue } from 'utils';

import { documentTypeByAge, getFieldsByType } from './utils';

import { documentType } from '../../../data';
import { normalizePhone, normalizeSlugifyName } from '../../../utils/format';
import { prepareDocumentDates } from '../documents/helpers';
import {
    getBirthdate,
    getEmail,
    getGender,
    getName,
    getNameByLocale,
    getPrimaryDocument,
    getProfileData,
    getProfileDocuments,
    getSurname,
    getSurnameByLocale,
    getTelephone,
} from '../profile';
import { formatDate } from '../profile/utils';

export const getRegisteredPhone = state => state.registration.registeredPhone;
export const getConfirmedSecret = state => state.registration.confirmedSecret;
export const getConfirmed = state =>
    pathOr(false, ['registration', 'confirmed'], state);
export const getConfirmError = state =>
    pathOr(null, ['registration', 'confirmError'], state);
export const getIsConfirmEmailFailed = state =>
    pathOr(false, ['registration', 'isConfirmEmailFailed'], state);
export const getIsConfirmEmailSended = state =>
    pathOr(false, ['registration', 'isConfirmEmailSended'], state);
export const getIsInviteSuccess = state =>
    pathOr(false, ['registration', 'isInviteSuccess'], state);

export const registerData = state => ({
    regError: pathOr(null, ['registration', 'regError'], state),
    sended: pathOr(false, ['registration', 'sended'], state),
    sending: pathOr(false, ['registration', 'sending'], state),
    smsCount: pathOr(0, ['registration', 'smsCount'], state),
});

export const getRegistrationCache = state =>
    pathOr({}, ['registration', 'cache'], state);
export const getForbiddenPhones = state =>
    pathOr([], ['registration', 'forbiddenPhones'], state);
export const getDraftProfile = path(['registration', 'draftProfile']);

const getInitialValuesFromProfileData = createSelector(
    [
        getBirthdate,
        getGender,
        state => getName(state, 'en') || '',
        state => getSurname(state, 'en') || '',
        getNameByLocale,
        getSurnameByLocale,
        getEmail,
        state => getTelephone(state) || getRegisteredPhone(state) || '',
        getPrimaryDocument,
    ],
    (
        birthdate,
        gender,
        latinName,
        latinSurname,
        name,
        surname,
        email,
        phone,
        doc,
    ) => {
        let initialValues = {
            birthdate: birthdate || '',
            documents: [
                {
                    country: 'RU',
                    docNumber: '',
                    docType: documentTypeByAge(birthdate),
                    expireDate: '',
                    issueDate: '',
                },
            ],
            email: email || '',
            gender,
            homeAirport: 'MOW',
            latinName: latinName || normalizeSlugifyName(name || ''),
            latinSurname: latinSurname || normalizeSlugifyName(surname || ''),
            name: name || '',
            phone: normalizePhone(phone),
            surname: surname || '',
        };

        if (doc) {
            initialValues = {
                ...initialValues,
                ...getFieldsByType(doc),
                documents: [
                    {
                        country: 'RU',
                        docNumber: '',
                        expireDate: '',
                        issueDate: '',
                        ...getFieldsByType(doc),
                    },
                ],
            };
        } else {
            initialValues.docType = documentTypeByAge(birthdate);
        }

        return initialValues;
    },
);

// Invitation
export const getInvitation = state =>
    pathOr('', ['registration', 'invitation'], state);

export const getRegistrationInitialValues = createSelector(
    [getInvitation, (state, email) => email],
    (invitation, email) => {
        const initialValue = {
            email,
            phone: '+7',
        };

        if (invitation) {
            const name = indexBy(prop('lang'), invitation?.names || []);

            if (name.en) {
                initialValue.latinName = name.en.firstName;
                initialValue.latinSurname = name.en.lastName;
            }

            initialValue.phone = invitation?.contactDetails?.phone?.value;
        }

        return initialValue;
    },
);

export const getRegistrationFullInitialValues = createSelector(
    [getProfileData, getDraftProfile, state => state],
    (profile, draftProfile, state) => {
        const finalState = assocPath(
            ['profile', 'data'],
            draftProfile || profile,
            state,
        );

        // Если в приглашении пользователю были заполнены необязательные поля
        // например документы и дата рождения, то мы не сохраняем их при создании
        // профиля, а кладем в сессию и используем как автозаполнение на втором шаге
        const invitation = getSessionValue('b2bConfirmRegistration');
        const initialValuesFromProfileData =
            getInitialValuesFromProfileData(finalState);

        if (invitation) {
            const { birthday, documents = [] } = invitation;
            // Если документы отсутствуют, создаем массив с пустым документом,
            // чтобы отобразить поля для документов в форме
            const currentDocuments =
                documents.length === 0
                    ? [
                          {
                              type: documentType.INTERNAL_PASSPORT,
                          },
                      ]
                    : documents;

            const documentsData = currentDocuments.map(document => {
                return {
                    country: document?.issueCountry,
                    docNumber: document?.number,
                    docType: document?.type,
                    expireDate:
                        document?.expiredAt && formatDate(document?.expiredAt),
                    issueDate:
                        document?.issuedAt && formatDate(document?.issuedAt),
                };
            });

            return {
                ...initialValuesFromProfileData,
                birthdate: birthday && formatDate(birthday),
                documents: documentsData,
            };
        }

        return {
            ...initialValuesFromProfileData,
        };
    },
);

export const getIsReferralModalOpen = pathOr(null, [
    'registration',
    'isReferralModalOpen',
]);
export const getRegistrationPrepareDocuments = createSelector(
    getProfileDocuments,
    documents => (documents ? documents.map(prepareDocumentDates) : []),
);
