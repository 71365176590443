import React from 'react';
import { UnitNounByLength } from 'components/UnitNounByLength';
import { Dictionary } from 'shared';

const textes = {
    birthCertificateSeries: (
        <Dictionary text="com.validation.birth-certificate.series" />
    ),
    dateCorrect: <Dictionary text="com.validation.date.incorrect" />,
    dateFuture: <Dictionary text="com.validation.date.incorrect" />,
    dateMinBirth: <Dictionary text="com.validation.date.incorrect" />,
    datePast: <Dictionary text="com.validation.date.incorrect" />,
    email: <Dictionary text="com.validation.email.invalid" />,
    isChecked: <Dictionary text="com.validation.required-long" />,
    latinName: <Dictionary text="com.validation.latin-only" />,
    maxLength: params => (
        <UnitNounByLength
            dictionaryKey="com.validation.max-length"
            {...params}
        />
    ),
    minLength: params => (
        <UnitNounByLength
            dictionaryKey="com.validation.min-length"
            {...params}
        />
    ),
    nonCyrillic: <Dictionary text="com.validation.noncrillic" />,
    numeric: <Dictionary text="com.validation.numbers-only" />,
    priority: <Dictionary text="com.validation.priority-card.number" />,
    required: <Dictionary text="com.validation.required-long" />,
    s7priority: (
        <Dictionary text="com.validation.s7priority-card.number.length" />
    ),
};

export default textes;
