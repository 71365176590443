import React, { useCallback, useRef } from 'react';
import { useWindowSize } from 'hooks';
import { arrayOf, func, string } from 'prop-types';
import { Dictionary, Paper, PaperContent, PaperTitle } from 'shared';

import { Link, RoundButton, StatusMessage, Text } from '@s7/ui-kit';
import { PlusLinear } from '@s7/ui-kit/icons';

import { QR_INPUT_ACCEPT } from './constants';
import QRCodeItem from './QRCodeItem';
import cx from './QRCodes.sss';

const QRCodes = ({ error, ids = [], onSave }) => {
    const { isMobile } = useWindowSize();
    const inputRef = useRef(null);
    const isEmpty = ids.length === 0;

    const handleAddClick = useCallback(() => {
        inputRef.current.click();
    }, []);

    const handleChange = event => {
        const file = event.target.files?.[0];

        if (file) {
            onSave(file);
        }
    };

    return (
        <Paper>
            <PaperTitle
                aside={
                    !error &&
                    isMobile &&
                    isEmpty && (
                        <Link
                            as="button"
                            type="button"
                            view="b2c"
                            onClick={handleAddClick}
                        >
                            <Dictionary text="com.profile.qrcodes.add" />
                        </Link>
                    )
                }
            >
                <Dictionary text="com.profile.qrcodes.qr-codes" />
            </PaperTitle>
            <PaperContent>
                {error ? (
                    <StatusMessage view="error">{error}</StatusMessage>
                ) : (
                    <>
                        {ids.map(id => (
                            <QRCodeItem key={id} id={id} />
                        ))}
                        {isEmpty && (
                            <Text>
                                <Dictionary text="com.profile.qrcodes.empty" />
                            </Text>
                        )}
                        {!isMobile && isEmpty && (
                            <div className={cx('add')}>
                                <RoundButton
                                    text={
                                        <Dictionary text="com.profile.qrcodes.add" />
                                    }
                                    direction="right"
                                    size="xs"
                                    view="b2c"
                                    onClick={handleAddClick}
                                >
                                    <PlusLinear />
                                </RoundButton>
                            </div>
                        )}
                        <input
                            ref={inputRef}
                            accept={QR_INPUT_ACCEPT}
                            className={cx('input')}
                            type="file"
                            onClick={event => {
                                event.target.value = null;
                            }}
                            onChange={handleChange}
                        />
                    </>
                )}
            </PaperContent>
        </Paper>
    );
};

QRCodes.propTypes = {
    error: string,
    ids: arrayOf(string),
    onSave: func,
};

export default QRCodes;
