import React, { useState } from 'react';
import { PETS_TYPE, PETS_WEIGHT } from 'consts/pets';
import { domToReact } from 'html-react-parser';
import { oneOf } from 'prop-types';
import { Dictionary, Paper, PaperContent, PaperTitle } from 'shared';

import { Link, Modal, Text, Title } from '@s7/ui-kit';
import { useMediaQuery } from '@s7/ui-kit/hooks';
import useDisableScroll from '@s7/ui-kit/hooks/useDisableScroll';
import { Slider } from '@s7/ui-kit/Slider';

import { CardBaggage } from './CardBaggage';
import { CardCargo } from './CardCargo';
import { CardSeparateSeat } from './CardSeparateSeat';
import { CardUnderSeat } from './CardUnderSeat';
import { getOnCardClickEvent } from './helpers';
import cx from './Transportation.sss';

import { useAnalyticsContext } from '../../../../contexts';
import { testIds } from '../../constants';

const isWeightUpTo10 = weight => weight === PETS_WEIGHT.UP_TO_10;
const isWeightUpTo23 = weight => weight === PETS_WEIGHT.UP_TO_23;
const isWeightUpTo50 = weight => weight === PETS_WEIGHT.UP_TO_50;
const isAvailableType = type => type !== PETS_TYPE.OTHER;

// Возвращаем массив, для корректного отображения слайдов
const getCards = ({ weight, type }) => {
    switch (true) {
        case isWeightUpTo10(weight) && isAvailableType(type):
            return [CardSeparateSeat, CardUnderSeat, CardBaggage, CardCargo];

        case isWeightUpTo23(weight) && isAvailableType(type):
            return [CardSeparateSeat, CardBaggage, CardCargo];

        case isWeightUpTo50(weight) && isAvailableType(type):
            return [CardBaggage, CardCargo];

        default:
            return [CardCargo];
    }
};

const parseOptions = {
    replace: domNode => {
        if (domNode.name && domNode.attribs && domNode.name === 'a') {
            return (
                <Link href={domNode.attribs.href} view="b2c">
                    {domToReact(domNode.children, parseOptions)}
                </Link>
            );
        }

        return domNode;
    },
};

export const Transportation = ({ weight, type }) => {
    const breakpoints = useMediaQuery('xs');
    const isMobile = breakpoints.xs;

    const { sendEvent } = useAnalyticsContext();

    const [currentModal, setCurrentModal] = useState(false);

    const handleShowModal = data => {
        sendEvent(getOnCardClickEvent(data.cardType));
        setCurrentModal(data);
    };

    const handleCloseModal = () => {
        setCurrentModal(null);
    };

    useDisableScroll({
        isOpen: currentModal,
        target: document.body,
    });

    return (
        <Paper {...testIds.transportation.block}>
            <PaperTitle>
                <Dictionary text="com.profile.pets.page.transportation.title" />
            </PaperTitle>
            <PaperContent>
                <div className={cx('slider')}>
                    <Slider
                        slideProps={{
                            classes: {
                                root: cx('slide'),
                            },
                        }}
                        arrows={false}
                        dots={false}
                        infinite={false}
                        responsive={null}
                        showHalfNextSlide={false}
                        variableWidth
                    >
                        {getCards({ type, weight }).map((Card, id) => {
                            // eslint-disable-next-line react/no-array-index-key
                            return <Card key={id} onClick={handleShowModal} />;
                        })}
                    </Slider>
                    {currentModal && (
                        <Modal
                            classes={{
                                content: cx('modal_content'),
                                divider: cx('modal_divider'),
                            }}
                            title={
                                <Title
                                    level={isMobile ? 5 : 3}
                                    {...testIds.transportation.info.title}
                                >
                                    <Dictionary text={currentModal?.title} />
                                </Title>
                            }
                            closeButtonProps={testIds.transportation.info.close}
                            contentProps={testIds.transportation.info.block}
                            mobilePosition="full"
                            wrapTitle={false}
                            portal
                            onClose={handleCloseModal}
                        >
                            <div className={cx('modal_image_container')}>
                                <img
                                    alt="rules"
                                    className={cx('modal_image')}
                                    src={currentModal?.modalImage}
                                    {...testIds.transportation.info.image}
                                />
                            </div>
                            <Text
                                alignment={isMobile ? 'left' : 'center'}
                                size="l"
                                weight="semibold"
                            >
                                <Dictionary text="com.profile.pets.page.card.modal.condition" />
                            </Text>
                            <ul
                                className={cx('list')}
                                {...testIds.transportation.info.conditions}
                            >
                                {currentModal?.conditions.map(item => (
                                    <li key={item} className={cx('list_item')}>
                                        <Text
                                            {...testIds.transportation.info
                                                .condition}
                                        >
                                            <Dictionary
                                                parseOptions={parseOptions}
                                                text={item}
                                                html
                                            />
                                        </Text>
                                    </li>
                                ))}
                            </ul>
                        </Modal>
                    )}
                </div>
            </PaperContent>
        </Paper>
    );
};

Transportation.propTypes = {
    type: oneOf(Object.values(PETS_TYPE)),
    weight: oneOf(Object.values(PETS_WEIGHT)),
};
