import React from 'react';
import { arrayOf, bool, func, node, shape, string } from 'prop-types';

import { Text, Title } from '@s7/ui-kit';

import cx from './View.sss';

import { testIds } from '../../constants';

const View = ({
    actions,
    actionsVisible,
    statusView,
    initials,
    birthday,
    children,
    contacts,
    documents,
    firstName,
    lastName,
    loyalty,
    onOpen,
    isDesktop,
}) => (
    <div className={cx('root', { allow_hover: !statusView })} {...testIds.item}>
        <div className={cx('heading')}>
            {initials && (
                <div className={cx('column', 'initials')}>{initials}</div>
            )}
            <div className={cx('column', 'person')}>
                <div className={cx('name')}>
                    <span className={cx('part')}>
                        <Title
                            as="span"
                            level={4}
                            ellipsis
                            {...testIds.firstName}
                        >
                            {firstName}
                        </Title>
                    </span>
                    {lastName && (
                        <span className={cx('part')}>
                            <Title
                                as="span"
                                level={4}
                                ellipsis
                                {...testIds.lastName}
                            >
                                {lastName}
                            </Title>
                        </span>
                    )}
                </div>
                {!statusView && (
                    <Text color="N100" size="s" {...testIds.birthday}>
                        {birthday}
                    </Text>
                )}
            </div>
            {isDesktop && (
                <>
                    <div className={cx('column', 'documents')}>{documents}</div>
                    {(loyalty || contacts) && (
                        <div className={cx('column', 'loyalty')}>{loyalty}</div>
                    )}
                    {contacts && (
                        <div className={cx('column', 'contacts')}>
                            {contacts}
                        </div>
                    )}
                    {actions && (
                        <div
                            className={cx('column', 'actions', {
                                visible: actionsVisible,
                                wide: !!children,
                            })}
                        >
                            {actions.map(({ key, item }) => (
                                <div key={key} className={cx('action')}>
                                    {item}
                                </div>
                            ))}
                        </div>
                    )}
                </>
            )}

            <button
                aria-hidden="true"
                aria-label={`${firstName} ${lastName}`}
                className={cx('open_area')}
                type="button"
                onClick={onOpen}
                {...testIds.edit}
            />
        </div>
        {children && <div className={cx('content')}>{children}</div>}
    </div>
);

View.propTypes = {
    actions: arrayOf(
        shape({
            item: node,
            key: string,
        }),
    ),
    actionsVisible: bool,
    allowHover: bool,
    birthday: node,
    children: node,
    contacts: node,
    documents: node,
    firstName: node,
    initials: node,
    isDesktop: bool,
    lastName: node,
    loyalty: node,
    onOpen: func,
    statusView: string,
};

export default React.memo(View);
