import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { SwitcherCanary, TextField } from 'components/Fields';
import { PETS_TYPE, PETS_WEIGHT } from 'consts/pets';
import { bool, func, node, string } from 'prop-types';
import { Field, getFormValues } from 'redux-form';
import { Dictionary } from 'shared';
import { getDictionary } from 'store/modules/languages';
import { createFieldValidate, validateRequired } from 'utils/createValidate';

import { Button, Modal, StatusMessage, Text, Title } from '@s7/ui-kit';
import { useMediaQuery } from '@s7/ui-kit/hooks';
import useDisableScroll from '@s7/ui-kit/hooks/useDisableScroll';

import { Avatar } from './Avatar';
import cx from './ModalForm.sss';

import { testIds } from '../constants';
import FlightBird from '../images/FlightBird.svg';
import FlightCat from '../images/FlightCat.svg';
import FlightDog from '../images/FlightDog.svg';
import FlightOther from '../images/FlightOther.svg';
import { getAnimalName, getAnimalWeight } from '../utils';

const validateNicknameAndCustomType = createFieldValidate({
    pets: {
        errorText: <Dictionary text="com.profile.validation.name" />,
    },
});

const Pet = ({ name, icon }) => {
    return (
        <div className={cx('pet')}>
            {icon}
            <Text
                classes={{ root: cx('switcher_text') }}
                color="N900"
                size="s"
                weight="semibold"
            >
                <Dictionary text={name} />
            </Text>
        </div>
    );
};

Pet.propTypes = {
    icon: node.isRequired,
    name: string.isRequired,
};

const optionsType = [
    {
        label: <Pet icon={<FlightDog />} name={getAnimalName(PETS_TYPE.DOG)} />,
        value: PETS_TYPE.DOG,
    },
    {
        label: <Pet icon={<FlightCat />} name={getAnimalName(PETS_TYPE.CAT)} />,
        value: PETS_TYPE.CAT,
    },
    {
        label: (
            <Pet icon={<FlightBird />} name={getAnimalName(PETS_TYPE.BIRD)} />
        ),
        value: PETS_TYPE.BIRD,
    },
    {
        label: (
            <Pet icon={<FlightOther />} name={getAnimalName(PETS_TYPE.OTHER)} />
        ),
        value: PETS_TYPE.OTHER,
    },
];

const optionsWeight = [
    {
        label: (
            <Text size="s">
                <Dictionary text={getAnimalWeight(PETS_WEIGHT.UP_TO_10)} html />
            </Text>
        ),
        value: PETS_WEIGHT.UP_TO_10,
    },
    {
        label: (
            <Text size="s">
                <Dictionary text={getAnimalWeight(PETS_WEIGHT.UP_TO_23)} html />
            </Text>
        ),
        value: PETS_WEIGHT.UP_TO_23,
    },
    {
        label: (
            <Text size="s">
                <Dictionary text={getAnimalWeight(PETS_WEIGHT.UP_TO_50)} html />
            </Text>
        ),
        value: PETS_WEIGHT.UP_TO_50,
    },
    {
        label: (
            <Text size="s">
                <Dictionary text={getAnimalWeight(PETS_WEIGHT.OVER_50)} html />
            </Text>
        ),
        value: PETS_WEIGHT.OVER_50,
    },
];

export const ModalForm = ({
    onClose,
    onSubmit,
    onRemove,
    handleSubmit,
    submitting,
    submitText,
    errorMessage,
    formName,
    title,
    defaultImage,
}) => {
    const breakpoints = useMediaQuery('xs');
    const isMobile = breakpoints.xs;

    const dictionary = useSelector(getDictionary);
    const formValues = useSelector(getFormValues(formName));

    const messageRef = useRef(null);

    const isOtherType = formValues?.type === PETS_TYPE.OTHER;

    const handleSubmitForm = ({
        weight,
        customType = '',
        nickname = '',
        ...values
    }) => {
        const currentWeight = PETS_TYPE.OTHER === values.type ? '' : weight;

        onSubmit({
            ...values,
            customType: customType ? customType.trim() : '',
            nickname: nickname.trim(),
            weight: currentWeight,
        });
    };

    useEffect(() => {
        if (errorMessage && messageRef.current) {
            messageRef.current.scrollIntoView();
        }
    }, [errorMessage]);

    useDisableScroll({
        isOpen: true,
        target: document.body,
    });

    return (
        <Modal
            classes={{
                root: cx('modal_root'),
                wrapper: cx('modal_wrapper'),
            }}
            title={
                <Title level={isMobile ? 5 : 4}>
                    <Dictionary text={title} />
                </Title>
            }
            closeButtonProps={testIds.edit.close}
            contentProps={testIds.edit.block}
            size="small"
            width={!isMobile ? 360 : undefined}
            wrapTitle={false}
            portal
            onClose={onClose}
        >
            <form onSubmit={handleSubmit(handleSubmitForm)}>
                <div className={cx('avatar')}>
                    <Field
                        buttonProps={testIds.edit.avatar}
                        component={Avatar}
                        defaultImage={defaultImage}
                        name="avatar"
                    />
                </div>
                <div className={cx('field')}>
                    <Field
                        label={dictionary.t(
                            'com.profile.pets.modal.form.nickname',
                        )}
                        validate={[
                            validateRequired,
                            validateNicknameAndCustomType,
                        ]}
                        component={TextField}
                        inputProps={testIds.edit.nickname}
                        maxLength={40}
                        name="nickname"
                    />
                </div>
                {isOtherType && (
                    <div className={cx('field')}>
                        <Field
                            label={dictionary.t(
                                'com.profile.pets.modal.form.customType',
                            )}
                            validate={[
                                validateRequired,
                                validateNicknameAndCustomType,
                            ]}
                            component={TextField}
                            inputProps={testIds.edit.typeCustom}
                            maxLength={40}
                            name="customType"
                        />
                    </div>
                )}
                <div className={cx('field')}>
                    <Field
                        classes={{
                            button: cx('switcher_button_type'),
                            root: cx('switcher'),
                        }}
                        component={SwitcherCanary}
                        name="type"
                        namespace={testIds.edit.type['data-test']}
                        options={optionsType}
                    />
                </div>
                {!isOtherType && (
                    <div className={cx('field')}>
                        <Field
                            classes={{
                                button: cx('switcher_button_weight'),
                                root: cx('switcher'),
                            }}
                            component={SwitcherCanary}
                            name="weight"
                            namespace={testIds.edit.weight['data-test']}
                            options={optionsWeight}
                        />
                    </div>
                )}
                <div className={cx('field')}>
                    <Button
                        disabled={submitting}
                        loading={submitting}
                        theme="b2c"
                        type="submit"
                        block
                        {...testIds.edit.submit}
                    >
                        <Dictionary text={submitText} />
                    </Button>
                </div>
                {onRemove && (
                    <div className={cx('field')}>
                        <Button
                            theme="secondary"
                            type="submit"
                            block
                            onClick={onRemove}
                            {...testIds.edit.remove}
                        >
                            <Dictionary text="com.profile.pets.modal.edit.button.delete" />
                        </Button>
                    </div>
                )}
                {errorMessage && (
                    <div className={cx('message')}>
                        <StatusMessage
                            ref={messageRef}
                            view="error"
                            {...testIds.edit.notification}
                        >
                            {errorMessage}
                        </StatusMessage>
                    </div>
                )}
            </form>
        </Modal>
    );
};

ModalForm.propTypes = {
    defaultImage: node,
    errorMessage: string,
    formName: string.isRequired,
    handleSubmit: func,
    onClose: func.isRequired,
    onRemove: func,
    onSubmit: func.isRequired,
    submitText: string.isRequired,
    submitting: bool,
    title: string.isRequired,
};
