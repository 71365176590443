import React from 'react';
import { func, node, string } from 'prop-types';

import { Button, StatusMessage } from '@s7/ui-kit';

export const Notification = React.memo(
    // eslint-disable-next-line no-unused-vars
    React.forwardRef(function Notification(props, ref) {
        const {
            button,
            buttonTheme = 'warning',
            icon = 'warning',
            link,
            onClickSecondary,
            secondary,
            text,
            title,
            ...rest
        } = props;

        return (
            <StatusMessage
                {...(button
                    ? {
                          actions: (
                              <>
                                  <Button
                                      key={0}
                                      as="a"
                                      href={link}
                                      theme={buttonTheme}
                                  >
                                      {button}
                                  </Button>
                                  {secondary && (
                                      <Button
                                          key={1}
                                          theme="secondary"
                                          type="button"
                                          onClick={onClickSecondary}
                                      >
                                          {secondary}
                                      </Button>
                                  )}
                              </>
                          ),
                      }
                    : {})}
                title={title}
                view={icon}
                outline
                {...rest}
            >
                {text}
            </StatusMessage>
        );
    }),
);

Notification.propTypes = {
    button: node,
    icon: string,
    link: node,
    onClickSecondary: func,
    secondary: node,
    text: node,
    title: node,
};
