import React from 'react';
import { DATE_FORMATS } from 'consts/dateFormats';
import moment from 'moment';
import { replace } from 'ramda';
import { Dictionary } from 'shared';
import { capitalize } from 'utils/format';
import slugify from 'utils/slugify';

const DD_MM_YYYY = DATE_FORMATS.DD.MM.YYYY_dot;

const TEXTS = {
    dateMinBirthTraveler: (
        <Dictionary text="com.profile.form.valid.dateMinBirthTraveler" />
    ),
    dateMinBirthTravelerRu: (
        <Dictionary text="com.profile.form.valid.dateMinBirthTravelerRu" />
    ),
    dateMinBirthUser: (
        <Dictionary text="com.profile.form.valid.dateMinBirthUser" />
    ),
    dateMinBirthUserRu: (
        <Dictionary text="com.profile.form.valid.dateMinBirthUserRu" />
    ),
    dateMinEffective: (
        <Dictionary text="com.profile.form.valid.dateMinEffective" />
    ),
    dateMinExpireVisa: (
        <Dictionary text="com.profile.form.valid.dateMinExpireVisa" />
    ),
    dateMinIssuedOn: (
        <Dictionary text="com.profile.form.valid.dateMinIssuedOn" />
    ),
};

export function validateDiffDate({
    add = {},
    date,
    isRussianPassport,
    traveler,
    type = 'expire',
    visa = false,
}) {
    const typePostfix = traveler ? 'Traveler' : 'User';
    const countryPostfix = isRussianPassport ? 'Ru' : '';

    const text = {
        effective: TEXTS[`dateMinBirth${typePostfix}${countryPostfix}`],
        expire: TEXTS[visa ? 'dateMinExpireVisa' : 'dateMinEffective'],
        issued: TEXTS.dateMinIssuedOn,
    };

    if (moment(date, DD_MM_YYYY, true).isValid()) {
        return {
            dateMin: {
                add,
                date,
                errorText: text[type],
            },
        };
    }

    return {};
}

// TODO перенести в каталог utils после слияния ветки 1091(редизайн ProfileHeader)
export const normalizeSeries = replace(/[^A-Z0-9]/gi, '');
export const normalizeNumber = replace(/[^0-9]/gi, '');
export const normalizeCheckbox = v => !!v;
export const normalizePlace = value =>
    capitalize(
        value
            .replace(/[^a-zа-яё\s-]/gi, '')
            .replace(/--/g, '-')
            .replace(/\s{2,}/g, ' '),
    );

export const normalizeSlugify = value => {
    // eslint-disable-next-line no-misleading-character-class,no-useless-escape
    const newValue = slugify(value.replace(/[№\&🦄♥\_\^\[\]]/gi, ''), {
        decamelize: false,
        lowercase: false,
    });

    return newValue.replace(/[^A-ZА-ЯЁ0-9]/gi, '');
};
