import React from 'react';
import { bool, func, node, object, shape } from 'prop-types';
import Hidden from 'ui-kit/atoms/Hidden';
import IconButton from 'ui-kit/atoms/IconButton';

import { RoundButton } from '@s7/ui-kit';
import { Pencil } from '@s7/ui-kit/icons';

import cx from './SectionItem.sss';

const SectionItem = ({
    children,
    edit,
    isEdit,
    onEdit,
    testIds,
    isPaper,
    wide,
}) => (
    <div className={cx('root', { isEdit, isPaper, wide })}>
        <div className={cx('item')}>{children}</div>

        {onEdit && (
            <div className={cx('action')}>
                <Hidden on="tablet">
                    <RoundButton
                        size="xs"
                        view="b2c"
                        onClick={onEdit}
                        {...testIds.editButton}
                    >
                        <Pencil />
                    </RoundButton>
                </Hidden>
                {isPaper || (
                    <Hidden on="desktop">
                        <IconButton
                            icon="chevron-right"
                            onClick={onEdit}
                            {...testIds.editButton}
                        />
                    </Hidden>
                )}
            </div>
        )}

        {isEdit && <div className={cx('edit')}>{edit}</div>}
    </div>
);

const testIdsShape = shape({
    editButton: object,
});

SectionItem.propTypes = {
    children: node,
    edit: node,
    isEdit: bool,
    isPaper: bool,
    onEdit: func,
    testIds: testIdsShape,
    wide: bool,
};

SectionItem.defaultProps = {
    testIds: {
        editButton: {},
    },
};

export default React.memo(SectionItem);
