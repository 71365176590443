import React, { useEffect } from 'react';
import { PROFILE_ANCHORS } from 'components/ProfileView/constants';
import { useRouter } from 'next/router';
import { shape, string } from 'prop-types';
import { Dictionary } from 'shared';

import { RoundButton } from '@s7/ui-kit';
import { ChevronSmallLeft } from '@s7/ui-kit/icons';

import { Pet } from './Pet';
import cx from './PetPage.sss';
import { Questions } from './Questions';
import { Transportation } from './Transportation';

import { useAnalyticsContext } from '../../../contexts';
import { testIds } from '../constants';

export const PetPage = ({ id, pet }) => {
    const router = useRouter();
    const { sendEvent } = useAnalyticsContext();

    const handleBack = () => {
        router.push(`/#${PROFILE_ANCHORS.pets}`);
    };

    useEffect(() => {
        sendEvent({
            event_name: 'view_screen',
            flow: 'companions',
            step: 'pets',
        });
    }, [sendEvent]);

    return (
        <div className={cx('root')} {...testIds.page}>
            <div className={cx('block')}>
                <RoundButton
                    direction="right"
                    size="xs"
                    text={<Dictionary text="com.profile.pets.page.back" />}
                    view="secondary-white"
                    onClick={handleBack}
                >
                    <ChevronSmallLeft />
                </RoundButton>
            </div>
            <div className={cx('block')}>
                <Pet
                    avatar={pet?.avatar}
                    customType={pet?.customType}
                    id={id}
                    nickname={pet.nickname}
                    type={pet.type}
                    weight={pet?.weight}
                />
            </div>
            <div className={cx('block')}>
                <Transportation type={pet.type} weight={pet?.weight} />
            </div>
            <div className={cx('block')}>
                <Questions />
            </div>
        </div>
    );
};

PetPage.propTypes = {
    id: string.isRequired,
    pet: shape({
        avatar: shape({
            mainLink: string.isRequired,
            previewLink: string.isRequired,
        }),
        customType: string,
        id: string.isRequired,
        nickname: string.isRequired,
        type: string.isRequired,
        weight: string,
    }),
};
