import { dictKeys, getTestIdGetter } from 'utils/testIds';

export const EDIT_FORM_NAME = 'edit_pets';
export const ADD_FORM_NAME = 'add_pets';

export const getTestId = getTestIdGetter(dictKeys.PROFILE_PETS);

export const testIds = {
    block: getTestId('block'),
    edit: {
        avatar: getTestId('editAvatar'),
        block: getTestId('edit'),
        close: getTestId('editClose'),
        nickname: getTestId('editNickname'),
        notification: getTestId('editNotification'),
        remove: getTestId('editRemove'),
        submit: getTestId('editSubmit'),
        type: getTestId('editType'),
        typeCustom: getTestId('editTypeCustom'),
        weight: getTestId('editWeight'),
    },
    empty: getTestId('empty'),
    item: {
        avatar: getTestId('itemAvatar'),
        block: getTestId('item'),
        delete: getTestId('itemDelete'),
        deleteCancel: getTestId('itemDeleteCancel'),
        deleteConfirm: getTestId('itemDeleteConfirm'),
        edit: getTestId('itemEdit'),
        icon: getTestId('itemIcon'),
        link: getTestId('itemLink'),
        nickname: getTestId('itemNickname'),
        type: getTestId('itemType'),
        weight: getTestId('itemWeight'),
    },
    list: getTestId('list'),
    more: getTestId('more'),
    new: getTestId('new'),
    page: getTestId('page'),
    questions: {
        block: getTestId('questions'),
        chat: getTestId('questionsChat'),
        title: getTestId('questionsTitle'),
    },
    transportation: {
        block: getTestId('transportation'),
        info: {
            block: getTestId('transportationInfo'),
            close: getTestId('transportationInfoClose'),
            condition: getTestId('transportationInfoCondition'),
            conditions: getTestId('transportationInfoConditions'),
            image: getTestId('transportationInfoImage'),
            title: getTestId('transportationInfoTitle'),
        },
        item: {
            block: getTestId('transportationItem'),
            button: getTestId('transportationItemButton'),
            size: getTestId('transportationItemSize'),
            text: getTestId('transportationItemText'),
            title: getTestId('transportationItemTitle'),
        },
    },
};
