import { createAction, createReducer } from 'redux-act';

const initialState = {
    business: {
        company: null,
        email: null,
        invitation: null,
    },
    error: '',
    isAllowAccess: true,
    isAuth: false,
    isBusiness: false,
    isConfirmed: false,
    isError: false,
    isProcess: false,
    isSuccess: false,
};

export const start = createAction('b2b/start');
export const reset = createAction('b2b/reset');

export const confirm = createAction('b2b/confirm');
export const confirmFailure = createAction('b2b/confirmFailure');
export const confirmSuccess = createAction('b2b/confirmSuccess');
export const setIsConfirmed = createAction('b2b/setIsConfirmed');
export const setIsProcess = createAction('b2b/setIsProcess');
export const setAllowAccess = createAction('b2b/setAllowAccess');

const handleStart = (state, { isAuth, ...business }) => ({
    ...state,
    business,
    isAuth: !!isAuth,
    isBusiness: true,
});

const handleReset = state => ({
    ...state,
    ...initialState,
});

const handleConfirm = state => ({
    ...state,
    isError: false,
    isProcess: true,
    isSuccess: false,
});

const handleConfirmSuccess = state => ({
    ...state,
    isError: false,
    isSuccess: true,
});

const handleConfirmFailure = (state, { errorMessage }) => ({
    ...state,
    error: errorMessage,
    isError: true,
    isSuccess: false,
});

const handleSetIsConfirmed = (state, isConfirmed) => ({
    ...state,
    isConfirmed,
});

const handleSetIsProcess = (state, isProcess) => ({
    ...state,
    isProcess,
});

const handleSetAllowAccess = (state, isAllowAccess) => ({
    ...state,
    isAllowAccess,
});

export default createReducer(
    {
        [confirm]: handleConfirm,
        [confirmFailure]: handleConfirmFailure,
        [confirmSuccess]: handleConfirmSuccess,
        [reset]: handleReset,
        [setAllowAccess]: handleSetAllowAccess,
        [setIsConfirmed]: handleSetIsConfirmed,
        [setIsProcess]: handleSetIsProcess,
        [start]: handleStart,
    },
    initialState,
);

export * from './selectors';
