import React from 'react';
import { Dictionary } from 'shared';

import createFieldValidate from '../createFieldValidate';

// TODO: Need to use individual error message

export const validateUppercaseRequired = createFieldValidate({
    uppercaseCharacterRequired: {
        errorText: <Dictionary text="com.validation.password.weak" />,
    },
});

export const validateLowercaseRequired = createFieldValidate({
    lowercaseCharacterRequired: {
        errorText: <Dictionary text="com.validation.password.weak" />,
    },
});

export const validateNumericCharacterRequired = createFieldValidate({
    numericCharacterRequired: {
        errorText: <Dictionary text="com.validation.password.weak" />,
    },
});

export const validateSpecialCharacterRequired = createFieldValidate({
    specialCharacterRequired: {
        errorText: <Dictionary text="com.validation.password.weak" />,
    },
});
