import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import AvatarEditor from 'components/Avatar/AvatarEditor';
import { bool, node, object, shape } from 'prop-types';
import { Dictionary } from 'shared';
import { addNotification } from 'store/modules/shared';
import { fieldInputPropTypes, fieldMetaPropTypes } from 'types';
import { keyCodes } from 'utils/keyCodes';

import { Button, Modal, RoundButton, Text } from '@s7/ui-kit';
import { CloseLinear, PlusLinear } from '@s7/ui-kit/icons';

import cx from './Avatar.sss';

const IMG_EXTENSIONS = ['.jpg', '.jpeg', '.png', '.gif'];
const IMG_MIME_TYPES = ['image/jpeg', 'image/pjpeg', 'image/png', 'image/gif'];

const ACCEPT = IMG_EXTENSIONS.join(', ');

export const Avatar = ({
    buttonProps,
    input,
    meta,
    defaultImage: DefaultImage,
}) => {
    const dispatch = useDispatch();

    const [showModal, setShowModal] = useState(false);
    const [fileImage, setFileImage] = useState(false);
    const [error, setError] = useState(null);

    const inputFileRef = useRef(null);
    const editorRef = useRef(null);

    const handleAddClick = () => {
        inputFileRef.current.click();
    };

    const handleRemoveClick = () => {
        input.onChange(null);
        inputFileRef.current.value = null;
        setFileImage(false);
    };

    const handleInputFileChange = e => {
        const file = e.target.files[0];

        if (!file) {
            return;
        }

        // Don't apply image to editor if it's mime type not in IMG_MIME_TYPES list
        // or extension not in IMG_EXTENSIONS list
        if (!IMG_MIME_TYPES.includes(file.type)) {
            const matches = file.name.match(/(\.[^.]+)$/);

            if (
                !matches ||
                !IMG_EXTENSIONS.includes(matches[1].toLowerCase())
            ) {
                setError('com.avatar.error.not-image');

                return;
            }
        }

        if (file.size > 5242880) {
            setError('com.avatar.error.too-big');

            return;
        }

        let img = new Image();

        img.src = window.URL.createObjectURL(file);

        img.onload = () => {
            if (img.width < 200 || img.height < 200) {
                setError('com.avatar.error.too-small');
            } else {
                setFileImage(file);
                setShowModal(true);
                img = null;
            }
        };
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setFileImage(false);
        inputFileRef.current.value = null;
    };

    const handleSaveImage = () => {
        const formData = new FormData();
        const { x, y, width, rotate } = editorRef.current.getData(true);
        const imgUrl = editorRef.current.getCroppedCanvas({ width: 132 });

        const centerOffset = width / 2;

        const centerX = Math.floor(x + centerOffset);
        const centerY = Math.floor(y + centerOffset);

        input.onChange({
            centerX: `${centerX}`,
            centerY: `${centerY}`,
            file: fileImage,
            height: `${width}`,
            imgUrl: imgUrl.toDataURL(formData),
            rotation: `${rotate}`,
            width: `${width}`,
        });
        handleCloseModal();
    };

    const handleAddOrRemove = () => {
        if (input.value?.imgUrl) {
            handleRemoveClick();
        } else {
            handleAddClick();
        }
    };

    const handleKeyDown = e => {
        if (e.keyCode === keyCodes.Enter || e.keyCode === keyCodes.Space) {
            handleAddOrRemove();
        }
    };

    useEffect(() => {
        if (error) {
            dispatch(
                addNotification({
                    delay: 3000,
                    message: <Dictionary text={error} />,
                    type: 'error',
                }),
            );
            setError(null);
        }
    }, [dispatch, error]);

    return (
        <>
            <div
                className={cx('root', {
                    disabled: meta.submitting,
                })}
                role="button"
                tabIndex="0"
                onClick={handleAddOrRemove}
                onKeyDown={handleKeyDown}
            >
                <div className={cx('button')}>
                    <RoundButton
                        size="s"
                        tabIndex="-1"
                        view="b2c-primary"
                        {...buttonProps}
                    >
                        {input.value?.imgUrl ? <CloseLinear /> : <PlusLinear />}
                    </RoundButton>
                </div>
                <Text color="G600" size="m">
                    <Dictionary text="com.profile.pets.modal.form.photo" />
                </Text>
                {input.value?.imgUrl && (
                    <img
                        alt="avatar"
                        className={cx('avatar')}
                        height={132}
                        src={input.value?.imgUrl}
                        width={132}
                    />
                )}
                {!input.value?.imgUrl && DefaultImage && (
                    <DefaultImage className={cx('avatar')} />
                )}
                <input
                    ref={inputFileRef}
                    accept={ACCEPT}
                    className={cx('input')}
                    type="file"
                    onChange={handleInputFileChange}
                />
            </div>
            {showModal && (
                <Modal
                    actions={[
                        <Button
                            theme="outline"
                            block
                            onClick={handleCloseModal}
                        >
                            <Dictionary text="com.action.cancel" />
                        </Button>,
                        <Button theme="b2c" block onClick={handleSaveImage}>
                            <Dictionary text="com.action.save" />
                        </Button>,
                    ]}
                    maxContentHeight={400}
                    size="medium"
                    withScroll={false}
                    ds
                    portal
                >
                    <AvatarEditor ref={editorRef} image={fileImage} />
                </Modal>
            )}
        </>
    );
};

Avatar.propTypes = {
    buttonProps: object,
    defaultImage: node,
    disabled: bool,
    input: shape(fieldInputPropTypes),
    meta: shape(fieldMetaPropTypes),
};
