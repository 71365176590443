import React from 'react';
import { bool, shape } from 'prop-types';
import { fieldInputPropTypes, fieldMetaPropTypes } from 'types';

import { Checkbox } from '@s7/ui-kit';

// eslint-disable-next-line react/prop-types
const CheckboxField = ({ error, input, meta, disabled, ...rest }) => {
    const invalid = meta.error && (meta.touched || meta.submitFailed);
    const { value, checked, onChange } = input;

    return (
        <Checkbox
            {...{
                checked,
                disabled,
                invalid,
                onChange,
                value,
            }}
            {...rest}
        />
    );
};

CheckboxField.propTypes = {
    disabled: bool,
    input: shape(fieldInputPropTypes),
    meta: shape(fieldMetaPropTypes),
};

CheckboxField.defaultProps = {
    input: {},
    meta: {},
};

const CheckboxFieldMemo = React.memo(CheckboxField);

export { CheckboxFieldMemo as CheckboxField };
