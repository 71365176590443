import React, { useMemo } from 'react';
import { MilitaryId } from 'components/FormSections';
import { FormSection } from 'redux-form';
import { Column, Dictionary } from 'shared';

import { DateFields } from './DateFields';

import { fieldsPropTypes } from '../propTypes';

const testPrefix = 'militaryId';
const minDateError = (
    <Dictionary text="com.profile.form.valid.date-min-birth-military-card" />
);

export const MilitaryIdFields = props => {
    const { getTestId, disabled } = props;

    const validateIssueDate = useMemo(
        () => ({
            dateMin: {
                add: {
                    years: 18,
                },
                date: props.birthday,
                errorText: minDateError,
            },
        }),
        [props.birthday],
    );

    return (
        <>
            <Column desktop={50} autoZIndex>
                <FormSection name="seriesNumber">
                    <MilitaryId
                        numberProps={{
                            disabled,
                            inputProps: getTestId(`${testPrefix}number`),
                            tooltipProps: {
                                ...getTestId(`${testPrefix}number`, {
                                    error: true,
                                }),
                            },
                        }}
                        seriesProps={{
                            disabled,
                            inputProps: getTestId(`${testPrefix}series`),
                            tooltipProps: {
                                ...getTestId(`${testPrefix}series`, {
                                    error: true,
                                }),
                            },
                        }}
                    />
                </FormSection>
            </Column>
            <Column desktop={50}>
                <DateFields
                    testIds={{
                        dateOfIssue: getTestId(`${testPrefix}DateOfIssue`),
                        dateOfIssueError: getTestId(
                            `${testPrefix}DateOfIssue`,
                            { error: true },
                        ),
                        expireDate: getTestId(`${testPrefix}ExpireDate`),
                        expireDateError: getTestId(`${testPrefix}ExpireDate`, {
                            error: true,
                        }),
                    }}
                    validateEffective={validateIssueDate}
                    onlyEffective
                    {...props}
                />
            </Column>
        </>
    );
};

MilitaryIdFields.propTypes = fieldsPropTypes;
