import React from 'react';
import { useWindowSize } from 'hooks';
import { arrayOf, bool, func, node, shape, string } from 'prop-types';

import { SuggestField as Suggest } from '@s7/ui-kit';

export const SuggestField = ({
    disabled,
    label,
    input = {},
    invalid: invalidFromProps,
    loadSuggestions,
    meta = {},
    options = [],
    placeholder,
    ...rest
}) => {
    const invalid =
        invalidFromProps || (meta.error && (meta.touched || meta.submitFailed));
    const { onBlur, ...inputProps } = input;
    const { isMobile } = useWindowSize();

    const handleBlur = e => {
        // https://s7airlines.atlassian.net/browse/DS-811
        if (e?.type) {
            onBlur(e);
        }
    };

    return (
        <Suggest
            {...rest}
            {...inputProps}
            {...{
                invalid,
                isDisabled: disabled,
                label,
                loadSuggestions,
                meta,
                options,
                placeholder,
                tooltip: invalid ? meta.error : undefined,
            }}
            {...(isMobile ? { onClose: handleBlur } : { onBlur: handleBlur })}
        />
    );
};

const optionsShape = shape({
    label: string,
});

SuggestField.propTypes = {
    disabled: bool,
    input: shape(),
    invalid: bool,
    label: node,
    loadSuggestions: func,
    meta: shape(),
    options: arrayOf(optionsShape),
    placeholder: node,
    value: string,
};
