import React from 'react';
import { useSelector } from 'react-redux';
import { compose, map, prop, sortBy } from 'ramda';
import { Field as FormField } from 'redux-form';
import { Column, Columns } from 'shared';

import {
    BirthFields,
    MilitaryIdFields,
    PassportFields,
    VisaFields,
} from './components';
import { propTypes } from './propTypes';

import { documentType } from '../../data';
import { useWindowSize } from '../../hooks';
import { getDictionary, getLocale } from '../../store/modules/languages';
import { getDocListByParams, isOver14YearsOld } from '../../utils';
import { SelectField } from '../Fields';

const filterCountry = countryOfIssue => countryOfIssue.visible;

const RUS_COUNTRY_CODE = 'RU';

const DocumentForm = React.memo(function DocumentForm(props) {
    const {
        birthday,
        countryOfIssue,
        disabled,
        getTestId,
        isVisa,
        prefix,
        type,
        onSelectChange,
    } = props;

    const dictionary = useSelector(getDictionary);
    const locale = useSelector(getLocale);

    const { isMobile } = useWindowSize();

    const isOver14Year = isOver14YearsOld(birthday);
    const isRussian = countryOfIssue === RUS_COUNTRY_CODE;
    const isBirth = type === documentType.BIRTH_CERTIFICATE;
    const isOther = type === documentType.OTHER;
    const isRussiaBirth = isBirth && isRussian;
    const isRussianPassport =
        type === documentType.INTERNAL_PASSPORT && isRussian;

    const componentProps = {
        ...props,
        dictionary,
        filterCountry,
        isBirth,
        isMobile,
        isOther,
        isOver14Year,
        isRussiaBirth,
        isRussian,
        isRussianLocale: locale === 'ru',
        isRussianPassport,
    };

    const renderByType = typeValue => {
        switch (typeValue) {
            case documentType.INTERNAL_PASSPORT:
            case documentType.INTERNATIONAL_PASSPORT:
            case documentType.OTHER:
                return <PassportFields {...componentProps} />;

            case documentType.VISA:
                return <VisaFields {...componentProps} />;

            case documentType.BIRTH_CERTIFICATE:
                return <BirthFields {...componentProps} />;

            case documentType.MILITARY_CARD:
                return <MilitaryIdFields {...componentProps} />;

            default:
                return <PassportFields {...componentProps} />;
        }
    };

    const items = compose(
        map(item => ({ ...item, label: dictionary.t(item.title) })),
        sortBy(prop('sort')),
    )(getDocListByParams({ birthday, militaryId: true }));

    return (
        <Columns>
            {!isVisa && (
                <Column key="main" autoZIndex {...getTestId('docTypeList')}>
                    <FormField
                        component={SelectField}
                        controlProps={getTestId('docType')}
                        disabled={disabled}
                        label={dictionary.t('com.profile.documents.form.type')}
                        name={`${prefix}type`}
                        namespace={getTestId('docType')['data-test']}
                        options={items}
                        onChange={onSelectChange}
                        {...getTestId('editForm')}
                    />
                </Column>
            )}
            {renderByType(type)}
        </Columns>
    );
});

DocumentForm.propTypes = propTypes;

DocumentForm.defaultProps = {
    countries: [],
    disabled: false,
    disabledDayFields: {},
    getTestId: () => {},
    prefix: '',
};

export default DocumentForm;
