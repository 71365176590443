import React from 'react';
import { useSelector } from 'react-redux';
import { useWindowSize } from 'hooks';
import { ContentLoader } from 'shared';
import { isLoading as getIsLoadingLanguages } from 'store/modules/languages';

import TravelerLoader from './TravelerLoader';

import Wrapper from '../Wrapper';

const TravelersLoader = () => {
    const { isDesktop } = useWindowSize();
    const isLoadingLanguages = useSelector(getIsLoadingLanguages);
    const propsWrapper = React.useMemo(() => {
        if (!isLoadingLanguages) {
            return {};
        }

        const size = {
            height: isDesktop ? 24 : 14,
            width: 200,
        };

        return {
            title: <ContentLoader {...size} />,
        };
    }, [isLoadingLanguages, isDesktop]);

    return (
        <Wrapper {...propsWrapper}>
            <TravelerLoader />
            <TravelerLoader />
        </Wrapper>
    );
};

export default TravelersLoader;
