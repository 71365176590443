import { createAction, createReducer } from 'redux-act';

const initialState = {
    deliveryType: '',
    isModalOpen: false,
};

export const closeModal = createAction('editAddress/closeModal');

const handleCloseModal = state => ({
    ...state,
    isModalOpen: false,
});

export default createReducer(
    {
        [closeModal]: handleCloseModal,
    },
    initialState,
);

export * from './selectors';
