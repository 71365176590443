import React, { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { CheckboxField } from 'components/Fields';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { Field as FormField, getFormValues, reduxForm } from 'redux-form';
import { getAirlineOptions } from 'store/modules/airlines/selectors';
import { getErrors } from 'store/modules/travelers';
import { v4 as uuidv4 } from 'uuid';

import { Button, RoundButton, StatusMessage, Text, Title } from '@s7/ui-kit';
import { PlusLinear, Trash } from '@s7/ui-kit/icons';

import cx from './Edit.sss';

import { SECTIONS, testIds, TEXTS } from '../../constants';
import { Contacts, Document, Loyalty, Personal } from '../Form';

const Edit = ({
    documents = [],
    form,
    handleSubmit,
    isNew,
    onClose,
    onDocScan,
    onSave,
    pristine,
    submitting,
}) => {
    const { haveLoyalty } = useSelector(getFormValues(form)) || {};
    const [newDocuments, setNewDocuments] = useState([]);
    const [removeDocuments, setRemoveDocuments] = useState([]);

    const errors = useSelector(getErrors);
    const airlinesOptions = useSelector(getAirlineOptions);

    const handleSave = handleSubmit(() => {
        if (pristine && !isNew) {
            onClose();
        }

        onSave(removeDocuments);
    });

    const handleNew = useCallback(() => {
        setNewDocuments([...newDocuments, { id: uuidv4(), isNewDoc: true }]);
    }, [newDocuments]);

    const handleRemove = useCallback(
        id => {
            setRemoveDocuments([...removeDocuments, id]);
        },
        [removeDocuments],
    );

    const documentsList = useMemo(
        () =>
            [...documents, ...newDocuments].filter(
                ({ id }) => removeDocuments.indexOf(id) === -1,
            ),
        [removeDocuments, newDocuments, documents],
    );

    return (
        <div className={cx('root')} {...testIds.form.root}>
            <div className={cx('section')}>
                <Personal />
            </div>
            <div className={cx('section')}>
                <Title level={5}>{TEXTS.contacts}</Title>
                <div className={cx('description')}>
                    <Text color="N100">{TEXTS.emptyContacts}</Text>
                </div>

                <div className={cx('item')}>
                    <Contacts />
                </div>
            </div>
            <div className={cx('section')}>
                <Title level={5}>{TEXTS.documents}</Title>

                {documentsList.length ? (
                    documentsList.map(({ id, isNewDoc }, idx) => {
                        const error =
                            errors.length > 0
                                ? errors.find(
                                      item =>
                                          item.description ===
                                          `traveler.documents[${idx}]`,
                                  )
                                : null;

                        return (
                            <div
                                key={id}
                                className={cx('document')}
                                {...testIds.form.document.item}
                            >
                                <div className={cx('item')}>
                                    {error && (
                                        <div className={cx('notification')}>
                                            <StatusMessage view="error">
                                                {error.message}
                                            </StatusMessage>
                                        </div>
                                    )}
                                    <Document
                                        form={form}
                                        prefix={`${SECTIONS.document}_${id}_`}
                                        onDocScan={
                                            isNewDoc ? onDocScan : undefined
                                        }
                                    />
                                </div>
                                <div
                                    className={cx('remove', {
                                        hasDocScan: isNewDoc,
                                    })}
                                >
                                    <RoundButton
                                        size="s"
                                        view="secondary"
                                        onClick={() => handleRemove(id)}
                                        {...testIds.form.document.remove}
                                    >
                                        <Trash />
                                    </RoundButton>
                                </div>
                            </div>
                        );
                    })
                ) : (
                    <div className={cx('description')}>
                        <Text color="N100" {...testIds.form.document.empty}>
                            {TEXTS.emptyDocuments}
                        </Text>
                    </div>
                )}
            </div>
            <div className={cx('item')}>
                <RoundButton
                    direction="right"
                    size="xs"
                    text={TEXTS.newDocument}
                    view="b2c"
                    onClick={handleNew}
                    {...testIds.form.document.new}
                >
                    <PlusLinear />
                </RoundButton>
            </div>

            <div className={cx('section')}>
                <FormField
                    component={CheckboxField}
                    format={Boolean}
                    name="haveLoyalty"
                    type="checkbox"
                    {...testIds.form.loyalty.hasCard}
                >
                    {TEXTS.form.cardHas}
                </FormField>

                {haveLoyalty && (
                    <div className={cx('item')}>
                        <Loyalty airlineOptions={airlinesOptions} />
                    </div>
                )}
            </div>

            <div className={cx('actions')}>
                <div className={cx('action')}>
                    <Button
                        disabled={pristine && removeDocuments.length === 0}
                        loading={submitting}
                        size="large"
                        theme="b2c"
                        type="submit"
                        block
                        onClick={handleSave}
                        {...testIds.form.save}
                    >
                        {TEXTS.save}
                    </Button>
                </div>
                <div className={cx('action')}>
                    <Button
                        size="large"
                        theme="secondary"
                        block
                        onClick={onClose}
                        {...testIds.form.cancel}
                    >
                        {TEXTS.cancel}
                    </Button>
                </div>
            </div>
        </div>
    );
};

Edit.propTypes = {
    documents: arrayOf(
        shape({
            id: string,
        }),
    ),
    form: string,
    handleSubmit: func,
    isNew: bool,
    onClose: func,
    onDocScan: func,
    onSave: func,
    pristine: bool,
    submitting: bool,
};

export default reduxForm()(React.memo(Edit));
