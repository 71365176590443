import React from 'react';
import { useWindowSize } from 'hooks/useWindowSize';
import { bool, node } from 'prop-types';

import { Text } from '@s7/ui-kit';

import cx from './GroupAccount.sss';

export const TravelersTitle = React.memo(function TravelersTitle({
    isCollapsed,
    children,
}) {
    const { isMobile } = useWindowSize();

    return (
        <div
            className={cx('travelers_title', {
                travelers_title_collapsed: isCollapsed,
            })}
        >
            <Text
                color="N300"
                size={!isMobile ? 'l' : undefined}
                weight="semibold"
            >
                {children}
            </Text>
        </div>
    );
});

TravelersTitle.propTypes = {
    children: node,
    isCollapsed: bool,
};
