import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Contacts } from 'components/Contacts';
import {
    PROFILE_ANCHORS,
    PROFILE_MODALS,
} from 'components/ProfileView/constants';
import { useGoogleAnalyticContext } from 'contexts';
import { contactType } from 'data';
import { bool, func } from 'prop-types';
import { find, propOr } from 'ramda';
import { getFormValues, reduxForm } from 'redux-form';
import { Dictionary } from 'shared';
import {
    changeIsOpenInfoAboutFurtherChanges,
    closeContactEditModal,
    closeContactNotification,
    confirmContact,
    getContactEditModalIsOpen,
    getContacts,
    getCurrentEditContact,
    getCurrentEditContactType,
    getIsAllContactsConfirmed,
    getIsOpenPhoneInfoAboutFurtherChanges,
    getRemainingSecondsForContact,
    hasCompany,
    openContactEditModal,
    resetContactTimer,
} from 'store/modules/profile';
import { getLocationHash } from 'utils/hash';
import { scrollerTo } from 'utils/scroller';

import { Link } from '@s7/ui-kit';

import { setFooterChatWidgetOpen } from '../../store/modules/shared';

const CHANGE_WARNING_BY_TYPE = {
    [contactType.PHONE]: {
        confirmLabel: (
            <Dictionary text="com.profile.contact.notification.phone.confirm" />
        ),
        description: (
            <Dictionary text="com.profile.contact.notification.phone.description" />
        ),
        title: (
            <Dictionary text="com.profile.contact.notification.phone.title" />
        ),
    },
    [contactType.EMAIL]: {
        confirmLabel: (
            <Dictionary text="com.profile.contact.notification.email.confirm" />
        ),
        description: (
            <Dictionary text="com.profile.contact.notification.email.description" />
        ),
        title: (
            <Dictionary text="com.profile.contact.notification.email.title" />
        ),
    },
};

const getChangeWarningB2B = dispatch => (
    <>
        <Dictionary text="com.profile.contact.notification.b2b.head" html />
        <Link
            view="info"
            onClick={() => dispatch(setFooterChatWidgetOpen(true))}
        >
            <Dictionary text="com.profile.contact.notification.b2b.link" html />
        </Link>
        <Dictionary text="com.profile.contact.notification.b2b.tail" html />
    </>
);

const formConfig = {
    fields: ['value', 'secret'],
    form: 'contactInformation',
};

const formValuesSelector = getFormValues('contactInformation');

const gaActionPathByType = {
    [contactType.PHONE]: {
        onConfirm: ['contacts', 'onConfirmPhoneSmsClick'],
        onRequestCode: ['contacts', 'onConfirmPhoneClick'],
        onResend: ['contacts', 'onResendPhoneClick'],
    },
    [contactType.EMAIL]: {
        onConfirm: ['contacts', 'onConfirmEmailSmsClick'],
        onRequestCode: ['contacts', 'onConfirmEmailClick'],
        onResend: ['contacts', 'onResendEmailClick'],
    },
};

const ContactsContainer = props => {
    const { handleSubmit, submitting, change, destroy } = props;

    const dispatch = useDispatch();
    const { sendMyProfileEvent } = useGoogleAnalyticContext();

    const contacts = useSelector(getContacts);
    const currentEditContactType = useSelector(getCurrentEditContactType);
    const editContact = useSelector(getCurrentEditContact) || {};
    const formValues = useSelector(formValuesSelector) || {};
    const isAllConfirmed = useSelector(getIsAllContactsConfirmed);
    const isB2bProfile = useSelector(hasCompany);
    const isModalOpen = useSelector(getContactEditModalIsOpen);
    const isOpenPhoneInfoAboutFurtherChanges = useSelector(
        getIsOpenPhoneInfoAboutFurtherChanges,
    );
    const remainingSeconds = useSelector(state =>
        getRemainingSecondsForContact(
            state,
            (currentEditContactType || '').toLowerCase(),
        ),
    );

    const [editContactWarnings, setEditContactWarnings] = useState({
        [contactType.EMAIL]: true,
        [contactType.PHONE]: true,
    });
    const [isOpenChangeWarning, setOpenChangeWarning] = useState(false);
    const [changeWarningData, setChangeWarningData] = useState({});

    const typeInLower = propOr('', 'type', editContact).toLowerCase();
    const gaActionPath = propOr({}, editContact.type, gaActionPathByType);
    const isAllowedChange = isAllConfirmed && !isB2bProfile;

    const handleCloseEditContactWarning = useCallback(
        type => {
            setEditContactWarnings({
                ...editContactWarnings,
                [type]: false,
            });
        },
        [editContactWarnings],
    );

    const handleOpenChangeWarning = () => {
        setOpenChangeWarning(true);
    };

    const handleCloseChangeWarning = () => {
        setOpenChangeWarning(false);
    };

    const handleClosePhoneInfoAboutFurtherChanges = useCallback(() => {
        dispatch(
            changeIsOpenInfoAboutFurtherChanges({
                isOpen: false,
                type: 'phone',
            }),
        );
    }, [dispatch]);

    const handleCloseNotification = useCallback(() => {
        dispatch(closeContactNotification(typeInLower));
    }, [dispatch, typeInLower]);

    const handleCloseModal = useCallback(
        isCancel => {
            dispatch(closeContactEditModal({ isCancel, type: typeInLower }));
        },
        [dispatch, typeInLower],
    );

    const isContactVerified = useCallback(
        type => {
            const targetContact =
                find(contact => contact.type === type, contacts) || {};

            return targetContact.isVerified;
        },
        [contacts],
    );

    const handleOpenModal = useCallback(
        type => () => {
            if (isContactVerified(type) && !isAllowedChange) {
                const needConfirmType =
                    type === contactType.PHONE
                        ? contactType.EMAIL
                        : contactType.PHONE;
                const text = CHANGE_WARNING_BY_TYPE[needConfirmType];

                setChangeWarningData(
                    isB2bProfile
                        ? {
                              description: getChangeWarningB2B(dispatch),
                              onClose: handleCloseChangeWarning,
                          }
                        : {
                              confirmLabel: text.confirmLabel,
                              description: text.description,
                              onClick: () => {
                                  dispatch(
                                      openContactEditModal(needConfirmType),
                                  );
                                  handleCloseChangeWarning();
                              },
                              onClose: handleCloseChangeWarning,
                              title: text.title,
                              view: 'warning',
                          },
                );

                handleOpenChangeWarning();

                return;
            }

            dispatch(openContactEditModal(type));
        },
        [dispatch, isAllowedChange, isB2bProfile, isContactVerified],
    );

    const onSubmit = useCallback(
        ({ mode, resend }) =>
            handleSubmit(({ secret, value }) => {
                dispatch(
                    confirmContact({
                        mode,
                        resend,
                        type: currentEditContactType,
                        value:
                            currentEditContactType === contactType.PHONE
                                ? value.replace(/[\s-()]/g, '')
                                : value,
                        ...(!resend ? { secret } : {}),
                    }),
                );

                handleCloseEditContactWarning(currentEditContactType);

                if (resend) {
                    sendMyProfileEvent({
                        eventActionPath: gaActionPath.onResend,
                    });
                } else if (secret) {
                    sendMyProfileEvent({
                        eventActionPath: gaActionPath.onConfirm,
                    });
                } else {
                    sendMyProfileEvent({
                        eventActionPath: gaActionPath.onRequestCode,
                    });
                }
            }),
        [
            handleSubmit,
            dispatch,
            currentEditContactType,
            handleCloseEditContactWarning,
            sendMyProfileEvent,
            gaActionPath.onResend,
            gaActionPath.onConfirm,
            gaActionPath.onRequestCode,
        ],
    );

    const handleConfirmContact = onSubmit({ mode: 'confirm' });

    const handleChangeContact = onSubmit({ mode: 'change' });

    const handleResendCode = () => {
        onSubmit({
            mode: editContact.isVerified ? 'change' : 'confirm',
            resend: true,
        })();
        change('secret', '');
    };

    useEffect(() => {
        setEditContactWarnings(prevState => ({
            ...prevState,
            [currentEditContactType]: isContactVerified(currentEditContactType),
        }));
    }, [isContactVerified, currentEditContactType]);

    useEffect(() => {
        if (isModalOpen && !editContact.isVerified) {
            change('value', editContact.value);
        }

        if (!isModalOpen && Object.keys(formValues).length > 0) {
            destroy('contactInformation');
        }
    }, [isModalOpen]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (
            formValues.secret &&
            /[\w]{4}/.test(formValues.secret) &&
            !submitting
        ) {
            const submit = editContact.isVerified
                ? handleChangeContact
                : handleConfirmContact;

            submit();
        }
    }, [formValues.secret]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        const hash = getLocationHash();

        const isEditPhone =
            hash === PROFILE_MODALS.editPhone ||
            hash === PROFILE_MODALS.contactInformation;
        const isEditEmail = hash === PROFILE_MODALS.editEmail;

        if (!isModalOpen && (isEditPhone || isEditEmail)) {
            scrollerTo(PROFILE_ANCHORS.contacts);

            handleOpenModal(
                isEditPhone ? contactType.PHONE : contactType.EMAIL,
            )();
        }
    }, [isModalOpen, handleOpenModal]);

    const handleResetTimer = useCallback(
        type => {
            dispatch(resetContactTimer(type));
        },
        [dispatch],
    );

    const contactsData = useMemo(
        () =>
            contacts.map(contact => ({
                ...contact,
                onClick: handleOpenModal(contact.type),
            })),
        [contacts, handleOpenModal],
    );

    return (
        <Contacts
            isOpenPhoneInfoAboutFurtherChanges={
                isOpenPhoneInfoAboutFurtherChanges
            }
            changeWarningData={changeWarningData}
            currentEditContactType={currentEditContactType}
            data={contactsData}
            editContact={editContact}
            editContactWarnings={editContactWarnings}
            isModalOpen={isModalOpen}
            isOpenChangeWarning={isOpenChangeWarning}
            isValueEmpty={!formValues.value}
            remainingSeconds={remainingSeconds}
            submitting={submitting}
            onClosePhoneInfoAboutFurtherChanges={
                handleClosePhoneInfoAboutFurtherChanges
            }
            onChangeContact={handleChangeContact}
            onCloseEditContactWarning={handleCloseEditContactWarning}
            onCloseModal={handleCloseModal}
            onCloseNotification={handleCloseNotification}
            onConfirmContact={handleConfirmContact}
            onResendCode={handleResendCode}
            onResetTimer={handleResetTimer}
        />
    );
};

ContactsContainer.propTypes = {
    change: func,
    destroy: func,
    handleSubmit: func,
    submitting: bool,
};

export default reduxForm(formConfig)(ContactsContainer);
