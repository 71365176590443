import React, { useMemo } from 'react';
import { DATE_FORMATS } from 'consts/dateFormats';
import moment from 'moment';
import { object } from 'prop-types';
import { Field as FormField } from 'redux-form';
import { Column, Dictionary } from 'shared';

import { documentType } from '../../../data';
import { createFieldValidate } from '../../../utils/createValidate';
import { DateField } from '../../Fields';
import { validateDiffDate } from '../helpers';
import { fieldsPropTypes, testIdsShape } from '../propTypes';

const DD_MM_YYYY = DATE_FORMATS.DD.MM.YYYY_dot;

const getDateOfIssueValidator = ({
    isRussianPassport,
    traveler,
    birthday,
    validate,
}) =>
    createFieldValidate({
        dateCorrect: {
            errorText: <Dictionary text="com.profile.form.valid.date" />,
        },
        datePast: {
            errorText: <Dictionary text="com.profile.form.valid.datepass" />,
        },
        required: {
            errorText: <Dictionary text="com.validation.required-long" />,
        },
        ...(validate ||
            validateDiffDate({
                date: birthday,
                isRussianPassport,
                traveler,
                type: 'effective',
                ...(isRussianPassport ? { add: { years: 14 } } : {}),
            })),
    });

const getExpireDateValidator = ({ traveler, dateOfIssue, type, validate }) =>
    createFieldValidate({
        ...(type !== documentType.INTERNAL_PASSPORT
            ? {
                  required: {
                      errorText: (
                          <Dictionary text="com.validation.required-long" />
                      ),
                  },
              }
            : {}),
        dateCorrect: {
            errorText: <Dictionary text="com.profile.form.valid.date" />,
        },
        dateFuture: {
            errorText: <Dictionary text="com.profile.form.valid.future" />,
        },
        ...(validate ||
            validateDiffDate({
                date: dateOfIssue || moment().format(DD_MM_YYYY),
                traveler,
            })),
    });

const getDateOfIssueDisabledDays = (birthday, type) =>
    birthday
        ? [
              {
                  after: new Date(),
                  before:
                      type === documentType.INTERNAL_PASSPORT
                          ? moment(birthday, DD_MM_YYYY)
                                .add(14, 'years')
                                .toDate()
                          : birthday,
              },
          ]
        : [{ after: new Date() }];

const getExpirationDateDisabledDays = disabledDayFields => {
    const date = disabledDayFields.expirationDate
        ? new Date(disabledDayFields.expirationDate[0].before)
        : new Date();

    date.setHours(0, 0, 0, 0);

    return [
        {
            after: moment().add(200, 'years').toDate(),
            before: date,
        },
    ];
};

export const DateFields = React.memo(function DateFields({
    birthday,
    dateOfIssue,
    dictionary,
    disabled,
    disabledDayFields,
    isRussianBirth,
    isRussianPassport,
    onlyEffective,
    onlyExpire,
    prefix,
    testIds,
    traveler,
    type,
    ...other
}) {
    const validateEffective = useMemo(
        () =>
            getDateOfIssueValidator({
                birthday,
                isRussianPassport,
                traveler,
                validate: other.validateEffective,
            }),
        [isRussianPassport, traveler, birthday, other.validateEffective],
    );

    const validateExpire = useMemo(
        () =>
            getExpireDateValidator({
                dateOfIssue,
                traveler,
                type,
                validate: other.validateExpire,
            }),
        [type, traveler, dateOfIssue, other.validateExpire],
    );

    const dateOfIssueDisabledDay = useMemo(
        () =>
            !onlyExpire
                ? getDateOfIssueDisabledDays(birthday, type)
                : undefined,
        [birthday, onlyExpire, type],
    );

    const effectiveField = !onlyExpire && (
        <FormField
            component={DateField}
            disabled={disabled}
            disabledDays={dateOfIssueDisabledDay}
            inputProps={testIds.dateOfIssue}
            label={dictionary.t('com.profile.documents.form.effective')}
            name={`${prefix}dateOfIssue`}
            placeholder={dictionary.t('com.date.placeholder')}
            tooltipProps={testIds.dateOfIssueError}
            validate={validateEffective}
            isBirthday
        />
    );

    const expirationDateDisabledDay = useMemo(
        () =>
            !onlyEffective
                ? getExpirationDateDisabledDays(disabledDayFields)
                : undefined,
        [disabledDayFields, onlyEffective],
    );

    const expireField = !onlyEffective && (
        <FormField
            label={dictionary.t(
                type === documentType.VISA
                    ? 'com.profile.documents.form.expire-visa'
                    : 'com.profile.documents.form.expire',
            )}
            component={DateField}
            disabled={disabled}
            disableDaysWhenIsBirthday={false}
            disabledDays={expirationDateDisabledDay}
            inputProps={testIds.expireDate}
            name={`${prefix}expirationDate`}
            placeholder={dictionary.t('com.date.placeholder')}
            tooltipProps={testIds.expireDateError}
            validate={validateExpire}
            isBirthday
        />
    );

    if (onlyEffective) {
        return effectiveField;
    }

    if (onlyExpire) {
        return expireField;
    }

    return (
        <>
            <Column desktop={50} autoZIndex>
                {effectiveField}
            </Column>

            {!isRussianBirth && !isRussianPassport && (
                <Column desktop={50} autoZIndex>
                    {expireField}
                </Column>
            )}
        </>
    );
});

DateFields.propTypes = {
    ...fieldsPropTypes,
    testIds: testIdsShape,
    validateEffective: object,
    validateExpire: object,
};

DateFields.defaultProps = {
    testIds: {},
};
