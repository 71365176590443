import { documentType } from 'data';
import { any, bool, func, oneOf, shape, string } from 'prop-types';

export const notificationType = shape({
    code: string,
    description: string,
    title: string,
    type: oneOf(['error', 'success', 'warning']),
});

export const dictionaryType = shape({
    t: func.isRequired,
});

export const documentPropType = shape({
    countryOfIssue: string,
    dateOfIssue: string,
    expirationDate: string,
    id: string,
    number: string,
    status: string,
    type: oneOf([
        documentType.INTERNAL_PASSPORT,
        documentType.INTERNATIONAL_PASSPORT,
        documentType.BIRTH_CERTIFICATE,
        documentType.MILITARY_CARD,
    ]),
});

// согласно документации все параметры input и meta опциональны
// https://github.com/redux-form/redux-form/blob/e63bd4934f08238dedfeecdb8ab812d3f4bf546a/docs/api/Field.md#ondragstart--event--void-optional
// но импортируемые proptypes из redux-form для всех полей input и meta содержат isRequired
// что приводит к ошибкам https://github.com/redux-form/redux-form/issues/2535
export const fieldInputPropTypes = {
    checked: bool,
    name: string,
    onBlur: func,
    onChange: func,
    onDragStart: func,
    onDrop: func,
    onFocus: func,
    value: any,
};

export const fieldMetaPropTypes = {
    active: bool,
    asyncValidating: bool,
    autofilled: bool,
    dirty: bool,
    dispatch: func,
    error: any,
    form: string,
    invalid: bool,
    pristine: bool,
    submitFailed: bool,
    submitting: bool,
    touched: bool,
    valid: bool,
    visited: bool,
    warning: string,
};
