import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { bool, func, string } from 'prop-types';
import { reduxForm } from 'redux-form';
import { getAirlineOptions } from 'store/modules/airlines/selectors';

import { StatusMessage } from '@s7/ui-kit';

import { SECTIONS, testIds, TEXTS } from '../../../constants';
import { Contacts, Document, Loyalty, Personal } from '../../Form';
import Modal from '../Modal';

const Edit = ({
    birthday,
    error,
    form,
    handleSubmit,
    isNew,
    onClose,
    onDocScan,
    onOpen,
    onSave,
    pristine,
    section,
    submitting,
}) => {
    const handleCancel = useMemo(
        () => (isNew ? onClose : onOpen),
        [isNew, onClose, onOpen],
    );
    const airlinesOptions = useSelector(getAirlineOptions);

    const handleSave = handleSubmit(() => {
        if (pristine && !isNew) {
            handleCancel();
        }

        onSave();
    });

    return (
        <Modal
            pristine={pristine}
            submitting={submitting}
            title={TEXTS[section]}
            white
            onCancel={handleCancel}
            onSave={handleSave}
            {...(SECTIONS[section] ? testIds.mobile[section].form : {})}
        >
            {section === SECTIONS.personal && <Personal />}
            {section === SECTIONS.contacts && <Contacts />}
            {section === SECTIONS.document && (
                <Document
                    birthday={birthday}
                    form={form}
                    onDocScan={
                        form.indexOf('document_new') >= 0
                            ? onDocScan
                            : undefined
                    }
                />
            )}
            {section === SECTIONS.loyalty && (
                <Loyalty airlineOptions={airlinesOptions} />
            )}

            {error && <StatusMessage view="error">{error}</StatusMessage>}
        </Modal>
    );
};

Edit.propTypes = {
    birthday: string,
    error: string,
    form: string,
    handleSubmit: func,
    isNew: bool,
    item: string,
    onClose: func,
    onDocScan: func,
    onOpen: func,
    onSave: func,
    pristine: bool,
    section: string,
    submitting: bool,
};

export default reduxForm()(React.memo(Edit));
