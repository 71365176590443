import React, { useMemo } from 'react';
import { func, node, object, string } from 'prop-types';

import { Button } from '@s7/ui-kit';

import cx from './GroupAccount.sss';

export const iconsByType = {
    error: 'error',
    info: 'default',
    success: 'success',
    warning: 'warning',
};

export const buttonThemeByType = {
    error: 'destructive',
    info: 'b2c',
    success: 'b2c',
    warning: 'primary',
};

export const ActionButtons = React.memo(function ActionButtons({
    cancelLabel,
    cancelProps,
    className,
    confirmLabel,
    confirmProps,
    confirmTheme,
    onCancel,
    onConfirm,
    type,
}) {
    const cancelButton = useMemo(
        () => (
            <div className={cx('action_button', className)}>
                <Button
                    {...cancelProps}
                    theme="secondary"
                    block
                    onClick={onCancel}
                >
                    {cancelLabel}
                </Button>
            </div>
        ),
        [className, onCancel, cancelLabel, cancelProps],
    );

    const confirmButton = useMemo(
        () => (
            <div className={cx('action_button', className)}>
                <Button
                    {...confirmProps}
                    theme={confirmTheme || buttonThemeByType[type]}
                    block
                    onClick={onConfirm}
                >
                    {confirmLabel}
                </Button>
            </div>
        ),
        [className, onConfirm, confirmTheme, type, confirmLabel, confirmProps],
    );

    return (
        <>
            {confirmButton}
            {cancelButton}
        </>
    );
});

ActionButtons.propTypes = {
    cancelLabel: node,
    cancelProps: object,
    className: string,
    confirmLabel: node,
    confirmProps: object,
    confirmTheme: string,
    onCancel: func,
    onConfirm: func,
    type: string,
};
