import React, { useCallback, useState } from 'react';
import { func, node, object, string } from 'prop-types';
import { ClickOutside } from 'shared';

import { Text } from '@s7/ui-kit';
import { DotsVertical } from '@s7/ui-kit/icons';

import cx from './Item.sss';

import { TEXTS } from '../../../constants';

const Item = ({
    children,
    id,
    onEdit,
    onRemove,
    editProps,
    toggleProps,
    removeProps,
    ...rest
}) => {
    const [open, setOpen] = useState(false);

    const handleToggle = useCallback(() => {
        setOpen(!open);
    }, [open]);

    const handleClose = useCallback(() => {
        if (open) {
            setOpen(false);
        }
    }, [open]);

    const handleEdit = useCallback(() => {
        onEdit(id);
    }, [onEdit, id]);

    const handleRemove = useCallback(() => {
        onRemove(id);
        setOpen(false);
    }, [onRemove, id]);

    return (
        <div {...rest} className={cx('root')}>
            <div className={cx('content')}>{children}</div>
            {(onEdit || onRemove) && (
                <ClickOutside onClickOutside={handleClose}>
                    <div className={cx('actions')}>
                        <button
                            {...toggleProps}
                            className={cx('toggle', { open })}
                            type="button"
                            onClick={handleToggle}
                        >
                            <DotsVertical />
                        </button>
                        {open && (
                            <div className={cx('menu')}>
                                {onEdit && (
                                    <button
                                        {...editProps}
                                        className={cx('action')}
                                        type="button"
                                        onClick={handleEdit}
                                    >
                                        <Text size="s" noWrap>
                                            {TEXTS.edit}
                                        </Text>
                                    </button>
                                )}
                                {onRemove && (
                                    <button
                                        {...removeProps}
                                        className={cx('action')}
                                        type="button"
                                        onClick={handleRemove}
                                    >
                                        <Text size="s" noWrap>
                                            {TEXTS.delete}
                                        </Text>
                                    </button>
                                )}
                            </div>
                        )}
                    </div>
                </ClickOutside>
            )}
        </div>
    );
};

Item.propTypes = {
    children: node,
    editProps: object,
    id: string,
    onEdit: func,
    onRemove: func,
    removeProps: object,
    toggleProps: object,
};

export default React.memo(Item);
