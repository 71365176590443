import { pathOr } from 'ramda';

export const getBussines = state => pathOr({}, ['b2b', 'business'], state);
export const isBusiness = state => pathOr(false, ['b2b', 'isBusiness'], state);
export const isConfirmed = state =>
    pathOr(false, ['b2b', 'isConfirmed'], state);
export const isProcess = state => pathOr(false, ['b2b', 'isProcess'], state);
export const isSuccess = state => pathOr(false, ['b2b', 'isSuccess'], state);
export const isError = state => pathOr(false, ['b2b', 'isError'], state);
export const isAuth = state => pathOr(false, ['b2b', 'isAuth'], state);
export const getIsAllowAccess = state =>
    pathOr(false, ['b2b', 'isAllowAccess'], state);
export const getError = state => pathOr(false, ['b2b', 'error'], state);
