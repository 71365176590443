import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { arrayOf, bool, func, node, shape, string } from 'prop-types';
import { compose, find, reduce } from 'ramda';
import { getDictionary } from 'store/modules/languages';
import { fieldInputPropTypes, fieldMetaPropTypes } from 'types';
import { isObject } from 'utils';

import { Select } from '@s7/ui-kit';

import cx from './SelectField.sss';

const SelectField = ({
    clearable,
    confirmLabel,
    disabled,
    defaultOptions,
    getNoOptions,
    input = {},
    label,
    loadOptions,
    loadingMessage,
    message,
    meta = {},
    multiple,
    onClickOverlay,
    options: optionsProps = [],
    placeholder,
    searchable,
    onValueChange,
    ...rest
}) => {
    const invalid = meta.error && (meta.touched || meta.submitFailed);
    const { value, onChange } = input;
    /**
     * Из-за того что Select может использоваться в двух вариациях Default/Async
     * могут возникать проблемы с определением текущих опций.
     * Поэтому здесь сначала собираем все доступные опции, после начинаем с ними работать,
     * чтобы не получить null в первом useEffect, так как если будет null,
     * то значение в Select будет пустым даже после выбора опции.
     * optionsProps - используется только в Default
     * defaultOptions - используется только в Async
     */
    const options = defaultOptions || optionsProps;

    const isGroup = useMemo(
        () => options.some(item => !!item.options),
        [options],
    );
    const findOption = useCallback(() => {
        let res;

        if (isGroup) {
            res = compose(
                find(option => option.value === value),
                reduce((acc, item) => [...acc, ...item.options], []),
            )(options);
        } else {
            res = find(option => option.value === value)(options);
        }

        return res || null;
    }, [options, isGroup, value]);

    const [currentOption, setCurrentOption] = useState(findOption());
    const dictionary = useSelector(getDictionary);
    const [currentPlaceholder, setCurrentPlaceholder] = useState(
        placeholder || label,
    );

    useEffect(() => {
        const option = findOption();

        if (
            (currentOption &&
                (value !== currentOption.value ||
                    (option && option.label !== currentOption.label))) ||
            !currentOption
        ) {
            setCurrentOption(option);
        }
    }, [currentOption, findOption, value]);

    // temporary
    useEffect(() => {
        if (typeof placeholder === 'object' && placeholder.props) {
            setCurrentPlaceholder(dictionary.t(placeholder.props.text));
        }
    }, [dictionary, label, placeholder]);
    // temporary

    const hasManyOptions = useMemo(() => options >= 6, [options]);

    const handleChange = React.useCallback(
        option => {
            if (onValueChange) {
                onValueChange(option);
            }

            onChange(isObject(option) ? option.value : option);
            setCurrentOption(option);
        },
        [onChange, onValueChange],
    );

    const tooltip = useMemo(
        () =>
            invalid && (meta.error || message)
                ? meta.error || message
                : undefined,
        [invalid, message, meta.error],
    );

    return (
        <div className={cx('root')}>
            <Select
                {...{
                    clearable,
                    confirmLabel,
                    defaultOptions,
                    invalid,
                    isDisabled: disabled,
                    label,
                    loadingMessage,
                    loadOptions,
                    message,
                    meta,
                    multiple,
                    noOptionsMessage: getNoOptions || (() => null),
                    onChange: handleChange,
                    onClickOverlay,
                    options: optionsProps,
                    placeholder: currentPlaceholder,
                    searchable: hasManyOptions || searchable,
                    tooltip,
                    value: currentOption,
                }}
                ellipsis
                {...rest}
            />
        </div>
    );
};

const optionsShape = shape({
    label: node,
    value: string,
});

SelectField.propTypes = {
    clearable: bool,
    confirmLabel: string,
    defaultOptions: arrayOf(optionsShape),
    disabled: bool,
    getNoOptions: func,
    input: shape(fieldInputPropTypes),
    label: node,
    loadingMessage: func,
    loadOptions: func,
    message: node,
    meta: shape(fieldMetaPropTypes),
    multiple: bool,
    onChange: func,
    onClickOverlay: func,
    onValueChange: func,
    options: arrayOf(optionsShape).isRequired,
    placeholder: node,
    searchable: bool,
    value: optionsShape,
};

const SelectFieldMemo = React.memo(SelectField);

export { SelectFieldMemo as SelectField };
