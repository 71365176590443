import React from 'react';
import { PhoneField, TextField } from 'components/Fields';
import { Field as FormField } from 'redux-form';

import cx from './Form.sss';

import { testIds, TEXTS } from '../../constants';

const Contacts = () => (
    <div className={cx('root')}>
        <div className={cx('column')} {...testIds.form.contacts.phone}>
            <FormField
                autoComplete="phone"
                component={PhoneField}
                label={TEXTS.form.phone}
                maxLength={30}
                name="phone"
                isRestoreCode
            />
        </div>
        <div className={cx('column')}>
            <FormField
                autoComplete="email"
                component={TextField}
                inputProps={testIds.form.contacts.email}
                label={TEXTS.form.email}
                name="email"
                tooltipProps={testIds.form.contacts.emailError}
            />
        </div>
    </div>
);

export default React.memo(Contacts);
