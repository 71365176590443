import React, { useCallback, useMemo } from 'react';
import { DATE_FORMATS } from 'consts/dateFormats';
import { documentType } from 'data';
import moment from 'moment';
import { arrayOf, func, shape, string } from 'prop-types';

import { Link, Text, Title } from '@s7/ui-kit';

import cx from './Documents.sss';

import { SECTIONS, testIds, TEXTS } from '../../../constants';
import Wrapper from '../../../Wrapper';
import { formatNumber } from '../../helpers';
import Item from '../Item';

const DD_MM_YYYY = DATE_FORMATS.DD.MM.YYYY_dot;
const D_MMM_YYYY = DATE_FORMATS.D.MMM.YYYY;

const expirationDocTypes = {
    [documentType.INTERNAL_PASSPORT]: true,
    [documentType.BIRTH_CERTIFICATE]: true,
};

const Documents = ({ documents, onEdit, onRemove }) => {
    const handleEdit = useCallback(
        item =>
            onEdit({
                item,
                section: SECTIONS.document,
            }),
        [onEdit],
    );

    const handleRemove = useCallback(item => onRemove(item), [onRemove]);

    const addButton = useMemo(
        () => (
            <Link
                as="button"
                type="button"
                view="b2c"
                onClick={() => handleEdit('new')}
                {...testIds.mobile.document.new}
            >
                {TEXTS.newShort}
            </Link>
        ),
        [handleEdit],
    );

    return (
        <Wrapper aside={addButton} title={TEXTS.documents}>
            {documents.length ? (
                documents.map(
                    ({ expirationDate, id, number, series, type }) => {
                        const expiration = moment(expirationDate, DD_MM_YYYY);
                        const showExpireDate = !expirationDocTypes[type];

                        return (
                            <Item
                                key={id}
                                editProps={testIds.mobile.document.edit}
                                id={id}
                                removeProps={testIds.mobile.document.remove}
                                toggleProps={testIds.mobile.document.toggle}
                                onEdit={handleEdit}
                                onRemove={handleRemove}
                                {...testIds.mobile.document.item}
                            >
                                <Title
                                    classes={{ root: cx('title') }}
                                    level={4}
                                    {...testIds.mobile.document.type}
                                >
                                    {TEXTS.documentsTypes[type]}
                                </Title>
                                <Text
                                    size="l"
                                    {...testIds.mobile.document.number}
                                >
                                    {formatNumber({ number, series, type })}
                                </Text>
                                {showExpireDate && expirationDate && (
                                    <Text
                                        color={
                                            expiration.isBefore()
                                                ? 'R600'
                                                : undefined
                                        }
                                        size="l"
                                        {...testIds.mobile.document.expire}
                                    >
                                        {TEXTS.documentsExpire}:{' '}
                                        {expiration.format(D_MMM_YYYY)}
                                    </Text>
                                )}
                            </Item>
                        );
                    },
                )
            ) : (
                <Item>
                    <Text color="N100" {...testIds.mobile.document.empty}>
                        {TEXTS.emptyDocuments}
                    </Text>
                </Item>
            )}
        </Wrapper>
    );
};

Documents.propTypes = {
    documents: arrayOf(
        shape({
            expirationDate: string,
            id: string,
            number: string,
            type: string,
        }),
    ),
    onEdit: func,
    onRemove: func,
};

export default React.memo(Documents);
