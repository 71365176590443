import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dictionary, Paper, PaperContent, PaperTitle } from 'shared';
import {
    closePetAddForm,
    createPet,
    getPets,
    getPetsAddFormError,
    getPetsAddFormShow,
    getPetsError,
    showPetAddForm,
} from 'store/modules/pets';

import { Button, RoundButton, StatusMessage, Text } from '@s7/ui-kit';
import { PlusLinear } from '@s7/ui-kit/icons';

import { ModalAddForm } from './ModalAddForm';
import { PetItem } from './PetItem';
import cx from './Pets.sss';

import { testIds } from '../constants';

const DEFAULT_COUNT_SHOW_PETS = 5;

export const Pets = () => {
    const [countPets, setCountPets] = useState(DEFAULT_COUNT_SHOW_PETS);

    const showModal = useSelector(getPetsAddFormShow);
    const errorAddForm = useSelector(getPetsAddFormError);
    const error = useSelector(getPetsError);
    const pets = useSelector(getPets) || [];

    const dispatch = useDispatch();

    const handleOpenModal = () => {
        dispatch(showPetAddForm());
    };

    const handleCloseModal = () => {
        dispatch(closePetAddForm());
    };

    const handleCreatePet = values => {
        dispatch(createPet(values));
    };

    const handlePetsMore = () => {
        setCountPets(prev => prev + DEFAULT_COUNT_SHOW_PETS);
    };

    const remainingQuantity = pets.length - countPets;

    return (
        <Paper {...testIds.block}>
            <PaperTitle>
                <Dictionary text="com.profile.pets.title" />
            </PaperTitle>
            <PaperContent>
                {error ? (
                    <StatusMessage view="error">{error}</StatusMessage>
                ) : (
                    <>
                        <div className={cx('content')}>
                            {pets.length > 0 ? (
                                <>
                                    <div {...testIds.list}>
                                        {pets
                                            .slice(0, countPets)
                                            .map(
                                                ({
                                                    id,
                                                    nickname,
                                                    type,
                                                    customType,
                                                    weight,
                                                    avatar,
                                                }) => (
                                                    <PetItem
                                                        key={id}
                                                        avatar={avatar}
                                                        customType={customType}
                                                        id={id}
                                                        nickname={nickname}
                                                        type={type}
                                                        weight={weight}
                                                    />
                                                ),
                                            )}
                                    </div>
                                    {remainingQuantity > 0 && (
                                        <div className={cx('load_more')}>
                                            <Button
                                                size="large"
                                                theme="outline"
                                                block
                                                onClick={handlePetsMore}
                                                {...testIds.more}
                                            >
                                                <Dictionary text="com.action.show-more" />
                                                &nbsp;({remainingQuantity})
                                            </Button>
                                        </div>
                                    )}
                                </>
                            ) : (
                                <Text color="N900" size="l" {...testIds.empty}>
                                    <Dictionary text="com.profile.pets.empty" />
                                </Text>
                            )}
                        </div>
                        <RoundButton
                            direction="right"
                            size="xs"
                            onClick={handleOpenModal}
                            {...testIds.new}
                            text={
                                <Dictionary text="com.profile.pets.button.add" />
                            }
                        >
                            <PlusLinear />
                        </RoundButton>
                        {showModal && (
                            <ModalAddForm
                                errorMessage={errorAddForm}
                                onClose={handleCloseModal}
                                onSubmit={handleCreatePet}
                            />
                        )}
                    </>
                )}
            </PaperContent>
        </Paper>
    );
};
