import { DATE_FORMATS } from 'consts/dateFormats';
import moment from 'moment';
import { isNil, trim } from 'ramda';
import { validatePhone } from 'utils/validate';

const DD_MM_YYYY = DATE_FORMATS.DD.MM.YYYY_dot;

const isEmpty = value =>
    value === undefined ||
    value === null ||
    value === '' ||
    Number.isNaN(value);

// true — валидно. false — не валидно
// Если поле пустое и обязательное — вызывается только required, если просто пустое — не вызывается ничего

function required() {
    return value => !isEmpty(trim(!isNil(value) ? value.toString() : ''));
}

function latinName() {
    return value => /^[A-z-\s]+$/g.test(value);
}

function dateCorrect({ format = DD_MM_YYYY }) {
    return value => {
        const date = moment(value, format, true);

        if (value !== date.format(format)) {
            return false;
        }

        if (
            !date.isBetween(
                moment().subtract(99, 'years'),
                moment().add(99, 'years'),
                'day',
            )
        ) {
            return false;
        }

        return true;
    };
}

function datePast({ format = DD_MM_YYYY }) {
    return value => moment(value, format, true).isSameOrBefore(moment(), 'day');
}

function dateFuture({ format = DD_MM_YYYY }) {
    return value => {
        return moment(value, format, true).isSameOrAfter(moment(), 'day');
    };
}

function dateMin({ format = DD_MM_YYYY, date, add, unit }) {
    const min = date ? moment(date, format, true) : moment();

    if (add) {
        min.add(add, unit || 'd');
    }

    return value => moment(value, format, true).isSameOrAfter(min, 'day');
}

function dateMax({ format = DD_MM_YYYY, date, add, subtract, unit }) {
    const max = date ? moment(date, format, true) : moment();

    if (add) {
        max.add(add, unit || 'd');
    } else if (subtract) {
        max.subtract(subtract, unit || 'd');
    }

    return value => moment(value, format, true).isSameOrBefore(max, 'day');
}

function ageNotOlder({ format = DD_MM_YYYY, age }) {
    return value => age >= moment().diff(moment(value, format), 'years');
}

function ageNotYounger({ format = DD_MM_YYYY, age }) {
    return value => age <= moment().diff(moment(value, format), 'years');
}

function s7priority() {
    return value => /^\d{9}$/g.test(value);
}

function priority() {
    return value => /^[0-9A-z]{5,15}$/.test(value);
}

function minLength({ length }) {
    return value => value.length >= length;
}

function maxLength({ length }) {
    return value => value.length <= length;
}

function fixLength({ length }) {
    return value => value.length === length;
}

function numeric() {
    return value => /^\d+$/.test(value);
}

function nonCyrillic() {
    return value => !/[А-Яа-яЁё]$/.test(value);
}

function isChecked(type = true) {
    return value => value === type;
}

function phone() {
    return validatePhone;
}

function email() {
    return value =>
        /^[а-яА-ЯёЁa-zA-Z0-9.+_-]+@[а-яА-ЯёЁa-zA-Z0-9.-]+\.[а-яА-ЯёЁa-zA-Z]{2,30}$/.test(
            value,
        );
}

function password() {
    return value => /^[A-Za-z0-9_.,():-]+$/.test(value);
}

function birthCertificateSeries() {
    return value => !/\d/g.test(value) && /[A-z]{3,}/g.test(value);
}

function pets() {
    return value => !/(?![\dA-zА-яЁё\s-])./.test(value);
}

function uppercaseCharacterRequired() {
    return value => /[A-ZА-ЯЁ]/.test(value);
}

function lowercaseCharacterRequired() {
    return value => /[a-zа-яё]/.test(value);
}

function numericCharacterRequired() {
    return value => /\d/.test(value);
}

function specialCharacterRequired() {
    // eslint-disable-next-line no-useless-escape
    return value => /[!№"#$%&'()*+,\-.\/:;<>=?@\]\[{}\\^_`~|]/.test(value);
}

export default {
    ageNotOlder,
    ageNotYounger,
    birthCertificateSeries,
    dateCorrect,
    dateFuture,
    dateMax,
    dateMin,
    datePast,
    email,
    fixLength,
    isChecked,
    latinName,
    lowercaseCharacterRequired,
    maxLength,
    minLength,
    nonCyrillic,
    numeric,
    numericCharacterRequired,
    password,
    pets,
    phone,
    priority,
    required,
    s7priority,
    specialCharacterRequired,
    uppercaseCharacterRequired,
};
