import React from 'react';
import Router from 'next/router';
import { path, pathOr } from 'ramda';
import { createSelector } from 'reselect';
import { Dictionary } from 'shared';

import { Link } from '@s7/ui-kit';

import { hasArchiveFiltered, hasBookings } from '../../store/modules/travels';
import { RULE_TRIPS_INFO_NOTIFICATION } from '../../utils/allowFeature';

export const getTravelsNotifications = createSelector(
    hasArchiveFiltered,
    hasBookings,
    (archive, bookings) => {
        const notifications = [];

        if ((archive || bookings) && RULE_TRIPS_INFO_NOTIFICATION) {
            notifications.push({
                icon: 'default',
                onClick: event => {
                    const hrefValue = pathOr(
                        {},
                        ['target', 'attributes', 'href', 'value'],
                        event,
                    );
                    const tagName = path(['target', 'tagName'], event);

                    if (
                        tagName === 'A' &&
                        hrefValue === '/miles?action=claimmiles'
                    ) {
                        event.preventDefault();
                        Router.push(hrefValue);
                    }
                },
                text: (
                    <>
                        <Dictionary
                            text="com.travels.recovery.text.part1"
                            html
                        />
                        <Link
                            href="/miles?action=claimmiles"
                            target="_blank"
                            view="info"
                        >
                            <Dictionary
                                text="com.travels.recovery.text.recovery-link"
                                html
                            />
                        </Link>
                        <Dictionary
                            text="com.travels.recovery.text.part2"
                            html
                        />
                    </>
                ),
                title: <Dictionary text="com.travels.recovery.title" />,
            });
        }

        return notifications;
    },
);
