import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Element } from 'react-scroll';
import { Pets } from 'components/Pets';
import QRCodes from 'components/QRCodes';
import Travelers from 'components/Travelers';
import { PROFILE_OPERATION_TYPE } from 'consts/profile';
import Cards from 'containers/Cards';
import Contacts from 'containers/Contacts';
import Documents from 'containers/Documents';
import { GroupAccountContainer } from 'containers/GroupAccount';
import MyCompany from 'containers/MyCompany';
import Notifications from 'containers/Notifications';
import PersonalData from 'containers/PersonalData';
import { modalIds } from 'data';
import { array, arrayOf, bool, func, shape, string } from 'prop-types';
import { compose, isEmpty } from 'ramda';
import { bindActionCreators } from 'redux';
import { Dictionary, Wrapper } from 'shared';
import { getCards, isError as getIsCardsError } from 'store/modules/cards';
import {
    closeModal as closeAddressModal,
    getDeliveryType,
    getIsModalOpen,
} from 'store/modules/editAddress';
import { getIsMemberOfGroup } from 'store/modules/loyalty';
import { getHasInvitation } from 'store/modules/myCompany';
import { getIsOpenRecoverySuccessMessage } from 'store/modules/passwordRecovery';
import {
    getFullName,
    getIsAnEmployee,
    getIsShortProfile,
    getIsShortSocialProfile,
    getProfileUnavailableOperations,
} from 'store/modules/profile';
import { getHasTravelers } from 'store/modules/travelers';
import {
    RULE_GROUP_ACCOUNT,
    RULE_QRCODES,
    RULE_TRAVELER_PETS,
} from 'utils/allowFeature';
import { scrollerTo } from 'utils/scroller';
import { dictKeys, getTestIdGetter } from 'utils/testIds';

import { PROFILE_ANCHORS } from './constants';
import cx from './ProfileView.sss';

import Modal from '../Modal';
import { PasswordChangeNotification } from '../Notifications';

const getTestId = getTestIdGetter(dictKeys.PROFILE_CONTACT_INFO);

const texts = {
    passwordRecoverySuccessMessage: (
        <Dictionary text="com.authorization.password.recover.success" />
    ),
};

const modalMessages = {
    REGISTERED_LETTER: <Dictionary text="com.address.thanks.registered" />,
    SIMPLE_LETTER: <Dictionary text="com.address.thanks.simple" />,
};

const mapStateToProps = state => ({
    cards: getCards(state),
    deliveryType: getDeliveryType(state),
    hasInvitation: getHasInvitation(state),
    hasTravelers: getHasTravelers(state),
    isAddressEditModalOpen: getIsModalOpen(state),
    isAnEmployee: getIsAnEmployee(state),
    isCardsError: getIsCardsError(state),
    isFillName: getFullName(state) !== '',
    isMembershipOfGroup: getIsMemberOfGroup(state),
    isShortProfile: getIsShortProfile(state),
    isShortSocialProfile: getIsShortSocialProfile(state),
    isShowPasswordRecoveryMessage: getIsOpenRecoverySuccessMessage(state),
    unavailableOperations: getProfileUnavailableOperations(state),
});

const mapDispatchToProps = dispatch => ({
    ...bindActionCreators(
        {
            closeAddressModal,
        },
        dispatch,
    ),
});

export const cardsShape = shape({
    cardholder: string.isRequired,
    date: string,
    id: string.isRequired,
    main: bool,
    number: string.isRequired,
    type: string.isRequired,
});

class ProfileView extends PureComponent {
    static propTypes = {
        cards: arrayOf(cardsShape),
        closeAddressModal: func,
        customerValue: string,
        deliveryType: string,
        docsArchiveIsActive: bool,
        hasInvitation: bool,
        hasTravelers: bool,
        isAddressEditModalOpen: bool,
        isAnEmployee: bool,
        isCardsError: bool,
        isFetchMoreTravelers: bool,
        isFillName: bool,
        isMembershipOfGroup: bool,
        isShortProfile: bool,
        isShortSocialProfile: bool,
        isShowPasswordRecoveryMessage: bool,
        loadMoreTravelers: func,
        onChangeDocsArchiveActive: func,
        onChangeVisaArchiveActive: func,
        unavailableOperations: array,
        visaArchiveIsActive: bool,
    };

    handleScroll = () => {
        scrollerTo(PROFILE_ANCHORS.contacts, {
            offset: 0,
        });
    };

    render() {
        const {
            cards,
            deliveryType,
            hasInvitation,
            hasTravelers,
            isAddressEditModalOpen,
            isCardsError,
            isAnEmployee,
            isFillName,
            isShortProfile,
            isShortSocialProfile,
            isShowPasswordRecoveryMessage,
            isMembershipOfGroup,
            unavailableOperations,
        } = this.props;

        return (
            <div className={cx('profileView')} data-test="page:profile">
                <Wrapper>
                    <Notifications isProfile onScroll={this.handleScroll} />

                    <Element
                        id={PROFILE_ANCHORS.personal}
                        name={PROFILE_ANCHORS.personal}
                    >
                        <div
                            className={cx('group')}
                            data-test="page:profile:personal"
                        >
                            <PersonalData />
                        </div>
                    </Element>

                    {!isShortSocialProfile && (
                        <Element
                            id={PROFILE_ANCHORS.contacts}
                            name={PROFILE_ANCHORS.contacts}
                        >
                            <div
                                className={cx('group')}
                                {...getTestId('block')}
                            >
                                <Contacts />
                            </div>
                        </Element>
                    )}

                    {RULE_QRCODES && (
                        <Element
                            id={PROFILE_ANCHORS.QRCodes}
                            name={PROFILE_ANCHORS.QRCodes}
                        >
                            <div className={cx('group')}>
                                <QRCodes />
                            </div>
                        </Element>
                    )}

                    <Element
                        id={PROFILE_ANCHORS.documents}
                        name={PROFILE_ANCHORS.documents}
                    >
                        <div className={cx('group')}>
                            <Documents withArchive />
                        </div>
                    </Element>

                    <Element
                        id={PROFILE_ANCHORS.visa}
                        name={PROFILE_ANCHORS.visa}
                    >
                        <div className={cx('group')}>
                            <Documents isVisa withArchive />
                        </div>
                    </Element>
                    {RULE_GROUP_ACCOUNT &&
                        ((!unavailableOperations.includes(
                            PROFILE_OPERATION_TYPE.MEMBERSHIP,
                        ) &&
                            isFillName &&
                            !isShortProfile) ||
                            isMembershipOfGroup) && (
                            <Element
                                id={PROFILE_ANCHORS.groupAccount}
                                name={PROFILE_ANCHORS.groupAccount}
                            >
                                <div className={cx('group')}>
                                    <GroupAccountContainer />
                                </div>
                            </Element>
                        )}

                    {(!isShortProfile || hasTravelers) && (
                        <Element
                            id={PROFILE_ANCHORS.travelers}
                            name={PROFILE_ANCHORS.travelers}
                        >
                            <div className={cx('group')}>
                                <Travelers />
                            </div>
                        </Element>
                    )}

                    {RULE_TRAVELER_PETS && !isShortProfile && (
                        <Element
                            id={PROFILE_ANCHORS.pets}
                            name={PROFILE_ANCHORS.pets}
                        >
                            <div className={cx('group')}>
                                <Pets />
                            </div>
                        </Element>
                    )}

                    {(isAnEmployee || hasInvitation) && (
                        <Element
                            id={PROFILE_ANCHORS.b2b}
                            name={PROFILE_ANCHORS.b2b}
                        >
                            <div className={cx('group')}>
                                <MyCompany />
                            </div>
                        </Element>
                    )}

                    {!isCardsError && !isEmpty(cards) && (
                        <Element
                            id={PROFILE_ANCHORS.cards}
                            name={PROFILE_ANCHORS.cards}
                        >
                            <div className={cx('group')}>
                                <Cards cards={cards} isError={isCardsError} />
                            </div>
                        </Element>
                    )}

                    {isAddressEditModalOpen && (
                        <Modal
                            confirmLabel={<Dictionary text="com.address.ok" />}
                            id={modalIds.ADDRESS_EDIT}
                            widthSize="x-medium"
                            ds
                            onClose={this.props.closeAddressModal}
                            onConfirm={this.props.closeAddressModal}
                        >
                            {modalMessages[deliveryType]}
                        </Modal>
                    )}

                    {isShowPasswordRecoveryMessage && (
                        <PasswordChangeNotification
                            text={texts.passwordRecoverySuccessMessage}
                            isOpen
                        />
                    )}
                </Wrapper>
            </div>
        );
    }
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
    ProfileView,
);
