/* eslint-disable react/prop-types */
import React from 'react';

export const getMembershipOfferNotificationText = ({
    name,
    gender,
    dictionary,
    getTestId,
}) => {
    let offerText = dictionary.t(
        'com.profile.family-account.invitation.offer-female',
    );

    let genderText = dictionary.t(
        'com.profile.family-account.invitation.gender-female',
    );

    if (gender === 'MALE') {
        offerText = dictionary.t(
            'com.profile.family-account.invitation.offer-male',
        );
        genderText = dictionary.t(
            'com.profile.family-account.invitation.gender-male',
        );
    }

    return `<p><b data-test="${
        getTestId('notificationName')['data-test']
    }">${name}</b> ${offerText} ${dictionary.t(
        'com.profile.family-account.invitation.notification-part1',
    )} ${genderText} ${dictionary.t(
        'com.profile.family-account.invitation.notification-part2',
    )}`;
};

export const getMembershipOfferEmailText = ({
    name,
    dictionary,
    getTestId,
}) => {
    return (
        <>
            <p>
                <b {...getTestId('notificationName')}>{name}</b>{' '}
                {dictionary.t(
                    'com.profile.family-account.invitation.email-part1',
                )}
            </p>
            <p>
                {dictionary.t(
                    'com.profile.family-account.invitation.email-part2',
                )}
            </p>
        </>
    );
};

export const getAnalyticEventParameter = (
    membersCount,
    travelersCount,
    petsCount,
) => `members:${membersCount};companions:${travelersCount};pets:${petsCount}`;
