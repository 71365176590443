import React, { useMemo } from 'react';
import { arrayOf, bool, object, shape } from 'prop-types';
import { fieldInputPropTypes, fieldMetaPropTypes } from 'types';

import { SwitcherCanary as SwitcherCanaryBase } from '@s7/ui-kit';

export const SwitcherCanary = ({ input, meta, ...props }) => {
    const currentOptions = useMemo(
        () =>
            meta.submitting
                ? props.options.map(item => ({
                      ...item,
                      disabled: true,
                  }))
                : props.options,
        [meta.submitting, props.options],
    );

    return (
        <SwitcherCanaryBase
            {...props}
            options={currentOptions}
            value={input.value}
            onChange={input.onChange}
        />
    );
};

SwitcherCanary.propTypes = {
    disabled: bool,
    input: shape(fieldInputPropTypes),
    meta: shape(fieldMetaPropTypes),
    options: arrayOf(object),
};

SwitcherCanary.defaultProps = {
    options: [],
};
