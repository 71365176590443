import React from 'react';
import { useSelector } from 'react-redux';
import MyCompanyInfo from 'components/MyCompanyInfo';
import MyCompanyInvitation from 'containers/MyCompanyInvitation';
import { Dictionary, Paper, PaperContent, PaperTitle } from 'shared';
import { isBusiness as getIsBusiness } from 'store/modules/b2b';
import { getHasInvitation } from 'store/modules/myCompany';
import { getCompanyName } from 'store/modules/profile';

const MyCompanyContainer = () => {
    const name = useSelector(getCompanyName);
    const hasInvitation = useSelector(getHasInvitation);
    const isBusiness = useSelector(getIsBusiness);

    return (
        <Paper>
            <PaperTitle>
                <Dictionary text="com.profile.mycompany.title" />
            </PaperTitle>
            <PaperContent>
                {hasInvitation && !isBusiness && <MyCompanyInvitation />}
                {name && <MyCompanyInfo {...{ name }} />}
            </PaperContent>
        </Paper>
    );
};

export default MyCompanyContainer;
