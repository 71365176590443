import React, { useCallback, useEffect, useRef } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import {
    PROFILE_ANCHORS,
    PROFILE_ANCHORS_VALUES,
} from 'components/ProfileView/constants';
import { useApi, useForceUpdate } from 'hooks';
import { MainLayout } from 'layouts';
import Router, { withRouter } from 'next/router';
import { bool, func, object, shape, string } from 'prop-types';
import { compose, pathOr } from 'ramda';
import { Dictionary } from 'shared';
import { ProfileStatus } from 'src/entities/delete-profile/model/domain';
import { checkShowInvitation, getHasInvitation } from 'store/modules/myCompany';
import { SkeletonLoaderProfileView } from 'ui-kit/atoms/SkeletonLoader';
import { getLocationHash } from 'utils/hash';
import { scrollerTo } from 'utils/scroller';

import ProfileView from '../containers/ProfileView';
import { withAuth } from '../decorators';
import { analyticsService } from '../services/analytics';
import {
    confirm as confirmBusiness,
    getBussines,
    isBusiness as getIsBusiness,
    isConfirmed as getIsBusinessConfirmed,
    isProcess as getIsBusinessProcess,
    reset as resetBusiness,
    setIsProcess as setIsBusinessProcess,
} from '../store/modules/b2b';
import { isLoadedConfig as getIsLoadedLanguages } from '../store/modules/languages';
import { changeMembershipConfirmationCode } from '../store/modules/loyalty';
import {
    getEmail,
    getIsSocialProfile,
    getProfileId,
    getStatus,
} from '../store/modules/profile';
import { getSessionValue, removeSessionValue, removeUrlParams } from '../utils';

const BREADCRUMBS = [
    {
        href: '#',
        title: <Dictionary text="com.header.tabs.profile" />,
    },
];
const LANGUAGES = ['profile', 'registration'];

const mapStateToProps = state => ({
    business: getBussines(state),
    email: getEmail(state),
    hasCompanyInvitation: getHasInvitation(state),
    isBusiness: getIsBusiness(state),
    isBusinessConfirmed: getIsBusinessConfirmed(state),
    isBusinessProcess: getIsBusinessProcess(state),
    isLoadedLanguages: getIsLoadedLanguages(state),
    isSocialProfile: getIsSocialProfile(state),
    routerHash: pathOr('', ['router', 'location', 'hash'], state),
});

const businessShape = shape({
    company: string,
    email: string,
    invitation: string,
});

const clearHash = () => {
    const { pathname, search } = window.location;

    window.history.pushState('', document.title, `${pathname}${search}`);
};

const API_MODULES = [
    'profile',
    'travelers',
    'documents',
    'cards',
    'countries',
    'loyalty',
    'jwt',
    'airlines',
];

const ProfilePageBase = props => {
    const {
        business,
        email,
        hasCompanyInvitation,
        isBusiness,
        isBusinessConfirmed,
        isBusinessProcess,
        isLoadedLanguages,
        link,
        router,
        setLanguage,
    } = props;

    const dispatch = useDispatch();
    const profileId = useSelector(getProfileId);
    const profileStatus = useSelector(getStatus);

    const isMounted = useRef(false);
    const forceUpdate = useForceUpdate();
    const forceUpdateRef = useRef(forceUpdate);

    const {
        modules: {
            cards = {},
            countries = {},
            documents = {},
            profile = {},
            travelers = {},
        },
    } = useApi(API_MODULES, !isMounted.current);

    const isAllLoad = useCallback(
        () =>
            isLoadedLanguages('global') &&
            isLoadedLanguages('profile') &&
            profile.loaded &&
            travelers.loaded &&
            documents.loaded &&
            cards.loaded &&
            countries.loaded,
        [
            cards.loaded,
            countries.loaded,
            documents.loaded,
            isLoadedLanguages,
            profile.loaded,
            travelers.loaded,
        ],
    );

    const checkB2b = useCallback(
        ({ invitation }) => {
            if (isAllLoad() && !isBusinessProcess) {
                if (business && business.email === email) {
                    dispatch(confirmBusiness({ invitation }));
                } else {
                    dispatch(setIsBusinessProcess(true));
                    analyticsService.linkAccountError();
                }
            }
        },
        [business, dispatch, email, isAllLoad, isBusinessProcess],
    );

    const handleScroll = useCallback(() => {
        const hashMultipleProfile = [
            PROFILE_ANCHORS.documents,
            PROFILE_ANCHORS.visa,
            PROFILE_ANCHORS.family,
            PROFILE_ANCHORS.travelers,
            PROFILE_ANCHORS.cards,
        ];

        if (
            isAllLoad() &&
            hashMultipleProfile.indexOf(getLocationHash()) >= 0
        ) {
            Router.push(router.pathname);
        }
    }, [isAllLoad, router.pathname]);

    const checkHash = useCallback(() => {
        const hash = getLocationHash();

        if (isAllLoad() && hash && PROFILE_ANCHORS_VALUES.includes(hash)) {
            let scrollName = hash;

            if (
                [
                    PROFILE_ANCHORS.email,
                    PROFILE_ANCHORS.phone,
                    PROFILE_ANCHORS.contacts,
                ].includes(hash)
            ) {
                scrollName = PROFILE_ANCHORS.contacts;
            }

            if (hash === PROFILE_ANCHORS.family) {
                scrollName = PROFILE_ANCHORS.groupAccount;
            }

            if (scrollName) {
                setTimeout(() => {
                    scrollerTo(scrollName);
                });

                clearHash();
            }
        }
    }, [isAllLoad]);

    const checkMembershipConfirmation = useCallback(() => {
        if (isAllLoad() && link) {
            dispatch(changeMembershipConfirmationCode({ code: link }));
            removeUrlParams('link');

            setTimeout(() => {
                scrollerTo(PROFILE_ANCHORS.groupAccount);
            }, 700);
        }
    }, [dispatch, isAllLoad, link]);

    const checkCompanyInvitation = useCallback(() => {
        if (isAllLoad() && !link && hasCompanyInvitation) {
            setTimeout(() => {
                scrollerTo(PROFILE_ANCHORS.b2b);
            });
        }
    }, [hasCompanyInvitation, isAllLoad, link]);

    useEffect(() => {
        const handleUnload = () => {
            if (getSessionValue('b2bConfirmRegistration')) {
                removeSessionValue('b2bConfirmRegistration');
            }
        };

        const handleHash = () => {
            if (getLocationHash()) {
                forceUpdateRef.current();
            }
        };

        window.addEventListener('scroll', handleScroll);
        window.addEventListener('unload', handleUnload);
        router.events.on('hashChangeComplete', handleHash);

        if (!isMounted.current) {
            isMounted.current = true;
        }

        return () => {
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('unload', handleUnload);
            router.events.off('hashChangeComplete', handleHash);

            if (isBusinessProcess) {
                dispatch(resetBusiness());
            }
        };
    }, []); // eslint-disable-line

    useEffect(() => {
        if (profileId) {
            dispatch(checkShowInvitation({ profileId }));
        }
    }, [dispatch, profileId]);

    useEffect(() => {
        if (setLanguage) {
            setLanguage();
        }

        if (isBusiness && !isBusinessConfirmed) {
            checkB2b(business);
        }

        checkHash();
        checkMembershipConfirmation();
        checkCompanyInvitation();
        forceUpdateRef.current = forceUpdate;
    });

    if (profileStatus === ProfileStatus.PendingDeletion) {
        return <SkeletonLoaderProfileView />;
    }

    return <ProfileView />;
};

ProfilePageBase.getInitialProps = ({ req }) => {
    const { link } = req ? req.query : {};

    return { link };
};

ProfilePageBase.propTypes = {
    business: businessShape,
    changeMembershipConfirmationCode: func,
    confirmBusiness: func,
    email: string,
    hasCompanyInvitation: bool,
    isBusiness: bool,
    isBusinessConfirmed: bool,
    isBusinessProcess: bool,
    isLoadedLanguages: func,
    isSocialProfile: bool,
    link: string,
    resetBusiness: func,
    router: object, // eslint-disable-line react/forbid-prop-types
    setIsBusinessProcess: func,
    setLanguage: func,
};

const ProfilePage = compose(
    withRouter,
    withAuth,
    connect(mapStateToProps),
)(ProfilePageBase);

ProfilePage.getLayout = page => (
    <MainLayout
        breadcrumbs={BREADCRUMBS}
        languages={LANGUAGES}
        title="com.title.profile"
        profileHeader
    >
        {page}
    </MainLayout>
);

export default ProfilePage;
