import React, { Component } from 'react';

import { SessionService } from '../services';

const getDisplayName = Cmp => Cmp.displayName || Cmp.name || 'Component';

// this code is unused now in favor of hooks/useUnloggedOrShortProfilesOnly
export default function withOutAuth(WrappedComponent) {
    return class extends Component {
        static displayName = `withOutAuth${getDisplayName(WrappedComponent)}`;

        render() {
            if (SessionService.isAuth()) {
                SessionService.logout();
            }

            return <WrappedComponent {...this.props} />;
        }
    };
}
