import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { string } from 'prop-types';
import { Dictionary } from 'shared';
import {
    deleteQRCode,
    getQRCodeById,
    getQRCodeStatusById,
    saveQRCode,
    statuses,
} from 'store/modules/qrcodes';

import QRCodeItem from './QRCodeItem';

import Preview from '../Preview';

const QRCodeItemContainer = ({ id }) => {
    const dispatch = useDispatch();
    const {
        subtitle = <Dictionary text="com.profile.qrcodes.subtitle" />,
        title = <Dictionary text="com.profile.qrcodes.title" />,
        value,
    } = useSelector(state => getQRCodeById(state, id));
    const status = useSelector(state => getQRCodeStatusById(state, id));
    const [isPreviewOpen, setIsPreviewOpen] = useState(false);
    const isLoading = status === statuses.loading;
    const isDeleting = status === statuses.deleting;

    const handleClosePreview = useCallback(() => {
        setIsPreviewOpen(false);
    }, [setIsPreviewOpen]);

    const handleOpenPreview = useCallback(() => {
        setIsPreviewOpen(true);
    }, [setIsPreviewOpen]);

    const handleSave = file => {
        dispatch(saveQRCode({ file, id }));
    };

    const handleRemove = () => {
        dispatch(deleteQRCode({ id }));
    };

    return (
        <>
            <QRCodeItem
                deleting={isDeleting}
                loading={isLoading}
                subtitle={subtitle}
                title={title}
                onShow={handleOpenPreview}
            />
            {isPreviewOpen && (
                <Preview
                    loading={isLoading}
                    title={title}
                    value={value}
                    onClose={handleClosePreview}
                    onRemove={handleRemove}
                    onSave={handleSave}
                />
            )}
        </>
    );
};

QRCodeItemContainer.propTypes = {
    id: string,
};

export default QRCodeItemContainer;
