import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useApi } from 'hooks';
import { getQRCodesIds, saveQRCode } from 'store/modules/qrcodes';
import { SkeletonLoader } from 'ui-kit/atoms/SkeletonLoader';

import QRCodes from './QRCodes';

const API_MODULES = ['qrcodes'];

const QRCodeItemSkeletonData = [
    {
        height: 24,
        type: 'circle',
        width: 24,
    },
    {
        items: [{ height: 24 }],
        type: 'items',
        width: 'calc(100% - 40px)',
    },
];
const desktop = {
    items: [
        {
            items: QRCodeItemSkeletonData,
            type: 'flex',
        },
        {
            items: QRCodeItemSkeletonData,
            type: 'flex',
        },
    ],
};

const QRCodesContainer = () => {
    const dispatch = useDispatch();
    const {
        modules: {
            qrcodes: { loading, error },
        },
    } = useApi(API_MODULES);
    const ids = useSelector(getQRCodesIds);

    const handleSave = file => {
        dispatch(saveQRCode({ file, id: new Date().getTime() }));
    };

    return loading ? (
        <SkeletonLoader desktop={desktop} mobile={desktop} title={200} />
    ) : (
        <QRCodes error={error} ids={ids} onSave={handleSave} />
    );
};

QRCodesContainer.propTypes = {};

export default QRCodesContainer;
