import React from 'react';
import { func } from 'prop-types';

import modalImage from './images/modal.png';
import image from './images/SeparateSeat.png';
import imageMobile from './images/SeparateSeatMobile.png';

import { Card } from '../Card';

export const CardSeparateSeat = ({ onClick }) => {
    return (
        <Card
            conditions={[
                'com.profile.pets.page.card.separate-seat.condition1',
                'com.profile.pets.page.card.separate-seat.condition2',
                'com.profile.pets.page.card.separate-seat.condition3',
                'com.profile.pets.page.card.separate-seat.condition4',
                'com.profile.pets.page.card.separate-seat.condition5',
                'com.profile.pets.page.card.separate-seat.condition6',
                'com.profile.pets.page.card.separate-seat.condition7',
            ]}
            cardType="separateSeat"
            image={image}
            imageMobile={imageMobile}
            modalImage={modalImage}
            size="com.profile.pets.page.card.separate-seat.size"
            subText="com.profile.pets.page.card.carrier"
            title="com.profile.pets.page.card.separate-seat.title"
            onClick={onClick}
        />
    );
};

CardSeparateSeat.propTypes = {
    onClick: func.isRequired,
};
