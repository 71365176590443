import React, { memo } from 'react';
import { bool, string } from 'prop-types';

import cx from './ProgressBar.sss';

import Loader from '../../atoms/Loader';

const ProgressBar = ({ size, theme, isOpen }) => (
    <div className={cx('overlay', { visible: isOpen })}>
        <div className={cx('loader')}>
            <Loader {...{ size, theme }} />
        </div>
    </div>
);

ProgressBar.propTypes = {
    isOpen: bool,
    size: string,
    theme: string,
};

ProgressBar.defaultProps = {
    isOpen: false,
    theme: 'green',
};

export default memo(ProgressBar);
