import React from 'react';
import { useWindowSize } from 'hooks';
import { bool, func, node } from 'prop-types';
import { Dictionary } from 'shared';

import { Link, Text, Title } from '@s7/ui-kit';
import { EyeOpened, Qr } from '@s7/ui-kit/icons';

import cx from './QRCodeItem.sss';

const QRCodeItem = ({ deleting, loading, onShow, subtitle, title }) => {
    const { isMobile } = useWindowSize();

    return (
        <div
            className={cx('root')}
            {...(isMobile && !loading && !deleting
                ? { onClick: onShow, role: 'button' }
                : {})}
        >
            <div className={cx('icon')}>
                <Qr />
            </div>
            <div className={cx('caption')}>
                {isMobile ? (
                    <Title as="span" level={4}>
                        {title}
                    </Title>
                ) : (
                    <Text as="span">{title}</Text>
                )}
                {isMobile && (
                    <div className={cx('subtitle')}>
                        {loading || deleting ? (
                            <Link
                                as="button"
                                size="s"
                                type="button"
                                view={loading ? 'b2c' : 'destructive'}
                                loading
                            >
                                <Dictionary
                                    text={
                                        loading
                                            ? 'com.profile.qrcodes.loading'
                                            : 'com.profile.qrcodes.deleting'
                                    }
                                />
                            </Link>
                        ) : (
                            <Text as="span" color="N100" size="s">
                                {subtitle}
                            </Text>
                        )}
                    </div>
                )}
            </div>
            {!isMobile && (
                <div className={cx('actions')}>
                    <div className={cx('action')}>
                        <Link
                            as="button"
                            icon={<EyeOpened height={16} width={16} />}
                            loading={loading || deleting}
                            type="button"
                            view={deleting ? 'destructive' : 'b2c'}
                            onClick={onShow}
                        >
                            {loading || deleting ? (
                                <Dictionary
                                    text={
                                        loading
                                            ? 'com.profile.qrcodes.loading'
                                            : 'com.profile.qrcodes.deleting'
                                    }
                                />
                            ) : (
                                <Dictionary text="com.profile.qrcodes.show" />
                            )}
                        </Link>
                    </div>
                </div>
            )}
        </div>
    );
};

QRCodeItem.propTypes = {
    deleting: bool,
    loading: bool,
    onShow: func,
    subtitle: node,
    title: node,
};

export default QRCodeItem;
