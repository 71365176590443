import React from 'react';
import { arrayOf, func, node, string } from 'prop-types';
import { Dictionary } from 'shared';

import { Button, Text, Title } from '@s7/ui-kit';
import { useMediaQuery } from '@s7/ui-kit/hooks';
import { ChevronNormalRight } from '@s7/ui-kit/icons';

import cx from './Card.sss';

import { testIds } from '../../../constants';

export const Card = ({
    cardType,
    image,
    imageMobile,
    size,
    subText,
    title,
    onClick,
    conditions = [],
    modalImage,
}) => {
    const breakpoints = useMediaQuery('xs');
    const isMobile = breakpoints.xs;

    const handleClickButton = () => {
        onClick({
            cardType,
            conditions,
            modalImage,
            title,
        });
    };

    return (
        <div className={cx('root')} {...testIds.transportation.item.block}>
            <div className={cx('image')}>
                <img
                    alt="background card"
                    src={isMobile ? imageMobile : image}
                />
            </div>
            <div className={cx('content')}>
                <div className={cx('title')}>
                    <Title
                        color="W100"
                        level={4}
                        {...testIds.transportation.item.title}
                    >
                        <Dictionary text={title} />
                    </Title>
                </div>
                {size && (
                    <Text
                        color="W100"
                        size={isMobile ? 's' : 'm'}
                        weight="semibold"
                        {...testIds.transportation.item.size}
                    >
                        <Dictionary text={size} />
                    </Text>
                )}
                {subText && (
                    <Text
                        color="W100"
                        size="s"
                        {...testIds.transportation.item.text}
                    >
                        <Dictionary text={subText} />
                    </Text>
                )}
                <div className={cx('action')}>
                    <Button
                        color="B600"
                        size="small"
                        theme="white"
                        onClick={handleClickButton}
                        {...testIds.transportation.item.button}
                    >
                        <Text color="B600" weight="semibold">
                            <Dictionary text="com.profile.pets.page.card.button" />
                        </Text>
                        <ChevronNormalRight />
                    </Button>
                </div>
            </div>
        </div>
    );
};

Card.propTypes = {
    conditions: arrayOf(node),
    image: node.isRequired,
    imageMobile: node.isRequired,
    modalImage: string.isRequired,
    onClick: func.isRequired,
    size: string,
    subText: string,
    title: string.isRequired,
};
