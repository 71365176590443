import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAnalyticsContext } from 'contexts';
import { documentType } from 'data';
import { func, string } from 'prop-types';
import { propOr } from 'ramda';
import { change } from 'redux-form';
import {
    closeTraveler,
    editTraveler,
    getTraveler,
    getTravelerStatusRemove,
    getTravelerStatusView,
    openTraveler,
    removeTraveler,
    removeTravelerCancel,
    removeTravelerConfirmation,
    removeTravelerContacts,
    removeTravelerDocument,
    removeTravelerLoyalty,
    saveTraveler,
} from 'store/modules/travelers';

import { getFormConfig } from './helpers';
import Traveler from './Traveler';

import { SECTIONS } from '../constants';

const TravelerContainer = ({ id, onNewClose }) => {
    const dispatch = useDispatch();
    const traveler = useSelector(state => getTraveler(id, state));
    const statusView = useSelector(state => getTravelerStatusView(id, state));
    const statusRemove = useSelector(state =>
        getTravelerStatusRemove(id, state),
    );
    const { sendEvent } = useAnalyticsContext();

    const isNew = !!onNewClose;
    const { name, item, section } = statusView;

    const formConfig = useMemo(
        () =>
            getFormConfig({
                id,
                item,
                name,
                section,
                traveler,
            }),
        [traveler, item, name, section, id],
    );

    useEffect(() => {
        if (isNew) {
            dispatch(
                editTraveler({
                    id,
                    section: SECTIONS.personal,
                }),
            );
        }
    }, [dispatch, isNew, id]);

    const handleEdit = useCallback(
        data => {
            dispatch(
                editTraveler({
                    id,
                    ...data,
                }),
            );

            sendEvent({
                event: 'edit_info',
                item_id: 'item_personal_data',
                result: 'initial_click',
                step: 'companions',
            });
        },
        [dispatch, id, sendEvent],
    );

    const handleOpen = useCallback(() => {
        dispatch(openTraveler({ id }));
    }, [dispatch, id]);

    const handleClose = useCallback(() => {
        dispatch(closeTraveler({ id }));

        if (onNewClose) {
            onNewClose();
        }
    }, [dispatch, id, onNewClose]);

    const handleRemove = useCallback(() => {
        sendEvent({
            event_name: 'click_submit',
            item_id: 'item_companions',
            result: 'go_to_delete',
            step: 'companions',
        });
        dispatch(removeTraveler({ id }));
    }, [sendEvent, dispatch, id]);

    const handleRemoveConfirmation = useCallback(() => {
        dispatch(removeTravelerConfirmation({ id }));
    }, [dispatch, id]);

    const handleRemoveCancel = useCallback(() => {
        dispatch(removeTravelerCancel({ id }));
    }, [dispatch, id]);

    const handleContactsRemove = useCallback(() => {
        dispatch(removeTravelerContacts({ id }));
        sendEvent({
            event: 'edit_info',
            item_id: 'item_contacts',
            result: 'initial_click',
            step: 'companions',
        });
    }, [sendEvent, dispatch, id]);

    const handleDocumentRemove = useCallback(
        document => {
            sendEvent({
                event: 'edit_info',
                item_id: 'item_documents',
                result: 'initial_click',
                step: 'companions',
            });
            dispatch(removeTravelerDocument({ document, id }));
        },
        [sendEvent, dispatch, id],
    );

    const handleLoyaltyRemove = useCallback(
        loyalty => {
            sendEvent({
                event: 'edit_info',
                item_id: 'item_loyalty_card',
                result: 'initial_click',
                step: 'companions',
            });
            dispatch(removeTravelerLoyalty({ id, loyalty }));
        },
        [sendEvent, dispatch, id],
    );

    const handleSave = useCallback(
        removeDocuments => {
            dispatch(
                saveTraveler({
                    form: formConfig.form,
                    id,
                    item,
                    name,
                    removeDocuments,
                    section,
                }),
            );
        },
        [dispatch, id, item, name, section, formConfig.form],
    );

    const handleDocScan = useCallback(
        ({ data, prefix }) => {
            dispatch(
                change(
                    formConfig.form,
                    `${prefix}type`,
                    propOr(documentType.OTHER, 'type', data),
                ),
            );
            dispatch(
                change(
                    formConfig.form,
                    `${prefix}countryOfIssue`,
                    propOr('', 'country', data),
                ),
            );
            dispatch(
                change(
                    formConfig.form,
                    `${prefix}placeOfIssue`,
                    propOr('', 'placeOfIssue', data) ||
                        propOr('', 'destinationCountry', data),
                ),
            );
            dispatch(
                change(
                    formConfig.form,
                    `${prefix}number`,
                    propOr('', 'seriesNumber', data),
                ),
            );
            dispatch(
                change(
                    formConfig.form,
                    `${prefix}expirationDate`,
                    propOr('', 'expireDate', data),
                ),
            );
            dispatch(
                change(
                    formConfig.form,
                    `${prefix}dateOfIssue`,
                    propOr('', 'dateOfIssue', data),
                ),
            );
            dispatch(
                change(
                    formConfig.form,
                    `${prefix}startDate`,
                    propOr('', 'issuedOn', data),
                ),
            );

            sendEvent({
                event: 'edit_info',
                item_id: 'item_documents',
                result: 'upload_scan',
                step: 'companions',
            });
        },

        [dispatch, formConfig.form, sendEvent],
    );

    return (
        <Traveler
            isNew={isNew}
            statusRemove={statusRemove}
            statusView={statusView}
            onClose={handleClose}
            onContactsRemove={handleContactsRemove}
            onDocScan={handleDocScan}
            onDocumentRemove={handleDocumentRemove}
            onEdit={handleEdit}
            onLoyaltyRemove={handleLoyaltyRemove}
            onOpen={handleOpen}
            onRemove={handleRemove}
            onRemoveCancel={handleRemoveCancel}
            onRemoveConfirmation={handleRemoveConfirmation}
            onSave={handleSave}
            {...formConfig}
            {...traveler}
        />
    );
};

TravelerContainer.propTypes = {
    id: string,
    onNewClose: func,
};

export default React.memo(TravelerContainer);
