import React, { useMemo } from 'react';
import { ACTION_STATUS_EDITING, ACTION_STATUS_OPENED } from 'consts';
import { useWindowSize } from 'hooks';
import { shape, string } from 'prop-types';

import Edit from './Edit';
import Mobile from './Mobile';
import MobileEdit from './Mobile/Edit';
import View from './View/ViewContainer';

const Traveler = ({ statusView: { item, name, section }, ...rest }) => {
    const { isDesktop } = useWindowSize();

    const statusView = useMemo(
        () => (isDesktop && name === ACTION_STATUS_OPENED ? '' : name),
        [isDesktop, name],
    );

    return (
        <View isDesktop={isDesktop} statusView={statusView} {...rest}>
            {isDesktop ? (
                name === ACTION_STATUS_EDITING && <Edit {...rest} />
            ) : (
                <>
                    {(name === ACTION_STATUS_OPENED || !section) && (
                        <Mobile {...rest} />
                    )}
                    {name === ACTION_STATUS_EDITING && section && (
                        <MobileEdit item={item} section={section} {...rest} />
                    )}
                </>
            )}
        </View>
    );
};

Traveler.propTypes = {
    statusView: shape({
        item: string,
        name: string,
        type: string,
    }),
};

export default React.memo(Traveler);
