import React from 'react';
import { node, shape } from 'prop-types';
import { fieldInputPropTypes, fieldMetaPropTypes } from 'types';

import { TextArea as TextAreaBase } from '@s7/ui-kit';

export const TextAreaField = React.memo(function TextAreaField({
    input,
    meta,
    message,
    ...rest
}) {
    const invalid = meta.error && (meta.touched || meta.submitFailed);

    return (
        <TextAreaBase
            {...rest}
            {...input}
            {...{
                invalid,
                tooltip: invalid ? meta.error || message : undefined,
            }}
        />
    );
});

TextAreaField.propTypes = {
    input: shape(fieldInputPropTypes),
    message: node,
    meta: shape(fieldMetaPropTypes),
};

TextAreaField.defaultProps = {
    input: {},
    meta: {},
};
