import React, { PureComponent } from 'react';
import { array, bool, object, oneOf } from 'prop-types';

import { Text } from '@s7/ui-kit';

import cx from './Sex.sss';

import Icon from '../../../components/Icon';

export default class Sex extends PureComponent {
    static propTypes = {
        currentColor: bool,
        sex: oneOf(['male', 'female', '']),
        size: oneOf(['small']),
        testId: object,
        text: array,
    };

    static defaultProps = {
        sex: 'male',
        testId: {},
        text: ['мужской', 'женский'],
    };

    render() {
        const { sex, size, text, currentColor, testId } = this.props;

        const info = sex === 'male' ? text[0] : text[1];

        return (
            <div
                className={cx('Sex', {
                    [`Sex_size_${size}`]: size,
                    currentColor,
                })}
            >
                <span className={cx('icon')}>
                    <Icon
                        color={currentColor ? 'currentColor' : undefined}
                        icon={sex}
                    />
                </span>
                <Text as="div" color="inherit" size="l" {...testId}>
                    {info}
                </Text>
            </div>
        );
    }
}
