import React, { useMemo } from 'react';
import { UnitNounByLength } from 'components/UnitNounByLength';
import { Field as FormField } from 'redux-form';
import { Column, Dictionary } from 'shared';

import { DateFields } from './DateFields';

import { createFieldValidate } from '../../../utils/createValidate';
import { CheckboxField, DateField, SelectField, TextField } from '../../Fields';
import {
    normalizeCheckbox,
    normalizePlace,
    normalizeSeries,
    validateDiffDate,
} from '../helpers';
import { fieldsPropTypes } from '../propTypes';

const validateNumber = createFieldValidate({
    required: {
        errorText: <Dictionary text="com.validation.required-long" />,
    },
});

const validateCountryOfIssue = createFieldValidate({
    required: {
        errorText: <Dictionary text="com.validation.required-long" />,
    },
});

const validatePlaceOfIssue = createFieldValidate({
    latinName: {
        errorText: <Dictionary text="com.profile.form.valid.latin" />,
    },
    required: {
        errorText: <Dictionary text="com.validation.required-long" />,
    },
});

const validateDestinationCountry = createFieldValidate({
    required: {
        errorText: <Dictionary text="com.validation.required-long" />,
    },
});

const validateBirthCountry = createFieldValidate({
    required: {
        errorText: <Dictionary text="com.validation.required-long" />,
    },
});

const validatePlaceOfBirth = createFieldValidate({
    latinName: {
        errorText: <Dictionary text="com.profile.form.valid.latin" />,
    },
    minLength: {
        errorText: (
            <UnitNounByLength
                dictionaryKey="com.validation.min-length"
                length={2}
            />
        ),
        length: 2,
    },
    required: {
        errorText: <Dictionary text="com.validation.required-long" />,
    },
});

const getDateOfIssueValidator = ({ birthday, traveler }) =>
    createFieldValidate({
        dateCorrect: {
            errorText: <Dictionary text="com.profile.form.valid.date" />,
        },
        datePast: {
            errorText: <Dictionary text="com.profile.form.valid.datepass" />,
        },
        required: {
            errorText: <Dictionary text="com.validation.required-long" />,
        },
        ...validateDiffDate({
            date: birthday,
            traveler,
            type: 'effective',
            visa: true,
        }),
    });

const getStartDateValidator = ({ dateOfIssue, traveler }) =>
    createFieldValidate({
        dateCorrect: {
            errorText: <Dictionary text="com.profile.form.valid.date" />,
        },
        required: {
            errorText: <Dictionary text="com.validation.required-long" />,
        },
        ...validateDiffDate({
            date: dateOfIssue,
            traveler,
            type: 'issued',
            visa: true,
        }),
    });

const noCountriesMessage = () => <Dictionary text="com.global.nocountry" />;

export const VisaFields = React.memo(function VisaFields(props) {
    const {
        birthday,
        countries,
        dateOfIssue,
        dictionary,
        disabled,
        filterCountry,
        getTestId,
        isRussianLocale,
        prefix,
        startDate,
        traveler,
    } = props;

    const validateExpire = useMemo(
        () => ({
            ...validateDiffDate({
                date: startDate,
                traveler,
                visa: true,
            }),
        }),
        [startDate, traveler],
    );

    const validateDateOfIssue = useMemo(
        () => getDateOfIssueValidator({ birthday, traveler }),
        [birthday, traveler],
    );

    const validateStartDate = useMemo(
        () => getStartDateValidator({ dateOfIssue, traveler }),
        [dateOfIssue, traveler],
    );

    return (
        <>
            <Column desktop={50}>
                <FormField
                    component={TextField}
                    disabled={disabled}
                    inputProps={getTestId('visaNumber')}
                    label={dictionary.t('com.profile.documents.form.visa')}
                    maxLength={15}
                    name={`${prefix}number`}
                    normalize={normalizeSeries}
                    tooltipProps={getTestId('visaNumber', { error: true })}
                    validate={validateNumber}
                />
            </Column>

            <Column desktop={50} autoZIndex {...getTestId('visaListCountry')}>
                <FormField
                    component={SelectField}
                    controlProps={getTestId('visaCountryOfIssue')}
                    disabled={disabled}
                    getNoOptions={noCountriesMessage}
                    label={dictionary.t('com.profile.documents.form.country')}
                    name={`${prefix}countryOfIssue`}
                    namespace={getTestId('countryOfIssue')['data-test']}
                    options={countries}
                    validate={validateCountryOfIssue}
                    visible={filterCountry}
                    searchable
                />
            </Column>

            <Column desktop={50}>
                <FormField
                    tooltipProps={getTestId('visaPlaceOfIssue', {
                        error: true,
                    })}
                    component={TextField}
                    disabled={disabled}
                    inputProps={getTestId('visaPlaceOfIssue')}
                    label={dictionary.t('com.profile.documents.form.locality')}
                    name={`${prefix}placeOfIssue`}
                    normalize={normalizePlace}
                    validate={validatePlaceOfIssue}
                />
            </Column>

            <Column desktop={50} autoZIndex>
                <FormField
                    component={DateField}
                    disabled={disabled}
                    disabledDays={[{ after: new Date() }]}
                    inputProps={getTestId('visaDateOfIssue')}
                    label={dictionary.t('com.profile.documents.form.effective')}
                    name={`${prefix}dateOfIssue`}
                    placeholder={dictionary.t('com.date.placeholder')}
                    tooltipProps={getTestId('visaDateOfIssue', { error: true })}
                    validate={validateDateOfIssue}
                    isBirthday
                />
            </Column>

            <Column desktop={50} autoZIndex>
                <FormField
                    component={DateField}
                    disabled={disabled}
                    inputProps={getTestId('visaStartDate')}
                    label={dictionary.t('com.profile.documents.form.issued')}
                    name={`${prefix}startDate`}
                    placeholder={dictionary.t('com.date.placeholder')}
                    tooltipProps={getTestId('visaStartDate', { error: true })}
                    validate={validateStartDate}
                    isBirthday
                    withoutDisabledDays
                />
            </Column>

            <Column desktop={50} autoZIndex>
                <DateFields
                    {...props}
                    {...{ validateExpire }}
                    testIds={{
                        expireDate: getTestId('visaEndDate'),
                        expireDateError: getTestId('visaEndDate', {
                            error: true,
                        }),
                    }}
                    onlyExpire
                />
            </Column>

            <Column
                desktop={50}
                autoZIndex
                {...getTestId('visaDestinationCountryList')}
            >
                <FormField
                    label={dictionary.t(
                        'com.profile.documents.form.destinationcountry',
                    )}
                    component={SelectField}
                    controlProps={getTestId('visaDestinationCountry')}
                    disabled={disabled}
                    getNoOptions={noCountriesMessage}
                    name={`${prefix}destinationCountry`}
                    namespace={getTestId('destinationCountry')['data-test']}
                    options={countries}
                    validate={validateDestinationCountry}
                    visible={filterCountry}
                    isAutocomplete
                    searchable
                />
            </Column>

            <Column desktop={50} autoZIndex {...getTestId('birthCountryList')}>
                <FormField
                    label={dictionary.t(
                        'com.profile.documents.form.birthcountry',
                    )}
                    component={SelectField}
                    controlProps={getTestId('visaBirthCountry')}
                    disabled={disabled}
                    getNoOptions={noCountriesMessage}
                    name={`${prefix}birthCountry`}
                    namespace={getTestId('birthCountry')['data-test']}
                    options={countries}
                    validate={validateBirthCountry}
                    visible={filterCountry}
                    isAutocomplete
                    searchable
                />
            </Column>

            <Column desktop={50}>
                <FormField
                    label={dictionary.t(
                        'com.profile.documents.form.placeofbirth',
                    )}
                    tooltipProps={getTestId('visaPlaceOfBirth', {
                        error: true,
                    })}
                    component={TextField}
                    disabled={disabled}
                    inputProps={getTestId('visaPlaceOfBirth')}
                    maxLength={30}
                    name={`${prefix}placeOfBirth`}
                    normalize={normalizePlace}
                    validate={validatePlaceOfBirth}
                />
            </Column>

            {isRussianLocale && (
                <Column>
                    <FormField
                        component={CheckboxField}
                        disabled={disabled}
                        format={Boolean}
                        name={`${prefix}multi`}
                        normalize={normalizeCheckbox}
                        type="checkbox"
                        {...getTestId('visaMulti')}
                    >
                        {dictionary.t('com.profile.documents.form.schengen')}
                    </FormField>
                </Column>
            )}
        </>
    );
});

VisaFields.propTypes = fieldsPropTypes;
