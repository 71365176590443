import React from 'react';
import { array } from 'prop-types';
import { isEmpty } from 'ramda';

import { Notification } from './Notification';
import cx from './Notifications.sss';

const Notifications = React.memo(function Notifications(props) {
    const { notifications } = props;

    if (isEmpty(notifications)) {
        return null;
    }

    return (
        <div className={cx('notifications')}>
            {notifications.map((localProps, key) => {
                return (
                    <div className={cx('notification')} {...{ key: key + 1 }}>
                        <Notification {...localProps} />
                    </div>
                );
            })}
        </div>
    );
});

Notifications.propTypes = {
    notifications: array,
};

export default Notifications;
