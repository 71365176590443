import React from 'react';
import { Dictionary } from 'shared';

import createFieldValidate from '../createFieldValidate';

export const validateRequired = createFieldValidate({
    required: true,
});

export const validateRequiredLong = createFieldValidate({
    required: {
        errorText: <Dictionary text="com.validation.required-long" />,
    },
});
