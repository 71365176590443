import React from 'react';
import { number, string } from 'prop-types';
import { Dictionary } from 'shared';

import { noun } from '../../utils/format';

export const UnitNounByLength = ({ length, unit = 'char', dictionaryKey }) => (
    <Dictionary
        transform={(text, dictionary) =>
            text.replace(
                /%length%/,
                `${length} ${noun(length, [
                    dictionary.t(`com.units.${unit}.one`),
                    dictionary.t(`com.units.${unit}.once`),
                    dictionary.t(`com.units.${unit}.mult`),
                ])}`,
            )
        }
        text={dictionaryKey}
    />
);

UnitNounByLength.propTypes = {
    dictionaryKey: string,
    length: number,
    unit: string,
};
