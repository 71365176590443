import React from 'react';
import { UnitNounByLength } from 'components/UnitNounByLength';

import createFieldValidate from '../createFieldValidate';

export const validateMinLength2 = createFieldValidate({
    minLength: {
        errorText: (
            <UnitNounByLength
                dictionaryKey="com.validation.min-length"
                length={2}
            />
        ),
        length: 2,
    },
});

export const validateMinLength3 = createFieldValidate({
    minLength: {
        errorText: (
            <UnitNounByLength
                dictionaryKey="com.validation.min-length"
                length={3}
            />
        ),
        length: 3,
    },
});

export const validateMinLength6 = createFieldValidate({
    minLength: {
        length: 6,
    },
});

export const validateMaxLength20 = createFieldValidate({
    maxLength: {
        length: 20,
    },
});

export const validateMaxLength30 = createFieldValidate({
    maxLength: {
        length: 30,
    },
});
