import React from 'react';
import { useSelector } from 'react-redux';
import PageLoader from 'components/PageLoader';
import { Loadable } from 'decorators';
import { string } from 'prop-types';
import { compose } from 'ramda';
import { Dictionary } from 'shared';
import {
    isError as isErrorLanguages,
    isLoading as isLoadingLanguages,
} from 'store/modules/languages';
import { getIsPetsLoading, getPetById } from 'store/modules/pets';
import FailedToLoad from 'ui-kit/atoms/FailedToLoad';

import { StatusMessage } from '@s7/ui-kit';

import { PetPage } from './PetPage';
import cx from './PetPage.sss';

const PetPageContainer = ({ id }) => {
    const pet = useSelector(getPetById(id));

    if (!pet) {
        return (
            <div className={cx('empty')}>
                <StatusMessage
                    title={<Dictionary text="com.profile.pets.page.empty" />}
                    view="warning"
                />
            </div>
        );
    }

    return <PetPage id={id} pet={pet} />;
};

PetPageContainer.propTypes = {
    id: string.isRequired,
};

const LoaderPetPageContainer = compose(
    Loadable({
        isError: state =>
            !(
                !state.profile.isError &&
                !state.pets.error &&
                !isErrorLanguages(state)
            ),
        isLoading: state =>
            !state.profile.isInitialized ||
            state.pets.isLoading ||
            getIsPetsLoading(state) ||
            state.promo.is3dsProcessing ||
            isLoadingLanguages(state),
        Loader: <PageLoader transparentBackground />,
        Rebooter: <FailedToLoad transparentBackground />,
    }),
)(PetPageContainer);

export { LoaderPetPageContainer as PetPage };
